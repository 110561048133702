import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {ToastContainer, toast} from 'react-toastify';
import Loader from 'react-loader-spinner';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import queryString from 'query-string';


import AccountService from '../../../../../services/account.service';
import AutomatedTestService from '../../../../../services/automatedTest.service';

import DashboardAccountsConnect from '../../../accounts/connect/connect.js';


import './create.scss';


class DashboardSpamTestAutomatedCreate extends React.Component {
	constructor(props) {
		super(props);
    this.state = {loading: true, search: false, automatedTest: {accounts: [], account_ids: []}, automatedTestId: {}};
    this.createAutomatedTest = this.createAutomatedTest.bind(this);
    this.onChange = this.onChange.bind(this);
		this.spamcheckerAddAccountSuccess = this.spamcheckerAddAccountSuccess.bind(this);
		this.searchAccount = this.searchAccount.bind(this);
		this.linkAccountToTest = this.linkAccountToTest.bind(this);
	}

	componentWillMount() {
    this.setState({automatedTestId: this.props.match.params.automated_test_id});

		if(this.props.match.params.automated_test_id){
			AutomatedTestService.get(this.props.match.params.automated_test_id).then((response) => {
				if(response.body.accounts[0]){
					response.body.account_ids = [response.body.accounts[0].id];
				}
				this.setState({automatedTest: response.body});
			})
			.catch((error) => {
				toast.error('Oops. Check email and password.');
			});
		}

    AccountService.all({spamchecker: true}, this.state.index).then((response) => {
      this.setState({accounts: response.body, loading: false})
    })
    .catch((error) => {
      toast.error('Oops, we have an issue, try again');
    });
	}

  onChange(event) {
		const field = event.target.name;
		const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		let automatedTest = this.state.automatedTest;
		console.log(value);
		automatedTest.account_ids = [parseInt(value)]
		return this.setState({automatedTest});
	}

	linkAccountToTest(e, accountId){
		e.preventDefault();
		let automatedTest = this.state.automatedTest;
		automatedTest.account_ids = [accountId]
		return this.setState({automatedTest});
	}

	searchAccount(e){
		this.setState({currentSearch: e.target.value});

		if(this.timeout) clearTimeout(this.timeout);
		this.timeout = setTimeout((e) => {
			AccountService.allSpamcheckerAccounts(this.state.currentSearch, 'Account_email_asc').then((response) => {
				this.setState({accounts: response.body, loading: false, search: true})
			})
			.catch((error) => {
				toast.error('Oops, we have an issue, try again');
			});
		}, 400);
	}


	spamcheckerAddAccountSuccess(body){
		let automatedTest = this.state.automatedTest;
		automatedTest.account_ids = [body.id];
		this.setState({automatedTest});
		toast.success('Email account successfully connected 🙌');
		this.createAutomatedTest();
	}

  createAutomatedTest(){
    event.preventDefault();

		if(this.state.automatedTestId){
			let automatedTest = this.state.automatedTest;
			automatedTest.account_id = automatedTest.account_ids[0];
			// Edit a given automated test
			AutomatedTestService.update(automatedTest).then((response) => {
				toast.success('User updated');
				this.props.history.push(`/dashboard/${this.props.currentProject.id}/spam-tests/auto/${response.body.id}/template`);
			})
			.catch((error) => {
				this.setState({error: true, signupForm: false, loading: false});
				toast.error('Oops. We have an issue');
			});

		}else{
			// Create a new automated test
			AutomatedTestService.create(this.state.automatedTest.account_ids[0]).then((response) => {
				this.props.history.push(`/dashboard/${this.props.currentProject.id}/spam-tests/auto/${response.body.id}/template`);
			})
			.catch((error) => {
				this.setState({error: true, signupForm: false, loading: false});
				toast.error('Oops. We have an issue');
			});
		}
  }




	render() {
		if(this.state.loading){
			return(
				<div className="app container text-center">
					<div className="loader">
						<Loader
						 type="Oval"
						 color="#b7b7b7"
						 height="100"
						 width="100"
						/>
					</div>
				</div>
			)
		}

		return(
			<>
        <div className="app container home automated-test-create">

          <div className="progress-automated-test">
            <ul>
              <li className="selected"></li>
              <li></li>
              <li></li>
              <li></li>
							<li></li>
            </ul>
            <div className="bar"></div>
          </div>



					<DashboardAccountsConnect location={this.props.location} match={this.props.match} spamtestConnect={true} spamcheckerAddAccountSuccess={this.spamcheckerAddAccountSuccess}/>

          <h1 className="auto-connect-title">Or select an existing account</h1>

					<div className={((this.state.accounts.length > 20) || this.state.search) ? '' : "card"}>
						<h4>Choose your email</h4>
	          <div className="fieldCont col-md-8 no-padding-left">
						{((this.state.accounts.length > 20) || this.state.search) &&
							<>
								{this.state.selectedAccountEmail &&
									<div className="selected-account-email" onClick={(e) => {this.linkAccountToTest(e, null); this.setState({selectedAccountEmail: null})}}>
										{this.state.selectedAccountEmail} <i class="fa-solid fa-xmark" onClick={(e) => {this.linkAccountToTest(e, null); this.setState({selectedAccountEmail: null})}}></i>
									</div>
								}

								{!this.state.selectedAccountEmail &&
									<form onSubmit={(e) => {e.preventDefault();}}>
										<div className={"search no-padding-left"}>
											<i class="fas fa-search"></i>
											<input onChange={this.searchAccount} value={this.state.currentSearch} placeholder="Search an account by email or domain" />
										</div>

										<ul className="accounts-selector-list">
											{this.state.accounts.map((account, index) => {
												return (
													<li className={this.state.automatedTest.account_ids.includes(account.id) ? 'selected' : 'lsda'} onClick={(e) => {this.linkAccountToTest(e, account.id); this.setState({selectedAccountEmail: account.email})}}>
														{account.email}
														{(account.days_delta < 15 && account.warming_enabled) &&
															<Tooltip title='This sender has been warmed for less than 14 days. You can still select it, but to protect your reputation, MailReach will trigger the first spam test once 14 days of warming are completed.' placement="bottom">
																<div className="tlps">👶</div>
															</Tooltip>
														}
														<div className="clearfix"></div>
													</li>
												)})}
										</ul>
									</form>
								}
							</>
							}

						{((this.state.accounts.length < 21) && !this.state.search) &&
							<form>
		            <select onChange={this.onChange} value={this.state.automatedTest.account_ids[0] ? this.state.automatedTest.account_ids[0] : null} name="channel">
		            <option value="">Select an account</option>
		            {this.state.accounts.map((account, index) => {
		              return (
		                <option value={account.id}>
											{account.email}
											{(account.days_delta < 15 && account.warming_enabled) ? ' 👶' : ''}
										</option>
		              )})}
		            </select>
								</form>
							}
	          </div>
						<div className="clearfix"></div>
					</div>

					<div className="text-center automated-spamtest-btns">
						<NavLink to={`/dashboard/${this.props.currentProject.id}/spam-tests/autos/lists`} className="btn btn-primary grey">
							Cancel
						</NavLink>
          	<button className="btn btn-primary purple" onClick={this.createAutomatedTest}>Next ></button>
					</div>
        </div>

      </>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		currentProject: state.currentProject,
		currentPricing: state.currentPricing
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardSpamTestAutomatedCreate));
