import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import { Route, NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';

import Sidebar from '../../../components/sidebar/sidebar.js';
//import * as organization from '../../../../actions/organizations.actions';

import DashboardWelcome from '../welcome/welcome.js';
import DashboardHome from '../home/home.js';
import DashboardGettingStartedCreate from '../getting-started/create/create.js';
// Accounts
import DashboardAccountsBase from '../accounts/show/base.js';
import DashboardAccountsShow from '../accounts/show/show.js';
import DashboardAccountsSettings from '../accounts/show/settings/settings.js';
import DashboardAccountsCheckups from '../accounts/show/checkups/checkups.js';
import DashboardAccountsConnect from '../accounts/connect/connect.js';
import DashboardAccountsConnectV2 from '../accounts/connect/v2/v2.js';
import DashboardWorkspaceConnectV2 from '../accounts/connect/v2/workspace/workspace.js';
import DashboardWorkspaceAddAccountsConnectV2 from '../accounts/connect/v2/workspace/addAccounts.js';
// Templates
import DashboardTemplatesBase from '../spamchecker/show/base.js';
import DashboardTemplatesHome from '../spamchecker/home/home.js';
import DashboardTemplatesList from '../spamchecker/list/list.js';
import DashboardTemplatesSettings from '../spamchecker/settings/settings.js';
import DashboardTemplatesTestResults from '../spamchecker/results/results.js';
import DashboardSpamTestResults from '../spamchecker/results/results-new.js';
// AutomatedTest
import DashboardSpamTestAutomatedCreate from '../spamchecker/automated/create/create.js';
import DashboardSpamTestAutomatedTemplate from '../spamchecker/automated/template/template.js';
import DashboardSpamTestAutomatedFrequency from '../spamchecker/automated/frequency/frequency.js';
import DashboardSpamTestAutomatedAlerts from '../spamchecker/automated/alerts/alerts.js';
import DashboardSpamTestAutomatedTarget from '../spamchecker/automated/target/target.js';
import DashboardSpamTestListAccounts from '../spamchecker/automated/accounts/accounts.js';
import DashboardSpamTestReconnectAccount from '../spamchecker/automated/accounts/reconnect.js';
import DashboardAutomatedTestsList from '../spamchecker/automated/lists/list.js';

// Apps management
import DashboardSettingsBilling from '../settings/billing.js';
import DashboardSettingsSpamchecker from '../settings/spamchecker.js';
import DashboardSettingsBillingEmailvalidation from '../settings/emailvalidation.js';
import DashboardSettingsApi from '../settings/api.js';
import DashboardSettingsTeam from '../settings/team.js';
import DashboardSettingsAlerts from '../settings/alerts.js';
import DashboardSettingsChangePassword from '../settings/changePassword.js';
import DashboardSettingsOthers from '../settings/others.js';
import DashboardSettingsCheckoutv2 from '../settings/checkoutv2/checkout.js';
import DashboardSettingsCheckoutv2Pay from '../settings/checkoutv2/pay.js';

// Emailvalidation
import DashboardEmailvalidationHome from '../emailvalidation/emailvalidation.js';

// Cockpit
import CockpitHome from '../cockpit/cockpit.js';

import * as projectsActions from '../../../actions/projects.actions';

import i18n from "../../../i18n";
import './base.css';

class DashboardBase extends React.Component {
	constructor(props) {
		super(props);
		this.state = {errors: [], organization: {}};
	}

	componentWillMount() {
		if (!localStorage.jwt) {
			this.props.history.push("/signin");
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps == undefined) {
			return false;
		}
	}

	render() {
		return (
			<div className="app">
				<Sidebar />
				<div className="app side">
					<Route path="/dashboard/getting-started/create" component={DashboardGettingStartedCreate}/>
					<Route path="/dashboard/:project_id/welcome" component={DashboardWelcome}/>
					<Route path="/dashboard/:project_id/home" component={DashboardHome}/>
					<Route path="/dashboard/:project_id/accounts/:account_id" component={DashboardAccountsBase}/>
					<Route path="/dashboard/:project_id/accounts/:account_id/show" component={DashboardAccountsShow}/>
					<Route path="/dashboard/:project_id/accounts/:account_id/settings" component={DashboardAccountsSettings}/>
					<Route path="/dashboard/:project_id/accounts/:account_id/checkups" component={DashboardAccountsCheckups}/>
					<Route path="/dashboard/:project_id/spam-tests/home" component={DashboardTemplatesHome}/>
					<Route path="/dashboard/:project_id/spam-test/:template_id" component={DashboardTemplatesBase}/>
					<Route path="/dashboard/:project_id/spam-test/:template_id/list" component={DashboardTemplatesList}/>
					<Route path="/dashboard/:project_id/spam-test/:template_id/settings" component={DashboardTemplatesSettings}/>
					<Route path="/dashboard/:project_id/spam-test/:template_id/results/:test_id" component={DashboardTemplatesTestResults}/>
					<Route path="/dashboard/:project_id/spam-tests/results/:test_id" component={DashboardSpamTestResults}/>
					<Route path="/dashboard/:project_id/spam-tests/auto/create" component={DashboardSpamTestAutomatedCreate}/>
					<Route path="/dashboard/:project_id/spam-tests/auto/:automated_test_id/accounts" component={DashboardSpamTestAutomatedCreate}/>
					<Route path="/dashboard/:project_id/spam-tests/auto/:automated_test_id/template" component={DashboardSpamTestAutomatedTemplate}/>
					<Route path="/dashboard/:project_id/spam-tests/auto/:automated_test_id/frequency" component={DashboardSpamTestAutomatedFrequency}/>
					<Route path="/dashboard/:project_id/spam-tests/auto/:automated_test_id/alerts" component={DashboardSpamTestAutomatedAlerts}/>
					<Route path="/dashboard/:project_id/spam-tests/auto/:automated_test_id/target" component={DashboardSpamTestAutomatedTarget}/>
					<Route path="/dashboard/:project_id/spam-tests/accounts" component={DashboardSpamTestListAccounts}/>
					<Route path="/dashboard/:project_id/spam-tests/account/:account_id/reconnect" component={DashboardSpamTestReconnectAccount}/>
					<Route path="/dashboard/:project_id/spam-tests/autos" component={DashboardAutomatedTestsList}/>
					<Route path="/dashboard/:project_id/account/connect" component={DashboardAccountsConnect}/>
					<Route path="/dashboard/:project_id/account/connectv2" component={DashboardAccountsConnectV2}/>
					<Route path="/dashboard/:project_id/account/workspaces/create" component={DashboardWorkspaceConnectV2}/>
					<Route path="/dashboard/:project_id/account/workspaces/:workspace_id/edit" component={DashboardWorkspaceConnectV2}/>
					<Route path="/dashboard/:project_id/account/workspaces/:workspace_id/connect" component={DashboardWorkspaceAddAccountsConnectV2}/>
					<Route path="/dashboard/:project_id/settings/billing" component={DashboardSettingsBilling}/>
					<Route path="/dashboard/:project_id/settings/checkout/plans" component={DashboardSettingsCheckoutv2}/>
					<Route path="/dashboard/:project_id/settings/checkout/pay" component={DashboardSettingsCheckoutv2Pay}/>
					<Route path="/dashboard/:project_id/settings/spamchecker" component={DashboardSettingsSpamchecker}/>
					<Route path="/dashboard/:project_id/settings/emailvalidation" component={DashboardSettingsBillingEmailvalidation}/>
					<Route path="/dashboard/:project_id/settings/api" component={DashboardSettingsApi}/>
					<Route path="/dashboard/:project_id/settings/team" component={DashboardSettingsTeam}/>
					<Route path="/dashboard/:project_id/settings/alerts" component={DashboardSettingsAlerts}/>
					<Route path="/dashboard/:project_id/settings/password" component={DashboardSettingsChangePassword}/>
					<Route path="/dashboard/:project_id/settings/others" component={DashboardSettingsOthers}/>
					<Route path="/dashboard/:project_id/overview" component={CockpitHome}/>
					<Route path="/dashboard/:project_id/emailvalidation" component={DashboardEmailvalidationHome}/>
				</div>
				<ToastContainer />

				<div className="container">
					{!window.location.search.includes('hideNvgt') &&
						<footer>
							<NavLink to={`/redirect`}>
								<img src="/images/logo_b.png" />
							</NavLink>

							{!(window.location.pathname.includes('settings/billing')) &&
								<ul className="pull-right">
									<li>
										<a href="https://help.mailreach.co/" target="_blank">Helpdesk</a>
									</li>
									<li>
										<a href="https://www.mailreach.co/blog/why-its-important-to-keep-warming-up-an-email-account/" target="_blank">Why to Always Be Warming</a>
									</li>
									<li>
										<a href="https://www.mailreach.co/blog/how-to-prevent-emails-from-going-to-spam-in-2021-the-most-in-depth-guide/" target="_blank">Your Ultimate Deliverability Guide</a>
									</li>
								</ul>
							}
						</footer>
					}

				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		projects: state.projects
	};
}

function mapDispatchToProps(dispatch) {
	return {
		projectsActions: bindActionCreators(projectsActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardBase));
