import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {ToastContainer, toast} from 'react-toastify';
import Loader from 'react-loader-spinner';
import Tooltip from '@material-ui/core/Tooltip';

import i18n from "../../../../i18n";
import TemplateService from '../../../../services/template.service';


class DashboardTemplatesBase extends React.Component {
	constructor(props) {
		super(props);
    this.state = {errors: [], currentTemplate: {}}
	}

	componentWillMount() {
    TemplateService.get(this.props.match.params.template_id).then(response => {
      this.setState({currentTemplate: response.body});
    }).catch((error) => {
     this.setState({error: true,});
     toast.error('Oops, we have an error');
   });
	}


	componentWillReceiveProps(nextProps) {
	}


	render() {
		if(window.location.href.includes("results")){
			return null;
		}
		return (
			<div className="sub-nav">
        <div className="ct">
          <div className="account-name pull-left">
					  <div className="ar-nav">
							<NavLink to={`/dashboard/${this.props.currentProject.id}/spam-tests/home`}>
								Spam Tests
							</NavLink>
							> {this.state.currentTemplate.name}
						</div>
          </div>
          <ul className="pull-right">
            <li>
              <NavLink to={`/dashboard/${this.props.match.params.project_id}/spam-test/${this.props.match.params.template_id}/list`} className="" activeClassName="active">
                <i class="fas fa-code-branch"></i> Versions
              </NavLink>
              <NavLink to={`/dashboard/${this.props.match.params.project_id}/spam-test/${this.props.match.params.template_id}/settings`} className="" activeClassName="active">
                <i class="fas fa-cog"></i> Settings
              </NavLink>
            </li>
          </ul>
          <div className="clearfix"></div>
        </div>
      </div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		currentProject: state.currentProject,
		currentPricing: state.currentPricing
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardTemplatesBase));
