import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import { Button, Modal } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';

import InappMessageService from '../../services/inappMessage.service';


class SpamcheckerNextSteps extends React.Component {

	constructor(props) {
		super(props);
		this.state = {error: false, inapp_messages: []};
    this.loadNextSteps = this.loadNextSteps.bind(this);
		this.handleCloseModal = this.handleCloseModal.bind(this);
	}

  componentWillMount() {
    this.loadNextSteps(this.props);
  }

	componentWillReceiveProps(nextProps) {
    this.loadNextSteps(nextProps);
	}

	handleCloseModal(){
		this.setState({showNextStepModal: null})
	}

  loadNextSteps(data){
    if(data.currentTest.analysis_results){
			if(data.currentTest.analysis_results.nextsteps){

	      InappMessageService.loadNextSteps(data.currentTest.analysis_results.nextsteps.join(',')).then((response) => {
	        this.setState({inapp_messages: response.body});

					console.log(response.body);
	      })
	      .catch((error) => {
	        this.setState({error: true, loading: false});
	        //toast.error('Oops, we have an error');
	      });
			}
    }
  }

	render() {
		return (
      <div>
				{this.state.inapp_messages.map((inapp_message, index) => {
							return (
								<div className="card col-md-12">
									<p className="delete pull-left no-margin-bottom">
										<div className="nextstep-number">
											{index + 1}
										</div>

										{inapp_message.title}
									</p>
									<button type="submit" className="btn btn-secondary pull-right" onClick={() => this.setState({showNextStepModal: inapp_message})}>Details</button>
								</div>
							)
				})}

				<Modal className="reputation-modal blacklists-modal links-analysis-modal" show={this.state.showNextStepModal} onHide={this.handleCloseModal}>
					<Modal.Body>
							{this.state.showNextStepModal &&
								<>
									<h1 className="text-center">{this.state.showNextStepModal.title}</h1>

									<div className="txt">
									<div
			dangerouslySetInnerHTML={{__html: this.state.showNextStepModal.content}}
			/>
									</div>

								</>
							}
						<div className="footer text-center">
							<Button variant="secondary" onClick={this.handleCloseModal}>
								Close
							</Button>
						</div>
					</Modal.Body>
				</Modal>
      </div>
		)
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SpamcheckerNextSteps));
