const request = require('superagent');

class UploaderService {

	static getSignedUrl(file) {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}uploader?filename=${file.name}&fileType=${file.type}`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.send()
	}

	static put(url, file, options) {
		return request
						.put(`${url}`)
						.set('Content-Type', options.headers['Content-Type'])
  					.set('Content-Disposition', 'inline')
						.set(options)
						.send(file)
	}
}

export default UploaderService;
