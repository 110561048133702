import React, { Suspense } from 'react';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import {ToastContainer, toast} from 'react-toastify';
import SessionService from '../../services/session.service';
import 'react-toastify/dist/ReactToastify.css';

import i18n from "../../i18n";

class ResetPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {credentials: {email: '', password: ''}, passwordShort: false, errors: [], isInvite: false, organization: {}, currentToken: null };
		this.onChange = this.onChange.bind(this);
		this.onSave = this.onSave.bind(this);
	}

	onChange(event) {
		const field = event.target.name;
		if(field === 'password'){
			if(event.target.value.length < 6){
				this.setState({passwordShort: true});
			}else{
				this.setState({passwordShort: false});
			}
		}
		const credentials = this.state.credentials;
		credentials[field] = event.target.value;
		return this.setState({credentials});
	}

	onSave(event) {
		event.preventDefault();
		SessionService.resetPassword(this.state.credentials).then((response) => {
			toast.success('Password reset. You can now login with your new password');
			this.props.history.push('/signin');
		})
  	.catch((error) => {
			this.setState({error: true, signupForm: false, loading: false});
			toast.error('Your link seem to be expired. You can request a new one.');
		});
	}

	componentWillMount(){
		if(localStorage.getItem('jwt')){
			this.props.history.push('/redirect');
		}
		let queryParams = queryString.parse(this.props.location.search);
		let c = this;
    this.setState({credentials: {reset_password_token: queryParams.reset_password_token}})
		// Get invite data if we have it on query params
	}

	componentWillReceiveProps(nextProps) {
		const errors = nextProps.errors;
		if (errors && errors != this.props.errors) {
			toast.error('Oops, we have an error');
		}
	}

	render() {
		return (
			<div className="container rows">
				<div className="sign signup col-md-6 col-md-offset-3">
					<div className="market-container">
						<h1>Reset password.</h1>
					</div>
							{this.state.isInvite &&
								<div className="invite-b text-center">
									<img src={this.state.organization.logoReadable} />
									<p>{i18n.t('signup.invited')} <b>{this.state.organization.name}</b> {i18n.t('signup.on')}</p>
								</div>
							}
							{/*<form id="google_sign_in" action="/auth/google/sign_in" accept-charset="UTF-8" method="post">
								<input name="utf8" type="hidden" value="✓" />
							  <button type="submit">
							    Your Google account
							  </button>
							</form>*/}
							<form class="new_user" id="new_user">
							  {/*<div class="authSeparator">
									<hr />
							    <span>OR</span>
							  </div>*/}

							  <fieldset>
                  <div class="fieldCont">
    								<input value={this.state.credentials.password} autoComplete="off" required="required" type="password" name="password" id="user_password" onChange={this.onChange}/>
    								<label class="over">{i18n.t('input.password')}</label>
    							</div>

    					    <div class="fieldCont last">
    					      <input value={this.state.credentials.password_confirmation} autoComplete="off" required="required" type="password" name="password_confirmation" id="user_password" onChange={this.onChange}/>
    					      <label class="over">{i18n.t('input.passwordConfirmation')}</label>
    					    </div>
							  </fieldset>

							  <div class="formActions">
							    <div class="buttonCont">
							      <input class="submit black-btn" type="submit" name="commit" value={i18n.t('password.reset')} onClick={this.onSave}/>
							    </div>
							  </div>
							</form>
							<div class="otherLinks">
								<Link to="/signup">{i18n.t('signup.button')}</Link>
							  {/*
									<a href="/amnesia">Forgot your password?</a>
								*/}
							</div>
							<ToastContainer />
						</div>
					</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ResetPassword));
