const request = require('superagent');

class ConfigService {

  static timezones() {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}config/timezones`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }
}

export default ConfigService ;
