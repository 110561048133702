import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {ToastContainer, toast} from 'react-toastify';
import Loader from 'react-loader-spinner';
import Tooltip from '@material-ui/core/Tooltip';
import Moment from 'react-moment';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import {CreatedInDayAgo} from "../../../../helpers/time"

import i18n from "../../../../i18n";

import ConversationTestService from '../../../../services/conversationTest.service';
import ConversationService from '../../../../services/conversation.service';
import TemplateService from '../../../../services/template.service';
import AccountService from '../../../../services/account.service';
import InappMessageService from '../../../../services/inappMessage.service';

import SpamCheckerService from '../../../../services/spamChecker.service';

import WordsAnalyzer from '../../../../components/words-analyzer/words-analyzer.js';
import CheckupDomain from '../../../../components/checkup-domain/checkup-domain.js';


const testTypes = {
	btob: 'Professional',
	btoc: 'Personal',
	all: 'All'
};

const getTypeIco = {
		btob: '👔 Professional inboxes',
		btoc: '🙎 Personal inboxes',
		all: '‍🌎 All types of inboxes'
	}


class DashboardTemplatesTestResults extends React.Component {
	constructor(props) {
		super(props);
    this.state = {errors: [], spamLogs: [], template: {}, loading: true, currentPage: 'test', errors: {setup: 0}, currentTest:{account: {}}, currentAccount: {}, test_type: 'all'};
		this.reloadTests = this.reloadTests.bind(this);
		this.loadInsight = this.loadInsight.bind(this);
	}

	componentWillMount() {
    this.setState({testId: this.props.match.params.test_id});
    if(this.props.match.params.test_id){
			if(!this.props.currentProject.config_feature_new_spamchecker_enabled){
				ConversationTestService.get(this.props.match.params.test_id).then((response) => {
					if(!response.body.account){
						this.setState({loading: false, no_account: true});
					}else {
						this.setState({currentTest: response.body, currentAccount: response.body.account})
						this.filterAccounts(response.body.test_type);
						if(!response.body.completed){
							// Reload test every 8s if not completed
							setInterval(
								() => this.reloadTests(),
								8000
							);
						}
						AccountService.get(response.body.account.id).then((response) => {

							if(response.body == null){
								// Do nothing
							}else{
								this.setState({
									currentAccount: response.body,
								});

								this.calculeNumberSetupAlert();
							}
						})
		        ConversationService.get(response.body.conversation_id).then((response) => {
		          this.setState({conversation: response.body})
							TemplateService.get(response.body.test_folder_id).then((response) => {
								this.setState({template: response.body, loading: false});
							})
		        })
					}
				})
				.catch((error) => {
					toast.error('Oops, we have an issue, try again');
				});

				this.reloadTests()
			}else{
				SpamCheckerService.get(this.props.match.params.test_id).then((response) => {
					this.setState({currentTest: response.body});
					this.filterAccounts(response.body.test_type);
					if(!response.body.completed){
						// Reload test every 8s if not completed
						setInterval(
							() => this.reloadTests(),
							8000
						);
					}
					this.setState({loading: false});

				})
				.catch((error) => {
					console.error(error);
					this.setState({error: true});
					toast.error('Oops, we have an error');
				});
				console.log('New spamtest called');
			}
    }
	}

	reloadTests(){
		this.setState({reloadTests: true});

		ConversationTestService.get(this.props.match.params.test_id).then((response) => {
			this.setState({currentTest: response.body});
			this.loadInsight();

			ConversationTestService.logs(this.props.match.params.test_id).then((response) => {
				this.setState({spamLogs: response.body.sort(function(a, b){
									if(a.to_account.provider < b.to_account.provider) { return -1; }
									if(a.to_account.provider > b.to_account.provider) { return 1; }
									return 0;
							}), reloadTests: false});
				this.filterAccounts(this.state.currentTest.test_type);
			})
			.catch((error) => {
				toast.error('Oops, we have an issue, try again');
			});
		})
		.catch((error) => {
			toast.error('Oops, we have an issue, try again');
		});
	}

	//
	calculeNumberSetupAlert(){

		let errorNb = 0;

		if(this.state.currentAccount.tld){
			if(this.state.currentAccount.tld.name != 'com' && this.state.currentAccount.tld.badness_index >= 5.3){
				errorNb = errorNb + 1;
			}
		}

		if(!this.state.currentAccount.domain.spf_value && CreatedInDayAgo(this.state.currentAccount.created_at) > 1){
			errorNb = errorNb + 1;
		}
		if(!this.state.currentAccount.domain.dkim_value && CreatedInDayAgo(this.state.currentAccount.created_at) > 1){
			errorNb = errorNb + 1;
		}

		this.setState({error: {setup: errorNb}})
	}

	loadInsight(){
		if(!this.state.currentTest.account){
			return;
		}
		let key = 'spamtest_good_deli_score_good_test';
		if(this.state.currentTest.account.score >= 80 && this.state.currentTest.account.score > 0 && this.state.currentTest.final_score <= 5){
			key = 'spamtest_good_deli_score_shit_test';
		}else if (this.state.currentTest.account.score >= 80 && this.state.currentTest.final_score >= 5 && this.state.currentTest.final_score <= 7.5){
			key = 'spamtest_good_deli_score_average_test';
		}else if (this.state.currentTest.account.score <= 80 && this.state.currentTest.final_score >= 7.5){
			key = 'spamtest_good_deli_score_good_test';
		}else if (this.state.currentTest.account.score <= 80 && this.state.currentTest.final_score >= 5 && this.state.currentTest.final_score <= 7.5){
			key = 'spamtest_shit_deli_score_average_test';
		}else if (this.state.currentTest.account.score <= 80 && this.state.currentTest.final_score >= 7.5){
			key = 'spamtest_shit_deli_score_good_test';
		}else if (this.state.currentTest.account.score <= 80 && this.state.currentTest.final_score <= 5){
			key = 'spamtest_shit_deli_score_shit_test';
		}else if (this.state.currentTest.final_score == 10){
			key = 'spamtest_perfect_test';
		}

		InappMessageService.get(key).then((response) => {
			if(response.body){
				this.setState({insightMessage: response.body.content});
			}
		})
		.catch((error) => {
			toast.error('Oops, we have an issue, try again');
		});
	}

	getScoreClass(score){
		if(score < 5){
			return 'bad';
		} else if (score < 7.5) {
			return 'average'
		}else {
			return 'good'
		}
	}

	filterAccounts(type){
		let freeMails = ['gmail.com', 'outlook.com', 'outlook.fr', 'hotmail.com', 'yahoo.com'];
		let spamLogs = this.state.spamLogs;
		if(type == 'btob'){
			spamLogs.map(spamLog => spamLog.hidden = freeMails.includes(spamLog.to_account.email.split('@')[1]));
		}
		if(type == 'btoc'){
			spamLogs.map(spamLog => spamLog.hidden = !freeMails.includes(spamLog.to_account.email.split('@')[1]));
		}
		if(type == 'all'){
			spamLogs.map(spamLog => spamLog.hidden = false);
		}
		this.setState({spamLogs, spamTestInboxFilter: type});
	}

	setTestType(type){
		// Set test type
		ConversationTestService.setType(this.props.match.params.test_id, type).then((response) => {
			// Reload test and show restults
			this.reloadTests();
			toast.success('Test type saved !');
		})
		.catch((error) => {
			toast.error('Oops, we have an issue, try again');
		});
	}


	render() {
		if(this.state.loading){
			return(
				<div className="app container text-center">
					<div className="loader">
						<Loader
						 type="Oval"
						 color="#b7b7b7"
						 height="100"
						 width="100"
						/>
					</div>
				</div>
			)
		}

		if(this.state.no_account){
			return(
				<div className="app container text-center">
					<div className="alert alert-danger">
						As this sender is not connected to MailReach anymore, we can't show you the result, sorry.
					</div>
				</div>
			)
		}

		return (
      <>
      <div className="sub-nav">
        <div className="ct">
          <div className="account-name pull-left">
					  <div className="ar-nav">
							<NavLink to={`/dashboard/${this.props.currentProject.id}/spam-tests/home`}>
								Spam Tests
							</NavLink>
		            > Results
						</div>
          </div>
          <ul className="pull-right">
            <li>
              <a onClick={() => this.setState({currentPage: 'test'})} className={this.state.currentPage == 'test' ? 'active' : ''}><i class="fas fa-envelope-open-text"></i> Deliverability</a>
              <a onClick={() => this.setState({currentPage: 'content'})} className={this.state.currentPage == 'content' ? 'active' : ''}>
								<i class="fas fa-spell-check"></i>
								Content Analysis
								{this.state.conversation.first_message_content.analysis_results.error_count > 0 &&
									<span className="nav-error-count">
									  {this.state.conversation.first_message_content.analysis_results.error_count}
									</span>
								}
							</a>
							<a onClick={() => this.setState({currentPage: 'technical'})} className={this.state.currentPage == 'technical' ? 'active' : ''}>
								<i class="fas fa-stethoscope"></i>
								Setup Analysis
								{this.state.error &&
									<>
										{this.state.error.setup > 0 &&
											<span className="nav-error-count">
												{this.state.error.setup}
											</span>
										}
									</>
								}
							</a>
            </li>
          </ul>
          <div className="clearfix"></div>
        </div>
      </div>

			<div className="app container home spamtest-results spam-results-modal template-home">

			{(!this.state.currentTest.test_type) &&
				<div className="requested-type-selector">
				  <p>To see your score, select the type of inbox you target</p>

					<button className="selector blue" onClick={() => this.setTestType('btob')}>👔 Professional inboxes</button>
					<button className="selector yellow" onClick={() => this.setTestType('btoc')}>🙎 Personal inboxes</button>
					<button className="selector red" onClick={() => this.setTestType('all')}>‍🌎 All types of inboxes</button>
				<div className="clearfix"></div>

					<div>
						<p>
						  Spam filters work differently between professional and personal addresses. Choose the type of inboxes used by the recipients of your real email campaigns.

							<div className="help-bubble-dropdown">
								<Tooltip title="It's not relevant to check your deliverability towards personal addresses if you're targeting professionals and vice versa.
								Google and Microsoft understand your emails and may consider suspicious the fact that you're speaking business to a personal inbox which is not supposed to happen. Result ? They put you in spam.
								Conclusion : your true deliverability has to be checked with the inbox type used of your real recipients." placement="bottom">
									<div>
										<i class="fas fa-info-circle"></i>
									</div>
								</Tooltip>
							</div>
						</p>
					</div>
				</div>
			}

				<div className="row accounts templates col-md-12">
					{this.state.currentPage == 'test' &&
					  <>
						  {this.state.currentTest.completed &&
								<h2>
								  Result
								</h2>
							}
							{!this.state.currentTest.completed &&
								<h2>
									Testing
								</h2>
							 }
						</>
					}

					{(this.state.currentPage == 'test' && this.state.currentTest.test_type) &&
						<Dropdown className="dropdown-date-filter last-days-filter">
							<Dropdown.Toggle className={"btn capitalize text-type-badge " + this.state.currentTest.test_type} id="dropdown-basic">
		            <div className="pull-left help-bubble-dropdown">
									<Tooltip title="It's not relevant to check your deliverability towards personal addresses if you're targeting professionals and vice versa. Google and Microsoft understand your emails and may consider suspicious the fact that you're speaking business to a personal inbox which is not supposed to happen. Result ? They put you in spam. Conclusion : your true deliverability has to be checked with the inbox type used of your real recipients." placement="bottom">
										<div>
											<i class="fas fa-info-circle"></i>
										</div>
									</Tooltip>
								</div>

								{getTypeIco[this.state.spamTestInboxFilter ? this.state.spamTestInboxFilter : 'all']}

								<i class="fas fa-angle-down" aria-hidden="true"></i>
							</Dropdown.Toggle>

							<Dropdown.Menu className="test-type-dropdown">
								<Dropdown.Item onClick={() => this.setTestType('all')}>‍🌎 All types of inboxes</Dropdown.Item>
								<Dropdown.Item onClick={() => this.setTestType('btob')}>👔 Professional inboxes</Dropdown.Item>
								<Dropdown.Item onClick={() => this.setTestType('btoc')}>🙎 Personal inboxes</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					}

					{this.state.currentPage == 'content' &&
						<h2>Content Analysis</h2>
					}

					{this.state.currentPage == 'technical' &&
						<h2>Technical Analysis</h2>
					}

					{(!this.state.currentTest.completed && this.state.currentPage == 'test') &&
						<Tooltip title='We wait up to 6 minutes for the mailboxes to receive your email before displaying the score 🔍.' placement="bottom">
						  <div className="score running pulse">
							  Running...
						  </div>
						</Tooltip>
					 }

					<div className="pull-right">
						<button type="submit" disabled={this.state.loading} className="btn btn-header btn-secondary pull-right" onClick={() => this.setState({openShowTemplateModal: true})}>See my email</button>
						<NavLink to={`/dashboard/${this.props.match.params.project_id}/accounts/${this.state.currentTest.account.id}/show`} className="btn btn-header btn-secondary pull-right margin-right" activeClassName="active">
							{this.state.currentTest.account.email} dashboard
						</NavLink>
					</div>

					<div className="clearfix"></div>
          <div className={'txt text-center ' + (this.state.spamTestInboxFilter ? '' : 'blured')}>
					{this.state.currentPage == 'test' &&
						<>
						{this.state.currentTest.completed &&
							<>
								<div className="col-md-3 no-padding-left text-left">
									<div className="card">
									  <div className="title">
										Score

										<div className="help-bubble pull-right">
											<Tooltip title="This score is 100% based on where your emails land as it's the best representation of your test email's deliverability. The formula is : [number of emails landed in inbox] / [number of email sent] x 10." placement="bottom">
												<div>
													<i class="fas fa-info-circle"></i>
												</div>
											</Tooltip>
										</div>
										</div>
										<div className="text-center">
									    <div className={'inbox-rate-nb score ' + this.getScoreClass(this.state.currentTest.final_score)}>{this.state.currentTest.final_score}/10</div>
										</div>
									</div>
								</div>
								<div className="col-md-9 no-padding-right text-left">
									<div className="card">
									  <div className="title">Insight</div>
										  <p className="insight-content">
											<div dangerouslySetInnerHTML={{__html: this.state.insightMessage}} />
											</p>
									</div>
								</div>
							</>
						}
						<div className="clearfix"></div>
						<h2 className="second-title">
							Where it landed
						</h2>
						{this.state.reloadTests &&
							<div className="pull-left spamtests-loading top-margin">
								<Loader
								 type="Oval"
								 color="#b7b7b7"
								 height="25"
								 width="25"
								/>
							</div>
						}
						<div className="clearfix"></div>
						</>
					}
					  {!['technical', 'content'].includes(this.state.currentPage) &&
	            <div className="card">
	              {this.state.currentPage == 'test' &&
	                <>
	                  {(this.state.spamLogs.length == 0 && !this.state.loading) &&
	                    <div className="empty text-center">
	                      <img src="/images/instat_analysis.svg" />
	                      <p>No results at the moment.</p>
	                    </div>
	                  }
	                  <table class="table table-results">
	                    <tbody>
	                    {this.state.spamLogs.map((log, index) => {
												if(log.hidden){
													return;
												}
	                      return (
	                        <tr>
	                          <td>
														  <div>
																{log.to_account.provider == 'custom' &&
																	 <img className="avatar pull-left" src={'/images/custom.svg'} />
																}
																{log.to_account.provider != 'custom' &&
																	 <img className="avatar pull-left" src={'/images/logo_' + log.to_account.provider + '.png'} />
																}
															</div>
	                            <span>{log.to_account.email}</span><br />
	                            <span className="account-desc">{log.to_account.desc}</span>
	                          </td>
	                          <td>
	                            <span className={"providerbadge " + log.to_account.provider}>
															  {log.to_account.provider == 'gmail' &&
																  <>Google</>
																}
																{log.to_account.provider == 'outlook' &&
																  <>Microsoft</>
																}
																{!['gmail','outlook'].includes(log.to_account.provider) &&
																	<>{log.to_account.provider}</>
																}
	                            </span>
	                          </td>
	                          <td className="hour">
	                          <Moment fromNow>{log.created_at}</Moment>
	                          </td>
	                          <td>
	                            {(!log.received_in && !this.state.currentTest.completed) &&
	                              <span className={"badge pending"}>Pending</span>
	                            }
	                            {(!log.received_in && this.state.currentTest.completed) &&
	                              <span className={"badge missing-badge-color"}>Missing</span>
	                            }
	                            {log.received_in &&
	                              <span className={"badge " + log.received_in}>{log.received_in.toLowerCase()}</span>
	                            }
	                          </td>
	                        </tr>
	                      )})}
	                    </tbody>
	                  </table>
	                </>
	              }
	            </div>
						}
						{this.state.currentPage == 'content' &&
							<div className="checkups">
								<WordsAnalyzer title={false} contentSpamwordsResponse={this.state.conversation.first_message_content.analysis_results} />
								<div className="clearfix"></div>
							</div>
						}
						{this.state.currentPage == 'technical' &&
							<div className="checkups">
								<CheckupDomain domain={this.state.currentAccount.domain} currentAccount={this.state.currentAccount} spamTest={true} />
								<div className="clearfix"></div>
							</div>
						}
          </div>
        </div>
      </div>

			<Modal className="spam-results-modal message-design" show={this.state.openShowTemplateModal} onHide={() => this.setState({openShowTemplateModal: false})}>
				<Modal.Body>
					<>
						<h1 className="text-center">{this.state.conversation.subject}</h1>
						{this.state.currentTest.account &&
							<div className='from'>
								<b>{this.state.currentTest.account.first_name} {this.state.currentTest.account.last_name}</b> {'<' + this.state.currentTest.account.email + '>'}
							</div>
						}
						<div className="txt">
						<div
dangerouslySetInnerHTML={{__html: this.state.conversation.first_message_content.content_html}}
/>
						</div>
						<div className="footer text-center">
							<button type="button" className="btn btn-secondary" onClick={() => this.setState({openShowTemplateModal: false})}>Close</button>
						</div>
					</>
				</Modal.Body>
			</Modal>
      </>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		currentProject: state.currentProject,
		currentPricing: state.currentPricing
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardTemplatesTestResults));
