const request = require('superagent');

class ContentSpamwordService {

  static check(content) {
    return request
            .post(`${process.env['REACT_APP_BACKEND_URL']}content_spamwords/check`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
            .send({content: content});
  }
}

export default ContentSpamwordService ;
