const request = require('superagent');

// Todo, rename as test folder
class AlertService {

	static create(notificationChannel) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}alerts`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.send(notificationChannel)
	}

	static get(id) {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}test_folders/${id}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}

	static update(data) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}alerts/${data.id}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.send(data);
	}

	static get(query = {}) {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}alerts`)
						.query(query)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}

	static delete(id) {
		return request
						.delete(`${process.env['REACT_APP_BACKEND_URL']}alerts/${id}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}
}

export default AlertService;
