import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast} from 'react-toastify';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import Loader from 'react-loader-spinner'
import { Dropdown } from 'react-bootstrap';
import queryString from 'query-string';
import { Button, Modal } from 'react-bootstrap';
import Moment from 'react-moment';

import * as projectsActions from '../../../../actions/projects.actions';
import * as currentProjectActions from '../../../../actions/currentProject.actions';
import * as notificationsActions from '../../../../actions/notifications.actions';

import AccountService from '../../../../services/account.service';
import ConversationTestService from '../../../../services/conversationTest.service';
import TemplateService from '../../../../services/template.service';

import TestsList from '../../../../components/tests-list/tests-list.js';
import LaunchSpamtest from '../../../../components/launch-spamtest/launch-spamtest.js';

const GreenSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#00d463',
    },
    '&$checked + $track': {
      backgroundColor: '#00d463',
    },
  },
  checked: {},
  track: {},
})(Switch);

class DashboardTemplatesList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {errors: [], tests: [], template: {}, accounts: []}
		this.reload = this.reload.bind(this);
	}

	componentWillMount() {
		this.props.currentProjectActions.getCurrent();
    // Reload every 30 seconds
    this.reload();
    setInterval(this.reload, 30000);
    AccountService.all().then((response) => {
      this.setState({accounts: response.body, loading: false})
    })
    .catch((error) => {
      toast.error('Oops, we have an issue, try again');
    });
	}


	reload(){
    TemplateService.get(this.props.match.params.template_id).then((response) => {
			this.setState({template: response.body, loading: false})
		})
		.catch((error) => {
			toast.error('Oops, we have an issue, try again');
		});

    ConversationTestService.all(this.props.match.params.template_id).then((response) => {
      this.setState({tests: response.body, loading: false})
    })
    .catch((error) => {
      toast.error('Oops, we have an issue, try again');
    });
	}


	render() {
		if(this.state.loading){
			return(
				<div className="app container text-center">
					<div className="loader">
						<Loader
						 type="Oval"
						 color="#b7b7b7"
						 height="100"
						 width="100"
						/>
					</div>
				</div>
			)
		}
		return (
			<div className="app container home">
        <LaunchSpamtest templateKey={this.state.template.key} accounts={this.state.accounts}/>

        {this.state.tests.length > 0 &&
          <>
    				<div className="title top-margin">
    					<h1 className="pull-left">Tests</h1>
    					<div className="clearfix"></div>
    				</div>
            <div className="templates">
              <TestsList tests={this.state.tests} lastTest={this.state.tests ? this.state.tests[0] : null}/>
            </div>
          </>
        }
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		currentProject: state.currentProject,
		currentUser: state.currentUser,
		currentProject: state.currentProject,
		currentPricing: state.currentPricing,
		notifications: state.notifications
	};
}

function mapDispatchToProps(dispatch) {
	return {
		projectsActions: bindActionCreators(projectsActions, dispatch),
		currentProjectActions: bindActionCreators(currentProjectActions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardTemplatesList));
