const request = require('superagent');

class StripeService {

	static createCustomer(data) {
    return request
            .post(`${process.env['REACT_APP_BACKEND_URL']}stripe/customer`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
            .send(data)
	}

	static request3ds(data) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}stripe/3ds`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.send(data)
	}

	static intent(data) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}stripe/intent`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.send(data)
	}

	static generatePaymentLink(data) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}stripe/link`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.send(data)
	}

	static subscriptions() {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}stripe/subscriptions`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
	}

	static invoices() {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}stripe/invoices`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
	}

	static customer() {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}stripe/customer`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
	}

	static vats() {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}stripe/vats`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
	}

	static cards() {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}stripe/cards`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
	}

	static removeCard(id) {
		return request
						.delete(`${process.env['REACT_APP_BACKEND_URL']}stripe/cards/${id}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
	}

	static setDefaultCard(id) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}stripe/customers/${id}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
	}

	static setVat(vat_id) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}stripe/vat`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.send({vat_id})
	}

	static setCustomer(customer) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}stripe/cus`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.send({customer})
	}

	static taxes() {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}stripe/taxes`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
	}

	static plan(stripePlanId) {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}stripe/plans/${stripePlanId}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
	}

	static preview(planId, country, vat, seats, product = null, period = null, credits = null) {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}stripe/preview/${planId}?country=${country ? country : ''}&vat=${vat ? vat : ''}&product=${product ? product : ''}&period=${period ? period : ''}&credits=${credits ? credits : ''}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
	}

	static previewSeats(seats, country, vat, period, coupon) {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}stripe/preview/seats?seats=${seats}&period=${period}&country=${country ? country : ''}&vat=${vat ? vat : ''}&coupon=${coupon ? coupon : ''}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
	}

	static portalLink(flow) {
		console.log('portal call');
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}stripe/portal?flow=${flow}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
	}

	static previewAll(allSubParams, country, vat, coupon) {
		allSubParams = new URLSearchParams(allSubParams).toString();
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}stripe/preview/all?${allSubParams}&country=${country ? country : ''}&vat=${vat ? vat : ''}&coupon=${coupon ? coupon : ''}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
	}

	static cancel(subscription_type = null) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}stripe/cancel`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.send({subscription_type})
	}
}

export default StripeService;
