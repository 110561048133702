import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import {ToastContainer, toast} from 'react-toastify';
import Loader from 'react-loader-spinner'
import { Button, Modal, Dropdown } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';
import { NavLink } from 'react-router-dom';

import ImapAuthService from '../../../../../services/imapAuth.service';
import AccountService from '../../../../../services/account.service';
import ProjectService from '../../../../../services/project.service';

import i18n from "../../../../../i18n";
import './connectv2.scss';

import * as projectsActions from '../../../../../actions/projects.actions';

class DashboardAccountsConnectV2 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {errors: [], currentAccount: {}, currentProject: {}, credentials: {}, queryString: {}, first_google_workspace_account: true, reconnect: true, connectBot: false, imapError: false, currentStep: 1};
    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
		this.openHelpModal = this.openHelpModal.bind(this);
		this.autoConnectionTry = this.autoConnectionTry.bind(this);
		this.onChangeMailgunServers = this.onChangeMailgunServers.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.resetFlow = this.resetFlow.bind(this);
		this.useOauthAsImap = this.useOauthAsImap.bind(this);
	}

	componentWillMount() {
		let queryParams = queryString.parse(this.props.location.search);
		let error = queryParams.error;
		if(error){
			toast.error(error);
		}

		if(queryParams.success){
			toast.success(queryParams.success);
		}

		if(queryParams.error_type){
			this.setState({modal_error_type: queryParams.error_type, showHelpModal: true});
		}

		if(queryParams.modal_connect_success_invite){
			this.setState({showHelpModal: true});
		}

		this.setState({queryString: queryParams})

		if(!queryParams.invitation_token){
			AccountService.all('', this.state.index).then((response) => {
				if(response.body.length == 0){
					this.setState({first_google_workspace_account: false})
				}
			})
		}

		if(queryParams.invitation_token){

			ProjectService.by_invite_token(queryParams.invitation_token).then((response) => {
				this.setState({currentProject: response.body})
			}).catch((error) => {
				toast.error('Oops, we have an error');
			});
		} else {
			ProjectService.get(this.props.match.params.project_id).then((response) => {
				this.setState({currentProject: response.body})
			}).catch((error) => {
				toast.error('Oops, we have an error');
			});

		}

		if(queryParams.edit_account_id){
			AccountService.get(queryParams.edit_account_id).then((response) => {

				let provider = response.body.provider;
				if (response.body.has_refresh_token && response.body.provider == 'gmail'){
					provider = 'gmail';
				}

				// Allow imap only for on custom provider only
				if(response.body.provider == 'custom'){
					if(queryParams.imap_only){
						this.selectProvider('imap_only');
					}
				}

				let credentials = {
					email: response.body.email,
					first_name: response.body.first_name,
					last_name: response.body.last_name,
					provider: provider
				}
				//if(credentials.provider == 'custom'){
					credentials['imap_server'] = response.body.imap_server;
					credentials['imap_server_username'] = response.body.imap_server_username;
					credentials['imap_server_port'] = response.body.imap_server_port;

					credentials['smtp_server'] = response.body.smtp_server;
					credentials['smtp_server_username'] = response.body.smtp_server_username;
					credentials['smtp_server_port'] = response.body.smtp_server_port;
					credentials['smtp_server_auth_type'] = response.body.smtp_server_auth_type;
					credentials['smtp_server_starttls'] = response.body.smtp_server_starttls;
				//}
				this.setState({
					edit: true,
					credentials,
					reconnect: queryParams.reconnect
				});


				if(queryParams.imap_only){
					this.selectProvider('imap_only');
				}

			}).catch((error) => {
				toast.error('Oops, we have an error');
			});
		}
	}

  onChange(event) {
    const field = event.target.name;
    const credentials = this.state.credentials;
		const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    credentials[field] = value;
    return this.setState({credentials});
  }


	autoConnectionTry(event, test = false){
		if(!this.state.autoConnectionTryed){
			toast.warning('Mailreach is trying to automatically fix your connection details.');
			// Only try if auto connection was not tryed before

			let credentials = this.state.credentials;

			if(credentials['imap_server']){
				credentials['imap_server'] = credentials['imap_server'].replace("http://", "");
				credentials['imap_server'] = credentials['imap_server'].replace("https://", "");
				credentials['imap_server'] = credentials['imap_server'].replace("/", "");
			}

			credentials['smtp_server'] = credentials['smtp_server'].replace("http://", "");
			credentials['smtp_server'] = credentials['smtp_server'].replace("https://", "");
			credentials['smtp_server'] = credentials['smtp_server'].replace("/", "");

			credentials['smtp_server_port'] = 587;
			credentials['smtp_server_auth_type'] = 'plain';
			credentials['smtp_server_starttls'] = true;
			credentials['imap_server_port'] = 993;

			this.setState({autoConnectionTryed: true, credentials: credentials});

      this.onSave(event, test);
		}
	}

  validateEmail(){
    let credentials = this.state.credentials;
    if(!credentials.email){
      toast.error('Please enter a valid email address');
      return;
    }
    let validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (true) {
      this.setState({currentStep: 3});
    } else {
      toast.error('Please enter a valid email address');
    }
  }

  onSave(event, test = false) {
		let queryParams = queryString.parse(this.props.location.search);

    event.preventDefault();
		this.setState({loading: true});
		let credentials = this.state.credentials;

		if(this.props.spamtestConnect){
			credentials.spamchecker_only = true;
		}

		if(credentials.sub_provider == 'sendgrid'){
      credentials.smtp_server = 'smtp.sendgrid.net';
			credentials.smtp_server_port = 587;
			credentials.smtp_server_username = 'apikey';
			credentials.smtp_server_auth_type = 'plain';
		}

		if(credentials.sub_provider == 'sendinblue'){
			credentials.smtp_server = 'smtp-relay.brevo.com';
			credentials.smtp_server_port = 587;
			credentials.smtp_server_auth_type = 'plain';
		}

		if(credentials.sub_provider == 'mailgun'){
			if(!credentials.smtp_server){
				credentials.smtp_server = 'smtp.mailgun.org';
			}
			credentials.smtp_server_port = 587;
			credentials.smtp_server_auth_type = 'plain';
		}
		if(queryParams.edit_account_id){
			credentials.id = queryParams.edit_account_id;
		}
		if(this.state.connectBot){
			credentials.bot = true;
		}
		if(this.state.credentials.provider == 'custom'){
			credentials.password = 'password';
		}

		if(this.state.queryString.invitation_token){
			credentials.auth_api_key = this.state.currentProject.api_key;
			credentials.quick_signin = true;
		}

    if(test){
      credentials.test = true;
    }else{
      credentials.test = false;
    }

	 // Set plain as default auth type
		credentials.smtp_server_auth_type = credentials.smtp_server_auth_type || 'plain';

		credentials.smtp_only = false;


    if(this.state.currentStep == 3){
      credentials.smtp_only = true;
    }

		if((['imap-mail.outlook.com', 'outlook.office365.com'].includes(credentials.imap_server) && this.props.currentProject.config_new_imap_smtp_form_enabled) || this.state.hide_imap_form_oauth){
			credentials.smtp_only = true;
		}

    ImapAuthService.create(credentials).then((response) => {

      if(test){
        this.setState({loading: false, currentStep: (this.state.currentStep+1)});
        return;
      }

			if(!response.body.warming_enabled && response.body.spamchecker_enabled){
				this.props.spamcheckerAddAccountSuccess(response.body);
				return;
			}

			window.gtag("event", "accounts", {
							event_category: "accounts",
							event_label: "connected"
					});
			window.gtag('event', 'conversion', {
					      'send_to': 'AW-444511731/6eLmCPyZwvkBEPPr-tMB',
					      'transaction_id': ''
					 });
			window.$crisp.push(["set", "session:segments", [["account-connected"]]]);
			window.$crisp.push(["set", "session:event", [[["account-connected"]]]]);

			// BING Tracking ADS
			window.uetq = window.uetq || [];
			window.uetq.push('event', 'purchase', {});

			// Redirect to the right success page
			if(queryParams.invitation_token){
				toast.success('Account added !');
				this.setState({loading: false});
			}else{
				// Redirect to step 2 for new auth system
				if(credentials.smtp_only){
					if(this.state.imap_provider_oauth == 'google'){
						this.props.history.push(`/dashboard/${this.props.currentProject.id}/account/workspaces/create?imap_only=true&account_id=${response.body.id}`);
					}else{
						this.props.history.push(`/dashboard/${this.props.currentProject.id}/account/connect?imap_only=true&edit_account_id=${response.body.id}`);
						this.selectProvider('imap_only');
						this.setState({loading: false});
						let queryParams = queryString.parse(this.props.location.search);
						this.setState({queryString: queryParams});
						toast.success('SMTP successfully connected, now sign in with IMAP 🙌');
					}
				} else {
					toast.success('Account connected !');
					this.props.history.push(`/dashboard/${this.props.currentProject.id}/home`);
				}
			}
		})
  	.catch((error) => {
			if(error.response){
				if(error.response.body.error.includes('Unpaid invoices')){
					toast.error('You have to pay past due invoices to connect an account. Go on the billing tab and use a card with sufficient funds.');
					this.setState({loading: false});
					return;
				}
			}

			this.setState({loading: false, imapError: true});
			window.$crisp.push(["set", "session:segments", [["connection-error"]]]);
			this.openHelpModal();
			if(this.state.credentials.provider === 'custom'){

				if(!this.state.autoConnectionTryed){
					this.autoConnectionTry(event, test);
          return;
				}

        if(this.state.autoConnectionTry){
          toast.error("Verify ports, hosts and credentials for IMAP server. IMAP server returned : " + error.response.body.error)
        }

				if(!error.response.body.imap_auth_success && !credentials.smtp_only){
					toast.error("Verify ports, hosts and credentials for IMAP server. IMAP server returned : " + error.response.body.error);
				}else{
					toast.error("Verify ports, hosts and credentials for SMTP server. SMTP server returned : " + error.response.body.error);
				}
			}else{
				if(error.response.body.error.includes('specific password')){
          toast.error('Oops, it looks like you have to create an App Password to connect your account.');
					this.setState({gmailAppPassword: true});
				}else if(error.response.body.error.includes('web browser')){
					toast.error('You\'re almost there ! Check account activities or display unlock captcha');
					this.setState({gmailDisplayUnlock: true});
				}else{
					if(this.state.credentials.provider === 'outlook'){
						toast.error('Verify credentials and SMTP/IMAP is activated on your account');
					}else{

						if(this.state.credentials.provider == 'gmail'){
							if(this.state.credentials.sub_provider == 'lsa'){
								toast.error('Failed to connect. Make sure you have followed all the steps needed 😇');
							}else{
								toast.error('Failed to connect. Make sure you have followed all the steps needed 😇');
							}
						}else{
							toast.error('Verify password, and SMTP/IMAP is activated on your account');
						}
					}
					this.setState({gmailAppPassword: false});
				}
			}
		});
  }

	componentWillReceiveProps(nextProps){
		if(nextProps.match.params.project_id == 'undefined'){
			if(nextProps.currentProject.id){
				window.location = `/dashboard/${nextProps.currentProject.id}/account/connect`;
			}
		}
	}

  resetFlow(){
    this.setState({currentStep: 1, credentials: {}})
  }

	copyText(value){
		navigator.clipboard.writeText(value);
		toast.success('Link successfully copied 🙌');
	}

	providerSelected(provider){
		if(this.state.credentials.sub_provider == provider){
			return 'col-md-3 card selected';
		}
		if(this.state.credentials.provider == provider && !this.state.credentials.sub_provider){
			return 'col-md-3 card selected';
		}
		return 'col-md-3 card';
	}

	selectProvider(provider){
		let credentials = this.state.credentials;
		credentials.provider = provider;
		credentials.sub_provider = null;

		if(provider == 'sendgrid'){
			credentials.sub_provider = 'sendgrid';
			credentials.provider = 'custom';
		}

		if(provider == 'gmail_lsa'){
			credentials.sub_provider = 'lsa';
			credentials.provider = 'gmail';
		}

		if(provider == 'sendinblue'){
			credentials.sub_provider = 'sendinblue';
			credentials.provider = 'custom';
		}

		if(provider == 'mailgun'){
			credentials.sub_provider = 'mailgun';
			credentials.provider = 'custom';
		}

		if(provider == 'imap_only'){
			credentials.sub_provider = 'imap_only';
			credentials.provider = 'custom';
		}
		this.setState({credentials, currentStep: 2});
	}

	onChangeMailgunServers(event) {
		let credentials = this.state.credentials;
		credentials.smtp_server = event.target.value;
		this.setState({credentials});
		return;
	}

	openHelpModal(){
		if(this.state.credentials.provider == 'gmail'){
			this.setState({showHelpModal: true});
		}
	}

	useOauthAsImap(e, provider){
		this.setState({hide_imap_form_oauth: true, imap_provider_oauth: provider});
		this.onSave(e);
	}

	render() {
		return (
			<div className="app container connect-v2">
				<div className="title">
				  {this.state.edit &&
						<>
							{this.state.reconnect &&
								<h2>Reconnect your email account</h2>
							}
							{!this.state.reconnect &&
						    <h2>Edit {this.state.credentials.email}</h2>
							}
						</>
				  }
				  {!this.state.edit &&
						<>
							{this.state.queryString.invitation_token &&
								<h1>You've been invited by {this.state.currentProject.main_user_email} to connect an email account on MailReach 🚀</h1>
							}
							{(!this.state.queryString.invitation_token && this.state.currentStep == 1) &&
								<h1>Connect a new email account</h1>
							}
						</>
				  }
				</div>
				<div className="row">

					  <div className="col-md-12">
							<>
								{this.state.queryString.invitation_token &&
									<h2>Please choose your mailbox provider</h2>
								}

								{this.state.currentStep == 1 &&
									<h2>Select a provider</h2>
								}
							</>


              {this.state.currentStep == 1 &&
                <>

						  <div className="row provider cov2">
							  <div className="col-md-12">
								<div className="clearfix"></div>

										{this.state.currentProject.config_feature_new_google_auth_enabled &&
											<div className={'no-left-margin ' + this.providerSelected('gmail')} onClick={() => this.selectProvider('gmail')}>
												<img className="pull-left" src="/images/gmail_logo_m.png" />
												<span className="name">Google Workspace/Gmail</span>
											</div>
										}

											{!this.state.currentProject.config_feature_new_google_auth_enabled &&
											  <div className={'no-left-margin ' + this.providerSelected('gmail')} onClick={() => this.selectProvider('gmail')}>
												  <img className="pull-left" src="/images/gmail_logo_m.png" />
												  <span className="name">Google Workspace/Gmail</span>
											  </div>
											}

										  <div className={this.providerSelected('outlook')} onClick={() => this.selectProvider('outlook')}>
											  <img className="pull-left" src="/images/logo_outlook.png" />
											  <span className="name">Outlook/Office365</span>
										  </div>
										  <div className={'no-left-margin ' + this.providerSelected('sendgrid')} onClick={() => this.selectProvider('sendgrid')}>
											  <img className="pull-left" src="/images/logo_sendgrid.png" />
											  <span className="name">Sendgrid</span>
										  </div>
											<div className={this.providerSelected('sendinblue')} onClick={() => this.selectProvider('sendinblue')}>
												<img className="pull-left" src="/images/logo_brevo.png" />
												<span className="name">Brevo</span>
											</div>

											<div className={'no-left-margin ' +this.providerSelected('mailgun')} onClick={() => this.selectProvider('mailgun')}>
												<img className="pull-left" src="/images/logo_mailgun.png" />
												<span className="name">Mailgun</span>
											</div>
										  <div className={this.providerSelected('custom')} onClick={() => this.selectProvider('custom')}>
											  <span className="ico pull-left config-ico">⚙️</span>
											  <span className="name">Any other with SMTP</span>
										  </div>
										  {localStorage.getItem('admin') &&
											  <>
											    <div className={'' + this.providerSelected('aol')} onClick={() => this.selectProvider('aol')}>
												    <img className="pull-left" src="https://logo.clearbit.com/aol.com" />
												    <span className="name">Aol</span>
											    </div>
											    <div className={this.providerSelected('gmx')} onClick={() => this.selectProvider('gmx')}>
												    <img className="pull-left" src="https://logo.clearbit.com/gmx.com" />
												    <span className="name">Gmx</span>
											    </div>
											    <div className={this.providerSelected('mailcom')} onClick={() => this.selectProvider('mailcom')}>
												    <img className="pull-left" src="https://logo.clearbit.com/mail.com" />
												    <span className="name">mail.com</span>
											    </div>
											  </>
										  }
							  </div>
						  </div>
              </>
            }

					  </div>


            {this.state.currentStep > 1 &&
              <div className="col-md-12 selected-provider provider">

                <span className="providers-list-back-btn" onClick={() => { this.resetFlow()}}>{'< Back to providers list'}</span>

                {this.state.credentials.provider == 'gmail' &&
                  <div className="col-md-3 col-md-offset-5 card" onClick={() => this.selectProvider('gmail')}>
                    <img className="pull-left" src="/images/gmail_logo_m.png" />
                  </div>
                }

                {this.state.credentials.provider == 'outlook' &&
                  <div className={this.providerSelected('outlook')} onClick={() => this.selectProvider('outlook')}>
                    <img className="pull-left" src="/images/logo_outlook.png" />
                  </div>
                }

                {this.state.credentials.sub_provider == 'sendgrid' &&
                  <div className={'no-left-margin ' + this.providerSelected('sendgrid')} onClick={() => this.selectProvider('sendgrid')}>
                    <img className="pull-left" src="/images/logo_sendgrid.png" />
                  </div>
                }


                {this.state.credentials.sub_provider == 'sendinblue' &&
                  <div className={this.providerSelected('sendinblue')} onClick={() => this.selectProvider('sendinblue')}>
                    <img className="pull-left" src="/images/logo_brevo.png" />
                  </div>
                }


                {this.state.credentials.sub_provider == 'mailgun' &&
                  <div className={'no-left-margin ' +this.providerSelected('mailgun')} onClick={() => this.selectProvider('mailgun')}>
                    <img className="pull-left" src="/images/logo_mailgun.png" />
                  </div>
                }

                {!this.state.credentials.sub_provider &&  this.state.credentials.provider== 'custom' &&
                  <div className={this.providerSelected('custom')} onClick={() => this.selectProvider('custom')}>
                    <span className="ico pull-left config-ico">⚙️</span>
                  </div>
                }
              </div>
            }



            {this.state.currentStep == 2 &&
              <>
              {this.state.credentials.provider == 'outlook' &&
                <>
                <h2 className="text-center">Connect an Outlook Inbox</h2>

								<div className="invite text-center">
									Not your account? <a onClick={() => this.setState({showInviteModal: true})}>Invite your team or client(s) to connect theirs</a>
								</div>

                <div className="col-md-6 card col-md-offset-3">
                  <div className="imap">
                    <div className="col-md-12">
                      <>
                        <div className="col-md-12">
                          <br />

													<p className="text-center desc-outlook-form">Step 1 : Make sure you are logged in to the Outlook account you want to connect to MailReach.</p>

													<p className="text-center desc-outlook-form">Step 2 : Click on the button below to connect in a few clicks</p>

                          <div className="text-center outlook-connect-content">

                            <a href={`${process.env.REACT_APP_BACKEND_URL}microsoft_auth/redirect?project_id=${this.state.currentProject.join_project_team_token}${this.state.queryString.edit_account_id ? ('&account_id=' + this.state.queryString.edit_account_id) : ''}${this.state.queryString.invitation_token ? '&quick_signin=true' : ''}${this.props.spamtestConnect ? '&spamcheck_only=true' : ''}`} className="btn google google-btn outlook-btn">
                              <div class="google-icon-wrapper">
                                <img src="/images/microsoft_m.png" />
                              </div>
                              <span>
                                Sign in with Outlook/Office 365
                              </span>
                            </a>
                            <br /><br /><br />

														<div className="red-alert yellow yellow-outlook text-center need-admin-approval">
															<i class="fa-solid fa-circle-info"></i>
															<span>
																Need Admin Approval error? <a href="https://help.mailreach.co/en/article/how-to-allow-user-consent-for-microsoft-outlook-office-365-need-admin-approval-pcgpip/" target="_blank">here's how to fix</a>.
															</span>
															<div className="clearfix"></div>
														</div>

                          </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="authSeparator cursor-pointer">
                          <hr />
                          <span onClick={() => this.setState({openFullForm: !this.state.openFullForm})}>Or enter your SMTP/IMAP credentials <i class="fa-solid fa-angle-down"></i></span>
                        </div>

                        {this.state.openFullForm &&
                          <>
                            <div className="red-alert yellow yellow-outlook">
                              <i class="fa-solid fa-circle-info"></i>
                              <span>
                                To connect your mailbox with SMTP/IMAP credentials, please follow our <a href="https://help.mailreach.co/en/article/how-to-connect-an-office-365-account-to-mailreach-1jya3ru/" target="_blank">dedicated guide</a>.
                              </span>
                              <div className="clearfix"></div>
                            </div>

                            {((this.state.openFullForm || this.state.credentials.provider == 'custom' || this.state.credentials.provider == 'gmail') && this.state.credentials.sub_provider != 'imap_only') &&
                            <div className="imap">
                            <form>
                                <div className="fieldCont col-md-6">
                                  <input required="required" placeholder="Sender First Name" value={this.state.credentials.first_name} type="text" name="first_name" id="account_first_name" onChange={this.onChange}/>
                                </div>
                                <div className="fieldCont col-md-6">
                                  <input required="required" placeholder="Sender Last Name" value={this.state.credentials.last_name} type="text" name="last_name" id="account_last_name" onChange={this.onChange}/>
                                </div>
                                <div className="fieldCont col-md-12">
                                  <input required="required" placeholder="Email address to warm" value={this.state.credentials.email} type="email" name="email" id="account_email" onChange={this.onChange}/>
                                </div>
                                {this.state.credentials.provider != 'custom' &&
                                  <div className="fieldCont col-md-12">
                                    {this.state.credentials.sub_provider == 'lsa' &&
                                      <input required="required" placeholder="Account password" value={this.state.credentials.password} type="password" name="password" id="account_password" onChange={this.onChange}/>
                                    }
                                    {this.state.credentials.sub_provider != 'lsa' &&
                                      <input required="required" placeholder="Your app password" value={this.state.credentials.password} type="password" name="password" id="account_password" onChange={this.onChange}/>
                                    }
                                  </div>
                                }

                                <div className="text-center">
                                  <button className="btn btn-primary purple" onClick={(e) => this.onSave(e, true)}>Next ></button>
                                </div>
                                </form>
                              </div>
                            }
                          </>
                        }
                      </>
                    </div>
                    </div>
                  </div>

                  <div className="text-center automated-spamtest-btns">
                    <button onClick={() => { this.resetFlow() }} className="btn btn-primary grey">
                      Back
                    </button>
                  </div>
                  </>
              }

              {(['sendgrid', 'sendinblue', 'mailgun', 'custom'].includes(this.state.credentials.provider)) &&
              <>
              <div className="progress-automated-test progress-automated-test-three">
                <ul>
                  <li className="selected"></li>
                  <li></li>
                  <li></li>
                </ul>
                <div className="bar"></div>
              </div>

              <div className="clearfix"></div>
              <h2 className="text-center">Fill in the address information</h2>

              <div>
                <div className="col-md-offset-3 col-md-6 card">
                  <div className="imap">
                  <form>
                  <div className="fieldCont col-md-6 first-form-padding">
                    <input required="required" placeholder="Sender First Name" value={this.state.credentials.first_name} type="text" name="first_name" id="account_first_name" onChange={this.onChange}/>
                  </div>
                  <div className="fieldCont col-md-6 first-form-padding">
                    <input required="required" placeholder="Sender Last Name" value={this.state.credentials.last_name} type="text" name="last_name" id="account_last_name" onChange={this.onChange}/>
                  </div>
                  <div className="fieldCont col-md-12">
                    <input required="required" placeholder="Email address to warm" value={this.state.credentials.email} type="email" name="email" id="account_email" onChange={this.onChange}/>
                  </div>
                  {this.state.credentials.provider != 'custom' &&
                    <div className="fieldCont col-md-12">
                      {this.state.credentials.sub_provider == 'lsa' &&
                        <input required="required" placeholder="Account password" value={this.state.credentials.password} type="password" name="password" id="account_password" onChange={this.onChange}/>
                      }
                      {this.state.credentials.sub_provider != 'lsa' &&
                        <input required="required" placeholder="Your app password" value={this.state.credentials.password} type="password" name="password" id="account_password" onChange={this.onChange}/>
                      }
                    </div>
                  }
                  </form>
                  </div>
                </div>
                <div className="text-center automated-spamtest-btns">
                  <button onClick={() => { this.resetFlow()}} className="btn btn-primary grey">
                    Back
                  </button>
                  <button className="btn btn-primary purple" onClick={() => { this.validateEmail();}}>Next ></button>
                </div>
                </div>
                </>
              }

              {this.state.credentials.provider == 'gmail' &&
                <>
                  <div className="progress-automated-test progress-automated-test-gmail">
                    <ul>
                      <li className="selected"></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                    <div className="bar"></div>
                  </div>

                  <div className="col-md-12 big-container-no-margin step-one-gmail">
      							<>
      								<h2 className="full-width">Step 1 - enable IMAP</h2>
                      <div className="invite pull-left">
                        Not your Google account? <a onClick={() => this.setState({showInviteModal: true})}>Invite your team or client(s) to connect theirs</a>

												{this.state.currentProject &&
													<>
														{this.state.currentProject.config_feature_email_workspace_enabled &&
															<>
																&nbsp; - <NavLink to={`/dashboard/${this.props.match.params.project_id}/account/workspaces/create`}>
																	I don't want to use an app password
																</NavLink>
															</>
														}
													</>
												}

                      </div>
      								<div className="card col-md-12 card-gmail">
                        <div className="col-md-7">
                          <ul className="connect-inbox-steps-list">
                            <li>1. Open <b>Gmail</b></li>
                            <li>2. Click on the <b>gear icon</b> on the top right</li>
                            <li>3. Select <b>All Settings</b></li>
                            <li>4. Select <b>Forwarding and POP/IMAP</b> tab.</li>
                            <li>5. in the "IMAP access" section, select <b>Enable IMAP</b>.</li>
                            <li>6. Click <b>Save Changes</b>.</li>
                          </ul>
                        </div>
                        <div className="col-md-5 nopadding-right-gmail">
                          <iframe className="gmail-guide" src="https://www.loom.com/embed/8bdd91b8bdeb410ca94c414775847250?sid=60bbdc8e-ded1-4e54-ad62-c347075af6e9" allow="fullscreen"></iframe>
                        </div>
                      </div>
                    </>
                  </div>
                  <div className="clearfix"></div>
                  <div className="text-center automated-spamtest-btns">
                    <button onClick={() => { this.resetFlow()}} className="btn btn-primary grey">
                      Back
                    </button>
                    <button className="btn btn-primary purple" onClick={() => { this.setState({currentStep: 3})}}>Done ></button>
                  </div>

                </>
              }
              </>
            }



            {this.state.currentStep == 3 &&
              <>

              {(['sendgrid', 'sendinblue', 'mailgun', 'custom'].includes(this.state.credentials.provider)) &&
              <>
              <div className="progress-automated-test progress-automated-test-three">
                <ul>
                  <li className="done" onClick={() => { this.setState({currentStep: 2})}}></li>
                  <li className="selected"></li>
                  <li></li>
                </ul>
                <div className="bar"></div>
              </div>
              <div className="clearfix"></div>

              {this.state.credentials.sub_provider == 'sendgrid' &&
                <h2 className="text-center">Enter your SendGrid API key</h2>
              }

              {this.state.credentials.sub_provider == 'sendinblue' &&
                <h2 className="text-center">Enter your Brevo SMTP credentials</h2>
              }

              {this.state.credentials.sub_provider == 'mailgun' &&
                <h2 className="text-center">Enter your Mailgun SMTP credentials</h2>
              }

              {(!['sendgrid', 'sendinblue', 'mailgun'].includes(this.state.credentials.sub_provider)) &&
                <h2 className="text-center">Enter your SMTP credentials</h2>
              }

              <div>
                <div className="col-md-offset-3 col-md-6 card">
                  <div className="imap">

                  {this.state.loading &&
                    <div className="loader text-center">
                      <Loader
                       type="Oval"
                       color="#b7b7b7"
                       height="80"
                       width="80"
                      />
                    </div>
                  }

                  <form>
                  {(this.state.credentials.provider == 'custom' && !this.state.loading) &&
                    <>
                      {this.state.credentials.sub_provider == 'sendgrid' &&
                        <>
                          <div className="instructions">
                            <a href="https://help.mailreach.co/en/article/how-to-connect-sendgrid-smtp-to-mailreach-1ihpf1c/" target="_blank">Where to find my SendGrid API key?</a>
                          </div>
                          <div className="fieldCont col-md-12">
                            <input required="required"  placeholder="API key" value={this.state.credentials.smtp_server_password} type="password" name="smtp_server_password" id="smtp_server_password" onChange={this.onChange} autocomplete="off"/>
                          </div>
                        </>
                      }

                      {this.state.credentials.sub_provider == 'sendinblue' &&
                        <>
                        <div className="instructions">
                          Struggling to connect or find the information ? <a href="https://help.mailreach.co/en/article/how-to-connect-brevo-formerly-sendinblue-smtp-to-mailreach-1amt87n/" target="_blank">Follow our guide</a>
                        </div>
                        <div className="fieldCont col-md-12">
                          <input required="required" placeholder="SMTP login" value={this.state.credentials.smtp_server_username} type="string" name="smtp_server_username" id="smtp_server_username" onChange={this.onChange} autocomplete="off"/>
                        </div>
                          <div className="fieldCont col-md-12">
                            <input required="required" placeholder="SMTP key" value={this.state.credentials.smtp_server_password} type="password" name="smtp_server_password" id="smtp_server_password" onChange={this.onChange} autocomplete="off"/>
                          </div>
                        </>
                      }

                      {this.state.credentials.sub_provider == 'mailgun' &&
                        <>
                        <div className="instructions">
                          <a href="https://help.mailreach.co/en/article/how-to-connect-a-mailgun-account-to-mailreach-mufu78/" target="_blank">Where to find my Mailgun SMTP credentials?</a>
                        </div>

                        <div className="col-md-12 config-title">Mailgun servers location</div>
                        <div className="fieldCont col-md-12">
                          <select onChange={this.onChangeMailgunServers} value={this.state.credentials.smtp_server}  name="mailgun_servers" id="mailgun_servers" className="mailgun_server_selector">
                            <option value="smtp.mailgun.org">US servers</option>
                            <option value="smtp.eu.mailgun.org">EU servers</option>
                          </select>
                        </div>
                        <div className="col-md-12 config-title">Mailgun Username and SMTP password</div>
                        <div className="fieldCont col-md-6">
                          <input required="required" placeholder="SMTP username" value={this.state.credentials.smtp_server_username} type="string" name="smtp_server_username" id="smtp_server_username" onChange={this.onChange} autocomplete="off"/>
                        </div>
                          <div className="fieldCont col-md-6">
                            <input required="required" placeholder="SMTP password" value={this.state.credentials.smtp_server_password} type="password" name="smtp_server_password" id="smtp_server_password" onChange={this.onChange} autocomplete="off"/>
                          </div>
                        </>
                      }

                      {this.state.credentials.sub_provider == 'imap_only' &&
                      <div className="col-md-12 imap_only">
                      <div className="red-alert">
                      <i class="fa-solid fa-circle-info"></i>
                        <span>
                          You entered a Microsoft Outlook mailbox as IMAP. Since Microsoft is disabling SMTP/IMAP authentication on October 22, we need you to sign in with IMAP using the "Sign in" button below to avoid any interruption.
                        </span>
                        <div className="clearfix"></div>
                      </div>

                        <>
                          <div className="col-md-12">
                            <p className="text-center">Click on the button below to connect in a few clicks</p>
                            <div className="text-center outlook-connect-content">

                              <a href={`${process.env.REACT_APP_BACKEND_URL}microsoft_auth/redirect?project_id=${this.state.currentProject.join_project_team_token}${this.state.queryString.invitation_token ? '&quick_signin=true' : ''}${this.state.queryString.imap_only ? '&imap_only=true&account_id=' + this.state.queryString.edit_account_id : ''}`} className="btn google google-btn outlook-btn">
                                <div class="google-icon-wrapper">
                                  <img src="/images/microsoft_m.png" />
                                </div>
                                <span>
                                  Sign in with Outlook/Office 365
                                </span>
                              </a>

                            </div>
                          </div>
                          <div className="clearfix"></div>
                        </>
                      </div>
                      }

                      {!this.state.credentials.sub_provider &&
                        <>
                          <div className="fieldCont col-md-9">
                            <input required="required" placeholder="SMTP host" value={this.state.credentials.smtp_server} type="string" name="smtp_server" id="smtp_server" onChange={this.onChange}/>
                          </div>
                          <div className="fieldCont col-md-3">
                            <input required="required" placeholder="Port" value={this.state.credentials.smtp_server_port} type="number" name="smtp_server_port" id="smtp_server_port" onChange={this.onChange}/>
                          </div>
                          <div className="fieldCont col-md-6">
                            <input required="required" placeholder="SMTP username" value={this.state.credentials.smtp_server_username} type="string" name="smtp_server_username" id="smtp_server_username" onChange={this.onChange} autocomplete="off"/>
                          </div>
                          <div className="fieldCont col-md-6">
                            <input required="required" placeholder="SMTP password" value={this.state.credentials.smtp_server_password} type="password" name="smtp_server_password" id="smtp_server_password" onChange={this.onChange} autocomplete="off"/>
                          </div>
                          <div className="fieldCont col-md-8">
                            <select required="required" value={this.state.credentials.smtp_server_auth_type || 'plain'} onChange={this.onChange} name="smtp_server_auth_type">
                              <option value="">Select an SMTP auth type</option>
                              <option value="plain">Plain</option>
                              <option value="login">Login</option>
                              <option value="cram_md5">cram_md5</option>
                            </select>
                          </div>
                          <div className="fieldCont col-md-4 text-center">
                            <label>Use SSL/TLS</label>
                            <input
                              name="smtp_server_starttls"
                              checked={this.state.credentials.smtp_server_starttls}
                              type="checkbox"
                              onChange={this.onChange} />
                          </div>
                        </>
                      }

                      </>

                  }
                  </form>
                  </div>
                </div>
                <div className="text-center automated-spamtest-btns">
                  <button onClick={() => { this.setState({currentStep: 2})}} className="btn btn-primary grey">
                    Back
                  </button>
                  <button className="btn btn-primary purple" onClick={(e) => this.onSave(e, true)}>Next ></button>
                </div>
                </div>
                </>
              }

              {this.state.credentials.provider == 'gmail' &&
                <>
                  <div className="progress-automated-test progress-automated-test-gmail">
                    <ul>
                      <li className="done" onClick={() => { this.setState({currentStep: 2})}}></li>
                      <li className="selected"></li>
                      <li></li>
                      <li></li>
                    </ul>
                    <div className="bar"></div>
                  </div>

                  <div className="clearfix"></div>

                  <div className="col-md-12 big-container-no-margin">
      							<>
      								<h2>Step 2 - Turn on 2-Step Verification</h2>
      								<div className="card col-md-12 card-gmail">
                        <div className="col-md-7">
                          <ul className="connect-inbox-steps-list">
                            <li>1. In the Google account, click on the avatar in the top right corner and then click on <b>Manage your Google account</b>.</li>
                            <li>2. In the navigation panel, select <b>Security</b> and then <b>2-Step Verification</b>.</li>
                            <li>3. Click on <b>Get started</b>.</li>
                            <li>4. Follow the on-screen steps.</li>
                          </ul>
                        </div>
                        <div className="col-md-5 nopadding-right-gmail">
                          <iframe className="gmail-guide" src="https://www.loom.com/embed/ea89bba10e0b4df7b81bf1bcd46bd188?sid=30910bf7-0536-4a75-bde7-269a801a5f61" allow="fullscreen"></iframe>
                        </div>
                      </div>
                    </>
                  </div>
                  <div className="clearfix"></div>
                  <div className="text-center automated-spamtest-btns">
                    <button onClick={() => { this.setState({currentStep: 2})}} className="btn btn-primary grey">
                      Back
                    </button>
                    <button className="btn btn-primary purple" onClick={() => { this.setState({currentStep: 4})}}>Done ></button>
                  </div>

                </>
              }
              </>
            }


            {this.state.currentStep == 4 &&
              <>

              {(['sendgrid', 'sendinblue', 'mailgun', 'custom'].includes(this.state.credentials.provider)) &&
              <>
              <div className="progress-automated-test progress-automated-test-three">
                <ul>
                  <li className="done" onClick={() => { this.setState({currentStep: 2})}}></li>
                  <li className="done" onClick={() => { this.setState({currentStep: 3})}}></li>
                  <li className="selected"></li>
                </ul>
                <div className="bar"></div>
              </div>
							<div className="clearfix"></div>
              <h2 className="text-center">Enter your IMAP credentials</h2>

              <div>
                <div className="col-md-offset-3 col-md-6 card">
                  <div className="imap">
                  <form>

                  {this.state.loading &&
                    <div className="loader text-center">
                      <Loader
                       type="Oval"
                       color="#b7b7b7"
                       height="100"
                       width="100"
                      />
                    </div>
                  }


                  {(this.state.credentials.provider == 'custom' && !this.state.loading) &&
                    <>
                      {this.state.credentials.sub_provider == 'imap_only' &&
                      <div className="col-md-12 imap_only">
                      <div className="red-alert">
                      <i class="fa-solid fa-circle-info"></i>
                        <span>
                          You entered a Microsoft Outlook mailbox as IMAP. Since Microsoft is disabling SMTP/IMAP authentication on October 22, we need you to sign in with IMAP using the "Sign in" button below to avoid any interruption.
                        </span>
                        <div className="clearfix"></div>
                      </div>

                        <>
                          <div className="col-md-12">
                            <p className="text-center">Click on the button below to connect in a few clicks</p>
                            <div className="text-center outlook-connect-content">

                              <a href={`${process.env.REACT_APP_BACKEND_URL}microsoft_auth/redirect?project_id=${this.state.currentProject.join_project_team_token}${this.state.queryString.invitation_token ? '&quick_signin=true' : ''}${this.state.queryString.imap_only ? '&imap_only=true&account_id=' + this.state.queryString.edit_account_id : ''}`} className="btn google google-btn outlook-btn">
                                <div class="google-icon-wrapper">
                                  <img src="/images/microsoft_m.png" />
                                </div>
                                <span>
                                  Sign in with Outlook/Office 365
                                </span>
                              </a>

                            </div>
                          </div>
                          <div className="clearfix"></div>
                        </>
                      </div>
                      }

                      {!this.state.credentials.sub_provider &&
                        <>

                          <div className="col-md-12 config-title">
                            <span className="pull-left">IMAP settings</span>
                            <span className="help-bubble">
                              <Tooltip title='We use IMAP to generate realistic conversations, put the replies in threads and hide MailReach emails from your inbox.' placement="bottom">
                                <div>
                                  <i class="fas fa-info-circle"></i>
                                </div>
                              </Tooltip>
                            </span>
                          </div>

													{this.state.currentProject.config_feature_email_workspace_enabled &&
														<div className="sub-provider-buttons-imap col-md-12">
															<button type="button" onClick={(e) => { let credentials = this.state.credentials; credentials.imap_server = 'imap.gmail.com'; credentials.imap_server_port = 993; this.setState({credentials: credentials, imap_provider_oauth: 'google'}); }}>Use a Google inbox <img className="pull-right" src="/images/gmail_logo_m.png" /></button>
															<button type="button" onClick={(e) => { this.useOauthAsImap(e, 'outlook'); }} >Use an Outlook inbox <img className="pull-right" src="/images/logo_outlook.png" /></button>
														</div>
													}

													{this.state.imap_provider_oauth == 'google' &&
														<div className="fieldCont col-md-9">
															<a onClick={(e) => { this.useOauthAsImap(e, 'google'); }}>I don't want to use an app password</a>
														</div>
													}


                          <div className="fieldCont col-md-9">
                            <input required="required" placeholder="IMAP host" value={this.state.credentials.imap_server} type="string" name="imap_server" id="imap_server" onChange={this.onChange}/>
                          </div>
                          <div className="fieldCont col-md-3">
                            <input required="required" placeholder="Port" value={this.state.credentials.imap_server_port}  type="number" name="imap_server_port" id="imap_server_port" onChange={this.onChange}/>
                          </div>
                          <div className="fieldCont col-md-6">
                            <input required="required" placeholder="IMAP username" value={this.state.credentials.imap_server_username} type="string" name="imap_server_username" id="imap_server_username" onChange={this.onChange} autocomplete="off"/>
                          </div>
                          <div className="fieldCont col-md-6">
                            <input required="required" placeholder="IMAP password" value={this.state.credentials.imap_server_password} type="password" name="imap_server_password" id="imap_server_password" onChange={this.onChange} autocomplete="off"/>
                          </div>
                        </>
                      }

                      {(this.state.credentials.sub_provider && this.state.credentials.sub_provider != 'imap_only') &&
                        <>

												{this.state.credentials.sub_provider == 'mailgun' &&
													<div className="instructions">
														<a href="https://help.mailreach.co/en/article/how-to-connect-a-mailgun-account-to-mailreach-mufu78/" target="_blank">Where to find my IMAP credentials?</a>
													</div>
												}

												{this.state.credentials.sub_provider == 'sendgrid' &&
													<div className="instructions">
														<a href="https://help.mailreach.co/en/article/how-to-connect-sendgrid-smtp-to-mailreach-1ihpf1c/" target="_blank">Where to find my IMAP credentials?</a>
													</div>
												}

												{this.state.credentials.sub_provider == 'sendinblue' &&
													<div className="instructions">
														<a href="https://help.mailreach.co/en/article/how-to-connect-brevo-formerly-sendinblue-smtp-to-mailreach-1amt87n/" target="_blank">Where to find my IMAP credentials?</a>
													</div>
												}

                          <div className="col-md-12 config-title">
                            <span className="pull-left">IMAP settings</span>
                            <span className="help-bubble">
                              <Tooltip title='We use IMAP to generate realistic conversations, put the replies in threads and hide MailReach emails from your inbox.' placement="bottom">
                                <div>
                                  <i class="fas fa-info-circle"></i>
                                </div>
                              </Tooltip>
                            </span>
                          </div>

													{this.state.currentProject.config_feature_email_workspace_enabled &&
														<div className="sub-provider-buttons-imap col-md-12">
															<button type="button" onClick={(e) => { let credentials = this.state.credentials; credentials.imap_server = 'imap.gmail.com'; credentials.imap_server_port = 993; this.setState({credentials: credentials, imap_provider_oauth: 'google'}); }}>Use a Google inbox <img className="pull-right" src="/images/gmail_logo_m.png" /></button>
															<button type="button" onClick={(e) => { this.useOauthAsImap(e, 'outlook'); }} >Use an Outlook inbox <img className="pull-right" src="/images/logo_outlook.png" /></button>
														</div>
													}

													{this.state.imap_provider_oauth == 'google' &&
														<div className="fieldCont col-md-9">
															<a onClick={(e) => { this.useOauthAsImap(e, 'google'); }}>I don't want to use an app password</a>
														</div>
													}

                          <div className="fieldCont col-md-9">
                            <input required="required" placeholder="IMAP host" value={this.state.credentials.imap_server} type="string" name="imap_server" id="imap_server" onChange={this.onChange}/>
                          </div>
                          <div className="fieldCont col-md-3">
                            <input required="required" placeholder="Port" value={this.state.credentials.imap_server_port}  type="number" name="imap_server_port" id="imap_server_port" onChange={this.onChange}/>
                          </div>
                          <div className="fieldCont col-md-6">
                            <input required="required" placeholder="IMAP username" value={this.state.credentials.imap_server_username} type="string" name="imap_server_username" id="imap_server_username" onChange={this.onChange} autocomplete="off"/>
                          </div>
                          <div className="fieldCont col-md-6">
                            <input required="required" placeholder="IMAP password" value={this.state.credentials.imap_server_password} type="password" name="imap_server_password" id="imap_server_password" onChange={this.onChange} autocomplete="off"/>
                          </div>
                        </>
                        }
                      </>

                  }
                  </form>
                  </div>
                </div>
                <div className="text-center automated-spamtest-btns">
                  <button onClick={() => { this.setState({currentStep: 2})}} className="btn btn-primary grey">
                    Back
                  </button>
                  <button className="btn btn-primary purple" onClick={this.onSave}>Sign in</button>
                </div>
                </div>
                </>
              }
              {this.state.credentials.provider == 'gmail' &&
                <>
                  <div className="progress-automated-test progress-automated-test-gmail">
                    <ul>
                      <li className="done" onClick={() => { this.setState({currentStep: 2})}}></li>
                      <li className="done" onClick={() => { this.setState({currentStep: 3})}}></li>
                      <li className="selected"></li>
                      <li></li>
                    </ul>
                    <div className="bar"></div>
                  </div>

                  <div className="col-md-12 big-container-no-margin">
                    <>
                      <h2>Step 3 - Create an app password</h2>
                      <div className="card col-md-12 card-gmail">
                        <div className="col-md-7">
                          <ul className="connect-inbox-steps-list">
                            <li>1. Go back to your <b>Google Account settings</b>.</li>
                            <li>2. Search for "<b>App passwords</b>" in the search bar at the top. Select the result labeled <b>"App passwords" with "Security" underneath</b>. (If your account isn’t in English, use the translated term for your language). You can also <a href="https://myaccount.google.com/apppasswords" target="_blank">use this link</a> but ensure you're  ensure you're logged into the correct Google account.</li>
                            <li>3. In the "<b>App Name</b>" field, enter any word (it doesn’t matter what).</li>
                            <li>4. Click "<b>Create</b>".</li>
                            <li>5. <b>Copy</b> the generated app password.</li>
                          </ul>
                        </div>
                        <div className="col-md-5 nopadding-right-gmail">
													<br />
                          <iframe className="gmail-guide" src="https://www.loom.com/embed/87fee6a1a8724b3cbe91cd117f42119b" allow="fullscreen"></iframe>
                        </div>
                      </div>
                    </>
                  </div>
                  <div className="clearfix"></div>
                  <div className="text-center automated-spamtest-btns">
                    <button onClick={() => { this.setState({currentStep: 3})}} className="btn btn-primary grey">
                      Back
                    </button>
                    <button className="btn btn-primary purple" onClick={() => { this.setState({currentStep: 5})}}>Done ></button>
                  </div>

                </>
              }
              </>
            }

            {this.state.currentStep == 5 &&
              <>
                {this.state.credentials.provider == 'gmail' &&
                  <>
                    <div className="progress-automated-test progress-automated-test-gmail">
                      <ul>
                        <li className="done" onClick={() => { this.setState({currentStep: 2})}}></li>
                        <li className="done" onClick={() => { this.setState({currentStep: 3})}}></li>
                        <li className="done" onClick={() => { this.setState({currentStep: 4})}}></li>
                        <li className="selected"></li>
                      </ul>
                      <div className="bar"></div>
                    </div>

                    <div className="col-md-12">
                      <>
                        <h2 className="text-center">Step 4 - Sign in with your app password</h2>
                        <form className="row" onSubmit={this.onSave}>
                          <div className="card col-md-6 col-md-offset-3">
                            <div className="col-md-12 imap">
                              {this.state.loading &&
                                <div className="loader text-center">
                                  <Loader
                                   type="Oval"
                                   color="#b7b7b7"
                                   height="80"
                                   width="80"
                                  />
                                </div>
                              }
                              {!this.state.loading &&
                                <>
                                  {(this.state.first_google_workspace_account && this.state.credentials.provider == 'google_workspace') &&
                                    <div className="col-md-12">
                                      <>
                                        <div className="col-md-12">
                                          <div className="text-center outlook-connect-content google-connect-content">
                                            <a href={`${process.env.REACT_APP_BACKEND_URL}gmail_auth/redirect?project_id=${this.state.currentProject.join_project_team_token}${this.state.queryString.invitation_token ? '&quick_signin=true' : ''}`} className="btn google google-btn">
                                              <div class="google-icon-wrapper">
                                                <img src="/images/google_g.svg" />
                                              </div>
                                              <span>
                                                Sign in with Google Workspace
                                              </span>
                                            </a>
                                            <div className="cant-btn">
                                              <span onClick={() => this.setState({openFullForm: !this.state.openFullForm})}>
                                                {!this.state.queryString.invitation_token &&
                                                  <div className="underline">
                                                    I'm not able to give MailReach an API access <br />OR Connect a GMAIL.COM inbox
                                                  </div>
                                                }
                                                {this.state.queryString.invitation_token &&
                                                  <>
                                                    I want to connect using an App Password
                                                  </>
                                                }
                                              </span>
                                            </div>

                                            {this.state.openFullForm &&
                                              <div className="red-alert yellow">
                                                <i class="fa-solid fa-circle-info"></i>
                                                <span>
                                                You can also connect by using an 'app password' with 2FA. Please <a href="https://help.mailreach.co/en/article/how-to-connect-a-gmail-google-workspace-account-to-mailreach-with-an-app-password-ulwj00/" target="_blank">follow our quick guide</a> and fill in the fields below.
                                                </span>
                                                <div className="clearfix"></div>
                                              </div>
                                            }

                                          </div>
                                        </div>
                                        <div className="clearfix"></div>
                                      </>
                                    </div>
                                  }
                                  {((this.state.openFullForm || this.state.credentials.provider == 'custom' || this.state.credentials.provider == 'gmail') && this.state.credentials.sub_provider != 'imap_only') &&
                                    <>
                                      <div className="fieldCont col-md-6 first-form-padding">
                                        <input required="required" placeholder="Sender First Name" value={this.state.credentials.first_name} type="text" name="first_name" id="account_first_name" onChange={this.onChange}/>
                                      </div>
                                      <div className="fieldCont col-md-6 first-form-padding">
                                        <input required="required" placeholder="Sender Last Name" value={this.state.credentials.last_name} type="text" name="last_name" id="account_last_name" onChange={this.onChange}/>
                                      </div>
                                      <div className="fieldCont col-md-12 fieldCont-new-margn">
                                        <input required="required" placeholder="Email address to warm" value={this.state.credentials.email} type="email" name="email" id="account_email" onChange={this.onChange}/>
                                      </div>
                                      {this.state.credentials.provider != 'custom' &&
                                        <div className="fieldCont col-md-12">
                                          {this.state.credentials.sub_provider == 'lsa' &&
                                            <input required="required" placeholder="Account password" value={this.state.credentials.password} type="password" name="password" id="account_password" onChange={this.onChange}/>
                                          }
                                          {this.state.credentials.sub_provider != 'lsa' &&
                                            <input required="required" placeholder="Your app password" value={this.state.credentials.password} type="password" name="password" id="account_password" onChange={this.onChange}/>
                                          }
                                        </div>
                                      }
                                    </>
                                  }

                                </>
                              }
                            </div>
                          </div>

                          <div className="text-center automated-spamtest-btns">
                            <button onClick={() => { this.setState({currentStep: 4})}} className="btn btn-primary grey">
                              Back
                            </button>
                            <input className="btn btn-primary purple" type="submit" name="commit" value={this.state.edit ? this.state.reconnect ? 'Reconnect' : 'Edit' : 'Sign in with the app password'}/>
                          </div>

                        </form>
                      </>
                    </div>
                  </>
                }
              </>
            }

				</div>

				<Modal className="reputation-modal help-modal" show={this.state.showHelpModal} onHide={() => this.setState({showHelpModal: false})}>
					<Modal.Body>

						{this.state.queryString &&
							<>
								{this.state.queryString.error_type == 'SEEM_TO_BE_DUPLICATE' &&
									<>
									{this.state.queryString.provider == 'GMAIL' &&
										<>
											<h1 className="text-center">You're not logged in the right Google account</h1>
											<div className="txt text-center small-font">
												<p>We detect that you're connecting an Google account that is already linked to MailReach : {this.state.queryString.email}.</p>

												<p>If your intention is to connect another Google account :</p>

												<p>In your browser, you have to be logged into the Google account you wish to connect to MailReach and then try connecting it again to MailReach.</p>

												<p>Get more details in our guide here :</p>
												<p><a href="https://help.mailreach.co/en/article/fix-i-cant-manage-to-connect-the-right-mailbox-account-to-mailreach-google-microsoft-i8a7e/">I can't manage to connect the right mailbox account to MailReach [Google / Microsoft]</a></p>
											</div>
										</>
									}
									{this.state.queryString.provider == 'OUTLOOK' &&
										<>
										<h1 className="text-center">You're not logged in the right Outlook account</h1>
										<div className="txt text-center small-font">
											<p>We detect that you're connecting an Outlook account that is already linked to MailReach : {this.state.queryString.email}.</p>

											<p>If your intention is to connect another Outlook account :</p>

											<p>In your browser, you have to be logged into the Outlook account you wish to connect to MailReach and then try connecting it again to MailReach.</p>

											<p>Get more details in our guide here :</p>

											<p><a href="https://help.mailreach.co/en/article/fix-i-cant-manage-to-connect-the-right-mailbox-account-to-mailreach-google-microsoft-i8a7e/">I can't manage to connect the right mailbox account to MailReach [Google / Microsoft]</a></p>
										</div>
										</>
									}
									</>
								}
							</>
						}

						{(this.state.modal_error_type && this.state.queryString.error_type != 'SEEM_TO_BE_DUPLICATE') &&
							<>
								<h1 className="text-center">MailReach can't manage to connect</h1>
								<div className="txt text-center">
									<p>To be able to connect your account, we need you to follow a few steps.</p>

									<p>No worries, it takes 1 minute. Please follow this guide : </p>
									<p>
										<a className='text-center' target="_blank" href="https://help.mailreach.co/en/article/how-to-fix-the-google-outdated-token-issue-1aaxvxp/">
											How to fix the Google outdated token issue
										</a>
									</p>
								</div>
							</>
						}
						{(this.state.queryString.modal_connect_success_invite) &&
							<>
								<h1 className="text-center">Well done, mailbox successfully connected 🙌</h1>
								<div className="txt text-center">
									<p>Your mailbox has been successfully connected to MailReach under the account {this.state.currentProject.main_user_email}. </p>

									<p>Now this mailbox will be warmed to maximize its sending reputation and deliverability. </p>

									<p>The warming emails (conversations between your inbox and our network of inboxes) will be stored in a folder named "To Follow" and hidden from you. </p>

									<p>Sometimes, there can be a small delay to automatically hide them from your main inbox and move them to the folder. That's normal. </p>

									<p>You're a wonderful person, have a great day.</p>

									<p>The MailReach team.</p>
								</div>
							</>
						}
						{this.state.credentials.provider == 'gmail' &&
							<>
							{(!this.state.gmailAppPassword && !this.state.gmailDisplayUnlock) &&
								<>
									<h1 className="text-center">
										Connection failed. Relax, here's how to fix 🧘‍♂️
									</h1>
									<div className="txt text-center">

									{this.state.credentials.sub_provider == 'lsa' &&
										<>
											<ul className="text-left li-margin">
												<li>Make sure you have properly allowed the 'Less Secure Apps' on the right mailbox.</li>
												<li>Google may have blocked the process. Check if you have received 'Security Alert' emails from Google. If yes, open them and say "It was me". And re-check if 'Less Secure Apps' are still well enabled (Google may have disabled them).</li>
												<li>Make sure the address and password are correct. It happens to the best of us.</li>
												<li>All the steps to make it work are detailed in this 3 minutes guide: <br /><a className='text-center' target="_blank" href="https://help.mailreach.co/en/article/how-to-connect-a-google-workspace-account-to-mailreach-with-less-secure-apps-1xsossm/">How to connect a Google Workspace account to MailReach with Less Secure Apps</a></li>
											</ul>
										</>
									}
									{this.state.credentials.sub_provider != 'lsa' &&
									<>
										<ul className="text-left li-margin">
											<li>Make sure that you have created a Google App Password and used it to sign in. It doesn't work if you use the regular password of the mailbox.</li>
											<li>Make sure the address is correct.</li>
											<li>All the steps to make it work are detailed in this 3 minutes guide: <br /><a className='text-center' target="_blank" href="https://help.mailreach.co/en/article/how-to-connect-a-gmail-google-workspace-account-to-mailreach-with-an-app-password-ulwj00/">How to connect a Google account to MailReach with an App Password.</a></li>
										</ul>
									</>
									}
									</div>
								</>
							}
							{this.state.gmailDisplayUnlock &&
								<>
									<h1 className="text-center">You're almost there</h1>
									<div className="txt text-center">
										<p>Sometimes, Google blocks the connection.<br /> To fix this :</p>
										<ul className="text-left">
										  <li>Check if the mailbox has received 'Security Alert' emails. If yes, treat them by saying "It was me" to reassure Google</li>
                      <li>Do the <a target="_blank" href="https://accounts.google.com/b/0/DisplayUnlockCaptcha">Display Unlock Captcha</a> (and make sure to do it on the right mailbox)</li>
                      <li>If you still get an error, <a target="_blank" href="https://help.mailreach.co/en/article/how-to-connect-a-google-workspace-account-to-mailreach-1xsossm#1-troubleshooting-if-you-have-done-the-4-steps-but-still-cant-connect-your-account-to-mailreach">please check the troubleshooting section of our Google connection guide here</a></li>
										</ul>
									</div>
								</>
							}
							{this.state.gmailAppPassword &&
								<>
									<h1 className="text-center">MailReach can't connect, we need you!</h1>
									<div className="txt text-center">
										<ul className="text-left">
											<li>Make sure you have created an 'App Password' for MailReach.</li>
											<li>Make sure you're using the 'App Password' to connect your account.</li>
										</ul>
										<p>
											<a className='text-center' target="_blank" href="https://help.mailreach.co/en/article/how-to-connect-a-gmail-google-workspace-account-to-mailreach-with-an-app-password-ulwj00/">
												How to connect a Gmail / Google Workspace account with 2FA to MailReach.
											</a>
										</p>
									</div>
								</>
							}
							</>
						}
						<div className="footer text-center">
							<Button variant="secondary" onClick={() => this.setState({showHelpModal: false})}>
								Close
							</Button>
						</div>
					</Modal.Body>
				</Modal>



        <Modal className="reputation-modal help-modal" show={this.state.showInviteModal} onHide={() => this.setState({showInviteModal: false})}>
          <Modal.Body>
            <div>
              <div class="modal-body">
                <div className="imap checkout imap team">

                  <h1 className="text-center">Invite people to connect theirs</h1>
                  <div className="col-md-12">
                    {this.state.credentials.provider == 'gmail' &&
                      <p className="desc-text">Send the following link to your teammates or customers so they can connect their account by themselves.</p>
                    }

                    {this.state.credentials.provider == 'outlook' &&
                      <p className="desc-text">Send the following link to your teammates or customers so they can connect their account by themselves.</p>
                    }

                    <div className="email-code copy-style copy-code code-share-team" onClick={() =>  this.copyText(`https://${window.location.hostname}/connect?invitation_token=${this.state.currentProject.join_project_team_token}`)}>
                      <div className="code">
                        https://{window.location.hostname}/connect?invitation_token={this.state.currentProject.join_project_team_token}
                      </div>
                      <div className="copy">
                        <i class="far fa-copy"></i>
                      </div>
                    </div>
                    <p className="desc-text">⚠️ Connecting a new account will increase your monthly subscription according to your current plan.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer text-center">
              <Button variant="secondary" onClick={() => this.setState({showInviteModal: false})}>
                Close
              </Button>
            </div>
          </Modal.Body>
        </Modal>


				<ToastContainer />

			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		currentProject: state.currentProject,
		currentPricing: state.currentPricing
	};
}

function mapDispatchToProps(dispatch) {
	return {
		projectsActions: bindActionCreators(projectsActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardAccountsConnectV2));
