import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import Tooltip from '@material-ui/core/Tooltip';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';

import InappMessageService from '../../services/inappMessage.service';

import './launch-spamtest.scss';


class LaunchSpamtest extends React.Component {

	constructor(props) {
		super(props);
		this.state = {lastTestId: null, folders: [], selectedAccount: null, templateKey: this.props.templateKey};
		this.startTest = this.startTest.bind(this);
		this.selectAccount = this.selectAccount.bind(this);
		this.selectFolder = this.selectFolder.bind(this);
	}

  copyText(value){
		navigator.clipboard.writeText(value);
		toast.info(value + ' copied');
	}

	componentWillReceiveProps(nextProps) {
		if(nextProps.lastTest){
			if(nextProps.lastTest.id != this.state.lastTestId){
				if(this.state.lastTestId){
					toast.success('A new spamtest is running ✌️');
					// Redirect
					this.props.history.push(`/dashboard/${this.props.match.params.project_id}/spam-test/${nextProps.lastTest.test_folder_id}/results/${nextProps.lastTest.id }`);
				}
				this.setState({lastTestId: nextProps.lastTest.id, start_test: false});
			}
		}

		if(nextProps.folders){
			this.setState({folders: nextProps.folders});
		}
		if(nextProps.templateKey){
			this.setState({templateKey: nextProps.templateKey});
		}
	}

	startTest(){
		setTimeout(() => {
      this.setState({start_test: false});
    }, 120000);
		this.setState({start_test: true});
	}


	selectAccount(e){
    this.setState({selectAccount: e.target.value});
	}

	selectFolder(e){
		this.setState({templateKey: e.target.value});
	}

	render() {
		return (
			<>
				<div className="title">
					<h1 className="pull-left">New spam test</h1>
				</div>
				<div className="clearfix"></div>

	      <div className="card request-action">
	        <div className="col-md-12">
					{!this.state.start_test &&
						<>
						 <ul className="launch-spamtest-tuto">
		 				  <li>
		 					  <span className="step">1</span>
		 						<select onChange={this.selectAccount}>
		 						  <option>Choose your sending address</option>
		 						  {this.props.accounts.sort(function(a, b){
										    if(a.email < b.email) { return -1; }
										    if(a.email > b.email) { return 1; }
										    return 0;
										}).map(account=> (
		                 <option value={account.email} key={account.id}>{account.email}</option>
		               ))}
		 						</select>
		 					</li>
							{(this.state.folders.length > 1 && this.state.selectAccount) &&
								<li>
									<span className="step">2</span>
									<select onChange={this.selectFolder}>
										<option>Choose your folder</option>

										{this.state.folders.map(folder=> (
											 <option value={folder.key} key={folder.id}>{folder.name}</option>
										 ))}
									</select>
								</li>
							}
							{this.state.selectAccount &&
								<>
				 					<li>
									  {this.state.folders.length > 1 &&
				 					    <span className="step">3</span>
									  }

										{this.state.folders.length < 2 &&
											<span className="step">2</span>
										}
				 						Send your email from {this.state.selectAccount} in <b>real conditions</b>

										<span className="help-bubble">
											<Tooltip title='Send your email exactly as you would send a campaign to your prospects. Use your sending solution so it will contains all the tracking elements, etc.' placement="bottom">
												<div>
													<i class="fas fa-info-circle"></i>
												</div>
											</Tooltip>
										</span>

										 to this address :
				 						<span className="start-email" onClick={() =>  this.copyText(this.state.templateKey + '@test.mailreach.co')}>
				 							{this.state.templateKey}@test.mailreach.co <i class="far fa-copy"></i>
				 						</span>
				 					</li>

									<li>
										<div className="text-center">
											<button type="submit" className="btn btn-primary purple" onClick={this.startTest}>Email sent</button>
										</div>
									</li>
								</>
							}
		 				</ul>
						</>
					}
					{this.state.start_test &&
						<div className="txt loading text-center">
							<div className="loader">
								<Loader
								 type="Oval"
								 color="#b7b7b7"
								 height="60"
								 width="60"
								/>
							</div>
	            <div>
							  Waiting to receive your message, it usually takes 10-30s 👀
							</div>
						</div>
					}
							        </div>
	        <div className="clearfix"></div>
				</div>
			</>
		)
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LaunchSpamtest));
