const request = require('superagent');

class SessionService {

	static emailSignup(data) {

		let coupon = document.cookie.replace(/(?:(?:^|.*;\s*)coupon\s*\=\s*([^;]*).*$)|^.*$/, "$1");
		if(coupon){
			data.coupon = coupon;
		}

		let utm_campaign = document.cookie.replace(/(?:(?:^|.*;\s*)utm_campaign\s*\=\s*([^;]*).*$)|^.*$/, "$1");
		if(utm_campaign){
			data.utm_campaign = utm_campaign;
		}

		let utm_medium = document.cookie.replace(/(?:(?:^|.*;\s*)utm_medium\s*\=\s*([^;]*).*$)|^.*$/, "$1");
		if(utm_medium){
			data.utm_medium = utm_medium;
		}
		let utm_source = document.cookie.replace(/(?:(?:^|.*;\s*)utm_source\s*\=\s*([^;]*).*$)|^.*$/, "$1");
		if(utm_source){
			data.utm_source = utm_source;
		}
		let utm_content = document.cookie.replace(/(?:(?:^|.*;\s*)utm_content\s*\=\s*([^;]*).*$)|^.*$/, "$1");
		if(utm_content){
			data.utm_content = utm_content;
		}
		let utm_term = document.cookie.replace(/(?:(?:^|.*;\s*)utm_term\s*\=\s*([^;]*).*$)|^.*$/, "$1");
		if(utm_term){
			data.utm_term = utm_term;
		}
		let gclid = document.cookie.replace(/(?:(?:^|.*;\s*)gclid\s*\=\s*([^;]*).*$)|^.*$/, "$1");
		if(gclid){
			data.gclid = gclid;
		}
		let fbclid = document.cookie.replace(/(?:(?:^|.*;\s*)fbclid\s*\=\s*([^;]*).*$)|^.*$/, "$1");
		if(fbclid){
			data.fbclid = fbclid;
		}
		let mscklid = document.cookie.replace(/(?:(?:^|.*;\s*)mscklid\s*\=\s*([^;]*).*$)|^.*$/, "$1");
		if(fbclid){
			data.mscklid = mscklid;
		}
		let li_fat_id = document.cookie.replace(/(?:(?:^|.*;\s*)li_fat_id\s*\=\s*([^;]*).*$)|^.*$/, "$1");
		if(li_fat_id){
			data.li_fat_id = li_fat_id;
		}


		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}users/signup`)
						.send(data)
	}

	static emailSignin(data) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}users/signin`)
						.send(data)
	}

	static requestResetPassword(data) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}users/password/instructions`)
						.send(data)
	}

	static resetPassword(data) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}users/password/reset`)
						.send(data)
	}
}

export default SessionService;
