import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import { Button, Modal } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';
import Moment from 'react-moment';

import {CreatedInDayAgo} from "../../helpers/time"

import DomainBlacklistService from '../../services/domainBlacklist.service';
import InappMessage from '../inapp-message/inapp-message.js';

import SpamCheckerService from '../../services/spamChecker.service';

class CheckupDomain extends React.Component {

	constructor(props) {
		super(props);
		this.state = {error: false, domain: {}, blacklists: [], nbIssue: 0, blacklist_sending_ip: [], blacklistsModal: []};
    this.openRecordValue = this.openRecordValue.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.loadAccount = this.loadAccount.bind(this);
    this.openBlacklist = this.openBlacklist.bind(this);
    this.openInappMessage = this.openInappMessage.bind(this);
	}

  componentWillMount() {
    this.loadAccount();

		if(this.props.queryParams){
			if(this.props.queryParams.spf_invalid_modal){
				//Open invalid spf modal
				if(!this.props.currentAccount.domain.spf_valid){
					this.openRecordValue('SPF_INVALID', this.props.currentAccount.domain.spf_value);
				}
			}

			if(this.props.queryParams.dkim_invalid_modal){
				//Open invalid spf modal
				if(!this.props.currentAccount.domain.dkim_valid){
					this.openRecordValue('DKIM_INVALID', this.props.currentAccount.domain.dkim_value);
				}
			}
		}
  }

	componentWillReceiveProps(){
	}

  loadAccount(){
		if(this.props.currentAccount.id){
			DomainBlacklistService.get(this.props.currentAccount.id).then((response) => {
				this.setState({blacklists: response.body.filter(b => ['domain', 'domain_ip'].includes(b.host_type)), blacklist_sending_ip: response.body.filter(b => b.host_type == 'smtp_server')})
			})
		}else{
			SpamCheckerService.get_blacklists(this.props.currentTest.public_id).then((response) => {
				this.setState({blacklists: response.body.blacklists})
			})
		}
  }

  handleCloseModal() {
    this.setState({showRecordModal: false, showBlacklistModal: false, openModalKey: null});
  }

  openInappMessage(key){
    this.setState({openModalKey: key})
  }

  openBlacklist(){
    this.setState({showBlacklistModal: true})
  }

  openRecordValue(recordName, recordValue){
    this.setState({showRecordModal: true,
                   modalRecordName: recordName,
                   modalRecordValue: recordValue});
  }


	render() {
		return (
      <>
      <div>
      {this.props.domain.creation_date &&
        <div className="card col-md-12">
          <p className="delete pull-left">
            {CreatedInDayAgo(this.props.domain.creation_date) > 31 &&
              <>
                <Tooltip title='👌 Good domain age' placement="left">
                  <div className="pull-left">
                    <i class="far fa-check-circle"></i>
                  </div>
                </Tooltip>

                Domain created <Moment fromNow>{this.props.domain.creation_date}</Moment>
              </>
            }
            {CreatedInDayAgo(this.props.domain.creation_date) < 32 &&
              <>
              <Tooltip title='👶 Young domain' placement="left">
                <div className="pull-left">
                  <i class="far fa-times-circle"></i>
                </div>
              </Tooltip>
              Your domain is very young and can be considered suspicious. Behave well 🤓
              </>
            }
          </p>
					<div className="clearfix"></div>
        </div>
      }

			{this.props.currentAccount &&
				<>
		      {this.props.currentAccount.created_at &&
		        <div className="card col-md-12">
		            {CreatedInDayAgo(this.props.currentAccount.created_at) > 13 &&
		              <>
		                <p className="delete pull-left">
		                  <i class="far fa-check-circle"></i> Warming started <Moment fromNow>{this.props.currentAccount.created_at}</Moment>
		                </p>
		                <button type="submit" className="btn btn-secondary pull-right" onClick={() => this.openInappMessage('accounts_checkup_warmupsince_modal_30')}>Recommendations</button>
		              </>
		            }
		            {CreatedInDayAgo(this.props.currentAccount.created_at) < 14 &&
		              <>
		                <p className="delete pull-left">
		                  <i class="far fa-times-circle"></i> Warming started <Moment fromNow>{this.props.currentAccount.created_at}</Moment>
		                </p>
		                <button type="submit" className="btn btn-secondary pull-right" onClick={() => this.openInappMessage('accounts_checkup_warmupsince_modal_0')}>Recommendations</button>
		              </>
		            }
							<div className="clearfix"></div>
		        </div>
		      }

					{(this.props.currentAccount.tld) &&
						<>
							{(Object.entries(this.props.currentAccount.tld).length > 0) &&
								<div className="card col-md-12">
									{this.props.currentAccount.tld.name === 'com' &&
										<p className="delete pull-left">
											<i class="far fa-check-circle"></i> Your domain extension is ".com". That's good.
										</p>
									}
									{(this.props.currentAccount.tld.name != 'com' && this.props.currentAccount.tld.badness_index < 5.3) &&
										<p className="delete pull-left">
											<i class="far fa-check-circle"></i> Your domain extension is OK.
										</p>
									}
									{(this.props.currentAccount.tld.name != 'com' && this.props.currentAccount.tld.badness_index >= 5.3 && this.props.currentAccount.tld.badness_index < 10) &&
										<p className="delete pull-left">
											<i class="far fa-dot-circle"></i> Your domain extension could potentially make you land in spam for some providers.
										</p>
									}
									{(this.props.currentAccount.name != 'com' && this.props.currentAccount.tld.badness_index >= 10) &&
										<>
											<p className="delete pull-left">
												<i class="far fa-dot-circle"></i> Your domain extension ".{this.props.currentAccount.tld.name}" can make you land in spam for some providers.
											</p>
										</>
									}
									<div className="clearfix"></div>
								</div>
							}
						</>
					}
				</>
			}

		{this.state.blacklist_sending_ip &&
			<>
				{this.state.blacklist_sending_ip.length == 0 &&
					<div className="card col-md-12">
						<p className="delete pull-left">
							<i class="far fa-check-circle"></i>
							Your email server's IP is not listed on significant blacklists.
						</p>
						<div className="clearfix"></div>
					</div>
				}

				{(this.state.blacklist_sending_ip.length > 0 && !['gmail', 'outlook'].includes(this.props.currentAccount.provider)) &&
					<div className="card col-md-12">
						<p className="delete pull-left">
							<i class="far fa-dot-circle"></i>
							Your email server's IP is listed on {this.state.blacklist_sending_ip.length} blacklist(s).
						</p>
						<button type="submit" className="btn btn-secondary pull-right" onClick={() => this.setState({showBlacklistModal: true, blacklistsModal: this.state.blacklist_sending_ip})}>Details</button>
						<div className="clearfix"></div>
					</div>
				}

				{(this.state.blacklist_sending_ip.length > 0 && ['gmail', 'outlook'].includes(this.props.currentAccount.provider)) &&
					<>
						{(this.state.blacklist_sending_ip.length > 0 && ['gmail'].includes(this.props.currentAccount.provider)) &&
							<div className="card col-md-12">
								<p className="delete pull-left">
									<i class="far fa-dot-circle"></i>
									Your emails are sent from Google IPs, that’s great for deliverability.

									<Tooltip title='Even if Google IPs are blacklisted (external blacklist organizations), these are ones of the best sending IPs to land in inbox compared to other email providers.' placement="left">
										<i class="fas fa-info-circle"></i>
									</Tooltip>
								</p>
								<div className="clearfix"></div>
							</div>
						}

						{(this.state.blacklist_sending_ip.length > 0 && ['outlook'].includes(this.props.currentAccount.provider)) &&
							<div className="card col-md-12">
								<p className="delete pull-left">
									<i class="far fa-dot-circle"></i>
									Your emails are sent from Microsoft IPs, that’s great for deliverability.

									<Tooltip title='Even if some Microsoft IPs are blacklisted (external blacklist organizations), these are ones of the best sending IPs to land in inbox compared to other email providers.' placement="left">
										<i class="fas fa-info-circle"></i>
									</Tooltip>
								</p>
								<div className="clearfix"></div>
							</div>
						}
					</>
				}
			</>
		}

		{(this.props.currentTest && !this.state.blacklist_sending_ip) &&
			<>
				{this.props.currentTest.blacklist_sending_ip.length == 0 &&
					<div className="card col-md-12">
						<p className="delete pull-left">
							<i class="far fa-check-circle"></i>
							Your email server's IP is not listed on significant blacklists.
						</p>
						<div className="clearfix"></div>
					</div>
				}

				{this.props.currentTest.blacklist_sending_ip.length > 0 &&
					<div className="card col-md-12">
						<p className="delete pull-left">
							<i class="far fa-dot-circle"></i>
							Your email server's IP is listed on {this.props.currentTest.blacklist_sending_ip.length} blacklist(s).
						</p>
						<button type="submit" className="btn btn-secondary pull-right" onClick={() => this.setState({showBlacklistModal: true, blacklistsModal: this.props.currentTest.blacklist_sending_ip})}>Details</button>
						<div className="clearfix"></div>
					</div>
				}

				{(this.props.currentTest.sending_provider && localStorage.getItem('admin')) &&
					<div className="card col-md-12">
						<p className="delete pull-left">
							<i class="far fa-check-circle"></i>
							Your email has been sent using {this.props.currentTest.sending_provider}.
						</p>
						<div className="clearfix"></div>
					</div>
				}
			</>
		}

      <div className="card col-md-12">
        {this.state.blacklists.filter(b => b.listed).length === 0 &&
          <p className="delete pull-left">
            <i class="far fa-check-circle"></i> Your domain isn't listed in any blacklist. Well done.
          </p>
        }
        {this.state.blacklists.filter(b => b.listed).length > 0 &&
          <>
            <p className="delete pull-left">
              <i class="far fa-dot-circle"></i> Your domain is in {this.state.blacklists.filter(b => b.listed).length} blacklist(s)
            </p>
          </>
        }
				<button className="btn btn-secondary pull-right" onClick={() => this.setState({showBlacklistModal: true, blacklistsModal: this.state.blacklists})}>See blacklists</button>
				<div className="clearfix"></div>
      </div>

			{(this.props.currentTest || (CreatedInDayAgo(this.props.currentAccount.created_at) > 1) || this.props.domain.spf_value !== null) &&
	      <div className="card col-md-12">
	          {(this.props.domain.spf_valid && this.props.domain.spf_value) &&
							<>
								<p className="delete pull-left">
			            <Tooltip title='SPF well setup 👌' placement="left">
			              <div className="pull-left">
			                <i class="far fa-check-circle"></i> SPF check - we found a valid record
			              </div>
			            </Tooltip>
								</p>
								<button type="submit" className="btn btn-secondary pull-right" onClick={() => this.openRecordValue('SPF', this.props.currentAccount.domain.spf_value)}>Details</button>
							</>
	          }
						{((this.props.domain.spf_value) && !this.props.domain.spf_valid) &&
							<>
								<p className="delete pull-left">
									<Tooltip title='SPF not found ⚠️' placement="left">
										<div className="pull-left">
											<i class="far fa-times-circle"></i> SPF check - we found a record but it's not valid
										</div>
									</Tooltip>
								</p>
								<button type="submit" className="btn btn-secondary pull-right" onClick={() => this.openRecordValue('SPF_INVALID', this.props.currentAccount.domain.spf_value)}>Details</button>
							</>
						}
	          {!this.props.domain.spf_value &&
							<>
								<p className="delete pull-left">
									<Tooltip title='Check our recommendations ⚠️' placement="left">
			              <div className="pull-left">
			              	<i class="far fa-times-circle"></i> SPF check - No record found
										</div>
			            </Tooltip>
								</p>
								<a className="btn btn-secondary pull-right" href="https://help.mailreach.co/en/article/how-to-properly-set-up-spf-and-dkim-records-1hlbcuq/#1-how-to-set-up-your-spf-record" target="_blank">How to setup SPF</a>
							</>
	          }
					<div className="clearfix"></div>
	      </div>
			}


     {(this.props.currentTest || CreatedInDayAgo(this.props.currentAccount.created_at) > 1 ||  (this.props.domain.dkim_value !== null)) &&
	      <div className="card col-md-12">
	        <p className="delete pull-left">
	          {((this.props.domain.dkim_value && this.props.domain.dkim_valid) || (!this.props.domain.dkim_value && this.props.domain.dkim_valid)) &&
	            <>
	              <i class="far fa-check-circle"></i> DKIM check - We found a valid record on selector &nbsp;
								{this.props.domain.dkim_selector &&
									<>
									  {this.props.domain.dkim_selector.length > 25 &&
											<>
											  {this.props.domain.dkim_selector.substring(0, 25)} ...
											</>
										}
										{this.props.domain.dkim_selector.length <= 25 &&
											<>
											  {this.props.domain.dkim_selector}
											</>
										}
									</>
								}
	            </>
	          }
						{((this.props.domain.dkim_value) && !this.props.domain.dkim_valid) &&
							<>
								<i class="far fa-times-circle"></i> DKIM check - we found a record but it's not valid on selector &nbsp;
								{this.props.domain.dkim_selector &&
									<>
										{this.props.domain.dkim_selector.length > 25 &&
											<>
												{this.props.domain.dkim_selector.substring(0, 25)} ...
											</>
										}
										{this.props.domain.dkim_selector.length <= 25 &&
											<>
												{this.props.domain.dkim_selector}
											</>
										}
									</>
								}
							</>
						}
	          {(!this.props.domain.dkim_value && !this.props.domain.dkim_valid) &&
	            <>
	              <i class="far fa-times-circle"></i> DKIM check - No record found
	            </>
	          }

	        </p>
	        {(this.props.domain.dkim_value && this.props.domain.dkim_valid) &&
	          <button type="submit" className="btn btn-secondary pull-right" onClick={() => this.openRecordValue('DKIM', this.props.currentAccount.domain.dkim_value)}>Details</button>
	        }
	        {(!this.props.domain.dkim_value && !this.props.domain.dkim_valid) &&
						<a className="btn btn-secondary pull-right" href="https://help.mailreach.co/en/article/how-to-properly-set-up-spf-and-dkim-records-1hlbcuq/#1-how-to-set-up-your-dkim-records" target="_blank">How to setup DKIM</a>
	        }

					{(this.props.domain.dkim_value && !this.props.domain.dkim_valid) &&
							<button type="submit" className="btn btn-secondary pull-right" onClick={() => this.openRecordValue('DKIM_INVALID', this.props.currentAccount.domain.dkim_value)}>Details</button>
					}
					<div className="clearfix"></div>
	      </div>
			}

			{(this.props.currentTest || CreatedInDayAgo(this.props.currentAccount.created_at) > 1) &&
	      <div className="card col-md-12">
	        <p className="delete pull-left">
	          {(this.props.domain.dmarc_value ||  this.props.domain.dmarc_valid) &&
	            <>
	              <i class="far fa-check-circle"></i> DMARC check - We found a record
	            </>
	          }
	          {(!this.props.domain.dmarc_value && !this.props.domain.dmarc_valid) &&
	            <>
	              <i class="far fa-dot-circle"></i> DMARC check - No record found
	            </>
	          }

	        </p>
	        {this.props.domain.dmarc_value &&
	          <button type="submit" className="btn btn-secondary pull-right" onClick={() => this.openRecordValue('DMARC', this.props.currentAccount.domain.dmarc_value)}>Details</button>
	        }
	        {(!this.props.domain.dmarc_value && !this.props.domain.dmarc_valid) &&
	          <button type="submit" className="btn btn-secondary pull-right" onClick={() => this.openInappMessage('accounts_checkup_dmarc_setup_tuto')}>How to setup DMARC</button>
	        }
					<div className="clearfix"></div>
	      </div>
			}

      {/*
        <div className="card col-md-12">
          {this.state.currentAccount.domain.domain_http_response &&
            <p className="delete pull-left">
              <i class="far fa-check-circle"></i> Website setup don't use secure connection (HTTPS)
            </p>
          }
          {!this.state.currentAccount.domain.domain_http_response &&
            <>
              <p className="delete pull-left">
                <i class="far fa-times-circle"></i> No website or redirection has been found behind {this.state.currentAccount.domain.domain} or www.{this.state.currentAccount.domain.domain}.
              </p>
              <button className="btn btn-secondary pull-right" onClick={() => this.openInappMessage('accounts_checkup_website_reco')}>Recommendations</button>
            </>
          }
        </div>
      */}


			{this.props.currentTest &&
				<div className="card col-md-12">
					<p className="delete pull-left">
						{this.props.currentTest.sending_reverse_dns_value &&
							<>
								<i class="far fa-check-circle"></i> Your mail server Reverse DNS is properly configured.
							</>
						}
						{!this.props.currentTest.sending_reverse_dns_value &&
							<>
								<i class="far fa-dot-circle"></i> Your mail server Reverse DNS is not configured.
							</>
						}

					</p>
					{this.props.currentTest.sending_reverse_dns_value &&
						<button type="submit" className="btn btn-secondary pull-right hide-responsive" onClick={() => this.openRecordValue('REVERSE_DNS', this.props.currentTest.sending_reverse_dns_value)}>Details</button>
					}
					<div className="clearfix"></div>
				</div>
			}

			{this.props.currentAccount &&
				<>
					{this.props.currentAccount.sending_ip &&
						<div className="card col-md-12">
							<p className="delete pull-left">
								{this.props.currentAccount.sending_reverse_dns_value &&
									<>
										<i class="far fa-check-circle"></i> Your mail server Reverse DNS is properly configured.
									</>
								}
								{!this.props.currentAccount.sending_reverse_dns_value &&
									<>
										<i class="far fa-dot-circle"></i> Your mail server Reverse DNS is not configured.
									</>
								}

							</p>
							{this.props.currentAccount.sending_reverse_dns_value &&
								<button type="submit" className="btn btn-secondary pull-right hide-responsive" onClick={() => this.openRecordValue('REVERSE_DNS', this.props.currentAccount.sending_reverse_dns_value)}>Details</button>
							}
							<div className="clearfix"></div>
						</div>
					}
				</>
			}

			{this.props.domain.mx_records &&
	      <div className="card col-md-12">
	        <p className="delete pull-left">
	          {this.props.domain.mx_records.length > 0 &&
	            <>
	              <i class="far fa-check-circle"></i> There are MX records on this domain
	            </>
	          }
	          {this.props.domain.mx_records.length == 0 &&
	            <>
	              <i class="far fa-dot-circle"></i> There are no MX records on this domain
	            </>
	          }

	        </p>
					<div className="clearfix"></div>
	      </div>
			}


    </div>

    <InappMessage keyname={this.state.openModalKey} show={this.state.openModalKey} handleCloseModal={this.handleCloseModal} />

    <Modal className="reputation-modal" show={this.state.showRecordModal} onHide={this.handleCloseModal}>
      <Modal.Body>
          <>
            <h1 className="text-center">
						{this.state.modalRecordName == 'SPF_INVALID' &&
							<>
								Invalid SPF record found
							</>
						}
						{this.state.modalRecordName == 'DKIM_INVALID' &&
							<>
								DKIM record found but it's not valid or incomplete.
							</>
						}

						{!['SPF_INVALID', 'DKIM_INVALID'].includes(this.state.modalRecordName) &&
							<>
								Your {this.state.modalRecordName} record
							</>
						}

						</h1>
            <div className="txt text-center">
							{this.state.modalRecordName == 'SPF_INVALID' &&
								<>
									{!this.state.modalRecordValue &&
										<>
											<p>A valid SPF record has to cover all the email providers used to send emails from your domain.</p>

											<p>There’s an SPF record set up for your domain BUT it doesn’t cover how your emails are sent OR there’s an error in the record.</p>

											<p>For example, if you send emails from your domain using Gmail, you need to properly set up your SPF for Gmail.</p>

											<p>Second example, if you send emails from your domain using Brevo and Outlook, your SPF record has to cover both Brevo and Outlook.</p>

											<p>Second example, if you send emails from your domain using Brevo and Outlook, your SPF record has to cover both Brevo and Outlook.</p>

											<p>Read our guide to fix your SPF 👉 <a href="https://help.mailreach.co/en/article/how-to-properly-set-up-spf-and-dkim-records-1hlbcuq/" target="_blank">How to properly setup SPF and DKIM record</a></p>
										</>
									}

									{this.state.modalRecordValue &&
										<div className="text-left">
											<p><b>Your current SPF record</b></p>

											<p className="line-break">{this.state.modalRecordValue}</p>

											<p><b>Guidelines</b></p>

											<p>Your SPF record can be detected as invalid for 3 reasons:</p>

											<ul className="no-list-style">
												<li>1. The record doesn’t match how your emails are sent on MailReach</li>
												<li>2. You have multiple SPF records. You should only have one.</li>
												<li>3. There is an error in the record</li>
											</ul>

											<p>To fix your SPF record, read the detailed instructions of our guide here 👉 <a href="https://help.mailreach.co/en/article/how-to-properly-set-up-spf-and-dkim-records-1hlbcuq/" target="_blank">How to fix the error: Invalid SPF record found</a></p>
										</div>
									}
								</>
							}

							{this.state.modalRecordName == 'DKIM_INVALID' &&
								<>
									{!this.state.modalRecordValue &&
										<>
											<p>There’s a DKIM record set up for your domain BUT it doesn’t cover how your emails are sent OR there’s an error in the record.</p>

											<p>For example, if you send emails from your domain using Gmail, you need to properly set up your DKIM for Gmail.</p>

											<p>Second example, if you send emails from your domain using Brevo and Outlook, you will need two separate DKIM records, one for Brevo and one for Outlook.</p>

											<p>Read our guide to fix your DKIM 👉 <a href="https://help.mailreach.co/en/article/how-to-properly-set-up-spf-and-dkim-records-1hlbcuq/" target="_blank">How to properly setup SPF and DKIM record</a></p>
										</>
									}


									{this.state.modalRecordValue &&
										<div className="text-left">
											<p><b>Your current DKIM record</b></p>

											<p className="line-break">{this.state.modalRecordValue}</p>

											<p><b>Guidelines</b></p>

											<p>Your DKIM record can be detected as invalid for 3 reasons:</p>

											<ul className="no-list-style">
												<li>1. The record doesn’t match how your emails are sent on MailReach</li>
												<li>2. The record doesn’t include the right selector</li>
												<li>3. There’s an error in the record</li>
											</ul>

											<p>To fix your DKIM record, read the detailed instructions of our guide here 👉 <a href="https://help.mailreach.co/en/article/how-to-properly-set-up-spf-and-dkim-records-1hlbcuq/" target="_blank">How to fix the error: Missing or Invalid DKIM record found</a></p>
										</div>
									}
								</>
							}

							{!['SPF_INVALID', 'DKIM_INVALID'].includes(this.state.modalRecordName) &&
								<>
									  <p className="line-break">{this.state.modalRecordValue}</p>
								</>
							}
            </div>
          </>
        <div className="footer text-center">
          <Button variant="secondary" onClick={this.handleCloseModal}>
            Close
          </Button>
        </div>
      </Modal.Body>
    </Modal>

		<Modal className="reputation-modal blacklists-modal" show={this.state.showBlacklistModal} onHide={this.handleCloseModal}>
			<Modal.Body>
					<>
						<h1 className="text-center">Blacklists check</h1>
						<div className="txt text-center">

						<div className="alert status warning card bounce">
							<span>
								Read our article about being blacklisted
							</span>
							<a href="https://help.mailreach.co/en/article/my-domain-is-blacklisted-is-it-serious-and-what-to-do-1qlp1f7/"  target="_blank" target="_blank" className="btn pull-right">Read</a>
						</div>


						{this.state.blacklistsModal.length == 0 &&
							<>
								<img src="/images/taken.svg" />
								<p>We don't have blacklist data for this domain. If you use a freemail (Like @gmail.com or @outlook.com) we don't run blacklists check. If not, we should run a check on the next 24 hours 😉</p>
							</>
						}


						{((this.state.blacklistsModal.filter(bl => bl.listed).length > 0) && this.props.currentTest) &&
							<>
								{false &&
									<div className="alert status warning card bounce">
										<p>You have a Google Inbox so the blacklisted IP is a Google IP. Google IPs can be blacklisted but it has ZERO impact on your deliverability as Google IPs have a very high sender reputation. In other words, relax.</p>
									</div>
								}
							</>
						}

						{((this.state.blacklistsModal.filter(bl => bl.listed).length > 0) && this.props.currentAccount) &&
							<>
								{false &&
									<div className="alert status warning card bounce">
										<p>You have a Google Inbox so the blacklisted IP is a Google IP. Google IPs can be blacklisted but it has ZERO impact on your deliverability as Google IPs have a very high sender reputation. In other words, relax.</p>
									</div>
								}
							</>
						}

						{this.state.blacklistsModal.length > 0 &&
							<table class="table">
								<thead>
									<tr>
										<th scope="col">List name</th>
										<th scope="col">Domain/IP</th>
										<th scope="col">Listed&nbsp;&nbsp;</th>
									</tr>
								</thead>
								<tbody>
								{this.state.blacklistsModal.map((blacklist, index) => {
									return (
										<tr>
											<td className="text-left">
												{blacklist.blacklist == 'fresh.spameatingmonkey.net' &&
													<Tooltip title="Don't panic, that's normal. This blacklist lists the 'fresh' domains that are only 5 days old or younger. It should go away once you pass the 5 days period." placement="bottom">
														<div>
															{blacklist.blacklist} &nbsp;
															<i class="fas fa-info-circle"></i>
														</div>
													</Tooltip>
												}
												{blacklist.blacklist != 'fresh.spameatingmonkey.net' &&
													<>
														{blacklist.blacklist}
													</>
												}
											</td>

											<td className="text-left">
												{blacklist.checked_value}
											</td>
											<td>
												{blacklist.listed &&
													<div className="red">
														<i class="far fa-times-circle"></i> Yes
													</div>
												}
												{!blacklist.listed &&
													<div className="green">
														<i class="far fa-check-circle"></i> No
													</div>
												}
											</td>
										</tr>
								)})}
								</tbody>
							</table>
						}
						</div>
					</>
				<div className="footer text-center">
					<Button variant="secondary" onClick={this.handleCloseModal}>
						Close
					</Button>
				</div>
			</Modal.Body>
		</Modal>
  </>
		)
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckupDomain));
