const request = require('superagent');

class AutomatedTestService {

	static create(account_id) {
    return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}automated_tests`)
						.send({account_id})
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}

  static get(id) {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}automated_tests/${id}`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }

	static all(page = 0) {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}automated_tests?page=${page}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}

	static count() {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}automated_tests/count`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}

	static update(automated_test) {
    return request
            .post(`${process.env['REACT_APP_BACKEND_URL']}automated_tests/${automated_test.id}`)
						.send(automated_test)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }

	static updateTarget(id, test) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}automated_tests/${id}`)
						.send(test)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}

	static runs(id, weekdays) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}automated_tests/${id}/runs`)
						.send({weekdays})
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}

	static runNow(id, weekdays) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}automated_tests/${id}/run_now`)
						.send({})
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}

	static delete(id) {
    return request
            .delete(`${process.env['REACT_APP_BACKEND_URL']}automated_tests/${id}`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }
}

export default AutomatedTestService;
