const request = require('superagent');

class ImapAuthService {

  static create(data) {
    // Add our coupon
    let coupon = document.cookie.replace(/(?:(?:^|.*;\s*)coupon\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    if(coupon){
      data.coupon = coupon;
    }

    let apiKey = localStorage.getItem('x-api-key');
    if(data.auth_api_key){
      apiKey = data.auth_api_key;
    }
    return request
            .post(`${process.env['REACT_APP_BACKEND_URL']}imap_auth`)
            .send(data)
            .set('X-Api-Key', `Bearer ${apiKey}`);
  }
}

export default ImapAuthService;
