import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {ToastContainer, toast} from 'react-toastify';
import Tooltip from '@material-ui/core/Tooltip';
import Loader from 'react-loader-spinner'
import Moment from 'react-moment';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import queryString from 'query-string';
import 'chartjs-plugin-annotation';
import {CreatedInDayAgo} from "../../../../../helpers/time"

import InappMessage from '../../../../../components/inapp-message/inapp-message.js';

import CheckupDomain from '../../../../../components/checkup-domain/checkup-domain.js';

import i18n from "../../../../../i18n";

import AccountService from '../../../../../services/account.service';
import CopilotService from '../../../../../services/copilot.service';

import { CHARTSJS_TOOLTIPS_STYLE } from "../../../../../constants";

import './checkups.scss';

class DashboardAccountsCheckups extends React.Component {
	constructor(props) {
		super(props);
    this.state = {errors: [], currentAccount: { domain: {}}, blacklists: [], loading: true, statsPastDays: 14, campaignsData: {labels: [], data: []}};
		this.loadAccount = this.loadAccount.bind(this);
		this.refreshCheckup = this.refreshCheckup.bind(this);
		this.generateChartData = this.generateChartData.bind(this);
		this.setStateDays = this.setStateDays.bind(this);
		this.loadCopilot = this.loadCopilot.bind(this);
		this.getMessageCopilot = this.getMessageCopilot.bind(this);
	}

  componentWillMount() {
    this.loadAccount();

		let queryParams = queryString.parse(this.props.location.search);
		this.setState({queryParams})
	}


	setStateDays(statsPastDays){
		// TODO : manage provider
		this.setState({statsPastDays});
		this.loadCopilot(statsPastDays);
	}

	loadAccount(){
		AccountService.get(this.props.match.params.account_id).then((response) => {
			this.setState({
				currentAccount: response.body,
				loading: false
			});

			if(response.body.copilot_enabled){
				// Load copilot charts data
				this.loadCopilot();
			}
		}).catch((error) => {
			toast.error('Oops, we have an error');
		});
	}

	loadCopilot(pastDays = 14){
		CopilotService.getCampaignsHistory(this.props.match.params.account_id, pastDays).then((response) => {
			this.setState({
				campaignsData: response.body
			});
			this.generateChartData();
		}).catch((error) => {
			console.log(error);
			toast.error('Oops, we have an error');
		});
	}

	getMessageCopilot(){
		let msg = '';
		let copilotMessagePicto = 'green';
		let maxSent = Math.max(...this.state.campaignsData.data);
		if(CreatedInDayAgo(this.state.currentAccount.domain) > 14){
			if(maxSent < 101){
				msg = '<i class="far fa-check-circle"></i> <p className="copilot-txt-insights">The volume of your own email campaigns is not too high</p>';
			}else if (maxSent < 121) {
				msg = '<i class="far fa-dot-circle"></i> <p className="copilot-txt-insights">The sending volume of your own campaigns is risky, it can harm the sender reputation</p>';
				copilotMessagePicto = 'orange';
			}else{
				msg = '<i class="far fa-dot-circle"></i> <p className="copilot-txt-insights">The sending volume of your own campaigns is too high, it\'s harmful for the sender reputation</p>';
				copilotMessagePicto = 'red';
			}
		} else {
			if(maxSent < 11){
				msg = '<i class="far fa-check-circle"></i> <p className="copilot-txt-insights">No campaigns sending during initial warm up phase. All good</p>';
			}else{
				msg = '<i class="far fa-dot-circle"></i> <p className="copilot-txt-insights">Harmful practice: emails are being sent while the domain is too young';
				copilotMessagePicto = 'red';
			}
		}

		this.setState({copilotMessage: msg, copilotMessagePicto: copilotMessagePicto})
	}

	domainCreateInDayAgo(date) {
    var today = new Date();
		const startDate = new Date(date);
		const diffInMs = Math.abs(startDate - today);
		return Number(((diffInMs / (1000 * 60 * 60 * 24))).toFixed(0));
	}

	refreshCheckup(){
		AccountService.checkupRefresh(this.props.match.params.account_id).then((response) => {
      this.setState({
        loading: true
      });
			toast.success("We're checking your domain, one moment please");
			setTimeout(
			  () => {
					this.loadAccount();
					this.setState({
						loading: false
					});
					toast.success('Checkup done !');
				},
			  10000
			);
    }).catch((error) => {
      toast.error('Oops, we have an error');
    });
	}

	barStyle(){
		let config = this.graphStyle();
		config['scales']['xAxes'][0]['stacked'] = true;
		config['scales']['yAxes'][0]['stacked'] = true;
		return config;
	}

	generateChartData(){


		// Todo : Get max value and add line break

		let finalChartCampaignsData = {
			labels: this.state.campaignsData.labels,
				datasets: [{

					label: 'Outgoing',
					type: 'bar',
					backgroundColor: '#41d9cc',
					hoverBackgroundColor: '#41d9cc',
					data: this.state.campaignsData.data
				}]
		};

		this.setState({finalChartCampaignsData});
		this.getMessageCopilot();
	}


	graphStyle(){
		return {
			layout: {
        padding: {
            left: 0,
            right: 0,
            top: 10,
            bottom: 0
        }
    },
		annotation: {
			annotations: [{
		                  id: 'box1',
		                  type: 'box',
											xScaleID: 'x-axis-0',
											yScaleID: 'y-axis-0',
		                  xMin: -1,
		                  xMax: 200,
		                  yMin: 0,
		                  yMax: 150,
		                  backgroundColor: 'rgb(47 208 89 / 5%)'
		                },
										{
									                  id: 'box2',
									                  type: 'box',
																		xScaleID: 'x-axis-0',
																		yScaleID: 'y-axis-0',
									                  xMin: -1,
									                  xMax: 200,
									                  yMin:150,
									                  yMax: 200,
									                  backgroundColor: 'rgb(253 136 49 / 5%)'
									                },{
																                  id: 'box3',
																                  type: 'box',
																									xScaleID: 'x-axis-0',
																									yScaleID: 'y-axis-0',
																                  xMin: -1,
																                  xMax: 200,
																                  yMin:200,
																                  yMax: 4000,
																                  backgroundColor: 'rgb(255 29 81 / 5%)'
																                }]
	                       },
			legend: {
				display: true,
				labels: {
					usePointStyle: true
				}
			},
			scales: {
		    xAxes: [{
		      gridLines: {
						display:false
					},
		    }],
				y: {
	border: {
		display: false
	},
	grid: {
		color: function(context) {
			if (context.tick.value < 0) {
				return Utils.CHART_COLORS.green;
			} else if (context.tick.value < 0) {
				return Utils.CHART_COLORS.red;
			}

			return '#000000';
		},
	},
},
				yAxes: [{
					gridLines: {
							display:true
					},
             ticks: {
                precision:0,
								min: 0,
             }
         },
			 ],
		  },
			tooltips: CHARTSJS_TOOLTIPS_STYLE
		}
	}

	render() {
    if(this.state.loading){
      return(
        <div className="app container text-center">
          <div className="loader">
            <Loader
             type="Oval"
             color="#b7b7b7"
             height="100"
             width="100"
            />
          </div>
        </div>
      )
    }

		return (
			<div className="app container account-page checkups">
					{(this.state.queryParams.copilot && this.state.finalChartCampaignsData) &&
						<>
						<div className="row">
							<h2>Checks - Your campaigns</h2>

							<div className="col-md-12 no-padding-left">
								<div className="col-md-6 no-padding-left">
									<div className="card">
										<div className="title">
											Your campaigns activity
											<Dropdown className="dropdown-date-filter pull-right last-days-filter">
												<Dropdown.Toggle className="btn" id="dropdown-basic">
													Last {this.state.statsPastDays} days <i class="fas fa-angle-down" aria-hidden="true"></i>
												</Dropdown.Toggle>

												<Dropdown.Menu>
													<Dropdown.Item onClick={() => this.setStateDays(14)}>Last 14 days</Dropdown.Item>
													<Dropdown.Item onClick={() => this.setStateDays(30)}>Last 30 days</Dropdown.Item>
													<Dropdown.Item onClick={() => this.setStateDays(90)}>Last 3 months</Dropdown.Item>
													<Dropdown.Item onClick={() => this.setStateDays(180)}>Last 6 months</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										</div>

										<Bar key={this.state.providerFilter} data={this.state.finalChartCampaignsData} options={this.barStyle()}/>
									</div>
								</div>
								<div className="col-md-6 card no-padding-right copilot-insights">

									<div className="txt" dangerouslySetInnerHTML={{__html: this.state.copilotMessage}}>
									</div>
								</div>
								</div>
							</div>
						</>
					}

					<div className="row">
					<h2>Checks - {this.state.currentAccount.domain.domain}</h2>

					<div className="pull-right">
						<div className="last-refresh">Last refresh: <Moment fromNow>{this.state.currentAccount.domain.updated_at}</Moment></div>
					</div>

					<CheckupDomain domain={this.state.currentAccount.domain} currentAccount={this.state.currentAccount} queryParams={this.state.queryParams}/>
        </div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		currentProject: state.currentProject
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardAccountsCheckups));
