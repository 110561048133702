import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {ToastContainer, toast} from 'react-toastify';
import Loader from 'react-loader-spinner';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import queryString from 'query-string';


import SpamCheckerService from '../../../../../services/spamChecker.service';
import AutomatedTestService from '../../../../../services/automatedTest.service';


class DashboardSpamTestAutomatedTarget extends React.Component {
	constructor(props) {
		super(props);
    this.state = {spamLogs: [], automatedTest: {}, selectedDays: []};
    //this.createAutomatedTest = this.createAutomatedTest.bind(this);
    this.onChange = this.onChange.bind(this);
    this.updateAndRedirect = this.updateAndRedirect.bind(this);
		this.updateSeedlist = this.updateSeedlist.bind(this);
		this.setRadioSeedlist = this.setRadioSeedlist.bind(this);
	}

	componentWillMount() {
    AutomatedTestService.get(this.props.match.params.automated_test_id).then((response) => {
      this.setState({automatedTest: response.body})

    })
    .catch((error) => {
      this.setState({error: true, signupForm: false, loading: false});
      toast.error('Oops. An error occured.');
    });

		SpamCheckerService.stats().then((response) => {
			this.setState({spamcheckStats: response.body})

		})
		.catch((error) => {
			this.setState({error: true, signupForm: false, loading: false});
			toast.error('Oops. An error occured.');
		});
	}

  onChange(e) {
		const field = event.target.name;
		const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    let automatedTest = this.state.automatedTest;
    automatedTest[field] = value;
		return this.setState({automatedTest});
	}

	selectAutomatedTestTarget(type){
		let automatedTest = this.state.automatedTest;
		if(type == 'btob'){
			automatedTest['btob'] = true;
			automatedTest['btoc'] = false;
		}else if (type == 'btoc') {
			automatedTest['btoc'] = true;
			automatedTest['btob'] = false;

		}else{
			automatedTest['btob'] = true;
			automatedTest['btoc'] = true;
		}
		this.setState({automatedTest});
	}

  updateAndRedirect(){
    AutomatedTestService.updateTarget(this.state.automatedTest.id, this.state.automatedTest).then((response) => {
      // Redirect to next step
      this.props.history.push(`/dashboard/${this.props.match.params.project_id}/spam-tests/auto/${this.props.match.params.automated_test_id}/frequency`);
    })
    .catch((error) => {
      this.setState({error: true, signupForm: false, loading: false});
      toast.error('Oops. An error occured.');
    });
  }

	setRadioSeedlist(seedlist){
		let automatedTest = this.state.automatedTest;
		automatedTest['seedlist'] = seedlist;
		this.setState({seedlist, automatedTest});
	}

	updateSeedlist(e){
		e.preventDefault();
		let automatedTest = this.state.automatedTest;
		automatedTest['seedlist'] = e.target.value;
		this.setState({seedlist: e.target.value, automatedTest});
	}



	render() {
		if(this.state.loading){
			return(
				<div className="app container text-center">
					<div className="loader">
						<Loader
						 type="Oval"
						 color="#b7b7b7"
						 height="100"
						 width="100"
						/>
					</div>
				</div>
			)
		}


		return(
			<>
        <div className="app container home">

          <div className="progress-automated-test">
            <ul>
							<NavLink to={`/dashboard/${this.props.currentProject.id}/spam-tests/auto/${this.state.automatedTest.id}/accounts`} className="done"></NavLink>
							<NavLink to={`/dashboard/${this.props.currentProject.id}/spam-tests/auto/${this.state.automatedTest.id}/template`} className="done"></NavLink>
              <li className="selected"></li>
              <li></li>
							<li></li>
            </ul>
            <div className="bar"></div>
          </div>

          <h1>Select inbox types and seed list options</h1>

						<div className="card automated-spamcheck">
							<div className="requested-type-selector">
								<div className="alert alert-purple-light yellow">
									<p><b className="no-padding-top">To avoid misleading results, run your spam tests only on inboxes your recipients use.</b> Choose Professional, Personal or All types of inboxes based on your target audience.</p>
								</div>
								<p><b>Select the type of inboxes to test :</b></p>
								<button className={((this.state.automatedTest.btob && !this.state.automatedTest.btoc) ? 'selected ' : '') + " selector blue"} onClick={() => this.selectAutomatedTestTarget('btob')}>👔 Professional inboxes</button>
								<button className={((!this.state.automatedTest.btob && this.state.automatedTest.btoc) ? 'selected ' : '') + " selector yellow"} onClick={() => this.selectAutomatedTestTarget('btoc')}>🙎 Personal inboxes</button>
								<button className={((this.state.automatedTest.btob && this.state.automatedTest.btoc) ? 'selected ' : '') + " selector red"} onClick={() => this.selectAutomatedTestTarget('all')}>‍🌎 All types of inboxes</button>
								<div class="clearfix"></div>
							</div>


							<div className="requested-type-selector">
							<p><b>Seed List Preferences :</b></p>

							<div className="requested-type-selector">
								<div className="pull-left seedlist-radio">
									<input class="form-check-input" type="radio" value="rotate" name="radioSeedlistOption" id="flexRadioDefault1" checked={this.state.automatedTest.seedlist === 0} onChange={() => {this.setRadioSeedlist(0)}}/>
								</div>
								<p className="cursor-pointer" onClick={() => { this.setRadioSeedlist(0) }}><b>Auto-rotate seed lists (recommended)</b></p>
								<p className="seedlist-desc-opt">Rotate between 4 seed lists to minimize bias and get more reliable spam test results.</p>
								<div class="clearfix"></div>
							</div>

							<div className="requested-type-selector">
								<div className="pull-left seedlist-radio">
									<input class="form-check-input" type="radio" value="fixed" name="radioSeedlistOption" id="flexRadioDefault1" checked={this.state.automatedTest.seedlist > 0} onChange={() => {this.setRadioSeedlist(1)}}/>
								</div>
								<p className="cursor-pointer" onClick={() => {this.setRadioSeedlist(1)}}><b>Always use the same seed list</b></p>
								<p className="seedlist-desc-opt">Select one of the 4 seed lists to consistently track your results against the same inboxes.</p>
								{this.state.automatedTest.seedlist > 0 &&
									<select class="timezones pull-left seedlist-select" value={this.state.automatedTest.seedlist || this.state.seedlist} onChange={this.updateSeedlist} aria-label="select">
									  <option value="1">Seed List 1</option>
									  <option value="2">Seed List 2</option>
									  <option value="3">Seed List 3</option>
										<option value="4">Seed List 4</option>
									</select>
								}
								<div class="clearfix"></div>
								</div>
							</div>
						</div>


          <div className="text-center automated-spamtest-btns">
            <NavLink to={`/dashboard/${this.props.currentProject.id}/spam-tests/auto/${this.state.automatedTest.id}/template`} className="btn btn-primary grey">
              Go back
            </NavLink>
            <button className="btn btn-primary purple" onClick={this.updateAndRedirect} disabled={!this.state.automatedTest.btob && ! this.state.automatedTest.btoc}>Next ></button>
          </div>



        </div>

      </>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		currentProject: state.currentProject,
		currentPricing: state.currentPricing
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardSpamTestAutomatedTarget));
