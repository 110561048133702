import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {ToastContainer, toast} from 'react-toastify';
import Loader from 'react-loader-spinner'
import { NavLink } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { Button, Modal } from 'react-bootstrap';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Slider from '@material-ui/core/Slider';
import Switch from '@material-ui/core/Switch';
import { Bar } from 'react-chartjs-2';

import i18n from "../../../../../i18n";

import WordsAnalyzer from '../../../../../components/words-analyzer/words-analyzer.js';

import ConfigService from '../../../../../services/config.service';
import AccountService from '../../../../../services/account.service';
import ContentSpamwordService from '../../../../../services/contentSpamword.service';

import { CHARTSJS_TOOLTIPS_STYLE } from "../../../../../constants";

import './settings.scss';

const MailreachSlider = withStyles({
root: {
	color: '#11103b',
	height: 8,
},
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
	  fontSize: 14,
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const GreenSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#00d463',
    },
    '&$checked + $track': {
      backgroundColor: '#00d463',
    },
  },
  checked: {},
  track: {},
})(Switch);

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#11103b',
    },
    '&$checked + $track': {
      backgroundColor: '#11103b',
    },
  },
  checked: {},
  track: {},
})(Switch);


class DashboardAccountsSettings extends React.Component {
	constructor(props) {
		super(props);
    this.state = {errors: [], currentAccount: {}, timezones: [], signatureSpamwords:[], signatureMaxLength: 5000, warmupForecast: {}, aliases: [], autopilot: true, loading: true}
    this.delete = this.delete.bind(this);
    this.suspend = this.suspend.bind(this);
    this.unsuspend = this.unsuspend.bind(this);
    this.updateTimezone = this.updateTimezone.bind(this);
    this.handleSignatureEditorChange = this.handleSignatureEditorChange.bind(this);
    this.saveSignature = this.saveSignature.bind(this);
		this.reconnect = this.reconnect.bind(this);
		this.generateForecast = this.generateForecast.bind(this);
		this.updateRampupTarget = this.updateRampupTarget.bind(this);
		this.updateRampupIncrease = this.updateRampupIncrease.bind(this);
		this.resetRampup = this.resetRampup.bind(this);
		this.saveRampupConfig = this.saveRampupConfig.bind(this);
		this.toggleAccountConfigSmtpAppendAfterSent = this.toggleAccountConfigSmtpAppendAfterSent.bind(this);
		this.toggleAccountPrivacy = this.toggleAccountPrivacy.bind(this);
		this.refreshAutopilot = this.refreshAutopilot.bind(this);
		this.toggleAutopilot = this.toggleAutopilot.bind(this);
		this.selectDisconnectReason = this.selectDisconnectReason.bind(this);
		this.updateConfigMaxConversationRunning = this.updateConfigMaxConversationRunning.bind(this);
		this.updateConfigWarmingOnlyOn = this.updateConfigWarmingOnlyOn.bind(this);
		this.toggleWarmingRecipe = this.toggleWarmingRecipe.bind(this);
		this.toggleAccountCopilot = this.toggleAccountCopilot.bind(this);
		this.loadAliases = this.loadAliases.bind(this);
		this.setAlias = this.setAlias.bind(this);
	}

  componentWillMount() {
    AccountService.get(this.props.match.params.account_id).then((response) => {
      this.setState({
        currentAccount: response.body,
        loading: false
      });
			this.refreshAutopilot();
			this.generateForecast();

			// Load aliase only if this is an Outlook account
			if(response.body.provider == 'outlook' || response.body.xoauth){
				this.loadAliases();
			}
    }).catch((error) => {
      toast.error('Oops, we have an error');
    });
		ConfigService.timezones().then((response) => {
			this.setState({
				timezones: response.body.timezones.sort(function(a, b){
				    if(a.name < b.name) { return -1; }
				    if(a.name > b.name) { return 1; }
				    return 0;
				}),
			});
		}).catch((error) => {
			console.error(error);
		});
	}

  delete(e){
    e.preventDefault();
    AccountService.delete(this.props.match.params.account_id, this.state.disconnectReason).then((response) => {
      toast.success("Account successfully deleted");
      this.props.history.push(`/dashboard/${this.props.match.params.project_id}/home?deleting=true`);
    }).catch((error) => {
      toast.error(i18n.t('alert.generic'));
    });
  }

	loadAliases(){
		AccountService.aliases(this.props.match.params.account_id).then((response) => {
			this.setState({
				aliases: response.body ? response.body['aliases'] : [],
			});
		}).catch((error) => {
			toast.error(i18n.t('alert.generic'));
		});
	}

	setAlias(e){
		e.preventDefault();
		let email = e.target.value;
		let currentAccount = this.state.currentAccount;
		AccountService.update(this.props.match.params.account_id, {alias: email}).then((response) => {
			toast.success("Account successfully updated");
			currentAccount.email= email;
			this.setState({currentAccount});
			window.location.reload(); //reload windows
		}).catch((error) => {
			toast.error(i18n.t('alert.generic'));
		});
	}

  suspend(e){
    e.preventDefault();
    AccountService.suspend(this.props.match.params.account_id, true).then((response) => {
      toast.success("Warming successfully suspended");
      this.setState({
        currentAccount: response.body,
        loading: false,
      });
    }).catch((error) => {
      toast.error(i18n.t('alert.generic'));
    });
  }

  unsuspend(e){
    e.preventDefault();
    AccountService.suspend(this.props.match.params.account_id, false).then((response) => {
      toast.success("Warming successfully started");
      this.setState({
        currentAccount: response.body,
        loading: false,
      });
    }).catch((error) => {
      toast.error(i18n.t('alert.generic'));
    });
  }

	reconnect(e){
		e.preventDefault();
		AccountService.reconnect(this.props.match.params.account_id).then((response) => {
			toast.success("We will try to reconnect your account on the next 60 mins");
		}).catch((error) => {
			toast.error(i18n.t('alert.generic'));
		});
	}


  saveSignature(){
    this.setState({saveSignatureLoading: true});
    // First step, check if signature is spammy
    // If not, save the signature
		AccountService.update(this.props.match.params.account_id, {signature: this.state.currentAccount.signature}).then((response) => {
			toast.success("Account successfully updated");
			this.setState({saveSignatureLoading: false});
		}).catch((error) => {
			toast.error(i18n.t('alert.generic'));
			this.setState({saveSignatureLoading: false});
		});
  }

  handleSignatureEditorChange(signature){
    let currentAccount = this.state.currentAccount;
    currentAccount.signature = signature;
    this.setState({currentAccount});
  }

  openChatbox(){
    window.$crisp.push(['do', 'chat:open']);
  }

  updateTimezone(e){
    e.preventDefault();
    AccountService.update(this.props.match.params.account_id, {time_zone: e.target.value}).then((response) => {
      toast.success("Account successfully updated");
    }).catch((error) => {
      toast.error(i18n.t('alert.generic'));
    });
  }

	barStyle(){
		let config = this.graphStyle();
		config['scales']['xAxes'][0]['stacked'] = true;
		config['scales']['yAxes'][0]['stacked'] = true;
		return config;
	}

	dateToDM(date) {
    var strArray=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var d = date.getDate();
    var m = strArray[date.getMonth()];
    var y = date.getFullYear();
    return '' + m + ' ' + (d <= 9 ? '0' + d : d);
	}

updateRampupTarget(e, value){
	let currentAccount = this.state.currentAccount;
	if(value == currentAccount.config_rampup_target){
		return;
	}
	currentAccount.config_rampup_target = value;
	this.setState({currentAccount});
	this.generateForecast();
}

updateRampupIncrease(e, value){
	let currentAccount = this.state.currentAccount;
	if(value == currentAccount.config_rampup_increase){
		return;
	}
	currentAccount.config_rampup_increase = value;
	this.setState({currentAccount});
	this.generateForecast();
}

updateConfigMaxConversationRunning(e, value){
	let currentAccount = this.state.currentAccount;
	if(value == currentAccount.config_max_conversation_running){
		return;
	}
	currentAccount.config_max_conversation_running = value;
	this.setState({currentAccount});
	this.generateForecast();
}

updateConfigWarmingOnlyOn(provider){
	let currentAccount = this.state.currentAccount;
	if(!currentAccount.warming_only_on.includes(provider)){
		currentAccount.warming_only_on.push(provider)
	}else{
		currentAccount.warming_only_on = currentAccount.warming_only_on.filter(element => element !== provider);
	}
	currentAccount.warming_only_on = currentAccount.warming_only_on.filter((item, index) => currentAccount.warming_only_on.indexOf(item) === index);
	this.setState({currentAccount});

	AccountService.update(this.props.match.params.account_id, {warming_only_on: this.state.currentAccount.warming_only_on}).then((response) => {
		toast.success("Account successfully updated");
	}).catch((error) => {
		toast.error(i18n.t('alert.generic'));
	});
}

refreshAutopilot(){
	if((this.state.currentAccount.config_rampup_increase == 1 && this.state.currentAccount.config_rampup_target == 16) && this.state.currentAccount.autopilot){
		this.setState({autopilot: true});
	} else {
		this.setState({autopilot: false});
	}
	this.setState({rampup_increase_slider_value: this.state.currentAccount.config_rampup_increase,
		             rampup_target_slider_value: this.state.currentAccount.config_rampup_target,
							   config_max_conversation_running: this.state.currentAccount.config_max_conversation_running,})
}

toggleAutopilot(){

	if(this.state.autopilot){
		this.setState({autopilot: false});
	}else{
		this.setState({autopilot: true});
		this.updateRampupIncrease(null, 1);
		this.updateRampupTarget(null, 16);
		this.generateForecast();
	}

	// Save autopilot settings inside the database
	AccountService.update(this.props.match.params.account_id, {autopilot: !this.state.autopilot}).then((response) => {

		this.saveRampupConfig();
		//toast.success("Account successfully updated");
	}).catch((error) => {
		toast.error(i18n.t('alert.generic'));
	});
}

resetRampup(){
	let currentAccount = this.state.currentAccount;
	currentAccount.config_rampup_increase = 1;
	currentAccount.config_rampup_target = 16;
	this.setState({currentAccount});
	this.generateForecast();
}

copyCode(value){
	navigator.clipboard.writeText(value);
	toast.success("Warming content code successfully copied 🙌");
}

	generateForecast(){
		let nextDays = [];
		let values = [];
		let date = new Date();
		let value = this.state.currentAccount.config_max_conversation_running;
		let rampup = this.state.currentAccount.config_rampup_increase;
		let messagesPerConversations = [2, 2.2, 2.4, 2.6, 2.8];
		for (let i = 1; i < 16; i++) {
			date = new Date( Date.now() + i * 24 * 60 * 60 * 1000)
		 	nextDays.push(this.dateToDM(date));
			// do something
			value = value + rampup;
			if(value > this.state.currentAccount.config_rampup_target){
				value = this.state.currentAccount.config_rampup_target;
			}

			const messagesIncrement = messagesPerConversations[Math.floor(Math.random() * messagesPerConversations.length)];
			let nbMessages = value * messagesIncrement;
			values.push(Math.round(nbMessages));
		}

		let warmupForecast = {
			 labels: nextDays,
			   datasets: [
			     {
			       label: 'Messages sent',
			       backgroundColor: '#41d9cc',
			       borderColor: '#41d9cc',
			       borderWidth: 1,
			       hoverBackgroundColor: '#41d9cc',
			       hoverBorderColor: '#41d9cc',
			       data: values
			     }
			   ]
    };

		this.setState({warmupForecast})
	}

	graphStyle(){
		return {
			layout: {
				padding: {
						left: 0,
						right: 0,
						top: 10,
						bottom: 0
				}
		},
			legend: {
				display: false,
				labels: {
					usePointStyle: true
				}
			},
			scales: {
	        xAxes: [{
	            gridLines: {
	                display:false
	            }
	        }],
	        yAxes: [{
	            gridLines: {
	                display:true
	            }
	        }]
	    },
			tooltips: CHARTSJS_TOOLTIPS_STYLE
		}
	}

	saveRampupConfig(){
		AccountService.update(this.props.match.params.account_id,
			{ config_rampup_target: this.state.currentAccount.config_rampup_target,
				config_max_conversation_running: this.state.currentAccount.config_max_conversation_running,
        config_rampup_increase: this.state.currentAccount.config_rampup_increase
		}).then((response) => {
			toast.success("Account successfully updated");
		}).catch((error) => {
			toast.error(i18n.t('alert.generic'));
		});
	}

	toggleWarmingRecipe(){
		let currentAccount = this.state.currentAccount;
		currentAccount.current_warming_recipe = null;
		this.setState({currentAccount});
		this.toggleAutopilot();
	}

	toggleAccountConfigSmtpAppendAfterSent(e, value){
		AccountService.update(this.props.match.params.account_id, {config_smtp_append_after_sent: value}).then((response) => {
			this.setState({currentAccount: response.body})
			toast.success("Account successfully updated");
		}).catch((error) => {
			toast.error(i18n.t('alert.generic'));
		});
	}

	toggleAccountCopilot(e, value){
		AccountService.update(this.props.match.params.account_id, {copilot_enabled: value}).then((response) => {
			this.setState({currentAccount: response.body})
			toast.success("Account successfully updated");
		}).catch((error) => {
			toast.error(i18n.t('alert.generic'));
		});
	}

	toggleAccountPrivacy(e, value){
		AccountService.update(this.props.match.params.account_id, {privacy: value}).then((response) => {
			this.setState({currentAccount: response.body})
			toast.success("Account successfully updated");
		}).catch((error) => {
			toast.error(i18n.t('alert.generic'));
		});
	}

	selectDisconnectReason(e){
		e.preventDefault();
		this.setState({disconnectReason: e.target.value});
	}


	render() {
    if(this.state.loading){
      return(
        <div className="app container text-center">
          <div className="loader">
            <Loader
             type="Oval"
             color="#b7b7b7"
             height="100"
             width="100"
            />
          </div>
        </div>
      )
    }

		return (
			<div className="app container account-page">
	      <div className="row">
				{(localStorage.getItem('admin') || this.props.currentProject.config_feature_signature_enabled)   &&
				<>
	      	<h2 className="full">Signature</h2>

	      	<div className="card col-md-7">
		        <Editor
							apiKey='lu1qi7s883botn6ahz17d906srna8xskgek95neusstx9s3i'
		          initialValue={this.state.currentAccount.signature}
		          init={{
		            height: 311,
		            menubar: false,
		            plugins: [
		              'advlist autolink lists charmap print preview anchor',
		              'searchreplace visualblocks code fullscreen',
		              'insertdatetime media table paste code help wordcount'
		            ],
		            toolbar:
		              'undo redo | formatselect | bold italic backcolor | \
		              alignleft aligncenter alignright alignjustify | \
		              bullist numlist outdent indent | removeformat | help'
		          }}
		          onEditorChange={this.handleSignatureEditorChange}
		        />
						{this.state.signatureMaxLength - (this.state.currentAccount.signature ? this.state.currentAccount.signature.length : 0)} remaining characters
		        <button type="submit" disabled={(this.state.signatureMaxLength - (this.state.currentAccount.signature ? this.state.currentAccount.signature.length : 0)) < 0} className="btn btn-primary purple pull-right" onClick={this.saveSignature}>
		          {this.state.saveSignatureLoading &&
		            <>
		              <span className="pull-left">
		                <Loader
		                 type="Oval"
		                 color="#b7b7b7"
		                 height="10"
		                 width="10"
		                />
		              </span>
		              Saving...
		            </>
		          }
		          {!this.state.saveSignatureLoading &&
		            <>
		              Save
		            </>
		          }
						</button>
					</div>

				</>
			}

      <h2 className="full">Configure</h2>
				<div className="card col-md-12">
					<p className="delete pull-left">Edit sending and ramp-up volumes</p>
					 <button type="submit" className="btn btn-secondary pull-right" onClick={() => this.setState({showWarmupSettingsModal: true})}>Edit</button>
					 <div className="clearfix"></div>
				</div>

			<div className="card col-md-12">
				<p className="delete pull-left">
					Working hours based interactions &nbsp;
					<div className="help-bubble pull-right">
						<Tooltip title='To make the warming look more human and more adapted to your sending schedule you just have to select the timezone you want.' placement="bottom">
							<div>
								<i class="fas fa-info-circle"></i>
							</div>
						</Tooltip>
					</div>
				</p>

				<select className="timezones" value={this.state.currentAccount.time_zone} onChange={this.updateTimezone}>
					<option value="">Select a timezone</option>
					{this.state.timezones.map((timezone) => {
						return (
							<option value={timezone}>{timezone}</option>
						)})}
				</select>
				<div className="clearfix"></div>
			</div>

      <div className="card col-md-12">
        <p className="delete pull-left">
					Your warming content code					&nbsp;
					<div className="help-bubble pull-right">
						<Tooltip title="This code is included in each warm up email received in your mailbox. By default, Mailreach hides the emails to avoid cluttering your inbox, but sometimes it has a little delay. If you want to filter Mailreach's emails based on a word, you can use this code." placement="bottom">
							<div>
								<i class="fas fa-info-circle"></i>
							</div>
						</Tooltip>
					</div>
				</p>
        <p className="delete pull-right bold content-code">
					<div class="copy-code pull-right" onClick={() => this.copyCode(`${this.state.currentAccount.custom_message_content_ref}-`)}>
						<div class="code">
							{this.state.currentAccount.custom_message_content_ref}-
						</div>
						<div class="copy">
							<i class="far fa-copy" aria-hidden="true"></i>
						</div>
					</div>
				</p>
				<div className="clearfix"></div>
      </div>

			{(!this.state.currentAccount.alive && !this.state.currentAccount.suspended) &&
				<div className="card col-md-12">
					<p className="delete pull-left">Reconnect</p>
					<NavLink to={`/dashboard/${this.props.match.params.project_id}/account/connect?edit_account_id=${this.props.match.params.account_id}&reconnect=true`} className="btn btn-secondary pull-right">
						Reconnect
					</NavLink>
					<div className="clearfix"></div>
				</div>
			}

      {(localStorage.getItem('admin') || this.props.currentProject.config_feature_privacy_enabled)   &&
				<div className="card col-md-12">
					<p className="delete pull-left">
						Enable privacy mode &nbsp;
						<div className="help-bubble pull-right">
							<Tooltip title='By enabling privacy mode, Mailreach will only start conversations with non human email accounts. It allows you to maximize your privacy and make sure your email account is on stealth mode 🕶️' placement="bottom">
								<div>
									<i class="fas fa-info-circle"></i>
								</div>
							</Tooltip>
						</div>
					</p>
					<div className="pull-right">
						<Switch
							checked={this.state.currentAccount.privacy}
							onChange={this.toggleAccountPrivacy}
							name="config_smtp_append_after_sent"
							inputProps={{ 'aria-label': 'secondary checkbox' }}
						/>
					</div>
					<div className="clearfix"></div>
				</div>
			}



      <div className="card col-md-12">
      {this.state.currentAccount.suspended &&
        <>
          <p className="delete pull-left">Start warming</p>
          <button type="submit" className="btn btn-primary purple pull-right" onClick={this.unsuspend}>Start warming</button>
					<div className="clearfix"></div>
        </>
      }
      {!this.state.currentAccount.suspended &&
        <>
          <p className="delete pull-left">Suspend warming</p>
          <button type="submit" className="btn btn-secondary pull-right" onClick={this.suspend}>Suspend warming</button>
					<div className="clearfix"></div>
        </>
      }
      </div>

			  <div className="card col-md-12">
				  <p className="delete pull-left">Edit account credentials</p>
					<NavLink to={`/dashboard/${this.props.match.params.project_id}/account/connect?edit_account_id=${this.props.match.params.account_id}`} className="btn btn-secondary pull-right">
						Edit credentials
					</NavLink>
					<div className="clearfix"></div>
			  </div>


			{this.props.currentProject.config_feature_warming_only_on_enabled &&
				<div className="card col-md-12">
					<p className="delete pull-left">Warming only on</p>
					<div className="warming-only-on">
					  <ul>
						  <li onClick={() => this.updateConfigWarmingOnlyOn('gmail')} className={this.state.currentAccount.warming_only_on.includes('gmail') ? 'selected' : ''}>
							  <img className="pull-left" src="/images/gmail_logo_m.png" />
							  <span className="name">Google Workspace/Gmail</span>
							</li>
							<li onClick={() => this.updateConfigWarmingOnlyOn('outlook')} className={this.state.currentAccount.warming_only_on.includes('outlook') ? 'selected' : ''}>
							  <img className="pull-left" src="/images/logo_outlook.png" />
							  <span className="name">Outlook/Office365</span>
							</li>
							<li onClick={() => this.updateConfigWarmingOnlyOn('custom')} className={this.state.currentAccount.warming_only_on.includes('custom') ? 'selected' : ''}>
							  <span className="ico pull-left config-ico">⚙️</span>
							  <span className="name">Custom SMTP/IMAP</span>
							</li>
						</ul>
					</div>
					<div className="clearfix"></div>
				</div>
			}

			{this.state.aliases &&
				<>
					{(this.state.aliases.length > 0) &&
						<div className="card col-md-12">
							<p className="delete pull-left">Warm a specific alias on that inbox</p>
							<div className="warming-only-on pull-right aliases-select">
								<select className="timezones" onChange={this.setAlias}>
									<option>Select an alias</option>
									{this.state.aliases.map((aliase) => {
										return (
											<option value={aliase}>{aliase}</option>
										)})}
								</select>
							</div>
							<div className="clearfix"></div>
						</div>
					}
				</>
			}

				{(localStorage.getItem('admin') || this.state.currentAccount.provider != 'gmail') &&
					<div className="card col-md-12">
						<p className="delete pull-left">
							Advanced - Force appending sent messages in the sent folder &nbsp;
							<div className="help-bubble pull-right">
								<Tooltip title="If you can't manage to see sent emails in the sent folder of your mailbox you can enable this feature. Do it only if you're a pro." placement="bottom">
									<div>
										<i class="fas fa-info-circle"></i>
									</div>
								</Tooltip>
							</div>
						</p>
						<div className="pull-right">
							<Switch
								checked={this.state.currentAccount.config_smtp_append_after_sent}
								onChange={this.toggleAccountConfigSmtpAppendAfterSent}
								name="config_smtp_append_after_sent"
								inputProps={{ 'aria-label': 'secondary checkbox' }}
							/>
						</div>
						<div className="clearfix"></div>
					</div>
				}

				{(((localStorage.getItem('admin') || this.props.currentProject.config_feature_copilot_enabled)) && ['gmail', 'outlook'].includes(this.state.currentAccount.provider)) &&
					<div className="card col-md-12">
						<p className="delete pull-left">
							Volume Checker
							<div className="help-bubble pull-right">
								<Tooltip title="MailReach's Volume Checker analyzes your own sending activity and give you insights to maximize your reputation and deliverability." placement="bottom">
									<div>
										<i class="fas fa-info-circle"></i>
									</div>
								</Tooltip>
							</div>
						</p>
						<div className="pull-right">
							<PurpleSwitch
								checked={this.state.currentAccount.copilot_enabled}
								onChange={this.toggleAccountCopilot}
								name="config_smtp_append_after_sent"
								inputProps={{ 'aria-label': 'secondary checkbox' }}
							/>
						</div>
						<div className="clearfix"></div>
					</div>
				}

        <div className="card col-md-12">
          <p className="delete pull-left">Disconnect and stop warming</p>
          <button type="submit" className="btn btn-primary red pull-right" onClick={(e) => {(this.props.currentProject.id == 5720) ? (this.delete(e)) : (this.setState({showDisconnectModal: true, disconnectStep: 1}))}}>Disconnect</button>
					<div className="clearfix"></div>
        </div>
      </div>

			<Modal className="reputation-modal" show={this.state.showDisconnectModal} onHide={() => this.setState({showDisconnectModal: false})}>
				<Modal.Body>
				  {this.state.disconnectStep == 1 &&
						<>
							<h1 className="text-center">Are you sure to stop email warming?</h1>
							<div className="txt text-center">
							  <h4 className="prevent-goodby">
								  By disconnecting this email from MailReach, the engagement rate will drop, which will lower your sender reputation and therefore your deliverability.
								</h4>
								<img className="prevent-stop" src="/images/stop_warming.png"/>
							</div>
							<div className="footer text-center del">
							  <Button className="btn purple red" onClick={() => this.setState({disconnectStep: 2})}>
								  I'm sure to stop
							  </Button>
								<Button variant="secondary" onClick={() => this.setState({showDisconnectModal: false})}>
									Keep warming
								</Button>
						</div>
					</>
					}
					{this.state.disconnectStep == 2 &&
						<>
							<h1 className="text-center">Can you give us more info ?</h1>
							<div className="txt text-center">
							  <h4>
								  As we're putting a lot of energy to improve MailReach, could you please give us more info about the reason you want to stop warming your inbox ?
                </h4><br /><br />
								<form>
								<select onChange={this.selectDisconnectReason} value={this.state.disconnectReason}>
								  <option value="">--Please choose a reason--</option>
									<option value="think_inbox_warmed_up">I think my inbox has been warmed up enough</option>
									<option value="i_will_come_back_later">I'm done for now but I will come back later for sure</option>
									<option value="any_improvement">I didn't notice any improvement on the deliverability of this inbox</option>
									<option value="other_warmup_solution">I'm leaving for another warming solution</option>
									<option value="not_used_anymore">This email is not used anymore</option>
									<option value="no_reason">I just don't want to give any reason</option>
									<option value="reconnect">I want to disconnect and connect it back</option>
									<option value="other">Other</option>
								</select>
								</form>
								<br />
							</div>
							<div className="footer text-center del">
							  <Button variant="secondary" onClick={() => this.setState({showDisconnectModal: false})}>
								  Cancel
							  </Button>
							  <Button className="btn purple red" onClick={this.delete} disabled={!this.state.disconnectReason}>
								  Disconnect my inbox
							  </Button>
							</div>
					  </>
					}
				</Modal.Body>
			</Modal>

			<Modal className="reputation-modal settings-modal" show={this.state.showWarmupSettingsModal} onHide={() => this.setState({showWarmupSettingsModal: false})}>
				<Modal.Body>
					<>
						<h1 className="text-center">Warming settings</h1>
						<div className="txt text-center">
						<div className="col-md-12 pilot-head">
						<div className="col-md-6 slider">
							{this.state.currentAccount.current_warming_recipe &&
								<>
									<h3>
										Reputation recovery recipe
									</h3>
									<p>
										This is the recommended warming recipe based on several data points. &nbsp;
										<Tooltip title="Beta" placement="bottom">
											<span>
												<i class="fas fa-info-circle"></i>
											</span>
										</Tooltip>

									</p>
									<PurpleSwitch
										checked={this.state.currentAccount.current_warming_recipe}
										onChange={this.toggleWarmingRecipe}
										name="config_smtp_append_after_sent"
										inputProps={{ 'aria-label': 'secondary checkbox' }}
									/>
								</>
							}
							{!this.state.currentAccount.current_warming_recipe &&
						  	<img src="/images/autopilot.svg" />
							}
						</div>
							<div className="col-md-6 slider">
									<h3>
										Autopilot mode
									</h3>
									<p>This is the by default mode. Gradual ramp up and max per day handled by MailReach.</p>
									<GreenSwitch
										checked={this.state.autopilot}
										onChange={this.toggleAutopilot}
										name="config_smtp_append_after_sent"
										inputProps={{ 'aria-label': 'secondary checkbox' }}
									/>
							</div>
							</div>
							{(!this.state.autopilot && !this.state.currentAccount.current_warming_recipe) &&
								<>
								<div className="col-md-12 pilot-manual">
									<h3 className="max-conversation-running-slider-title">
										Starting volume
										<div className="help-bubble pull-right">
											<Tooltip title="Don't touch this if your email address and/or domain is new and has not send enough emails. Very important." placement="bottom">
												<div>
													<i class="fas fa-info-circle"></i>
												</div>
											</Tooltip>
										</div>
									</h3>
									<div className="clearfix"></div>
									<p className="text-center">
									  Only if your address is already warm, you can start with a higher volume of conversations.<br /><br />
										We recommend 16.<br /><br />
										Actual config is <b>{this.state.config_max_conversation_running || this.state.currentAccount.config_max_conversation_running}</b> conversations, It represents between <b>{Math.round(this.state.config_max_conversation_running * 2.2)}</b> and <b>{Math.round(this.state.config_max_conversation_running * 3)}</b> emails.
									</p>
									<div className="max-conversation-running-slider">
										<MailreachSlider
											key={`slider-rampupincrease-${this.state.currentAccount.config_max_conversation_running}`}
											defaultValue={this.state.currentAccount.config_max_conversation_running}
											onChange={ (e, val) => this.setState({config_max_conversation_running: val}) }
											aria-labelledby="discrete-slider"
											onChangeCommitted={this.updateConfigMaxConversationRunning}
											step={1}
											min={1}
											max={this.state.currentAccount.config_rampup_target}
										/>
									</div>
								</div>
								<div className="col-md-12 pilot-manual">
									<div className="col-md-6 increase">
										<h3>
											Increase per day
											<div className="help-bubble">
												<Tooltip title="This is the number of new conversations to start each day until reaching the 'Max sends per day'." placement="bottom">
													<div>
														<i class="fas fa-info-circle"></i>
													</div>
												</Tooltip>
											</div>
										</h3>
										<p>
										We recommend between 1 and 3. Max is 4.<br /><br />
										Actual config is <b>{this.state.rampup_increase_slider_value}</b> conversations, It represents between <b>{Math.round(this.state.rampup_increase_slider_value * 2.2)}</b> and <b>{Math.round(this.state.rampup_increase_slider_value * 3)}</b> additional emails sent per day.
										</p>
										<MailreachSlider
											key={`slider-rampupincrease-${this.state.currentAccount.config_rampup_increase}`}
											defaultValue={this.state.currentAccount.config_rampup_increase}
											onChange={ (e, val) => this.setState({rampup_increase_slider_value: val}) }
											aria-labelledby="discrete-slider"
											onChangeCommitted={this.updateRampupIncrease}
											step={1}
											min={1}
											max={4}
										/>
									</div>
									<div className="col-md-6 max">
										<h3>
											Max sends per day
											<div className="help-bubble">
												<Tooltip title="This is the number of conversations max per day to reach." placement="bottom">
													<div>
														<i class="fas fa-info-circle"></i>
													</div>
												</Tooltip>
											</div>
										</h3>
										<p>We recommend 16. Max is 35.<br /><br />
										Actual config is <b>{this.state.rampup_target_slider_value}</b> conversations, It represents between <b>{Math.round(this.state.rampup_target_slider_value * 2.2)}</b> and <b>{Math.round(this.state.rampup_target_slider_value * 3)}</b> max emails sent per day
										</p>
										<MailreachSlider
											key={`slider-rampuptarget-${this.state.currentAccount.config_rampup_target}`}
											defaultValue={this.state.currentAccount.config_rampup_target}
											aria-labelledby="discrete-slider"
											onChange={ (e, val) => this.setState({rampup_target_slider_value: val}) }
											onChangeCommitted={this.updateRampupTarget}
											step={1}
											min={10}
											max={35}
										/>
									</div>
								</div>
							  </>
							}

							<div>
								<h3 className="pull-left">
								  Warm up forecast
									<div className="help-bubble pull-right">
										<Tooltip title='This an estimated forecast of your warm up schedule on the next 14 days. The numbers can change based on how your deliverability evolves.' placement="bottom">
											<div>
												<i class="fas fa-info-circle"></i>
											</div>
										</Tooltip>
									</div>
								</h3>
								<div className="clearfix"></div>
								<Bar key={this.state.providerFilter} data={this.state.warmupForecast} options={this.barStyle()}/>
							</div>
						</div>
					</>
									<div className="footer text-center">
									  <Button className="btn purple" onClick={this.saveRampupConfig}>
										  Save
									  </Button>
										<Button variant="secondary" onClick={() => this.setState({showWarmupSettingsModal: false})}>
											Close
										</Button>
									</div>
								</Modal.Body>
							</Modal>
      </div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		currentProject: state.currentProject,
		currentPricing: state.currentPricing
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardAccountsSettings));
