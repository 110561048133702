const request = require('superagent');

class NotificationService {

  static all() {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}notifications`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }

  static summary() {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}notifications/summary`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }


  static by_event(event) {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}notifications?event=${event}`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }

  static account(account_id) {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}notifications?account_id=${account_id}`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }

  static close(id) {
    return request
            .delete(`${process.env['REACT_APP_BACKEND_URL']}notifications/${id}`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }
}

export default NotificationService ;
