import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import {ToastContainer, toast} from 'react-toastify';
import Loader from 'react-loader-spinner'
import { Button, Modal, Dropdown } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';
import { NavLink, useHistory } from 'react-router-dom';

import ImapAuthService from '../../../../services/imapAuth.service';
import AccountService from '../../../../services/account.service';
import ProjectService from '../../../../services/project.service';

import i18n from "../../../../i18n";
import './connect.scss';

import * as projectsActions from '../../../../actions/projects.actions';

class DashboardAccountsConnect extends React.Component {
	constructor(props) {
		super(props);
		this.state = {errors: [], currentAccount: {}, currentProject: {}, currentProjectFi: {}, credentials: {}, queryString: {}, first_google_workspace_account: true, reconnect: true, connectBot: false, imapError: false};
    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
		this.openHelpModal = this.openHelpModal.bind(this);
		this.autoConnectionTry = this.autoConnectionTry.bind(this);
		this.onChangeMailgunServers = this.onChangeMailgunServers.bind(this);
	}

	componentWillMount() {
		let queryParams = queryString.parse(this.props.location.search);
		let error = queryParams.error;
		if(error){
			toast.error(error);
		}

		if(queryParams.success){
			toast.success(queryParams.success);
		}

		if(queryParams.error_type){
			this.setState({modal_error_type: queryParams.error_type, showHelpModal: true});
		}

		if(queryParams.modal_connect_success_invite){
			this.setState({showHelpModal: true});
		}

		// Trigger events
		if(queryParams.events){
			window.gtag("event", "billing", { event_category: "credit_card", event_label: "added"});
			window.$crisp.push(["set", "session:segments", [["cb-added"]]]);
			window.$crisp.push(["set", "session:event", [[["cb-added"]]]])
			window.gtag("event", "purchase", {
				currency: 'USD',
				value: (queryParams.seats*25),
				transaction_id: Date.now(),
				items: [
					{item_iname: 'warmer_seats'}
				]
			});
		}

		this.setState({queryString: queryParams})

		if(!queryParams.invitation_token){
			AccountService.all('', this.state.index).then((response) => {
				if(response.body.length == 0){
					this.setState({first_google_workspace_account: false})
				}
			})
		}

		if(queryParams.invitation_token){
			ProjectService.by_invite_token(queryParams.invitation_token).then((response) => {
				this.setState({currentProject: response.body, currentProjectFi: response.body})
			}).catch((error) => {
				toast.error('Oops, we have an error');
			});
		} else {
			ProjectService.get(this.props.match.params.project_id).then((response) => {
				this.setState({currentProject: response.body, currentProjectFi: response.body});
					if(response.body.config_feature_connectv2_enabled && !this.props.spamtestConnect){
						// Redirect to the new auth
						this.props.history.push({pathname: './connectv2', search: this.props.location.search});
					}
			}).catch((error) => {
				console.log(error);
				toast.error('Oops, we have an error');
			});

		}

		if(queryParams.edit_account_id){
			AccountService.get(queryParams.edit_account_id).then((response) => {

				// Redirect to the spamchecker reconnect page
				if(response.body.warming_enabled == false && response.body.spamchecker_enabled && this.props.history){
					this.props.history.push({pathname: '../spam-tests/account/' + queryParams.edit_account_id + '/reconnect', search: this.props.location.search});
				}

				let provider = response.body.provider;
				if (response.body.has_refresh_token && response.body.provider == 'gmail'){
					provider = 'gmail';
				}

				// Allow imap only for on custom provider only
				if(response.body.provider == 'custom'){
					if(queryParams.imap_only){
						this.selectProvider('imap_only');
					}
				}

				let credentials = {
					email: response.body.email,
					first_name: response.body.first_name,
					last_name: response.body.last_name,
					provider: provider
				}
				//if(credentials.provider == 'custom'){
					credentials['imap_server'] = response.body.imap_server;
					credentials['imap_server_username'] = response.body.imap_server_username;
					credentials['imap_server_port'] = response.body.imap_server_port;

					credentials['smtp_server'] = response.body.smtp_server;
					credentials['smtp_server_username'] = response.body.smtp_server_username;
					credentials['smtp_server_port'] = response.body.smtp_server_port;
					credentials['smtp_server_auth_type'] = response.body.smtp_server_auth_type;
					credentials['smtp_server_starttls'] = response.body.smtp_server_starttls;
				//}
				this.setState({
					edit: true,
					credentials,
					reconnect: queryParams.reconnect
				});


				if(queryParams.imap_only){
					this.selectProvider('imap_only');
				}

			}).catch((error) => {
				console.error(error);
				toast.error('Oops, we have an error');
			});
		}
	}

  onChange(event) {
    const field = event.target.name;
    const credentials = this.state.credentials;
		const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    credentials[field] = value;
    return this.setState({credentials});
  }


	autoConnectionTry(event){
		if(!this.state.autoConnectionTryed){
			toast.success('Mailreach is trying to automatically fix your connection details.');
			// Only try if auto connection was not tryed before

			let credentials = this.state.credentials;


			credentials['imap_server'] = credentials['imap_server'].replace("http://", "");
			credentials['imap_server'] = credentials['imap_server'].replace("https://", "");
			credentials['imap_server'] = credentials['imap_server'].replace("/", "");
			credentials['smtp_server'] = credentials['smtp_server'].replace("http://", "");
			credentials['smtp_server'] = credentials['smtp_server'].replace("https://", "");
			credentials['smtp_server'] = credentials['smtp_server'].replace("/", "");
			credentials['smtp_server_port'] = 587;
			credentials['smtp_server_auth_type'] = 'plain';
			credentials['smtp_server_starttls'] = true;
			credentials['imap_server_port'] = 993;

			this.setState({autoConnectionTryed: true, credentials: credentials});
			this.onSave(event);
		}
	}

  onSave(event) {
		let queryParams = queryString.parse(this.props.location.search);

    event.preventDefault();
		this.setState({loading: true});
		let credentials = this.state.credentials;

		if(this.props.spamtestConnect){
			credentials.spamchecker_only = true;
		}

		if(credentials.sub_provider == 'sendgrid'){
      credentials.smtp_server = 'smtp.sendgrid.net';
			credentials.smtp_server_port = 587;
			credentials.smtp_server_username = 'apikey';
			credentials.smtp_server_auth_type = 'plain';
		}

		if(credentials.sub_provider == 'sendinblue'){
			credentials.smtp_server = 'smtp-relay.brevo.com';
			credentials.smtp_server_port = 587;
			credentials.smtp_server_auth_type = 'plain';
		}

		if(credentials.sub_provider == 'mailgun'){
			if(!credentials.smtp_server || credentials.smtp_server != 'smtp.eu.mailgun.org'){
				credentials.smtp_server = 'smtp.mailgun.org';
			}
			credentials.smtp_server_port = 587;
			credentials.smtp_server_auth_type = 'plain';
		}
		if(queryParams.edit_account_id){
			credentials.id = queryParams.edit_account_id;
		}
		if(this.state.connectBot){
			credentials.bot = true;
		}
		if(this.state.credentials.provider == 'custom'){
			credentials.password = 'password';
		}

		if(this.state.queryString.invitation_token){
			credentials.auth_api_key = this.state.currentProject.api_key;
			credentials.quick_signin = true;
		}

	 // Set plain as default auth type
		credentials.smtp_server_auth_type = credentials.smtp_server_auth_type || 'plain';

		credentials.smtp_only = false;


		if((['imap-mail.outlook.com', 'outlook.office365.com'].includes(credentials.imap_server) && this.props.currentProject.config_new_imap_smtp_form_enabled) || this.state.hide_imap_form_oauth){
			credentials.smtp_only = true;
		}

    ImapAuthService.create(credentials).then((response) => {
			if(!response.body.warming_enabled && response.body.spamchecker_enabled){
				this.props.spamcheckerAddAccountSuccess(response.body);
				return;
			}

			window.gtag("event", "accounts", {
							event_category: "accounts",
							event_label: "connected"
					});
			window.gtag('event', 'conversion', {
					      'send_to': 'AW-444511731/6eLmCPyZwvkBEPPr-tMB',
					      'transaction_id': ''
					 });
			window.$crisp.push(["set", "session:segments", [["account-connected"]]]);
			window.$crisp.push(["set", "session:event", [[["account-connected"]]]]);

			// BING Tracking ADS
			window.uetq = window.uetq || [];
			window.uetq.push('event', 'purchase', {});

			// Redirect to the right success page
			if(queryParams.invitation_token){
				toast.success('Account added !');
				this.setState({loading: false});
			}else{
				// Redirect to step 2 for new auth system
				if(credentials.smtp_only){
					if(this.state.imap_provider_oauth == 'google'){
						this.props.history.push(`/dashboard/${this.props.currentProject.id}/account/workspaces/create?imap_only=true&account_id=${response.body.id}${this.props.spamtestConnect ? '?spamchecker_only=true' : ''}`);
					}else{
						this.props.history.push(`/dashboard/${this.props.currentProject.id}/account/connect?imap_only=true&edit_account_id=${response.body.id}${this.props.spamtestConnect ? '?spamchecker_only=true' : ''}`);
						this.selectProvider('imap_only');
						this.setState({loading: false});
						let queryParams = queryString.parse(this.props.location.search);
						this.setState({queryString: queryParams});
					}
					toast.success('SMTP successfully connected, now sign in with IMAP 🙌');
				} else {
					toast.success('Account connected !');
					this.props.history.push(`/dashboard/${this.props.currentProject.id}/home`);
				}
			}
		})
  	.catch((error) => {
			if(error.response){
				if(error.response.body.error.includes('Unpaid invoices')){
					toast.error('You have to pay past due invoices to connect an account. Go on the billing tab and use a card with sufficient funds.');
					this.setState({loading: false});
					return;
				}
				if(error.response.body.error.includes('All seats already used')){
					toast.error('All of your seat(s) are already in use. You can add a new one and re-submit this form.');
					this.setState({loading: false});
					return;
				}
			}

			this.setState({loading: false, imapError: true});
			window.$crisp.push(["set", "session:segments", [["connection-error"]]]);
			this.openHelpModal();
			if(this.state.credentials.provider === 'custom'){

				if(!this.state.autoConnectionTry){
					this.autoConnectionTry(event);
				}

				if(!error.response.body.imap_auth_success && !credentials.smtp_only){
					toast.error("Verify ports, hosts and credentials for IMAP server. IMAP server returned : " + error.response.body.error);
				}else{
					toast.error("Verify ports, hosts and credentials for SMTP server. SMTP server returned : " + error.response.body.error);
				}
			}else{
				if(error.response.body.error.includes('specific password')){
          toast.error('Oops, it looks like you have to create an App Password to connect your account.');
					this.setState({gmailAppPassword: true});
				}else if(error.response.body.error.includes('web browser')){
					toast.error('You\'re almost there ! Check account activities or display unlock captcha');
					this.setState({gmailDisplayUnlock: true});
				}else{
					if(this.state.credentials.provider === 'outlook'){
						toast.error('Verify credentials and SMTP/IMAP is activated on your account');
					}else{

						if(this.state.credentials.provider == 'gmail'){
							if(this.state.credentials.sub_provider == 'lsa'){
								toast.error('Failed to connect. Make sure you have followed all the steps needed 😇');
							}else{
								toast.error('Failed to connect. Make sure you have followed all the steps needed 😇');
							}
						}else{
							toast.error('Verify password, and SMTP/IMAP is activated on your account');
						}
					}
					this.setState({gmailAppPassword: false});
				}
			}
		});
  }

	componentWillReceiveProps(nextProps){
		if(nextProps.match.params.project_id == 'undefined'){
			if(nextProps.currentProject.id){
				window.location = `/dashboard/${nextProps.currentProject.id}/account/connect`;
			}
		}
	}

	copyText(value){
		navigator.clipboard.writeText(value);
		toast.success('Link successfully copied 🙌');
	}

	providerSelected(provider){
		if(this.state.credentials.sub_provider == provider){
			return 'col-md-3 card selected';
		}
		if(this.state.credentials.provider == provider && !this.state.credentials.sub_provider){
			return 'col-md-3 card selected';
		}
		return 'col-md-3 card';
	}

	selectProvider(provider){
		let credentials = this.state.credentials;
		credentials.provider = provider;
		credentials.sub_provider = null;

		this.setState({hide_imap_form_oauth: false});

		if(provider == 'sendgrid'){
			credentials.sub_provider = 'sendgrid';
			credentials.provider = 'custom';
		}

		if(provider == 'gmail_lsa'){
			credentials.sub_provider = 'lsa';
			credentials.provider = 'gmail';
		}

		if(provider == 'sendinblue'){
			credentials.sub_provider = 'sendinblue';
			credentials.provider = 'custom';
		}

		if(provider == 'mailgun'){
			credentials.sub_provider = 'mailgun';
			credentials.provider = 'custom';
		}

		if(provider == 'imap_only'){
			credentials.sub_provider = 'imap_only';
			credentials.provider = 'custom';
			this.setState({hide_imap_form_oauth: true});
		}
		this.setState({credentials, openFullForm: false, imap_provider_oauth: null});
	}

	onChangeMailgunServers(event) {
		let credentials = this.state.credentials;
		credentials.smtp_server = event.target.value;
		this.setState({credentials, imap_provider_oauth: null});
		return;
	}

	openHelpModal(){
		if(this.state.credentials.provider == 'gmail'){
			this.setState({showHelpModal: true});
		}
	}

	render() {
		return (
			<div className="app container">
				<div className="title title-v2">
				  {this.state.edit &&
						<>
							{this.state.reconnect &&
								<h2>Reconnect your email account</h2>
							}
							{!this.state.reconnect &&
						    <h2>Edit {this.state.credentials.email}</h2>
							}
						</>
				  }
				  {!this.state.edit &&
						<>
							{this.state.queryString.invitation_token &&
								<h1>You've been invited by {this.state.currentProject.main_user_email} to connect an email account on MailReach 🚀</h1>
							}
							{!this.state.queryString.invitation_token &&
								<h1>Connect a new email account</h1>
							}
						</>
				  }
				</div>
				<div className="row">

					  <div className="col-md-12">
							<>
								{this.state.queryString.invitation_token &&
									<h2>Please choose your mailbox provider</h2>
								}
								{!this.state.queryString.invitation_token &&
									<h2>Select a provider</h2>
								}
							</>
						  <div className="row provider">
							  <div className="col-md-12">
								<div className="clearfix"></div>

								{this.state.queryString.invitation_token &&
									<>
										{this.state.currentProject.config_feature_new_google_auth_enabled &&
											<div className={'no-left-margin card-big ' + this.providerSelected('gmail')} onClick={() => this.selectProvider('gmail')}>
												<img className="pull-left" src="/images/gmail_logo_m.png" />
												<span className="name">Google Workspace/Gmail</span>
											</div>
										}
										{!this.state.currentProject.config_feature_new_google_auth_enabled &&
											<div className={'no-left-margin card-big ' + this.providerSelected('gmail')} onClick={() => this.selectProvider('gmail')}>
												<img className="pull-left" src="/images/gmail_logo_m.png" />
												<span className="name">Google Workspace/Gmail</span>
											</div>
										}
										<div className={'card-big ' + this.providerSelected('outlook')} onClick={() => this.selectProvider('outlook')}>
											<img className="pull-left" src="/images/logo_outlook.png" />
											<span className="name">Outlook/Office365</span>
										</div>

									</>
								}

								{!this.state.queryString.invitation_token &&
									<>

										{this.state.currentProject.config_feature_new_google_auth_enabled &&
											<div className={'no-left-margin ' + this.providerSelected('gmail')} onClick={() => this.selectProvider('gmail')}>
												<img className="pull-left" src="/images/gmail_logo_m.png" />
												<span className="name">Google Workspace/Gmail</span>
											</div>
										}

											{!this.state.currentProject.config_feature_new_google_auth_enabled &&
											  <div className={'no-left-margin ' + this.providerSelected('gmail')} onClick={() => this.selectProvider('gmail')}>
												  <img className="pull-left" src="/images/gmail_logo_m.png" />
												  <span className="name">Google Workspace/Gmail</span>
											  </div>
											}

										  <div className={this.providerSelected('outlook')} onClick={() => this.selectProvider('outlook')}>
											  <img className="pull-left" src="/images/logo_outlook.png" />
											  <span className="name">Outlook/Office365</span>
										  </div>
										  <div className={this.providerSelected('sendgrid')} onClick={() => this.selectProvider('sendgrid')}>
											  <img className="pull-left" src="/images/logo_sendgrid.png" />
											  <span className="name">Sendgrid</span>
										  </div>
											<div className={this.providerSelected('sendinblue')} onClick={() => this.selectProvider('sendinblue')}>
												<img className="pull-left" src="/images/logo_brevo.png" />
												<span className="name">Brevo</span>
											</div>

											<div className={'no-left-margin ' +this.providerSelected('mailgun')} onClick={() => this.selectProvider('mailgun')}>
												<img className="pull-left" src="/images/logo_mailgun.png" />
												<span className="name">Mailgun</span>
											</div>
										  <div className={this.providerSelected('custom')} onClick={() => this.selectProvider('custom')}>
											  <span className="ico pull-left config-ico">⚙️</span>
											  <span className="name">Any other SMTP</span>
										  </div>
										  {localStorage.getItem('admin') &&
											  <>
											    <div className={'' + this.providerSelected('aol')} onClick={() => this.selectProvider('aol')}>
												    <img className="pull-left" src="https://logo.clearbit.com/aol.com" />
												    <span className="name">Aol</span>
											    </div>
											    <div className={this.providerSelected('gmx')} onClick={() => this.selectProvider('gmx')}>
												    <img className="pull-left" src="https://logo.clearbit.com/gmx.com" />
												    <span className="name">Gmx</span>
											    </div>
											    <div className={this.providerSelected('mailcom')} onClick={() => this.selectProvider('mailcom')}>
												    <img className="pull-left" src="https://logo.clearbit.com/mail.com" />
												    <span className="name">mail.com</span>
											    </div>
											  </>
										  }
										</>
									}
							  </div>
						  </div>
					  </div>


					{this.state.credentials.provider &&
						<>
						{this.state.credentials.provider == 'gmail' &&
						<div className="col-md-6">
							<>
								<h2>Instructions</h2>
								<div className="card col-md-12">

									<p>To be able to connect your Google Workspace account to MailReach and start the warming process, you need to follow a few steps first.</p>

									<p>No worries, it should be done in less than 2 minutes.</p>

									<p>Please follow the steps explained in our guide below 💪.</p>

                  <br />

									{this.state.credentials.sub_provider == 'lsa' &&
										<p className="text-center"><a className="btn btn-primary purple" target="_blank" href="https://help.mailreach.co/en/article/how-to-connect-a-google-workspace-account-to-mailreach-with-less-secure-apps-1xsossm/">How to connect a Google Workspace account</a></p>
									}
									{this.state.credentials.sub_provider != 'lsa' &&
										<p className="text-center"><a className="btn btn-primary purple" target="_blank" href="https://help.mailreach.co/en/article/how-to-connect-a-gmail-google-workspace-account-to-mailreach-with-an-app-password-ulwj00/">How to connect a Google Workspace account</a></p>
									}
								</div>
							</>
						</div>
						}

						{this.state.credentials.provider == 'google_workspace' &&
							<div className="col-md-6">
								{!this.state.queryString.invitation_token &&
									<>
										<h2>Instructions</h2>
										<div className="card col-md-12">
											<p>To be able to connect your Google Workspace account and start the warming process, you need to grant MailReach an API access.</p>

											<p>No worries, that's quick and easy. It takes around 1 minute ⚡</p>

											<p>Please follow our quick guide below.</p>

											<br />
											<p className="text-center"><a className="btn btn-secondary btn-gw-guide" target="_blank" href="https://help.mailreach.co/en/article/how-to-connect-a-google-workspace-account-to-mailreach-2022-version-bc8nop/">How to connect a Google Workspace account</a></p>
										</div>
									</>
								}
								{this.state.queryString.invitation_token &&
									<>
										<h2>Instructions</h2>
										<div className="card col-md-12">
											<p>To connect a professional Gmail account (Google Workspace), you simply have to click on the blue button 'Sign in with Google Workspace' and follow the process.</p>

											<p>You have to be logged in the Gmail account you want to connect. </p>

											<p>If you have an error message, it means the MailReach admin didn't give MailReach an API access.</p>
										</div>
									</>
								}
							</div>
						}

						{this.state.credentials.provider == 'outlook' &&
							<div className="col-md-6">
								<>
									<h2>Instructions</h2>

									{this.state.queryString.invitation_token &&
										<div className="card col-md-12">
											<p>To connect a professional Outlook account (Office 365), you simply have to click on the blue button 'Sign in with Outlook/Office 365' and follow the process.</p>

											<p>You have to be logged in the Outlook account you want to connect.</p>
										</div>
									}
									{!this.state.queryString.invitation_token &&
										<div className="card col-md-12">
											<p>To connect your Office 365 account, you can do so in just a few clicks by clicking on the button ‘Sign in with Office 365’.</p>

											<p>If it doesn't work and you receive a "Need approval" message from Microsoft, we got your back. Please <a href="https://help.mailreach.co/en/article/how-to-allow-user-consent-for-microsoft-outlook-office-365-pcgpip/" target="_blank">follow the steps explained in this guide</a>.</p>
										</div>
									}
								</>
							</div>
						}

					{this.state.credentials.sub_provider == 'mailgun' &&
						<div className="col-md-6">
							<>
								<h2>Instructions</h2>
								<div className="card col-md-12">
									<p>If you need help finding your SMTP and IMAP credentials, we got your back with a dedicated guide for MailGun 💪</p>

									<p>It should be done in minutes. </p> <br />

									<p className="text-center">
										<a className="primary btn" target="_blank" href="https://help.mailreach.co/en/article/how-to-connect-a-mailgun-account-to-mailreach-mufu78/">How to connect MailGun SMTP to MailReach</a>
									</p>
								</div>
							</>
						</div>
					}

					{this.state.credentials.sub_provider == 'imap_only' &&
						<div className="col-md-6">
						</div>
					}

					{this.state.credentials.sub_provider == 'sendinblue' &&
						<div className="col-md-6">
							<>
								<h2>Instructions</h2>
								<div className="card col-md-12">
									<p>If you need help finding your SMTP and IMAP credentials, we got your back with a dedicated guide for Sendinblue 💪</p>

									<p>It should be done in minutes. </p> <br />

									<p className="text-center">
										<a className="primary btn" target="_blank" href="https://help.mailreach.co/en/article/how-to-connect-sendinblue-smtp-to-mailreach-1amt87n/">How to connect Sendinblue SMTP to MailReach</a>
									</p>
								</div>
							</>
						</div>
					}

					{this.state.credentials.sub_provider == 'sendgrid' &&
						<div className="col-md-6">
							<>
								<h2>Instructions</h2>
								<div className="card col-md-12">
									<p>If you need help finding your API key and IMAP credentials, we got your back with a dedicated guide for SendGrid 💪</p>

									<p>It should be done in minutes. </p> <br />

									<p className="text-center">
										<a className="primary btn" target="_blank" href="https://help.mailreach.co/en/article/how-to-connect-sendgrid-smtp-to-mailreach-1ihpf1c/">How to connect SendGrid SMTP to MailReach</a>
									</p>
								</div>
							</>
						</div>
					}

						{(this.state.credentials.provider != 'gmail' && this.state.credentials.provider != 'google_workspace' && this.state.credentials.provider != 'outlook' && !this.state.credentials.sub_provider) &&
	          <div className="col-md-6">
							<>
								<h2>Instructions</h2>
								<div className="card col-md-12">
								  <b>Where to find your SMTP and IMAP credentials?</b><br /><br />

                  <p>SMTP: Check with your email provider. Most of the time, you just have to search "find *email provider name* SMTP information" on Google.</p><br />

                  <p>IMAP: It's where the replies land. Check with your provider aswell.</p><br />

									<p>Find more information in our <a href="https://help.mailreach.co/en/article/how-to-connect-with-custom-smtp-imap-to-mailreach-acljqy/" target="_blank">full guide here</a>.</p><br />

                  <b>If you have a SMTP connection error :</b>
                  <ul className="list-number">
									  <li>Make sure your credentials are correct</li>
									  <li>Try to connect with SMTP port: 587 with "Use SSL/TLS" checked</li>
									  <li>Try to connect with SMTP port: 587 without "Use SSL/TLS"</li>
									  <li>Try to connect with SMTP port: 25 with "Use SSL/TLS" checked</li>
									  <li>Try to connect with SMTP port: 25 without "Use SSL/TLS"</li>
									</ul>

                  <b>If you have a IMAP connection error : </b>
									<ul className="list-number">
									  <li>Make sure your credentials are correct</li>
									  <li>Try to connect with IMAP port: 993</li>
									  <li>Try to connect with IMAP port: 143</li>
									</ul><br />
									<p className="text-center">
										<a className="primary btn" href="https://help.mailreach.co/en/article/how-to-connect-with-custom-smtp-imap-to-mailreach-acljqy/" target="_blank">Read the full guide here</a>
									</p>
								</div>
							</>
	          </div>
					}

          <div className="col-md-6 imap">


					  {this.state.edit &&
							<>
								{this.state.reconnect &&
									<h2>Reconnect your email account</h2>
								}
								{(!this.state.reconnect && this.state.credentials.sub_provider != 'imap_only') &&
									<h2>Edit {this.state.credentials.email}</h2>
								}
								{this.state.credentials.sub_provider == 'imap_only' &&
									<h2>Sign in Outlook/Office for IMAP</h2>
								}
							</>
						}

						{!this.state.edit &&
							<>
								{this.state.credentials.provider == 'outlook' &&
								  <h2>Connect your Office 365 account</h2>
						   	}
								{this.state.credentials.provider == 'google_workspace' &&
									<h2>Connect your Google Workspace account</h2>
								}
								{this.state.credentials.provider == 'gmail' &&
									<>
										{this.state.credentials.sub_provider == 'lsa' &&
											<h2>Sign in with Less Secure Apps</h2>
										}
										{this.state.credentials.sub_provider != 'lsa' &&
											<h2>Sign in with a Google App Password</h2>
										}
									</>
								}
								{(this.state.credentials.provider != 'outlook' && this.state.credentials.provider != 'google_workspace' && this.state.credentials.sub_provider != 'imap_only' && this.state.credentials.provider != 'gmail') &&
								  <h2>Connect with SMTP/IMAP</h2>
								}
							</>
					  }
		            <div className="card col-md-12">
		              {this.state.loading &&
		                <div className="loader text-center">
		                  <Loader
		                   type="Oval"
		                   color="#b7b7b7"
		                   height="100"
		                   width="100"
		                  />
		                </div>
		              }
		              {!this.state.loading &&
		                <>
										{this.state.credentials.provider == 'outlook' &&
											<div className="col-md-12">
												<>
													<div className="col-md-12">
													  <p className="text-center">Click on the button below to connect in a few clicks</p>
														<div className="text-center outlook-connect-content">

															<a href={`${process.env.REACT_APP_BACKEND_URL}microsoft_auth/redirect?project_id=${this.state.currentProjectFi.join_project_team_token}${this.state.queryString.edit_account_id ? ('&account_id=' + this.state.queryString.edit_account_id) : ''}${this.state.queryString.invitation_token ? '&quick_signin=true' : ''}${this.props.spamtestConnect ? '&spamcheck_only=true' : ''}`} className="btn google google-btn outlook-btn">
																<div class="google-icon-wrapper">
																	<img src="/images/microsoft_m.png" />
																</div>
																<span>
																	Sign in with Outlook/Office 365
																</span>
															</a>

														</div>
													</div>
													<div className="clearfix"></div>
													<div className="authSeparator cursor-pointer">
														<hr />
												    <span onClick={() => this.setState({openFullForm: !this.state.openFullForm})}>Or enter your SMTP/IMAP credentials <i class="fa-solid fa-angle-down"></i></span>
												  </div>

													{this.state.openFullForm &&
														<div className="red-alert yellow yellow-outlook">
															<i class="fa-solid fa-circle-info"></i>
															<span>
																To connect your mailbox with SMTP/IMAP credentials, please follow our <a href="https://help.mailreach.co/en/article/how-to-connect-an-office-365-account-to-mailreach-1jya3ru/" target="_blank">dedicated guide</a> follow our quick guide.
															</span>
															<div className="clearfix"></div>
														</div>
													}
												</>
											</div>
										}

										{(this.state.first_google_workspace_account && this.state.credentials.provider == 'google_workspace') &&
											<div className="col-md-12">
												<>
													<div className="col-md-12">
														<div className="red-alert">
															<i class="fa-solid fa-circle-info"></i>
															<span>
																{!this.state.queryString.invitation_token &&
																	<>
																		To use the 'Sign in' button below, MailReach needs a <a href="https://help.mailreach.co/en/article/how-to-connect-a-google-workspace-account-to-mailreach-2022-version-bc8nop/" target="_blank">Google API access</a>. If it hasn't been granted before, it will not work.
																	</>
																}
																{this.state.queryString.invitation_token &&
																	<>
																		To use the 'Sign in' button below, MailReach needs a <a href="https://help.mailreach.co/en/article/how-to-connect-a-google-workspace-account-to-mailreach-2022-version-bc8nop/" target="_blank">Google API access</a>. If it hasn't been granted by the admin, it won't work.
																	</>
																}
															</span>
															<div className="clearfix"></div>
														</div>
														<div className="text-center outlook-connect-content google-connect-content">
															<a href={`${process.env.REACT_APP_BACKEND_URL}gmail_auth/redirect?project_id=${this.state.currentProject.join_project_team_token}${this.state.queryString.invitation_token ? '&quick_signin=true' : ''}`} className="btn google google-btn">
																<div class="google-icon-wrapper">
																	<img src="/images/google_g.svg" />
																</div>
																<span>
																	Sign in with Google Workspace
																</span>
															</a>
															<div className="cant-btn">
																<span onClick={() => this.setState({openFullForm: !this.state.openFullForm})}>
																	{!this.state.queryString.invitation_token &&
																		<div className="underline">
																			I'm not able to give MailReach an API access <br />OR Connect a GMAIL.COM inbox
																		</div>
																	}
																	{this.state.queryString.invitation_token &&
																		<>
																			I want to connect using an App Password
																		</>
																	}
																</span>
															</div>

															{this.state.openFullForm &&
																<div className="red-alert yellow">
																	<i class="fa-solid fa-circle-info"></i>
																	<span>
																	You can also connect by using an 'app password' with 2FA. Please <a href="https://help.mailreach.co/en/article/how-to-connect-a-gmail-google-workspace-account-to-mailreach-with-an-app-password-ulwj00/" target="_blank">follow our quick guide</a> and fill in the fields below.
																	</span>
																	<div className="clearfix"></div>
																</div>
															}

														</div>
													</div>
													<div className="clearfix"></div>
												</>
											</div>
										}

										{(!this.state.first_google_workspace_account && this.state.credentials.provider == 'google_workspace') &&
											<div className="col-md-12 links-underline">
												<>
													<div className="col-md-12">
														<ul className="tuto-li">
															<li><span>1</span> In the Google Workspace Admin, grant MailReach an API access by following the <a href="https://help.mailreach.co/en/article/how-to-connect-a-google-workspace-account-to-mailreach-2022-version-bc8nop/">Step 1 of our quick guide</a>.</li>
															<li><span>2</span> Click on "API access granted, I'm ready to sign in"</li>
														</ul>

														<div className="text-center tuto-gw-btn">
															<button className="btn btn-primary purple" onClick={() => this.setState({first_google_workspace_account: true})}>API access granted, I'm ready to sign in 🚀</button>
														</div>

														<div className="cant-btn text-center">
															<span onClick={() => this.setState({first_google_workspace_account: true, openFullForm: !this.state.openFullForm})}>I'm not able to give MailReach an API access <br />OR I have a "Gmail.com" inbox</span>
														</div>

													</div>
													<div className="clearfix"></div>
												</>
											</div>
										}

										{(this.state.imapError && this.state.credentials.provider == 'gmail') &&
											<div className="alert alert-warning">
												<ul>
												{this.state.credentials.sub_provider == 'lsa' &&
													<li> Please make sure to follow the steps in our <a target="_blank" href="https://help.mailreach.co/en/article/how-to-connect-a-google-workspace-account-to-mailreach-1xsossm">Google connection guide here</a>.</li>
												}
												{this.state.credentials.sub_provider != 'lsa' &&
													<li> Please make sure to follow the steps in our <a target="_blank" href="https://help.mailreach.co/en/article/how-to-connect-a-gmail-google-workspace-account-to-mailreach-with-an-app-password-ulwj00/">Google connection guide here</a>.</li>
												}
												</ul>
											</div>
										}
                   {(!this.state.imapError && this.state.credentials.provider == 'gmail') &&
											<div className="red-alert yellow alert-no-margin">
												<i class="fa-solid fa-circle-info"></i>

												{this.state.credentials.sub_provider == 'lsa' &&
													<span>
														 Please follow the steps of  <a href="https://help.mailreach.co/en/article/how-to-connect-a-google-workspace-account-to-mailreach-with-less-secure-apps-1xsossm/" target="_blank">our dedicated guide</a> and fill the fields below.
													</span>
												}
												{this.state.credentials.sub_provider != 'lsa' &&
													<span>
														 Please follow the steps of  <a href="https://help.mailreach.co/en/article/how-to-connect-a-gmail-google-workspace-account-to-mailreach-with-an-app-password-ulwj00/" target="_blank">our dedicated guide</a> and fill the fields below.
													</span>
												}
												<div className="clearfix"></div>
											</div>
										}

											{(this.state.imapError && this.state.credentials.provider == 'outlook') &&
												<div className="alert alert-warning">
													<ul>
														<li>For Office365 account, ensure IMAP is enabled. <a target="_blank" href="https://docs.microsoft.com/en-us/exchange/clients-and-mobile-in-exchange-online/pop3-and-imap4/enable-or-disable-pop3-or-imap4-access">How to enable IMAP</a>.</li>
														<li>For Office365 account, ensure SMTP is enabled. <a target="_blank" href="https://docs.microsoft.com/en-us/exchange/clients-and-mobile-in-exchange-online/authenticated-client-smtp-submission">How to enable SMTP</a>.</li>
													</ul>
												</div>
											}
		                  <form className="row" onSubmit={this.onSave}>
												{((this.state.openFullForm || this.state.credentials.provider == 'custom' || this.state.credentials.provider == 'gmail') && this.state.credentials.sub_provider != 'imap_only') &&
													<>
				                    <div className="fieldCont col-md-6">
				                      <input required="required" placeholder="Sender First Name" value={this.state.credentials.first_name} type="text" name="first_name" id="account_first_name" onChange={this.onChange}/>
				                    </div>
				                    <div className="fieldCont col-md-6">
				                      <input required="required" placeholder="Sender Last Name" value={this.state.credentials.last_name} type="text" name="last_name" id="account_last_name" onChange={this.onChange}/>
				                    </div>
				                    <div className="fieldCont col-md-12">
				                      <input required="required" placeholder={this.props.spamtestConnect ? 'Email address to link to MailReach' : 'Email address to warm'} value={this.state.credentials.email} type="email" name="email" id="account_email" onChange={this.onChange}/>
				                    </div>
														{this.state.credentials.provider != 'custom' &&
					                    <div className="fieldCont col-md-12">
																{this.state.credentials.sub_provider == 'lsa' &&
																	<input required="required" placeholder="Account password" value={this.state.credentials.password} type="password" name="password" id="account_password" onChange={this.onChange}/>
																}
																{this.state.credentials.sub_provider != 'lsa' &&
																	<input required="required" placeholder="Your app password" value={this.state.credentials.password} type="password" name="password" id="account_password" onChange={this.onChange}/>
																}
					                    </div>
														}
													</>
												}

												{this.state.credentials.provider == 'custom' &&
													<>
                          	{this.state.credentials.sub_provider == 'sendgrid' &&
														  <>
															  <div className="col-md-12 config-title">Sendgrid API key</div>
																<div className="fieldCont col-md-12">
																	<input required="required" placeholder="API key" value={this.state.credentials.smtp_server_password} type="password" name="smtp_server_password" id="smtp_server_password" onChange={this.onChange}/>
																</div>
															</>
													  }

														{this.state.credentials.sub_provider == 'sendinblue' &&
															<>
															<div className="col-md-12 config-title">Sendinblue SMTP Username and SMTP password</div>
															<div className="fieldCont col-md-6">
																<input required="required" placeholder="SMTP username" value={this.state.credentials.smtp_server_username} type="string" name="smtp_server_username" id="smtp_server_username" onChange={this.onChange}/>
															</div>
																<div className="fieldCont col-md-6">
																	<input required="required" placeholder="SMTP password" value={this.state.credentials.smtp_server_password} type="password" name="smtp_server_password" id="smtp_server_password" onChange={this.onChange}/>
																</div>
															</>
														}

														{this.state.credentials.sub_provider == 'mailgun' &&
															<>
															<div className="col-md-12 config-title">Mailgun servers location</div>
															<div className="fieldCont col-md-12">
																<select onChange={this.onChangeMailgunServers} value={this.state.credentials.smtp_server}  name="mailgun_servers" id="mailgun_servers" className="mailgun_server_selector">
																	<option value="smtp.mailgun.org">US servers</option>
																	<option value="smtp.eu.mailgun.org">EU servers</option>
																</select>
															</div>
															<div className="col-md-12 config-title">Mailgun Username and SMTP password</div>
															<div className="fieldCont col-md-6">
																<input required="required" placeholder="SMTP username" value={this.state.credentials.smtp_server_username} type="string" name="smtp_server_username" id="smtp_server_username" onChange={this.onChange}/>
															</div>
																<div className="fieldCont col-md-6">
																	<input required="required" placeholder="SMTP password" value={this.state.credentials.smtp_server_password} type="password" name="smtp_server_password" id="smtp_server_password" onChange={this.onChange}/>
																</div>
															</>
														}

														{this.state.credentials.sub_provider == 'imap_only' &&
														<div className="col-md-12 imap_only">
														<div className="red-alert">
														<i class="fa-solid fa-circle-info"></i>
															<span>
																You entered a Microsoft Outlook mailbox as IMAP. Since Microsoft is disabling SMTP/IMAP authentication on October 22, we need you to sign in with IMAP using the "Sign in" button below to avoid any interruption.
															</span>
															<div className="clearfix"></div>
														</div>

															<>
																<div className="col-md-12">
																	<p className="text-center">Click on the button below to connect in a few clicks</p>
																	<div className="text-center outlook-connect-content">

																		<a href={`${process.env.REACT_APP_BACKEND_URL}microsoft_auth/redirect?project_id=${this.state.currentProjectFi.join_project_team_token}${this.state.queryString.invitation_token ? '&quick_signin=true' : ''}${this.state.queryString.imap_only ? '&imap_only=true&account_id=' + this.state.queryString.edit_account_id : ''}`} className="btn google google-btn outlook-btn">
																			<div class="google-icon-wrapper">
																				<img src="/images/microsoft_m.png" />
																			</div>
																			<span>
																				Sign in with Outlook/Office 365
																			</span>
																		</a>

																	</div>
																</div>
																<div className="clearfix"></div>
															</>
														</div>
														}

													  {!this.state.credentials.sub_provider &&
															<>
																<div className="col-md-12 config-title">SMTP settings</div>
																<div className="fieldCont col-md-9">
																	<input required="required" placeholder="SMTP host" value={this.state.credentials.smtp_server} type="string" name="smtp_server" id="smtp_server" onChange={this.onChange}/>
																</div>
																<div className="fieldCont col-md-3">
																	<input required="required" placeholder="Port" value={this.state.credentials.smtp_server_port} type="number" name="smtp_server_port" id="smtp_server_port" onChange={this.onChange}/>
																</div>
																<div className="fieldCont col-md-6">
																	<input required="required" placeholder="SMTP username" value={this.state.credentials.smtp_server_username} type="string" name="smtp_server_username" id="smtp_server_username" onChange={this.onChange}/>
																</div>
																<div className="fieldCont col-md-6">
																	<input required="required" placeholder="SMTP password" value={this.state.credentials.smtp_server_password} type="password" name="smtp_server_password" id="smtp_server_password" onChange={this.onChange}/>
																</div>
																<div className="fieldCont col-md-8">
																	<select required="required" value={this.state.credentials.smtp_server_auth_type || 'plain'} onChange={this.onChange} name="smtp_server_auth_type">
																		<option value="">Select an SMTP auth type</option>
																		<option value="plain">Plain</option>
																		<option value="login">Login</option>
																		<option value="cram_md5">cram_md5</option>
																	</select>
																</div>
																<div className="fieldCont col-md-4 text-center">
																	<label>Use SSL/TLS</label>
																	<input
																		name="smtp_server_starttls"
																		checked={this.state.credentials.smtp_server_starttls}
																		type="checkbox"
																		onChange={this.onChange} />
																</div>

																{!this.state.hide_imap_form_oauth &&
																	<>
																		<div className="col-md-12 config-title">
																			<span className="pull-left">IMAP settings</span>
																			<span className="help-bubble">
																				<Tooltip title='We use IMAP to generate realistic conversations, put the replies in threads and hide MailReach emails from your inbox.' placement="bottom">
																					<div>
																						<i class="fas fa-info-circle"></i>
																					</div>
																				</Tooltip>
																			</span>
																		</div>

																		{this.state.currentProject.config_feature_email_workspace_enabled &&
																			<div className="sub-provider-buttons-imap col-md-12">
																				<button type="button" onClick={() => { let credentials = this.state.credentials; credentials.imap_server = 'imap.gmail.com'; credentials.imap_server_port = 993; this.setState({imap_provider_oauth: 'google', hide_imap_form_oauth: false, credentials}) }}>Use a Google inbox <img className="pull-right" src="/images/gmail_logo_m.png" /></button>
																				<button type="button" onClick={() => { this.setState({imap_provider_oauth: 'outlook', hide_imap_form_oauth: true}) }} >Use an Outlook inbox <img className="pull-right" src="/images/logo_outlook.png" /></button>
																			</div>
																		}

																		{this.state.imap_provider_oauth == 'google' &&
																			<div className="fieldCont col-md-9">
																				<a onClick={() => { this.setState({hide_imap_form_oauth: true}) }}>I don't want to use an app password</a>
																			</div>
																		}

																		<div className="fieldCont col-md-9">
																			<input required="required" placeholder="IMAP host" value={this.state.credentials.imap_server} type="string" name="imap_server" id="imap_server" onChange={this.onChange}/>
																		</div>
																		<div className="fieldCont col-md-3">
																			<input required="required" placeholder="Port" value={this.state.credentials.imap_server_port}  type="number" name="imap_server_port" id="imap_server_port" onChange={this.onChange}/>
																		</div>
																		<div className="fieldCont col-md-6">
																			<input required="required" placeholder="IMAP username" value={this.state.credentials.imap_server_username} type="string" name="imap_server_username" id="imap_server_username" onChange={this.onChange}/>
																		</div>
																		<div className="fieldCont col-md-6">
																			<input required="required" placeholder="IMAP password" value={this.state.credentials.imap_server_password} type="password" name="imap_server_password" id="imap_server_password" onChange={this.onChange}/>
																		</div>
																	</>
																}
															</>
														}

														{((this.state.credentials.sub_provider && this.state.credentials.sub_provider != 'imap_only' && !this.state.hide_imap_form_oauth)) &&
															<>
																<div className="col-md-12 config-title">
																	<span className="pull-left">IMAP settings</span>
																	<span className="help-bubble">
																		<Tooltip title='We use IMAP to generate realistic conversations, put the replies in threads and hide MailReach emails from your inbox.' placement="bottom">
																			<div>
																				<i class="fas fa-info-circle"></i>
																			</div>
																		</Tooltip>
																	</span>
																</div>

																{this.state.currentProject.config_feature_email_workspace_enabled &&
																	<div className="sub-provider-buttons-imap col-md-12">
																		<button type="button" onClick={() => { this.setState({imap_provider_oauth: 'google', hide_imap_form_oauth: false}) }}>Use a Google inbox <img className="pull-right" src="/images/gmail_logo_m.png" /></button>
																		<button type="button" onClick={() => { this.setState({imap_provider_oauth: 'outlook', hide_imap_form_oauth: true}) }} >Use an Outlook inbox <img className="pull-right" src="/images/logo_outlook.png" /></button>
																	</div>
																}

																{this.state.imap_provider_oauth == 'google' &&
																	<div className="fieldCont col-md-9">
																		<a onClick={() => { this.setState({hide_imap_form_oauth: true}) }}>I don't want to use an app password</a>
																	</div>
																}

																<div className="fieldCont col-md-9">
																	<input required="required" placeholder="IMAP host" value={this.state.credentials.imap_server} type="string" name="imap_server" id="imap_server" onChange={this.onChange}/>
																</div>
																<div className="fieldCont col-md-3">
																	<input required="required" placeholder="Port" value={this.state.credentials.imap_server_port}  type="number" name="imap_server_port" id="imap_server_port" onChange={this.onChange}/>
																</div>
																<div className="fieldCont col-md-6">
																	<input required="required" placeholder="IMAP username" value={this.state.credentials.imap_server_username} type="string" name="imap_server_username" id="imap_server_username" onChange={this.onChange}/>
																</div>
																<div className="fieldCont col-md-6">
																	<input required="required" placeholder="IMAP password" value={this.state.credentials.imap_server_password} type="password" name="imap_server_password" id="imap_server_password" onChange={this.onChange}/>
																</div>
															</>
															}
														</>

												}
												{this.state.openFullForm &&
				                  <div className="col-md-12">
				                    <input className="btn" type="submit" name="commit" value={this.state.edit ? this.state.reconnect ? 'Reconnect' : 'Edit' : 'Sign in with the app password'}/>
				                  </div>
												}
												{(this.state.credentials.provider != 'google_workspace' && this.state.credentials.provider != 'outlook') &&
													<div className="col-md-12">
														{!this.props.currentProject.config_new_imap_smtp_form_enabled &&
															<>
																<input className="btn" type="submit" name="commit" value={this.state.edit ? this.state.reconnect ? 'Reconnect' : 'Edit' : 'Sign In with SMTP/IMAP'}/>
															</>
														}
														{this.props.currentProject.config_new_imap_smtp_form_enabled &&
															<>
																{(this.state.credentials.sub_provider == 'imap_only' && this.state.openFullForm) &&
																// TODO : hidden if no open full form
																	<input className="btn" type="submit" name="commit" value={this.state.edit ? this.state.reconnect ? 'Reconnect' : 'Edit IMAP' : 'Sign In with IMAP'}/>
																}
																{((this.state.credentials.sub_provider != 'imap_only') && (this.state.credentials.provider != 'gmail')) &&
																	<input className="btn" type="submit" name="commit" value={this.state.edit ? this.state.reconnect ? 'Reconnect' : 'Edit' : 'Sign In with SMTP'}/>
																}
																{(this.state.credentials.provider == 'gmail') &&
																	<>
																		{this.state.credentials.sub_provider == 'lsa' &&
																			<input className="btn" type="submit" name="commit" value={this.state.edit ? this.state.reconnect ? 'Reconnect' : 'Edit' : 'Sign in'}/>
																		}
																		{this.state.credentials.sub_provider != 'lsa' &&
																			<input className="btn" type="submit" name="commit" value={this.state.edit ? this.state.reconnect ? 'Reconnect' : 'Edit' : 'Sign in with the app password'}/>
																		}
																	</>
																}
															</>
														}
													</div>
												}

		                  </form>
		                </>
		              }


									{this.state.credentials.provider == 'gmail' &&
										<div className="cant-btn text-center">
											{this.state.credentials.sub_provider == 'lsa' &&
											<span onClick={() => this.selectProvider('gmail')}>
												I prefer to sign in using a Google App Password
											</span>
											}
											{(this.state.credentials.sub_provider != 'lsa' && this.state.currentProject) &&
												<>
													{this.state.currentProject.config_feature_email_workspace_enabled &&
														<NavLink to={`/dashboard/${this.props.match.params.project_id}/account/workspaces/create${this.props.spamtestConnect ? '?spamchecker_only=true' : ''}`}>
															I don't want to use an app password
														</NavLink>
													}
													{!this.state.currentProject.config_feature_email_workspace_enabled &&
														<span onClick={() => this.selectProvider('gmail_lsa')}>
															I prefer to sign in with less secure apps
														</span>
													}
												</>
											}
										</div>
									}

									{this.state.currentProject &&
										<>
											{(this.state.currentProject.config_feature_new_google_auth_enabled && this.state.credentials.provider == 'gmail') &&
												<div className="cant-btn text-center">
													<span onClick={() => this.selectProvider('google_workspace')}>
															<>
																I want to connect using the API (Not recommended)
															</>
													</span>
												</div>
											}
										</>
									}

		            </div>

								{this.state.credentials.sub_provider == 'imap_only' &&
									<div className="col-md-12">
										<a href={`/dashboard/${this.props.match.params.project_id}/account/connect?edit_account_id=${this.state.queryString.edit_account_id}`} className="go-back-btn">
											<i class="fa-solid fa-arrow-left"></i> Go back
										</a>
									</div>
								}

          </div>
					</>
				}
				</div>


					<>
						{(!this.state.queryString.invitation_token && !this.props.spamtestConnect && (this.state.credentials.provider == 'google_workspace' || this.state.credentials.provider == 'gmail' || this.state.credentials.provider == 'outlook')) &&
								<div className="row">
									<div className="col-md-offset-6 col-md-6 imap checkout imap team">
										<h2>Or invite people to connect theirs</h2>
										<div className="card col-md-12">
											{this.state.credentials.provider == 'google_workspace' &&
												<p>Send the following link to the people you want to connect their Google account to MailReach.</p>
											}

											{this.state.credentials.provider == 'outlook' &&
												<p>Send the following link to the people you want to connect their Outlook to MailReach.</p>
											}

											<div className="email-code copy-style copy-code code-share-team" onClick={() =>  this.copyText(`https://${window.location.hostname}/connect?invitation_token=${this.state.currentProject.join_project_team_token}`)}>
												<div className="code">
													https://{window.location.hostname}/connect?invitation_token={this.state.currentProject.join_project_team_token}
												</div>
												<div className="copy">
													<i class="far fa-copy"></i>
												</div>
											</div>
											<p>⚠️ Connecting a new Google account will increase your monthly subscription according to your current plan.</p>
										</div>
									</div>
								</div>
							}
						</>

				<Modal className="reputation-modal help-modal" show={this.state.showHelpModal} onHide={() => this.setState({showHelpModal: false})}>
					<Modal.Body>

						{this.state.queryString &&
							<>
								{this.state.queryString.error_type == 'SEEM_TO_BE_DUPLICATE' &&
									<>
									{this.state.queryString.provider == 'GMAIL' &&
										<>
											<h1 className="text-center">You're not logged in the right Google account</h1>
											<div className="txt text-center small-font">
												<p>We detect that you're connecting an Google account that is already linked to MailReach : {this.state.queryString.email}.</p>

												<p>If your intention is to connect another Google account :</p>

												<p>In your browser, you have to be logged into the Google account you wish to connect to MailReach and then try connecting it again to MailReach.</p>

												<p>Get more details in our guide here :</p>
												<p><a href="https://help.mailreach.co/en/article/fix-i-cant-manage-to-connect-the-right-mailbox-account-to-mailreach-google-microsoft-i8a7e/">I can't manage to connect the right mailbox account to MailReach [Google / Microsoft]</a></p>
											</div>
										</>
									}
									{this.state.queryString.provider == 'OUTLOOK' &&
										<>
										<h1 className="text-center">You're not logged in the right Outlook account</h1>
										<div className="txt text-center small-font">
											<p>We detect that you're connecting an Outlook account that is already linked to MailReach : {this.state.queryString.email}.</p>

											<p>If your intention is to connect another Outlook account :</p>

											<p>In your browser, you have to be logged into the Outlook account you wish to connect to MailReach and then try connecting it again to MailReach.</p>

											<p>Get more details in our guide here :</p>

											<p><a href="https://help.mailreach.co/en/article/fix-i-cant-manage-to-connect-the-right-mailbox-account-to-mailreach-google-microsoft-i8a7e/">I can't manage to connect the right mailbox account to MailReach [Google / Microsoft]</a></p>
										</div>
										</>
									}
									</>
								}
							</>
						}

						{(this.state.modal_error_type && this.state.queryString.error_type != 'SEEM_TO_BE_DUPLICATE') &&
							<>
								<h1 className="text-center">MailReach can't manage to connect</h1>
								<div className="txt text-center">
									<p>To be able to connect your account, we need you to follow a few steps.</p>

									<p>No worries, it takes 1 minute. Please follow this guide : </p>
									<p>
										<a className='text-center' target="_blank" href="https://help.mailreach.co/en/article/how-to-fix-the-google-outdated-token-issue-1aaxvxp/">
											How to fix the Google outdated token issue
										</a>
									</p>
								</div>
							</>
						}
						{(this.state.queryString.modal_connect_success_invite) &&
							<>
								<h1 className="text-center">Well done, mailbox successfully connected 🙌</h1>
								<div className="txt text-center">
									<p>Your mailbox has been successfully connected to MailReach under the account {this.state.currentProject.main_user_email}. </p>

									<p>Now this mailbox will be warmed to maximize its sending reputation and deliverability. </p>

									<p>The warming emails (conversations between your inbox and our network of inboxes) will be stored in a folder named "To Follow" and hidden from you. </p>

									<p>Sometimes, there can be a small delay to automatically hide them from your main inbox and move them to the folder. That's normal. </p>

									<p>You're a wonderful person, have a great day.</p>

									<p>The MailReach team.</p>
								</div>
							</>
						}
						{this.state.credentials.provider == 'gmail' &&
							<>
							{(!this.state.gmailAppPassword && !this.state.gmailDisplayUnlock) &&
								<>
									<h1 className="text-center">
										Connection failed. Relax, here's how to fix 🧘‍♂️
									</h1>
									<div className="txt text-center">

									{this.state.credentials.sub_provider == 'lsa' &&
										<>
											<ul className="text-left li-margin">
												<li>Make sure you have properly allowed the 'Less Secure Apps' on the right mailbox.</li>
												<li>Google may have blocked the process. Check if you have received 'Security Alert' emails from Google. If yes, open them and say "It was me". And re-check if 'Less Secure Apps' are still well enabled (Google may have disabled them).</li>
												<li>Make sure the address and password are correct. It happens to the best of us.</li>
												<li>All the steps to make it work are detailed in this 3 minutes guide: <br /><a className='text-center' target="_blank" href="https://help.mailreach.co/en/article/how-to-connect-a-google-workspace-account-to-mailreach-with-less-secure-apps-1xsossm/">How to connect a Google Workspace account to MailReach with Less Secure Apps</a></li>
											</ul>
										</>
									}
									{this.state.credentials.sub_provider != 'lsa' &&
									<>
										<ul className="text-left li-margin">
											<li>Make sure that you have created a Google App Password and used it to sign in. It doesn't work if you use the regular password of the mailbox.</li>
											<li>Make sure the address is correct.</li>
											<li>All the steps to make it work are detailed in this 3 minutes guide: <br /><a className='text-center' target="_blank" href="https://help.mailreach.co/en/article/how-to-connect-a-gmail-google-workspace-account-to-mailreach-with-an-app-password-ulwj00/">How to connect a Google account to MailReach with an App Password.</a></li>
										</ul>
									</>
									}
									</div>
								</>
							}
							{this.state.gmailDisplayUnlock &&
								<>
									<h1 className="text-center">You're almost there</h1>
									<div className="txt text-center">
										<p>Sometimes, Google blocks the connection.<br /> To fix this :</p>
										<ul className="text-left">
										  <li>Check if the mailbox has received 'Security Alert' emails. If yes, treat them by saying "It was me" to reassure Google</li>
                      <li>Do the <a target="_blank" href="https://accounts.google.com/b/0/DisplayUnlockCaptcha">Display Unlock Captcha</a> (and make sure to do it on the right mailbox)</li>
                      <li>If you still get an error, <a target="_blank" href="https://help.mailreach.co/en/article/how-to-connect-a-google-workspace-account-to-mailreach-1xsossm#1-troubleshooting-if-you-have-done-the-4-steps-but-still-cant-connect-your-account-to-mailreach">please check the troubleshooting section of our Google connection guide here</a></li>
										</ul>
									</div>
								</>
							}
							{this.state.gmailAppPassword &&
								<>
									<h1 className="text-center">MailReach can't connect, we need you!</h1>
									<div className="txt text-center">
										<ul className="text-left">
											<li>Make sure you have created an 'App Password' for MailReach.</li>
											<li>Make sure you're using the 'App Password' to connect your account.</li>
										</ul>
										<p>
											<a className='text-center' target="_blank" href="https://help.mailreach.co/en/article/how-to-connect-a-gmail-google-workspace-account-to-mailreach-with-an-app-password-ulwj00/">
												How to connect a Gmail / Google Workspace account with 2FA to MailReach.
											</a>
										</p>
									</div>
								</>
							}
							</>
						}
						<div className="footer text-center">
							<Button variant="secondary" onClick={() => this.setState({showHelpModal: false})}>
								Close
							</Button>
						</div>
					</Modal.Body>
				</Modal>
				<ToastContainer />
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		currentProject: state.currentProject,
		currentPricing: state.currentPricing
	};
}

function mapDispatchToProps(dispatch) {
	return {
		projectsActions: bindActionCreators(projectsActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardAccountsConnect));
