import React, { Suspense } from 'react';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import {ToastContainer, toast} from 'react-toastify';
import SessionService from '../../services/session.service';
import 'react-toastify/dist/ReactToastify.css';

import i18n from "../../i18n";

class ForgotPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {credentials: {email: '', password: ''}, passwordShort: false, errors: [], isInvite: false, organization: {}, currentToken: null };
		this.onChange = this.onChange.bind(this);
		this.onSave = this.onSave.bind(this);
	}

	onChange(event) {
		const field = event.target.name;
		const credentials = this.state.credentials;
		credentials[field] = event.target.value;
		return this.setState({credentials});
	}

	onSave(event) {
		event.preventDefault();
		SessionService.requestResetPassword(this.state.credentials).then((response) => {
			toast.success('We just sent a reset link, check you email');
		})
  	.catch((error) => {
			this.setState({error: true, signupForm: false, loading: false});
			toast.error('Oops, we have an issue. Ensure email is correct.');
		});
	}

	componentWillMount(){
		if(localStorage.getItem('jwt')){
			this.props.history.push('/redirect');
		}
		let queryParams = queryString.parse(this.props.location.search);
		let c = this;
		// Get invite data if we have it on query params
	}

	componentWillReceiveProps(nextProps) {
		const errors = nextProps.errors;
		if (errors && errors != this.props.errors) {
			toast.error('Oops, we have an issue. Ensure email is correct.');
		}
	}

	render() {
		return (

					<div>
						<div className="sign signup col-md-5">
							<div className="ct">
								<div className="market-container text-center">
									<img src="/images/logo_b.png" />
									<h1>Reset password.</h1>
								</div>
									{this.state.isInvite &&
										<div className="invite-b text-center">
											<img src={this.state.organization.logoReadable} />
											<p>{i18n.t('signup.invited')} <b>{this.state.organization.name}</b> {i18n.t('signup.on')}</p>
										</div>
									}
									{/*<form id="google_sign_in" action="/auth/google/sign_in" accept-charset="UTF-8" method="post">
										<input name="utf8" type="hidden" value="✓" />
										<button type="submit">
											Your Google account
										</button>
									</form>*/}
									<form class="new_user" id="new_user">
									  {/*<div class="authSeparator">
											<hr />
									    <span>OR</span>
									  </div>*/}

									  <fieldset>
									    <div class="fieldCont">
									      <input value={this.state.credentials.email} required="required" type="email" name="email" id="user_email" onChange={this.onChange}/>
									      <label class="over">{i18n.t('input.email')}</label>
									    </div>
									  </fieldset>

									  <div class="formActions">
									    <div class="buttonCont">
									      <input class="submit purple-btn" type="submit" name="commit" value='Request reset password' onClick={this.onSave}/>
									    </div>
									  </div>
									</form>
									<div class="otherLinks">
										{i18n.t('signin.create')} <Link to={'/signup' + this.props.location.search}>{i18n.t('signup.button')}</Link><br /><br />
										{i18n.t('signup.alreadyDesc')}
										<Link to={'/signin' + this.props.location.search}>{i18n.t('signin.button')}</Link>
									</div>
									<ToastContainer />
									</div>
								</div>
								<div className="col-md-7 teaser">
									<img src="/images/pattern_bg.svg" loading="lazy" class="cta-pattern-bg" />
									<div className="col-md-12">

										<div className="col-md-5 review-card margin-left">
											<div className="star-icon-wrap">
												<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
												<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
												<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
												<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
												<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
											</div>
											<p class="paragraph-18px">“We’ve tested many solutions and MailReach is definitely the <strong>most efficient email deliverability tool.</strong> MailReach helps us raise and maintain the deliverability of 20 email accounts. <strong>A great deliverability booster.”</strong></p>
											<div class="author-contain">
												<img src="/images/jy.png" loading="lazy" alt="" class="author-img-small" />
												<div>
													<div class="author-name">Jean-Yves Delmotte</div>
													<div class="author-position">Co-Founder @ PaletteHQ</div>
												</div>
											</div>
										</div>

										<div className="col-md-5 review-card hidden-mobile">
											<div className="star-icon-wrap">
												<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
												<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
												<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
												<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
												<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
											</div>
											<p class="paragraph-18px">“MailReach is the most <strong>effective and user friendly cold email deliverability tool.</strong> Within a few weeks, we’ve seen great improvements on our outreach campaigns.”</p>
											<div class="author-contain">
												<img src="/images/marion.png" loading="lazy" alt="" class="author-img-small" />
												<div>
													<div class="author-name">Marion Gurtekin</div>
													<div class="author-position">Head of Sales @ Shine</div>
												</div>
											</div>
										</div>
									</div>

									<div className="col-md-12 hidden-light hidden-mobile">
										<div className="col-md-5 review-card margin-left">
											<div className="star-icon-wrap">
												<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
												<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
												<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
												<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
												<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
											</div>
											<p class="paragraph-18px">“That’s simple, MailReach has helped us <strong>multiply our cold emails open rates x2.</strong> It has became <strong>our life insurance.</strong> Their deliverability guide is a gold mine too.”</p>
											<div class="author-contain">
												<img src="/images/bastien.png" loading="lazy" alt="" class="author-img-small" />
												<div>
													<div class="author-name">Bastien Paul</div>
													<div class="author-position">Growth @ BigBlue</div>
												</div>
											</div>
										</div>

										<div className="col-md-5 review-card">
											<div className="star-icon-wrap">
												<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
												<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
												<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
												<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
												<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
											</div>
											<p class="paragraph-18px">“As a cold email expert, I always recommend using MailReach to maximize the reputation of an email address / domain. MailReach combines complex algorithms and email best practices to offer <strong>a very efficient deliverability solution.”</strong></p>
											<div class="author-contain">
												<img src="/images/anas.png" loading="lazy" alt="" class="author-img-small" />
												<div>
													<div class="author-name">Anas El-Mhamdi</div>
													<div class="author-position">Growth Engineer @ Gorgias</div>
												</div>
											</div>
										</div>

									</div>

										<div className="text-center">
										<div className="col-md-11 review-all text-center">
											<div className="review-hero-content">


												<div className="review-hero-item col-md-4">
													<div className="star-icon-wrap">
														<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
														<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
														<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
														<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
														<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
													</div>
													<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/667430364f853b83702fac8a_Capterra%20Logo%20Image.svg" loading="lazy" alt="" class="review-hero-item-image" />
													<div className="review-hero-item-text">5/5 on Capterra</div>
												</div>

												<div className="review-hero-item col-md-4 hidden-mobile">
													<div className="star-icon-wrap">
														<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
														<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
														<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
														<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
														<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
													</div>
													<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/66743036d8a496da50bd3727_SaaSHub%20Logo%20Image.svg" loading="lazy" alt="" class="review-hero-item-image" />
													<div className="review-hero-item-text">5/5 on SaaSHub</div>
												</div>

												<div className="review-hero-item col-md-4 hidden-mobile">
													<div className="star-icon-wrap">
														<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
														<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
														<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
														<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
														<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
													</div>
													<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/66743037734084ff623315b1_G2%20Logo%20Image.svg" loading="lazy" alt="" class="review-hero-item-image" />
													<div className="review-hero-item-text">4.6/5 on G2</div>
												</div>


											</div>
										</div>
										</div>


								</div>


								<div className="clearfix"></div>
							</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ForgotPassword));
