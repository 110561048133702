const CHARTSJS_TOOLTIPS_STYLE = {
  backgroundColor: '#FFF',
   titleFontSize: 18,
   titleFontColor: '#18164c',
   titleMarginBottom: 16,
   bodyFontColor: 'rgba(19, 20, 49, 0.4)',
   bodyFontSize: 14,
   displayColors: false,
   borderColor: '#e0e5e9',
   xPadding: 13,
   yPadding: 13,
   borderWidth: 1
};






export { CHARTSJS_TOOLTIPS_STYLE };
