export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_ERROR = 'LOG_IN_ERROR';
export const CURRENT_USER_GET_SUCCESS = 'CURRENT_USER_GET_SUCCESS';
export const CURRENT_USER_UPDATE_SUCCESS = 'CURRENT_USER_UPDATE_SUCCESS';
export const CURRENT_PROJECT_GET_SUCCESS = 'CURRENT_PROJECT_GET_SUCCESS';
export const PROJECT_CREATE_SUCCESS = 'PROJECT_CREATE_SUCCESS';
export const PROJECT_GET_SUCCESS = 'PROJECT_GET_SUCCESS';
export const PROJECT_UPDATE_SUCCESS = 'PROJECT_UPDATE_SUCCESS';
export const CURRENT_PRICING_GET_SUCCESS = 'CURRENT_PRICING_GET_SUCCESS';
export const NOTIFICATIONS_GET_SUCCESS = 'NOTIFICATIONS_GET_SUCCESS';
export const NOTIFICATIONS_CLOSE_SUCCESS = 'NOTIFICATIONS_CLOSE_SUCCESS';
