import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {ToastContainer, toast} from 'react-toastify';
import Loader from 'react-loader-spinner';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import queryString from 'query-string';


import AccountService from '../../../../../services/account.service';
import AutomatedTestService from '../../../../../services/automatedTest.service';
import ConfigService from '../../../../../services/config.service';

const numToWordObj = {
  0: 'zero',
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
  9: 'nine',
  10: 'ten',
  11: 'eleven',
  12: 'twelve',
  13: 'thirteen',
  14: 'fourteen',
  15: 'fifteen',
  16: 'sixteen',
  17: 'seventeen',
  18: 'eighteen',
  19: 'nineteen',
  20: 'twenty',
  30: 'thirty',
  40: 'forty',
  50: 'fifty',
  60: 'sixty',
  70: 'seventy',
  80: 'eighty',
  90: 'ninety'
};

const dayWords = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

const hoursConfig = [{value: 0, text: '00:00 AM'},{value: 1, text: '01:00 AM'},{value: 2, text: '02:00 AM'},{value: 3, text: '03:00 AM'},{value: 4, text: '04:00 AM'},{value: 5, text: '05:00 AM'},{value: 6, text: '06:00 AM'},{value: 7, text: '07:00 AM'},{value: 8, text: '08:00 AM'},{value: 9, text: '09:00 AM'},{value: 10, text: '10:00 AM'},{value: 11, text: '11:00 AM'},{value: 12, text: '12:00 PM'},{value: 13, text: '01:00 PM'},{value: 14, text: '02:00 PM'},{value: 15, text: '03:00 PM'},{value: 16, text: '04:00 PM'},{value: 17, text: '05:00 PM'},{value: 18, text: '06:00 PM'},{value: 19, text: '07:00 PM'},{value: 20, text: '08:00 PM'},{value: 21, text: '09:00 PM'},{value: 22, text: '10:00 PM'},{value: 23, text: '11:00 PM'}];


class DashboardSpamTestAutomatedFrequency extends React.Component {
	constructor(props) {
		super(props);
    this.state = {spamLogs: [], timezones: [], automatedTest: { automated_test_runs: []}, selectedDays: []};
    //this.createAutomatedTest = this.createAutomatedTest.bind(this);
    this.onChange = this.onChange.bind(this);
		this.onChangeAll = this.onChangeAll.bind(this);
    this.selectDay = this.selectDay.bind(this);
    this.saveRunAtAndRedirect = this.saveRunAtAndRedirect.bind(this);
    this.checkWeekAlert = this.checkWeekAlert.bind(this);
	}

	componentWillMount() {

		ConfigService.timezones().then((response) => {
      this.setState({timezones: response.body.timezones})
    })
    .catch((error) => {
      toast.error('Oops. An error occured.');
    });

    AutomatedTestService.get(this.props.match.params.automated_test_id).then((response) => {

      let selectedDays = response.body.automated_test_runs.map(function (obj) {
        return obj.weekday;
      });

			if(!response.body.time_zone == 'UTC' && (response.body.time_zone != Intl.DateTimeFormat().resolvedOptions().timeZone.split('/')[1])){
				response.body.time_zone = response.body.time_zone;
			}

      this.setState({automatedTest: response.body, selectedDays})

    })
    .catch((error) => {
      this.setState({error: true, signupForm: false, loading: false});
      toast.error('Oops. An error occured.');
    });
	}

  onChange(event) {
		const field = event.target.name;
		const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		return this.setState({hourUtc: value});
	}

	onChangeAll(event) {
		let automatedTest = this.state.automatedTest;
		const field = event.target.name;
		const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    if(field == 'repeat_period' && value == 'DAY'){
      this.setState({selectedDays: []});
    }
		automatedTest[field] = value;
		return this.setState({automatedTest});
	}


  selectDay(day){
    let selectedDays = this.state.selectedDays;

    if(selectedDays.includes(day)){
      selectedDays = selectedDays.filter(x => x !== day);
    }else{
      selectedDays.push(day);
    }
    this.setState({selectedDays});

    this.checkWeekAlert(selectedDays);
  }

  saveRunAtAndRedirect(){
		if(!this.state.selectedDays){
			toast.error('You must select a running hour');
			return;
		}

		AutomatedTestService.update(this.state.automatedTest).then((response) => {
			AutomatedTestService.runs(this.props.match.params.automated_test_id, this.state.selectedDays).then((response) => {
				// Redirect to next step
				this.props.history.push(`/dashboard/${this.props.match.params.project_id}/spam-tests/auto/${this.props.match.params.automated_test_id}/alerts`);
			})
			.catch((error) => {
				this.setState({error: true, signupForm: false, loading: false});
				toast.error('Oops. An error occured.');
			});
		})
		.catch((error) => {
			this.setState({error: true, signupForm: false, loading: false});
			toast.error('Oops. An error occured.');
		});
  }


  checkWeekAlert(values){
    let txt = values.sort((x,y)=> x-y).join('');

    let checkContain = ['12', '23', '34', '45', '56', '67'];

    let showWeekAlert = false;
    let i = 0;
    while (i < checkContain.length) {
      if(txt.includes(checkContain[i])){
        showWeekAlert = true;
      }
      i++;
    }

    this.setState({showWeekAlert})
  }


	render() {
		if(this.state.loading){
			return(
				<div className="app container text-center">
					<div className="loader">
						<Loader
						 type="Oval"
						 color="#b7b7b7"
						 height="100"
						 width="100"
						/>
					</div>
				</div>
			)
		}


		return(
			<>
        <div className="app container home frequency-page">

          <div className="progress-automated-test">
            <ul>
              <NavLink to={`/dashboard/${this.props.currentProject.id}/spam-tests/auto/${this.state.automatedTest.id}/accounts`} className="done"></NavLink>
              <NavLink to={`/dashboard/${this.props.currentProject.id}/spam-tests/auto/${this.state.automatedTest.id}/template`} className="done"></NavLink>
              <NavLink to={`/dashboard/${this.props.currentProject.id}/spam-tests/auto/${this.state.automatedTest.id}/target`} className="done"></NavLink>
              <li className="selected"></li>
							<li></li>
            </ul>
            <div className="bar"></div>
          </div>

          <h1>Set up the frequency</h1>

					<div className="card">
						<div className="alert alert-purple-light yellow">
							<p>Choose at what frequency MailReach will run the automatic test.</p>
							<p>Note that it will cost you one spam test credit each time an automated test is triggered.</p>
						</div>

            {(this.state.automatedTest.repeat_period == 'DAY' && this.state.automatedTest.repeat_period_delay < 2) &&
              <div className="alert alert-warning">
                You should have more days between each test. 2 days is the minimum, we recommend at least 7 days.
              </div>
            }

            {(this.state.automatedTest.repeat_period == 'DAY' && this.state.automatedTest.repeat_period_delay == 2) &&
              <div className="alert alert-warning">
                Running spam tests too frequently isn't recommended. 2 days is the minimum, we recommend at least 7 days.
              </div>
            }

            {(this.state.automatedTest.repeat_period == 'WEEK' && this.state.showWeekAlert) &&
              <div className="alert alert-warning">
                Running tests two days in a row isn't recommended. A minimum delay of 2 days is required. We recommend at least 7.
              </div>
            }

	          <div className="repeat-day">

						<div className="col-md-12">
							<b className="pull-left">Repeat every</b> <i className="pull-left info-frequencies">We recommend a minimum of 7 days between spam tests.</i>
							<div className="fieldCont col-md-12">
								<form className="repeat-period">
									<input type="number" name="repeat_period_delay" onChange={this.onChangeAll} min={this.state.automatedTest.repeat_period == "DAY" ? "2" : "1"} value={this.state.automatedTest.repeat_period_delay} />
									<select onChange={this.onChangeAll} name="repeat_period" value={this.state.automatedTest.repeat_period}>
										<option value="WEEK">weeks</option>
										<option value="DAY">days</option>
									</select>

								</form>
							</div>
						</div>


						{this.state.automatedTest.repeat_period == 'WEEK' &&
								<div className="col-md-12">
			            <b>Repeat on</b>
			            <ul className="spamcheck-repeat-day">
			              <li className={this.state.selectedDays.includes(7) ? 'selected' : ''} onClick={() => this.selectDay(7)}>S</li>
			              <li className={this.state.selectedDays.includes(1) ? 'selected' : ''} onClick={() => this.selectDay(1)}>M</li>
			              <li className={this.state.selectedDays.includes(2) ? 'selected' : ''} onClick={() => this.selectDay(2)}>T</li>
			              <li className={this.state.selectedDays.includes(3) ? 'selected' : ''} onClick={() => this.selectDay(3)}>W</li>
			              <li className={this.state.selectedDays.includes(4) ? 'selected' : ''} onClick={() => this.selectDay(4)}>T</li>
			              <li className={this.state.selectedDays.includes(5) ? 'selected' : ''} onClick={() => this.selectDay(5)}>F</li>
			              <li className={this.state.selectedDays.includes(6) ? 'selected' : ''} onClick={() => this.selectDay(6)}>S</li>
			            </ul>
								</div>
							}

              <div className="clearfix"></div>

							<div className="col-md-12">
								<b>Select the time</b>
		            <div className="fieldCont col-md-12">
									<form className="col-md-6">

			              <select className="col-md-4 hour-dropdown-select" onChange={this.onChangeAll} name="hour" value={this.state.automatedTest.hour}>
										<label>Repeat at</label>
			              <option value="">Repeat at</option>
			              {hoursConfig.map((hour, index) => {
			                return (
			                  <option value={hour.value}>{hour.text}</option>
			                )})}
			              </select>

										<select className="col-md-6" onChange={this.onChangeAll} name="time_zone" value={this.state.automatedTest.time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone.split('/')[1]}>
											<label>Timezone</label>
											<option value="">Time zone</option>
											{this.state.timezones.map((timezone, index) => {
												return (
													<option value={timezone}>{timezone}</option>
												)})}
										</select>

									</form>
                  <div className="clearfix"></div>
		            </div>
							</div>

							{(this.state.automatedTest.repeat_period && this.state.automatedTest.hour != null && !((this.state.showWeekAlert) || (this.state.automatedTest.repeat_period == 'DAY' && this.state.automatedTest.repeat_period_delay < 2) || (this.state.automatedTest.repeat_period == 'WEEK' && this.state.selectedDays.length == 0))) &&
								<>

                {this.state.automatedTest.account_day_delta < 15 &&
                  <div className="imap col-md-8 no-padding-left">
                    <div class="red-alert yellow alert-no-margin">
                      <i class="fa-solid fa-circle-info"></i> The first test will be run once this address reaches 14 days of warming to protect its reputation.
                    </div>
                  </div>
                }

                <div className="col-md-8">
  								<p className="spamtest-auto-schedule-preview"><span className="tag">Recap</span> Your automatic test will run every&nbsp;
  								{this.state.automatedTest.repeat_period_delay < 2 &&
  									<>
  										{numToWordObj[this.state.automatedTest.repeat_period_delay]} {this.state.automatedTest.repeat_period.toLowerCase()}
  									</>
  								}

  								{this.state.automatedTest.repeat_period_delay > 1 &&
  									<>
  										{numToWordObj[this.state.automatedTest.repeat_period_delay]} {this.state.automatedTest.repeat_period.toLowerCase()}s
  									</>
  								}

  								{this.state.automatedTest.repeat_period == 'WEEK' &&
  									<>
  									 &nbsp;on&nbsp;

  									 {this.state.selectedDays.map((day, index) => {
  										 return (
  											 <>{dayWords[day]}{((this.state.selectedDays.length - 1) > index) ? ', ' : ''}</>
  										 )})}
  									</>
  								}

  								  &nbsp;at {hoursConfig.find(element => element.value == this.state.automatedTest.hour)['text']} ({this.state.automatedTest.time_zone})</p>
                  </div>
									</>
							 }

							<div className="clearfix"></div>
						</div>

          </div>

          <div className="text-center automated-spamtest-btns">
            <NavLink to={`/dashboard/${this.props.currentProject.id}/spam-tests/auto/${this.state.automatedTest.id}/target`} className="btn btn-primary grey">
              Go back
            </NavLink>
            <button className="btn btn-primary purple" onClick={this.saveRunAtAndRedirect} disabled={((!this.state.automatedTest.hour) || (this.state.automatedTest.repeat_period == 'WEEK' && this.state.selectedDays.length == 0) || (this.state.showWeekAlert) || (this.state.automatedTest.repeat_period == 'DAY' && this.state.automatedTest.repeat_period_delay < 2))}>Next ></button>
          </div>



        </div>

      </>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		currentProject: state.currentProject,
		currentPricing: state.currentPricing
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardSpamTestAutomatedFrequency));
