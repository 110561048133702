import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation} from 'react-router-dom';
import Loader from 'react-loader-spinner';

import './App.css';

import DsSuccess from './pages/dashboard/settings/3dsSuccess.js';
import Signup from './pages/signup/signup.js';
import Signin from './pages/signin/signin.js';
import Invite from './pages/invite/invite.js';
import ForgotPassword from './pages/password/password.js';
import ResetPassword from './pages/password/reset.js';
import DashboardBase from './pages/dashboard/base/base.js';
import AppRedirect from './pages/dashboard/redirect/redirect.js';
import DashboardAccountsConnect from './pages/dashboard/accounts/connect/connect.js';

const GaTrack = () => {
  const location = useLocation()
  React.useEffect(() => {
    window.gtag('event', 'page_view', {
      page_title: 'Mailreach Inner APP',
      page_location: window.location.pathname + window.location.search,
    })
  }, [location]);
  return null;
}


// currentTagInEditionl app loading
class LoadingApp extends React.Component {
  render() {
    return (
      <div className="app container text-center">
        <div className="loader">
          <Loader
           type="Oval"
           color="#b7b7b7"
           height="100"
           width="100"
          />
        </div>
      </div>
    )
  }
}

function App() {

  return (
    <Suspense fallback={<LoadingApp />}>
      <Router>
        <GaTrack />
        <Switch>
          <Route path="/dssuccess" component={DsSuccess}/>
          <Route path="/redirect" component={AppRedirect}/>
          <Route path="/connect" component={DashboardAccountsConnect}/>
          <Route path="/dashboard" component={DashboardBase}/>
          <Route path="/signup" component={Signup}/>
          <Route path="/signin" component={Signin}/>
          <Route path="/password" component={ForgotPassword}/>
          <Route path="/users/password/edit" component={ResetPassword}/>
          <Route path="/invite" component={Invite}/>
          <Redirect from="*" to="/signup"/>
        </Switch>
      </Router>
    </Suspense>
  );
}

export default App;
