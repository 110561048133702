const request = require('superagent');

class CopilotService {

  static getCampaignsHistory(accountId, pastDays) {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}copilot/${accountId}/campaigns?past_days=${pastDays}`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }
}

export default CopilotService;
