const request = require('superagent');

class RecipeService {

	static get(account_id) {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}recipes?account_id=${account_id}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}

	static enable(rcptn_id, account_id) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}recipes/${rcptn_id}/enable`)
						.send({account_id})
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}

	static enableAll() {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}recipes/enable_all`)
						.send()
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}

	static ignore(rcptn_id, account_id) {
    return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}recipes/${rcptn_id}/ignore`)
						.send({account_id})
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}
}

export default RecipeService;
