const request = require('superagent');

class AccountService {

  static all(search = null, index = '', page = 0, facetFilters = null, tags = null, max_score = null) {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}accounts?page=${page}${max_score ? '&max_score=' + max_score : ''}${(search && search['spamchecker']) ? '&spamchecker=true' : ''}${(search && search['spamchecker_only']) ? '&spamchecker_only=true' : ''}${(search && !search.spamchecker_only && !search.spamchecker) ? '&search=' + search : ''}${index ? '&index=' + index : ''}${facetFilters ? '&facetFilters=' + facetFilters : ''}${tags ? '&tags=' + tags.join(',') : ''}`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }

  static allSpamcheckerAccounts(search = null, index = '') {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}accounts?page=0&spamchecker_only=true${search ? '&search=' + search : ''}${index ? '&index=' + index : ''}`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }

  static get(id) {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}accounts/${id}`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }

  static aliases(id) {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}accounts/${id}/aliases`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }

  static stats(id, provider, pastDays = 15) {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}accounts/${id}/stats?past_days=${pastDays}${provider != 'All' ? '&provider=' + provider : ''}`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }

  static delete(id, disconnectReason) {
    return request
            .del(`${process.env['REACT_APP_BACKEND_URL']}accounts/${id}`)
            .send({disconnect_reason: disconnectReason})
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }

  static suspend(id, suspended) {
    return request
            .post(`${process.env['REACT_APP_BACKEND_URL']}accounts/${id}/suspend`)
            .send({suspended})
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }

  static reconnect(id) {
    return request
            .post(`${process.env['REACT_APP_BACKEND_URL']}accounts/${id}/reconnect`)
            .send({})
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }

  static update(id, account) {
    return request
            .post(`${process.env['REACT_APP_BACKEND_URL']}accounts/${id}`)
            .send(account)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }

  static checkupRefresh(id) {
    return request
            .post(`${process.env['REACT_APP_BACKEND_URL']}accounts/${id}/checkup`)
            .send({})
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }

  static batchUpdate(accountIds, tags, action) {
    return request
            .post(`${process.env['REACT_APP_BACKEND_URL']}accounts/batch`)
            .send({account_ids: accountIds, tags: tags, action: action})
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }
}

export default AccountService ;
