import projectService from '../services/project.service';
import * as types from './actionTypes';

function createSuccess() {
	return {type: types.PROJECT_CREATE_SUCCESS};
}

function getSuccess() {
	return {type: types.PROJECT_GET_SUCCESS};
}

function error(errors) {
	return {type: types.LOG_IN_ERROR, errors};
}

export function create(data) {
	return function (dispatch) {
		return projectService.create(data).then(response => {
			dispatch(createSuccess(response.body));
      return response;
		}).catch(error => {
			console.error(error);
			dispatch(error(['An error occured']))
      throw (error);
		});
	};
}

export function get(id) {
	return function (dispatch) {
    return projectService.get(id).then(response => {
			dispatch(getSuccess(response.body));
      return response;
		}).catch(err => {
			console.error(err);
			dispatch(error(['An error occured']))
      throw (err);
		});
	};
}
