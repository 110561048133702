const request = require('superagent');

class DomainBlacklistService {

  static get(id) {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}domain_blacklists/${id}`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }
}

export default DomainBlacklistService;
