import React, { Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import {BrowserRouter, Route, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {ToastContainer, toast} from 'react-toastify';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import Loader from 'react-loader-spinner'
import Tooltip from '@material-ui/core/Tooltip';
import queryString from 'query-string';

import AccountService from '../../../../services/account.service';
import ConversationStatusService from '../../../../services/conversationStatus.service';
import ConversationMessageLogsService from '../../../../services/conversationMessageLog.service';
import InappMessageService from '../../../../services/inappMessage.service';
import NotificationService from '../../../../services/notification.service';

import RecipeService from '../../../../services/recipe.service';

import InappMessage from '../../../../components/inapp-message/inapp-message.js';
import Notifications from '../../../../components/notifications/notifications.js';

import { CHARTSJS_TOOLTIPS_STYLE } from "../../../../constants";

import i18n from "../../../../i18n";
import './show.scss';

import * as projectsActions from '../../../../actions/projects.actions';
import * as notificationsActions from '../../../../actions/notifications.actions';

class DashboardAccountsShow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {errors: [], currentAccount: {}, timezones: [], accountStats: {}, notifications: [], accountStatsLoaded: false, signatureSpamwords:[], statsPastDays: 14, loading: true, providerFilter: 'All', inboxRate: 0, usageData: {
	  labels: [],
	  datasets: [
	    {
	      label: 'Conversations started',
	      fill: false,
	      backgroundColor: 'rgb(61 70 251)',
	      borderColor: 'rgb(61 70 251)',
	      borderCapStyle: 'butt',
	      borderDash: [],
	      borderDashOffset: 0.0,
	      borderJoinStyle: 'miter',
	      pointBorderColor: 'rgb(61 70 251)',
	      pointBackgroundColor: '#fff',
	      pointBorderWidth: 1,
	      pointHoverRadius: 5,
	      pointHoverBackgroundColor: 'rgb(61 70 251)',
	      pointHoverBorderColor: 'rgb(61 70 251)',
	      pointHoverBorderWidth: 2,
	      pointRadius: 1,
	      pointHitRadius: 10,
	      data: []
	    },
			{
				label: 'Emails sent',
				fill: false,
				backgroundColor: '#41d9cc',
				borderColor: '#41d9cc',
				borderCapStyle: 'butt',
				borderDash: [],
				borderDashOffset: 0.0,
				borderJoinStyle: 'miter',
				pointBorderColor: '#41d9cc',
				pointBackgroundColor: '#fff',
				pointBorderWidth: 1,
				pointHoverRadius: 5,
				pointHoverBackgroundColor: '#41d9cc',
				pointHoverBorderColor: '#41d9cc',
				pointHoverBorderWidth: 2,
				pointRadius: 1,
				pointHitRadius: 10,
				data: []
			},
			{
				label: 'Emails received',
				fill: false,
				backgroundColor: '#fa577c',
				borderColor: '#fa577c',
				borderCapStyle: 'butt',
				borderDash: [],
				borderDashOffset: 0.0,
				borderJoinStyle: 'miter',
				pointBorderColor: '#fa577c',
				pointBackgroundColor: '#fff',
				pointBorderWidth: 1,
				pointHoverRadius: 5,
				pointHoverBackgroundColor: '#fa577c',
				pointHoverBorderColor: '#fa577c',
				pointHoverBorderWidth: 2,
				pointRadius: 1,
				pointHitRadius: 10,
				data: []
			}
	  ]
	}, foldersMsg: {
		labels: [],
datasets: [{
	label: 'Inbox',
	backgroundColor: '#41d9cc',
	hoverBackgroundColor: '#41d9cc',
	data: []
}, {
	label: 'Spam',
	backgroundColor: '#E33B1C',
	hoverBackgroundColor: '#E33B1C',
	data: []
}, {
	label: 'Categories',
	backgroundColor: '#ffd32b',
	hoverBackgroundColor: '#ffd32b',
	data: []
}, {
	label: 'Missing',
	backgroundColor: '#ccc',
	hoverBackgroundColor: '#ccc',
	data: []
}]

	}};
		this.calculeInboxRate = this.calculeInboxRate.bind(this);
		this.generatePieData = this.generatePieData.bind(this);
		this.handleCloseReputationModal = this.handleCloseReputationModal.bind(this);
		this.loadStats = this.loadStats.bind(this);
		this.loadScoreDesc = this.loadScoreDesc.bind(this);
		this.closeNotification = this.closeNotification.bind(this);
		this.loadRecipe = this.loadRecipe.bind(this);
		this.enableRecipe = this.enableRecipe.bind(this);
		this.ignoreRecipe = this.ignoreRecipe.bind(this);
		this.openRecipeModal = this.openRecipeModal.bind(this);
	}

	componentWillMount() {
		this.loadStats('All');
		let queryParams = queryString.parse(this.props.location.search);
		if(queryParams.recipe){
			this.loadRecipe();
		}
	}

	setStateDays(statsPastDays){
		// TODO : manage provider
		this.setState({statsPastDays});
		this.loadStats('All');
	}

	openRecipeModal(){
		this.loadRecipe();
		this.setState({showWarmupRecipeModal: true});
	}

	loadRecipe(){
		RecipeService.get(this.props.match.params.account_id).then((response) => {
			this.setState({showWarmupRecipeModal: true, currentRecipe: response.body});
		}).catch((error) => {
			this.setState({error: true,});
			toast.error('Oops, we have an error');
		});
	}

	enableRecipe(){
		RecipeService.enable(this.state.currentRecipe.notification.id, this.props.match.params.account_id).then((response) => {
			NotificationService.account(this.props.match.params.account_id).then((response) => {
				this.setState({notifications: response.body, showWarmupRecipeModal: false, currentRecipe: null});
			}).catch((error) => {
				this.setState({error: true,});
				toast.error('Oops, we have an error');
			});
			toast.success('New warming mode enabled 🙌');
		}).catch((error) => {
			this.setState({error: true,});
			toast.error('Oops, we have an error');
		});
	}

	ignoreRecipe(){
		RecipeService.ignore(this.state.currentRecipe.notification.id, this.props.match.params.account_id).then((response) => {
			NotificationService.account(this.props.match.params.account_id).then((response) => {
				this.setState({notifications: response.body, showWarmupRecipeModal: false, currentRecipe: null});
			}).catch((error) => {
				this.setState({error: true,});
				toast.error('Oops, we have an error');
			});
		}).catch((error) => {
			this.setState({error: true,});
			toast.error('Oops, we have an error');
		});
	}

	closeNotification(id){
		this.props.notificationsActions.closeOne(id);
		NotificationService.close(id).then((response) => {
			NotificationService.account(this.props.match.params.account_id).then((response) => {
				this.setState({notifications: response.body});
			}).catch((error) => {
				this.setState({error: true,});
				toast.error('Oops, we have an error');
			});
			this.props.notificationsActions.getAll();
		})
		.catch((error) => {
		});
	}


	loadStats(provider = 'All'){
		this.setState({providerFilter: provider});


	NotificationService.account(this.props.match.params.account_id).then((response) => {
		this.setState({notifications: response.body});
	}).catch((error) => {
		this.setState({error: true,});
		toast.error('Oops, we have an error');
	});

		AccountService.get(this.props.match.params.account_id).then(accountResponse =>
			Promise
        .all([
          ConversationStatusService.getGraph(accountResponse.body.id, provider, this.state.statsPastDays),
          ConversationMessageLogsService.getSentGraph(accountResponse.body.id, provider, this.state.statsPastDays),
          ConversationMessageLogsService.getRecievedGraph(accountResponse.body.id, provider, this.state.statsPastDays),
					ConversationMessageLogsService.getSentsByCategorie(accountResponse.body.id, 'INBOX', provider, this.state.statsPastDays),
					ConversationMessageLogsService.getSentsByCategorie(accountResponse.body.id, 'SPAM', provider, this.state.statsPastDays),
					ConversationMessageLogsService.getSentsByCategorie(accountResponse.body.id, 'CATEGORIES', provider, this.state.statsPastDays),
          Promise.resolve(accountResponse),
        ]))
	      .then(([conversationsResponse, sentsResponse, receivedResponse, inboxResponse, spamResponse, categoriesResponse, accountResponse]) => {
	        this.setState({
	          currentAccount: accountResponse.body,
	          loading: false,
	        });
					let usageData = this.state.usageData;
					usageData.labels = conversationsResponse.body.labels;
					usageData.datasets[0].data = conversationsResponse.body.data;
					usageData.datasets[1].data = sentsResponse.body.data;
					usageData.datasets[2].data = receivedResponse.body.data;
					this.setState({usageData});

					let foldersMsg = this.state.foldersMsg;
					foldersMsg.labels = sentsResponse.body.labels;
					// foldersMsg.datasets[0].data = sentsResponse.body.data;
					foldersMsg.datasets[0].data = inboxResponse.body.data;
					foldersMsg.datasets[1].data = spamResponse.body.data;
					foldersMsg.datasets[2].data = categoriesResponse.body.data;
					//foldersMsg.datasets[3].data = othersResponse.body.data;
					this.setState({foldersMsg});

					this.calculeInboxRate();
					this.generatePieData(inboxResponse.body.data, categoriesResponse.body.data, spamResponse.body.data);

					AccountService.stats(accountResponse.body.id, provider, this.state.statsPastDays).then((accountStatsResponse) => {
						ConversationMessageLogsService.getSentsByCategorie(this.props.match.params.account_id, 'OTHERS', provider, this.state.statsPastDays).then((othersResponse) => {
							let foldersMsg = this.state.foldersMsg;
							foldersMsg.datasets[3].data = othersResponse.body.data;
							this.setState({foldersMsg});
							this.calculeInboxRate();
						})

						this.setState({accountStats: accountStatsResponse.body, accountStatsLoaded: true})
					}).catch((error) => {
						this.setState({error: true,});
						toast.error('Oops, we have an error');
					});
	      }).catch((error) => {
					this.setState({error: true,});
					toast.error('Oops, we have an error');
				});
	}

	calculeInboxRate(){
		let inboxGrade = 'great';
		let inboxRate = this.state.currentAccount.score;

		if(inboxRate <= 59){
			inboxGrade = 'bad';
		}else if (inboxRate >= 60 && inboxRate < 90) {
			inboxGrade = 'average';
		}else if (inboxRate >= 90 && inboxRate < 101) {
			inboxGrade = 'great';
		}
		this.setState({inboxRate, inboxGrade});

		if(inboxGrade){
		  this.loadScoreDesc(inboxGrade);
		}
	}

	generatePieData(inbox, categories, spams){
		let totalInbox = inbox.reduce((a, b) => a + b, 0);
		let totalCategories = categories.reduce((a, b) => a + b, 0);
		let totalSpams = spams.reduce((a, b) => a + b, 0);
		let pieData =  {datasets: [{
        data: [totalInbox, totalCategories, totalSpams],
				backgroundColor: ['#41d9cc', '#ffd32b', '#E33B1C']
    }],
    labels: [
        'Landed in inbox',
        'Landed in categories',
        'Landed in spam'
    ]};
		this.setState({pieData});
	}

	pieStyle(){
		return {
			tooltips: this.graphStyle()['tooltips'],
			legend: {
				position: 'bottom'
			}
		}
	}

	handleCloseReputationModal(){
		this.setState({showReputationModal: false});
	}

	barStyle(){
		let config = this.graphStyle();
		config['scales']['xAxes'][0]['stacked'] = true;
		config['scales']['yAxes'][0]['stacked'] = true;
		return config;
	}

	graphStyle(){
		return {
			layout: {
        padding: {
            left: 0,
            right: 0,
            top: 10,
            bottom: 0
        }
    },
			legend: {
				display: true,
				labels: {
					usePointStyle: true
				}
			},
			scales: {
		    xAxes: [{
		      gridLines: {
						display:false
					},
		    }],
				yAxes: [{
					gridLines: {
							display:true
					},
             ticks: {
                precision:0,
								min: 0,
             }
         },
			 ],
		  },
			tooltips: CHARTSJS_TOOLTIPS_STYLE
		}
	}


	loadScoreDesc(inboxGrade){

		if(inboxGrade == 'damaged'){
			inboxGrade = 'average';
		}

		let key = 'account_inboxgrade_bad';

		if(inboxGrade == 'bad'){
			key = 'account_inboxgrade_bad';
		}else if (inboxGrade == 'average'){
			key = 'account_inboxgrade_average';
		}else if (inboxGrade == 'good'){
			key = 'account_inboxgrade_good';
		}else if (inboxGrade == 'great'){
			key = 'account_inboxgrade_great';
		}

		InappMessageService.get(key).then((response) => {
			if(response.body){
				this.setState({accountInboxgradeMessage: response.body.content});
			}
		})
		.catch((error) => {
			toast.error('Oops, we have an issue, try again qsqsdqsdqsd');
		});
	}

	render() {
		if(this.state.loading){
			return(
				<div className="app container text-center">
					<div className="loader">
						<Loader
						 type="Oval"
						 color="#b7b7b7"
						 height="100"
						 width="100"
						/>
					</div>
				</div>
			)
		}
		return (
			<div className="app container account-page">
				<div className="notifications-full">
					<Notifications closeNotification={this.closeNotification} showWarmupRecipeModal={this.openRecipeModal} summary={{}} showAccount={true} notifications={this.state.notifications} currentProject={this.props.currentProject}/>
				</div>
				<div className="row">
					<h2>Warming activity</h2>

					<Dropdown className="dropdown-date-filter pull-right">
				  <Dropdown.Toggle className="btn" id="dropdown-basic">
				    Provider : {this.state.providerFilter} <i class="fas fa-angle-down" aria-hidden="true"></i>
				  </Dropdown.Toggle>

				  <Dropdown.Menu className="dropdown-providers">
						<Dropdown.Item onClick={() => this.loadStats('All')}>All</Dropdown.Item>
				    <Dropdown.Item onClick={() => this.loadStats('Gmail')}>Gmail / Google Workspace</Dropdown.Item>
				    <Dropdown.Item onClick={() => this.loadStats('Outlook')}>Outlook / Office 365</Dropdown.Item>
						<Dropdown.Item onClick={() => this.loadStats('Others')}>Others</Dropdown.Item>
				  </Dropdown.Menu>
				</Dropdown>

				<Dropdown className="dropdown-date-filter pull-right last-days-filter">
					<Dropdown.Toggle className="btn" id="dropdown-basic">
						Last {this.state.statsPastDays} days <i class="fas fa-angle-down" aria-hidden="true"></i>
					</Dropdown.Toggle>

					<Dropdown.Menu>
						<Dropdown.Item onClick={() => this.setStateDays(14)}>Last 14 days</Dropdown.Item>
						<Dropdown.Item onClick={() => this.setStateDays(30)}>Last 30 days</Dropdown.Item>
						<Dropdown.Item onClick={() => this.setStateDays(90)}>Last 3 months</Dropdown.Item>
						<Dropdown.Item onClick={() => this.setStateDays(180)}>Last 6 months</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>

				<div className="clearfix"></div>

					<div className="col-md-12 no-padding">
						<div className="nbstats">
							<div className={"col-md-3 " + (this.state.accountStatsLoaded ? '' : 'is-loading')}>
								<div className="bl">
									<Tooltip title='These are the warming emails sent by MailReach from your mailbox, not your campaign emails' placement="bottom">
										<div className="txt">
										  <i class="far fa-paper-plane"></i>
										  <span>Total emails sent</span>
										</div>
									</Tooltip>
									<span className="nb">{this.state.accountStats.total_messages_sent}</span>
								</div>
								<div className="clearfix"></div>
							</div>
							<div className={"col-md-3 " + (this.state.accountStatsLoaded ? '' : 'is-loading')}>
								<div className="text-center bl">
									<Tooltip title='These are the warming emails sent by others to your mailbox, replies and direct emails' placement="bottom">
										<div className="txt">
										  <i class="fas fa-inbox"></i>
											<span>Total emails received</span>
										</div>
									</Tooltip>
									<span className="nb">{this.state.accountStats.total_messages_received}</span>
								</div>
								<div className="clearfix"></div>
							</div>
							<div className={"col-md-3 " + (this.state.accountStatsLoaded ? '' : 'is-loading')}>
								<div className="text-center bl">
									<Tooltip title='These are the emails saved from spam by MailReach' placement="bottom">
										<div className="txt">
										  <i class="fas fa-capsules"></i>
										  <span>Removed from spam & tabs</span>
										</div>
									</Tooltip>
									<span className="nb">{this.state.accountStats.total_spam}</span>
								</div>
								<div className="clearfix"></div>
							</div>
							<div className={"col-md-3 last " + (this.state.accountStatsLoaded ? '' : 'is-loading')}>
								<div className="text-center bl">
									<div className="txt">
										<i class="far fa-comments"></i>
										<span>Conversations running</span>
									</div>
									<span className="nb">{this.state.accountStats.config_current_conversation_running}</span>
								</div>
								<div className="clearfix"></div>
							</div>
							<div className="clearfix"></div>
						</div>
					</div>
					<div className="col-md-6 no-padding-left">
						<div className="card">
							<div className="title">Where your warming emails land</div>
							<Bar key={this.state.providerFilter} data={this.state.foldersMsg} options={this.barStyle()}/>
						</div>
					</div>
					<div className="col-md-6 no-padding-right">
						<div className="card">
							<div className="title">Your warming activity</div>
							<Line key={this.state.providerFilter} data={this.state.usageData} options={this.graphStyle()}/>
						</div>
					</div>
							<div className="col-md-6 no-padding-left score-blck">
								<div className="card">
									<div className="title">
									    Your reputation score
										  {this.state.currentAccount.score > 0  &&
									      <button className="pull-right btn btn-secondary" onClick={() => this.setState({showReputationModal: this.state.inboxGrade})}>Tips</button>
                      }
									</div>
									{this.state.currentAccount.score > 0  &&
										<>
											<div className="inbox-rate-nb">{this.state.currentAccount.score}</div>
											<div className="rate-desc">
												<div className="col-md-2">
													<div className={'badge ' + this.state.inboxGrade}>
														{(this.state.inboxGrade == 'average') ? 'Damaged': this.state.inboxGrade}
													</div>
												</div>
												<div className="col-md-10">
													<div dangerouslySetInnerHTML={{__html: this.state.accountInboxgradeMessage}}>
							          	</div>
												</div>
												<div className="clearfix"></div>
											</div>
										</>
									}
									{this.state.currentAccount.score < 1  &&
										<>
											<div className="inbox-rate-nb score-calculating"><span>Waiting for data</span></div>
											<div className="rate-desc">
												<div className="col-md-12">
												 Your score will appear 72 hours after your email address is connected. MailReach needs to receive more data 📡
												</div>
												<div className="clearfix"></div>
											</div>
										</>
									}
								</div>
							</div>
							<div className="col-md-6 no-padding-right">
								<div className="card">
									<div className="title">Where your warming emails have landed</div>
									<div className="pie">
										<Pie data={this.state.pieData} options={this.pieStyle()}/>
									</div>
								</div>
							</div>
					</div>
					<Modal className="reputation-modal settings-modal recpt" show={this.state.showWarmupRecipeModal} onHide={() => this.setState({showWarmupRecipeModal: false})}>
						<Modal.Body>
							<>
								<h1 className="text-center">
									Better warming mode identified 🧪
									<>
										<Tooltip title='This feature is currently in beta. If something looks wrong, please give us your feedback using the chat :)' placement="right">
											<div className="beta">
												Beta <i class="fa-solid fa-info-circle"></i>
											</div>
										</Tooltip>
									</>
								</h1>
								<div className="txt text-center">

									<div className="text-center">
										<p className="head-p">As the reputation of this email account is doing bad, MailReach Copilot recommends switching to a more efficient mode. We also advise you to read and apply our guidelines. That’s game-changing.</p>

										<br />
										<div className="clearfix"></div>


											<div className="receipt text-left">

												<div className="text-center opener" onClick={() => this.setState({showRecipeInfos: !this.state.showRecipeInfos})}>We recommend the Recovery mode <i class="fa-solid fa-angle-down"></i></div>

												{this.state.showRecipeInfos &&
													<div className="recipe-infos">
														<p>⚡ Increase per day : from {this.state.currentAccount.config_rampup_increase} conversation(s) currently to
														<Tooltip title='(9 to 12 additional warming emails per day)' placement="right">
															<span className="updated-value">{this.state.currentRecipe.recipe.config_rampup_increase} conversations</span>
														</Tooltip>
														</p>

														<p>🎯 Maximum per day : from {this.state.currentAccount.config_rampup_target}  conversations currently to
														<Tooltip title='(77 to 105 maximum warming emails per day)' placement="right">
															<span className="updated-value">{this.state.currentRecipe.recipe.config_rampup_target} conversations</span>
														</Tooltip>

														</p>
													</div>
													}
											</div>

										<div className="red-alert yellow">
											<i class="fa-solid fa-circle-info"></i>
											<p>To restore your reputation and deliverability ASAP, make sure to follow our guidelines: <a href="https://help.mailreach.co/en/article/how-to-restore-a-damaged-email-reputation-and-deliverability-using-mailreach-3egzf6/" target="_blank">How to Restore a Bad Sender Reputation & Fix a Low Score with MailReach</a>.</p>
											<div className="clearfix"></div>
										</div>
									</div>
								</div>
								<div className="footer text-center">
									<Button variant="secondary btn-primary" onClick={() => this.ignoreRecipe()}>
										Ignore
									</Button>
									<Button variant="btn purple btn btn-primary" onClick={() => this.enableRecipe()}>
										Apply
									</Button>
								</div>
							</>
						</Modal.Body>
					</Modal>
				<InappMessage keyname={'accounts_show_reputation_modal_' + this.state.inboxGrade} show={this.state.showReputationModal} handleCloseModal={this.handleCloseReputationModal} />
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		currentProject: state.currentProject
	};
}

function mapDispatchToProps(dispatch) {
	return {
		projectsActions: bindActionCreators(projectsActions, dispatch),
		notificationsActions: bindActionCreators(notificationsActions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardAccountsShow));
