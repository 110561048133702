import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import {toast} from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import {loadStripe} from '@stripe/stripe-js';
import InjectedCheckoutForm from './checkoutForm';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {Elements} from '@stripe/react-stripe-js';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import Slider, { SliderThumb } from '@mui/material/Slider';

import { styled } from '@mui/material/styles';

import i18n from "../../../i18n";

import ProjectService from '../../../services/project.service';

import StripeService from '../../../services/stripe.service';

import './settings.scss';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const MailreachSlider = styled(Slider)({
  height: 8
});


const PurpleSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#11103b',
    },
    '&$checked + $track': {
      backgroundColor: '#11103b',
    },
  },
  checked: {},
  track: {
      backgroundColor: '#11103b',
  },
})(Switch);

class DashboardSettingsBillingSpamchecker extends React.Component {
	constructor(props) {
		super(props);
		this.state = {errors: [], invoices: [], selectedPlan: 'growth', pricingPlan: 12, totalMonthlyPricing: 12, totalMonthlyTests: 150, sliderPricing: 46, spamCheckerPlan: 4, monthly: false}
		this.signout = this.signout.bind(this);
    this.setPricingPlan = this.setPricingPlan.bind(this);
    this.cancel = this.cancel.bind(this);
    this.selectDisconnectReason = this.selectDisconnectReason.bind(this);
    this.billingPeriodChanges = this.billingPeriodChanges.bind(this);
	}

	componentWillMount() {
		window.gtag("event", "billing", {
            event_category: "access",
            event_label: "billing_page_spamchecker"
        });
    ProjectService.get(this.props.match.params.project_id).then((response) => {
      this.setState({project: response.body});
      localStorage.setItem('x-api-key', response.body.api_key);
    })
    .catch((error) => {
      this.setState({error: true,});
      toast.error('Oops, we have an error');
    });

		StripeService.subscriptions().then((response) => {
			this.setState({subscriptions: response.body.data})
		})
		.catch((error) => {
			this.setState({error: true,});
			toast.error('Oops, we have an error');
		});

		let queryParams = queryString.parse(this.props.location.search);
		let hideSignout = queryParams.hide_signout;
		this.setState({hideSignout});
	}

	componentWillReceiveProps(nextProps) {
	}

	signout(){
		localStorage.clear();
		//this.props.history.push(`/redirect`);
	}

	submitVat(e){
		e.preventDefault();
		StripeService.setVat(this.state.vat_id).then((response) => {
			toast.success('VAT number saved !');
		})
		.catch((error) => {
			this.setState({error: true,});
			toast.error('Invalid VAT number');
		});
	}

  setPricingPlan(value) {

    let pricingConfig = {
      1: {
        totalMonthlyPricing: 12,
        totalSubPricing: 9.6,
        totalMonthlyTests: 25
      },
      2: {
        totalMonthlyPricing: 23,
        totalSubPricing: 18.4,
        totalMonthlyTests: 50
      },
      3: {
        totalMonthlyPricing: 35,
        totalSubPricing: 28,
        totalMonthlyTests: 100
      },
      4: {
        totalMonthlyPricing: 46,
        totalSubPricing: 36.8,
        totalMonthlyTests: 150
      },
      5: {
        totalMonthlyPricing: 54,
        totalSubPricing: 43.2,
        totalMonthlyTests: 200
      },
      6: {
        totalMonthlyPricing: 68,
        totalSubPricing: 54.4,
        totalMonthlyTests: 300
      },
      7: {
        totalMonthlyPricing: 92,
        totalSubPricing: 73.6,
        totalMonthlyTests: 500
      },
      8: {
        totalMonthlyPricing: 135,
        totalSubPricing: 108,
        totalMonthlyTests: 1000
      },
      9: {
        totalMonthlyPricing: 180,
        totalSubPricing: 144,
        totalMonthlyTests: 1500
      },
      10: {
        totalMonthlyPricing: 200,
        totalSubPricing: 160,
        totalMonthlyTests: 2000
      }
    };

    let totalMonthlyPricing = pricingConfig[parseInt(value.target.value)].totalMonthlyPricing;

    if(this.state.monthly){
      totalMonthlyPricing = pricingConfig[parseInt(value.target.value)].totalSubPricing;
    }

    let totalMonthlyTests = pricingConfig[parseInt(value.target.value)].totalMonthlyTests;

    this.setState({totalMonthlyPricing, totalMonthlyTests, sliderPricing: totalMonthlyPricing, spamCheckerPlan: parseInt(value.target.value)});

  }

  valuetext(value) {
    return `${value}°C`;
  }

  thumbComponent(props) {
    const { children, ...other } = props;
    return (
      <SliderThumb {...other}>
        {children}
        <i class="fas fa-angle-left"></i>
        <i class="fas fa-angle-right"></i>
      </SliderThumb>
    );
  }

  getButtonClass(toCompare) {
    if(this.props.currentProject.config_spamchecker_plan > toCompare){
      return 'downgrade'
    }

    if(this.props.currentProject.config_spamchecker_plan < toCompare){
      return 'green'
    }

    if(this.props.currentProject.config_spamchecker_plan == toCompare){
      return 'current'
    }

    if(!this.props.currentProject.config_spamchecker_plan){
      return 'green'
    }
  }

  billingPeriodChanges(){

    this.setState({monthly: !this.state.monthly},
        function() {
          this.setPricingPlan({target: {value: 4}});
        }
    );
  }


  cancel(){

    StripeService.cancel(this.state.disconnectReason).then((response) => {
			this.setState({showCancelModal: false});
      toast.success('Subscription cancelled');
      // reload project
      c.props.history.push(`/dashboard/${c.props.projectId}/spam-tests/home`);
		})
		.catch((error) => {
			this.setState({error: true,});
			toast.error('Oops, we have an error');
		});
  }

  selectDisconnectReason(e){
    e.preventDefault();
    this.setState({disconnectReason: e.target.value});
  }


	render() {
		return (
			<div className="app container add-credit-card">


        <div className="col-md-12">
    				<div className="row">
    					<h1>Choose your pack</h1>

              <div className="select-billing-period text-center">
                {this.props.stripe_subscription_period != 'yearly' &&
                <>
                  <span className={'pull-left ' + (!this.state.monthly ? 'selected' : '')} onClick={this.billingPeriodChanges}>Pay as you go</span>
                  <FormGroup>
                      <FormControlLabel control={<PurpleSwitch
                      checked={this.state.monthly}
                      onChange={this.billingPeriodChanges}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />} label="" />
                  </FormGroup>
                    <span className={'pull-left ' + (this.state.monthly ? 'selected' : '')} onClick={this.billingPeriodChanges}>Monthly</span>
                    </>
                  }
                  <div className="clearfix"></div>
              </div>
              <div className="clearfix"></div>

              <div className="card">
                <div className="plans">
                  <div className={"plan col-md-4" + (this.state.selectedPlan == 'basic' ? ' selected' : '')} onClick={() => { this.setState({selectedPlan: 'basic', spamCheckerPlan: 1}) }}>
                    <h2>Basic</h2>
                    <div className="pricing clcsq">
                      {!this.state.monthly &&
                        <span>$12</span>
                      }
                      {this.state.monthly &&
                        <span>$9.6</span>
                      }
                    </div>
                    <ul className="features">
                      <li><i class="fas fa-check-circle"></i> <b>25</b> spam tests credits</li>
                      <li><i class="fas fa-check-circle"></i> Reliable spam score</li>
                      <li><i class="fas fa-check-circle"></i> Inbox placement by provider</li>
                      <li><i class="fas fa-check-circle"></i> DKIM, SPF & DMARC checker</li>
                      <li><i class="fas fa-check-circle"></i> Blacklists checker</li>
                      <li><i class="fas fa-check-circle"></i> Links checker</li>
                      <li><i class="fas fa-check-circle"></i> HTML checker</li>
                      <li><i class="fas fa-check-circle"></i> Spam words checker</li>
                      <li><i class="fas fa-check-circle"></i> Image / text ratio</li>
                    </ul>
                    <div className="text-center selectplan">
                      <NavLink to={`/dashboard/${this.props.currentProject.id}/settings/billing?spamCheckerPlan=1&monthly=${this.state.monthly}`} className={"btn purple-btn " + this.getButtonClass(1)} activeClassName="active">
                        Select
                      </NavLink>
                    </div>
                  </div>
                  <div className={"plan col-md-4 popular" + (this.state.selectedPlan == 'growth' ? ' selected' : '')} onClick={() => { this.setState({selectedPlan: 'growth', spamCheckerPlan: 3}) }}>
                    <h2>Growth</h2>
                    <div className="pricing clcsq">
                      {!this.state.monthly &&
                        <span>$35</span>
                      }
                      {this.state.monthly &&
                        <span>$28</span>
                      }
                    </div>
                    <ul className="features">
                      <li><i class="fas fa-check-circle"></i> <b>100</b> spam tests credits</li>
                      <li><i class="fas fa-check-circle"></i> Reliable spam score</li>
                      <li><i class="fas fa-check-circle"></i> Inbox placement by provider</li>
                      <li><i class="fas fa-check-circle"></i> DKIM, SPF & DMARC checker</li>
                      <li><i class="fas fa-check-circle"></i> Blacklists checker</li>
                      <li><i class="fas fa-check-circle"></i> Links checker</li>
                      <li><i class="fas fa-check-circle"></i> HTML checker</li>
                      <li><i class="fas fa-check-circle"></i> Spam words checker</li>
                      <li><i class="fas fa-check-circle"></i> Image / text ratio</li>
                    </ul>
                    <div className="text-center selectplan">
                      <NavLink to={`/dashboard/${this.props.currentProject.id}/settings/billing?spamCheckerPlan=3&monthly=${this.state.monthly}`} className={"btn purple-btn " + this.getButtonClass(3)} activeClassName="active">
                        Select
                      </NavLink>
                    </div>
                  </div>
                  <div className={"plan col-md-4"} onClick={() => { this.setState({selectedPlan: 'custom',  showSlider: true}) }}>
                    <h2>Custom</h2>
                    <div className="pricing">
                      <span>${this.state.sliderPricing}</span>
                    </div>
                      <div className="range-slider col-md-12">
                          <MailreachSlider
                          components={{ Thumb: this.thumbComponent }}
                          defaultValue={1}
                          onChange={this.setPricingPlan}
                          step={1}
                          marks
                          min={4}
                          max={10}
                          color="secondary"
                          />
                      </div>
                    <ul className="features">
                      <li><i class="fas fa-check-circle"></i> <b>{this.state.totalMonthlyTests}</b> spam tests credits</li>
                      <li><i class="fas fa-check-circle"></i> Reliable spam score</li>
                      <li><i class="fas fa-check-circle"></i> Inbox placement by provider</li>
                      <li><i class="fas fa-check-circle"></i> DKIM, SPF & DMARC checker</li>
                      <li><i class="fas fa-check-circle"></i> Blacklists checker</li>
                      <li><i class="fas fa-check-circle"></i> Links checker</li>
                      <li><i class="fas fa-check-circle"></i> HTML checker</li>
                      <li><i class="fas fa-check-circle"></i> Spam words checker</li>
                      <li><i class="fas fa-check-circle"></i> Image / text ratio</li>
                    </ul>
                    <div className="text-center selectplan">
                      <NavLink to={`/dashboard/${this.props.currentProject.id}/settings/billing?spamCheckerPlan=${this.state.spamCheckerPlan}&monthly=${this.state.monthly}`} className={"btn purple-btn " + this.getButtonClass(this.state.spamCheckerPlan)} activeClassName="active">
                        Select
                      </NavLink>
                    </div>
                  </div>
                </div>

                {this.props.currentProject.config_spamchecker_plan &&
                  <div className="col-md-12 text-center cancel-btn">
                    <button className="btn" onClick={() => this.setState({showCancelModal: true})}>Cancel</button>
                  </div>
                }

                <div className="clearfix"></div>
              </div>
					  </div>
        </div>

        <Modal className="reputation-modal" show={this.state.showCancelModal} onHide={() => this.setState({showCancelModal: false})}>
          <Modal.Body>
              <>
                <h1 className="text-center">Can you give us more info ?</h1>
                <div className="txt text-center">
                  <h4>
                    As we're putting a lot of energy to improve MailReach, could you please give us more info about the reason you want to stop warming your inbox ?
                  </h4><br /><br />
                  <form>
                  <select onChange={this.selectDisconnectReason} value={this.state.disconnectReason}>
                    <option value="">--Please choose a reason--</option>
                    <option value="think_inbox_warmed_up">I think my inbox has been warmed up enough</option>
                    <option value="i_will_come_back_later">I'm done for now but I will come back later for sure</option>
                    <option value="any_improvement">I didn't notice any improvement on the deliverability of this inbox</option>
                    <option value="other_warmup_solution">I'm leaving for another warming solution</option>
                    <option value="no_reason">I just don't want to give any reason</option>
                    <option value="other">Other</option>
                  </select>
                  </form>
                  <br />
                </div>
                <div className="footer text-center del">
                  <Button variant="secondary" onClick={() => this.setState({showCancelModal: false})}>
                    Cancel
                  </Button>
                  <Button className="btn purple red" onClick={this.cancel} disabled={!this.state.disconnectReason}>
                    Disconnect my inbox
                  </Button>
                </div>
              </>
          </Modal.Body>
        </Modal>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		currentUser: state.currentUser,
		currentProject: state.currentProject,
		currentPricing: state.currentPricing
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardSettingsBillingSpamchecker));
