const request = require('superagent');

class ProjectService {

	static create(data) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}projects/`)
            .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
						.send(data);
	}

	static close_onboarding(data) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}projects/${data.id}`)
						.set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
						.send({spamchecker_onboarding_closed: true});
	}

  static get(id) {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}projects/${id}`)
            .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`);
  }

	static toggleCopilot(config_copilot_default_value) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}projects/copilot`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.send({config_copilot_default_value});
  }

	static by_invite_token(token) {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}projects/invite?invite_token=${token}`)
						.set('Authorization', `Bearer ${localStorage.getItem('jwt')}`);
	}

  static all(data) {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}projects`)
            .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`);
  }

	static domains(order = 'asc') {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}projects/domains?order=${order}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}
}

export default ProjectService;
