import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast} from 'react-toastify';
import {Controlled as CodeMirror} from 'react-codemirror2'
import Tooltip from '@material-ui/core/Tooltip';
import Loader from 'react-loader-spinner';
import queryString from 'query-string';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';


import * as projectsActions from '../../../actions/projects.actions';
import * as currentProjectActions from '../../../actions/currentProject.actions';

import ProjectService from '../../../services/project.service';
import AccountService from '../../../services/account.service';
import NotificationService from '../../../services/notification.service';
import TagService from '../../../services/tag.service';

import Notifications from '../../../components/notifications/notifications.js';

import ConnectBtn from '../../../components/connectBtn/connectBtn.js';

import './cockpit.scss';


class CockpitHome extends React.Component {
	constructor(props) {
		super(props);
		this.state = {errors: [], loading: true, tags: [], providerTags: [], domains: [], domainsScoreChanges: [], badAccounts: [], filters: {}, notifications: [], domainOrder: 'asc', domainScoreChangeOrder: 'asc', accountsScoreChangesOrder: 'desc', tagsScoreChangesOrder: 'desc', tagsScoreOrder: 'desc', notificationsSummary: {details: {}}};
		this.loadStats = this.loadStats.bind(this);
		this.majDomainsScoreChangeOrder = this.majDomainsScoreChangeOrder.bind(this);
		this.majAccountsScoreChangeOrder = this.majAccountsScoreChangeOrder.bind(this);
		this.majTagsScoreChangeOrder = this.majTagsScoreChangeOrder.bind(this);
		this.majTagsScoreOrder = this.majTagsScoreOrder.bind(this);
	}

	componentWillMount() {

		this.loadStats('All');

		NotificationService.summary().then((response) => {
			this.setState({notificationsSummary: response.body})
		})
		.catch((error) => {
		});

		AccountService.all(null, null, 0, null, null, 84).then((response) => {
			this.setState({badAccounts: response.body});
			this.majAccountsScoreChangeOrder();
		})
		.catch((error) => {
		});

		TagService.all().then((response) => {
			this.setState({tags: response.body.filter(tag => tag.avg_score > 0.0)});
			this.majTagsScoreChangeOrder();
		})
		.catch((error) => {
		});

		// Get providers tags
		TagService.providers().then((response) => {
			this.setState({providerTags: response.body.filter(tag => tag.avg_score > 0.0)});
			this.majTagsScoreChangeOrder();
		})
		.catch((error) => {
		});

		window.gtag("event", "feature", {
						event_category: "feature",
						event_label: "overview"
				});
	}

	componentWillReceiveProps(nextProps) {
		//this.reload();
	}

	loadDomains(){
		let domainOrder = 'asc';
		if(this.state.domainOrder == 'asc'){
			domainOrder = 'desc';
		}
		ProjectService.domains(domainOrder).then((response) => {
			this.setState({domains: response.body.domains, loading: false, domainOrder: domainOrder});
		}).catch((error) => {
			this.setState({error: true});
			toast.error('Oops, we have an error');
		});
	}

	majDomainsScoreChangeOrder(){
		let order = 'asc';
		if(this.state.domainsScoreChangesOrder == 'asc'){
			order = 'desc';
		}

		let results = this.state.domains;
		if(order == 'asc'){
			results  = [...results].sort((a, b) => a.score_change - b.score_change);
		}else{
		  results = [...results].sort((a, b) => b.score_change - a.score_change);
		}
		this.setState({domains: results, domainsScoreChangesOrder: order})
	}

	majTagsScoreChangeOrder(){
		let order = 'asc';
		if(this.state.tagsScoreChangesOrder == 'asc'){
			order = 'desc';
		}

		let results = this.state.tags;
		if(order == 'asc'){
			results  = [...results].sort((a, b) => a.score_change - b.score_change);
		}else{
			results = [...results].sort((a, b) => b.score_change - a.score_change);
		}
		this.setState({tags: results, tagsScoreChangesOrder: order})
	}

	majTagsScoreOrder(){
		let order = 'asc';
		if(this.state.tagsScoreOrder == 'asc'){
			order = 'desc';
		}

		let results = this.state.tags;
		if(order == 'asc'){
			results  = [...results].sort((a, b) => a.avg_score - b.avg_score);
		}else{
			results = [...results].sort((a, b) => b.avg_score - a.avg_score);
		}
		this.setState({tags: results, tagsScoreOrder: order})
	}

	majAccountsScoreChangeOrder(){
		let order = 'asc';
		if(this.state.accountsScoreChangesOrder == 'asc'){
			order = 'desc';
		}

		let results = this.state.badAccounts;
		if(order == 'asc'){
			results  = [...results].sort((a, b) => a.score - b.score);
		}else{
			results = [...results].sort((a, b) => b.score - a.score);
		}
		this.setState({badAccounts: results, accountsScoreChangesOrder: order})
	}

	loadStats(provider = 'All'){
		ProjectService.domains().then((response) => {
			this.setState({domains: response.body.domains, domainsScoreChanges: response.body.domains, loading: false});
			this.majDomainsScoreChangeOrder('asc');
		}).catch((error) => {
			console.log(error);
			this.setState({error: true});
			toast.error('Oops, we have an error');
		});
	}

	closeNotification(id){
		this.props.notificationsActions.closeOne(id);
		NotificationService.close(id).then((response) => {
			this.props.notificationsActions.getAll();
		})
		.catch((error) => {
		});

		NotificationService.summary().then((response) => {
			this.setState({notificationsSummary: response.body})
		})
		.catch((error) => {
		});
	}




	render() {
		if(this.state.loading){
			return(
				<div className="app container text-center">
					<div className="loader">
						<Loader
						 type="Oval"
						 color="#b7b7b7"
						 height="100"
						 width="100"
						/>
					</div>
				</div>
			)
		}
		return (
			<div className="app container home cockpit">
				<div className="title">
					<h1 className="pull-left">Overview</h1>

					<ConnectBtn currentProject={this.props.currentProject} currentPricing={this.props.currentPricing}/>
        </div>

				<div className="row">

				<div className="clearfix"></div>


				{this.props.notifications.filter(notif => ['ACCOUNT_BOUNCE', 'ACCOUNT_DISCONNECTED', 'ACCOUNT_OUTLOOK_MISSING', 'ACCOUNT_SENDINBLUE_SMTP_ACTIVATION', 'ACCOUNT_MISSING_INBOUND_MESSAGES', 'ACCOUNT_GMAIL_MISSING_ALIAS', 'ACCOUNT_SENDGRID_SMTP_ACTIVATION', 'ACCOUNT_GMAIL_MAIL_SERVICE_NOT_ENABLED', 'GMAIL_INVALID_SENDER_ALIASE', 'OUTLOOK_INVALID_SENDER_ALIASE'].includes(notif.event)).length > 0 &&
					<div className="col-md-12 notifs-priority">
						<div className="card domains">
							<div className="col-md-12 table-head">
									<div className="account">
										Top issues
									</div>
									<div className="score cursor-pointer">
										Severity
									</div>
							</div>
							<div className="cockpit-block-container">
								{this.props.notifications.length == 0 &&
									<div className="no-results">
										<img src="/images/no_issues.svg" />
									</div>
								}

								<Notifications closeNotification={this.closeNotification} summary={this.state.notificationsSummary.details} notifications={this.props.notifications} currentProject={this.props.currentProject} cockpit={true}/>
							</div>
						</div>
					</div>
				}


				<div className="col-md-12 score-blck">
					<div className="card domains">
						<div className="col-md-12 table-head">
								<div className="account">
									Accounts that need attention
								</div>
								<div className="score cursor-pointer"  onClick={() => this.majAccountsScoreChangeOrder()}>
									Score
									<i class="fa-solid fa-sort"></i>
								</div>
						</div>
						<div className="cockpit-block-container">
							{(this.state.badAccounts.length == 0 && this.state.domains.length > 0) &&
								<div className="no-results no-account-issues">
									<h3>You're doing well. Keep warming and following the best practices.</h3>
									<img src="/images/no_data.svg" />
								</div>
							}

							{(this.state.badAccounts.length == 0 && this.state.domains.length == 0) &&
								<div className="no-results no-account-issues">
									<h3>MailReach needs at least 72 hours of data to display information here 🕒</h3>
									<img src="/images/no_data.svg" />
								</div>
							}
							{this.state.badAccounts.map((account, index) => {
								return (
								<NavLink to={`/dashboard/${this.props.match.params.project_id}/accounts/${account.id}/show`} className="line">
									<div className="account">
										<NavLink to={`/dashboard/${this.props.match.params.project_id}/accounts/${account.id}/show`} activeClassName="active">
											{account.email}
										</NavLink>

										<div className="bot">
											{account.has_notifications &&
												<>
													<Tooltip title='This email is facing one or several technical issues that can only be fixed on your end. Fix the issue(s) ASAP!' placement="right">
														<div>
															<NavLink to={`/dashboard/${this.props.match.params.project_id}/accounts/${account.id}/show`} activeClassName="active">
																<i class="fa-solid fa-circle-exclamation"></i>
															</NavLink>
														</div>
													</Tooltip>
												</>
											}
										</div>
									</div>

									<div className="score">
										{account.score > 0 &&
											 <div className='pull-left sc'>
												 {account.score  <= 59 &&
													<Tooltip title='Doing bad 🌡️' placement="left">
														<div className="deliverability-score bad">{account.score}</div>
													</Tooltip>
												 }
												 {(account.score >= 60 && account.score <= 89) &&
													<Tooltip title='Not great 😐' placement="left">
														<div className="deliverability-score average">{account.score}</div>
													</Tooltip>
												 }
												{(account.score > 89) &&
													<Tooltip title='Doing good 🙌' placement="left">
														<div className="deliverability-score good">{account.score}</div>
													</Tooltip>
												}
											</div>
										}
									</div>
								</NavLink>
							)})}
							</div>
					</div>
		</div>


					 <div className="col-md-12">
							<div className="card domains">
							<div className="col-md-12 table-head">
									<div className="account">
										Your domains
									</div>
									<div className="score cursor-pointer" onClick={() => this.loadDomains()}>
										<Tooltip title='This is the average reputation score of all your accounts being warmed for a given domain' placement="left">
											<div>AVG Score</div>
										</Tooltip>
										<i class="fa-solid fa-sort"></i>
									</div>
									<div className="score cursor-pointer" onClick={() => this.majDomainsScoreChangeOrder()}>
										<Tooltip title='This is the reputation score variation over the last 48 hours' placement="left">
											<div>Evolution</div>
										</Tooltip>
										<i class="fa-solid fa-sort"></i>
									</div>
							</div>
							<div className="cockpit-block-container">
								{this.state.domains.length == 0 &&
									<div className="no-results">
										<h3>MailReach needs at least 72 hours of data to display information here as well 🕒</h3>
										<img src="/images/no_data.svg" />
									</div>
								}

								{this.state.domains.map((domain, index) => {
									return (
										<NavLink to={`/dashboard/${this.props.match.params.project_id}/home?search=${domain.domain}`} className="line">
											<div className="account">
												<NavLink to={`/dashboard/${this.props.match.params.project_id}/home?search=${domain.domain}`} activeClassName="active">
													{domain.domain}
												</NavLink>
											</div>

											<div className="score cursor-pointer">
												{ domain.avg_score > 0 &&
													 <div className='pull-left sc'>
														 {domain.avg_score <= 59 &&
															<Tooltip title='Doing bad 🌡️' placement="left">
																<div className="deliverability-score bad">{domain.avg_score}</div>
															</Tooltip>
														 }
														 {(domain.avg_score >= 60 && domain.avg_score <= 89) &&
															<Tooltip title='Not great 😐' placement="left">
																<div className="deliverability-score average">{domain.avg_score}</div>
															</Tooltip>
														 }
														{(domain.avg_score > 89) &&
															<Tooltip title='Doing good 🙌' placement="left">
																<div className="deliverability-score good">{domain.avg_score}</div>
															</Tooltip>
														}
													</div>
												}
											</div>

											<div className="score cursor-pointer">
													 <div className='pull-left sc avg-score'>
														 {domain.score_change <= -1 &&
															  <Tooltip title='😐' placement="left">
																	<div className="deliverability-score bad">{Math.round(domain.score_change)}</div>
																</Tooltip>
														 }
														 {(domain.score_change > -1 && domain.score_change < 1) &&
															 	<Tooltip title='Stable' placement="left">
																	<div className="deliverability-score stable">~</div>
																</Tooltip>
														 }
														{(domain.score_change >= 1) &&
															<Tooltip title='👍' placement="left">
																<div className="deliverability-score good">+{Math.round(domain.score_change)}</div>
															</Tooltip>
														}
												</div>
											</div>
									</NavLink>
								)})}
								</div>
							</div>
						</div>


						{this.state.providerTags.length > 1 &&
							<div className="col-md-12">
								<div className="card domains">
								<div className="col-md-12 table-head">
										<div className="account">
											Your providers
										</div>
										<div className="score cursor-pointer" onClick={() => this.majTagsScoreOrder()}>
											<Tooltip title='This is the average reputation score of your accounts per email service provider' placement="left">
												<div>AVG Score</div>
											</Tooltip>
											<i class="fa-solid fa-sort"></i>
										</div>
										<div className="score cursor-pointer" onClick={() => this.majTagsScoreChangeOrder()}>
											<Tooltip title='This is the reputation score variation over the last 48 hours' placement="left">
												<div>Evolution</div>
											</Tooltip>
											<i class="fa-solid fa-sort"></i>
										</div>
								</div>
								<div className="cockpit-block-container">
									{this.state.providerTags.map((tag, index) => {
										return (
											<>
												{tag.avg_score > 0.0 &&
													<div className="line">
														<div className="account capitalize">
																{tag.name}
														</div>

														<div className="score cursor-pointer">
															{tag.avg_score > 0 &&
																 <div className='pull-left sc'>
																	 {tag.avg_score <= 59 &&
																		<Tooltip title='Doing bad 🌡️' placement="left">
																			<div className="deliverability-score bad">{Math.round(tag.avg_score)}</div>
																		</Tooltip>
																	 }
																	 {(tag.avg_score >= 60 && tag.avg_score <= 89) &&
																		<Tooltip title='Not great 😐' placement="left">
																			<div className="deliverability-score average">{Math.round(tag.avg_score)}</div>
																		</Tooltip>
																	 }
																	{(tag.avg_score > 89) &&
																		<Tooltip title='Doing good 🙌' placement="left">
																			<div className="deliverability-score good">{Math.round(tag.avg_score)}</div>
																		</Tooltip>
																	}
																</div>
															}
														</div>

														<div className="score cursor-pointer">
																 <div className='pull-left sc avg-score'>
																	 {tag.score_change <= -1 &&
																			<Tooltip title='😐' placement="left">
																				<div className="deliverability-score bad">{Math.round(tag.score_change)}</div>
																			</Tooltip>
																	 }
																	 {(tag.score_change > -1 && tag.score_change < 1) &&
																			<Tooltip title='Stable' placement="left">
																				<div className="deliverability-score stable">~</div>
																			</Tooltip>
																	 }
																	{(tag.score_change >= 1) &&
																		<Tooltip title='👍' placement="left">
																			<div className="deliverability-score good">+{Math.round(tag.score_change)}</div>
																		</Tooltip>
																	}
															</div>
														</div>
													</div>
												}
											</>
										)})}
									</div>
								</div>
							</div>
						}


						<div className="col-md-12">
							<div className="card domains">
							<div className="col-md-12 table-head">
									<div className="account">
										Your tags
									</div>
									<div className="score cursor-pointer" onClick={() => this.majTagsScoreOrder()}>
										<Tooltip title='This is the average reputation score of all your accounts being warmed for a given tag' placement="left">
											<div>AVG Score</div>
										</Tooltip>
										<i class="fa-solid fa-sort"></i>
									</div>
									<div className="score cursor-pointer" onClick={() => this.majTagsScoreChangeOrder()}>
										<Tooltip title='This is the reputation score variation over the last 48 hours' placement="left">
											<div>Evolution</div>
										</Tooltip>
										<i class="fa-solid fa-sort"></i>
									</div>
							</div>
							<div className="cockpit-block-container">
								{(this.state.tags.length == 0 && this.state.domains.length == 0) &&
									<div className="no-results tags">
										<h3>MailReach needs at least 72 hours of data to display information here as well 🕒</h3>
										<img src="/images/no_data.svg" width="130px"/>
									</div>
								}
								{(this.state.tags.length == 0 && this.state.domains.length > 0) &&
									<div className="no-results tags">
										<h3>You haven't assigned any tag yet.</h3>
										<p>Tip: you can use them to organize your email accounts by sales rep, customer (for agencies), campaign, project. You'll then be able to see here the average score per tag.</p>
										<img src="/images/no_data.svg" width="130px" />
									</div>
								}
								{this.state.tags.map((tag, index) => {
									return (
										<>
											{tag.avg_score > 0.0 &&
												<NavLink to={`/dashboard/${this.props.match.params.project_id}/home?tag=${tag.name}`} className="line">
													<div className="account">
														<NavLink to={`/dashboard/${this.props.match.params.project_id}/home?tag=${tag.name}`} activeClassName="active">
															{tag.name}
														</NavLink>
													</div>

													<div className="score cursor-pointer">
														{tag.avg_score > 0 &&
															 <div className='pull-left sc'>
																 {tag.avg_score <= 59 &&
																	<Tooltip title='Doing bad 🌡️' placement="left">
																		<div className="deliverability-score bad">{Math.round(tag.avg_score)}</div>
																	</Tooltip>
																 }
																 {(tag.avg_score >= 60 && tag.avg_score <= 89) &&
																	<Tooltip title='Not great 😐' placement="left">
																		<div className="deliverability-score average">{Math.round(tag.avg_score)}</div>
																	</Tooltip>
																 }
																{(tag.avg_score > 89) &&
																	<Tooltip title='Doing good 🙌' placement="left">
																		<div className="deliverability-score good">{Math.round(tag.avg_score)}</div>
																	</Tooltip>
																}
															</div>
														}
													</div>

													<div className="score cursor-pointer">
															 <div className='pull-left sc avg-score'>
																 {tag.score_change <= -1 &&
																		<Tooltip title='😐' placement="left">
																			<div className="deliverability-score bad">{Math.round(tag.score_change)}</div>
																		</Tooltip>
																 }
																 {(tag.score_change > -1 && tag.score_change < 1) &&
																		<Tooltip title='Stable' placement="left">
																			<div className="deliverability-score stable">~</div>
																		</Tooltip>
																 }
																{(tag.score_change >= 1) &&
																	<Tooltip title='👍' placement="left">
																		<div className="deliverability-score good">+{Math.round(tag.score_change)}</div>
																	</Tooltip>
																}
														</div>
													</div>
												</NavLink>
											}
										</>
									)})}
								</div>
							</div>
						</div>

					</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		currentProject: state.currentProject,
		currentUser: state.currentUser,
		currentProject: state.currentProject,
		currentPricing: state.currentPricing,
		notifications: state.notifications
	};
}

function mapDispatchToProps(dispatch) {
	return {
		projectsActions: bindActionCreators(projectsActions, dispatch),
		currentProjectActions: bindActionCreators(currentProjectActions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CockpitHome));
