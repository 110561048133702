const request = require('superagent');

class UserService {

	static current() {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}users/current`)
            .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`);
	}

	static currentUpdate(user) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}users/current`)
						.set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
						.send(user);
	}
}

export default UserService;
