const request = require('superagent');

class ConversationService {

  static all(templateId) {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}conversations?template_id=${templateId}`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }

  static get(conversationId) {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}conversations/${conversationId}`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }

  static test(conversationId) {
    return request
            .post(`${process.env['REACT_APP_BACKEND_URL']}conversations/${conversationId}/test`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }

  static update(conversation) {
    return request
    .post(`${process.env['REACT_APP_BACKEND_URL']}conversations/${conversation.id}`)
    .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
    .send(conversation)
  }
}

export default ConversationService ;
