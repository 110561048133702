const request = require('superagent');

class EmailvalidationService {
	static createTask(fileKey, fileName) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}emailvalidation/tasks`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.send({file_key: fileKey, original_filename: fileName});
	}

	static createTaskRaw(emails, fileName) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}emailvalidation/tasks/raw`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.send({emails, original_filename: fileName});
	}

	static tasks() {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}emailvalidation/tasks`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}


	static testInput(fileKey, fileName, emails = []) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}emailvalidation/test`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.send({file_key: fileKey, original_filename: fileName, emails: emails});
	}
}

export default EmailvalidationService;
