const request = require('superagent');

// Todo, rename as test folder
class SlackChannelService {

	static all() {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}slack_channels`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}
}

export default SlackChannelService;
