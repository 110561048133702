const request = require('superagent');

class ProjectMemberService {
	static create(token) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}project_members`)
            .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
						.send({invite_token: token});
	}

	static all() {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}project_members`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.set('Authorization', `Bearer ${localStorage.getItem('jwt')}`);
	}

	static delete(id) {
		return request
						.delete(`${process.env['REACT_APP_BACKEND_URL']}project_members/${id}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.set('Authorization', `Bearer ${localStorage.getItem('jwt')}`);
	}
}

export default ProjectMemberService;
