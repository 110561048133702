import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import {toast} from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import {loadStripe} from '@stripe/stripe-js';
import InjectedCheckoutForm from './checkoutForm';
import {Elements} from '@stripe/react-stripe-js';

import i18n from "../../../i18n";

import ProjectService from '../../../services/project.service';
import ProjectMemberService from '../../../services/projectMember.service';


import './settings.css';

class DashboardSettingsTeam extends React.Component {
	constructor(props) {
		super(props);
		this.state = {errors: [], project: {}, projectMembers: []}
		this.toggleField = this.toggleField.bind(this);
		this.deleteProjectMember = this.deleteProjectMember.bind(this);
		this.reloadProjectmembers = this.reloadProjectmembers.bind(this);
	}

	componentWillMount() {
    ProjectService.get(this.props.match.params.project_id).then((response) => {
      this.setState({project: response.body});
      localStorage.setItem('x-api-key', response.body.api_key);
    })
    .catch((error) => {
      this.setState({error: true,});
      toast.error('Oops, we have an error');
    });
    // Get projet members if the current user is the project admin
		this.reloadProjectmembers();
	}

	reloadProjectmembers(){
		ProjectMemberService.all().then((response) => {
			this.setState({projectMembers: response.body});
		})
		.catch((error) => {
			this.setState({error: true,});
			toast.error('Oops, we have an error');
		});
	}

	toggleField(e){
		e.preventDefault();
		if(this.state.keyFieldState == 'password'){
      this.setState({keyFieldState: 'text'});
		} else {
			this.setState({keyFieldState: 'password'});
		}
	}

	copyText(value){
		navigator.clipboard.writeText(value);
		toast.info('Link successfully copied 🙌');
	}

	deleteProjectMember(id){
		ProjectMemberService.delete(id).then((response) => {
		  // Success alert and reload
			toast.success('Team member removed');
			this.reloadProjectmembers();
		})
		.catch((error) => {
			this.setState({error: true,});
			toast.error('Oops, we have an error');
		});
	}

	signout(){
		localStorage.clear();
		//this.props.history.push(`/redirect`);
	}

	render() {

		if(this.props.currentProject.admin_user_id != this.props.currentUser.id){
			return (
				<div className="app container add-credit-card">
					<div className="card">
						Only the project owner can manage team
					</div>
				</div>
			)
		}

		return (
			<div className="app container add-credit-card team">
        <div className="col-md-3">
					<div className="title">
						<h1>Team</h1>
					</div>
          <div className="card">
            <ul className="nav-left">
              <li>
                <NavLink to={`/dashboard/${this.props.currentProject.id}/settings/billing`} activeClassName="active">
                  <i class="far fa-credit-card"></i> Billing
                </NavLink>
							</li>
              <li>
                <NavLink to={`/dashboard/${this.props.currentProject.id}/settings/api`} activeClassName="active">
                  <i class="fas fa-key"></i> API
                </NavLink>
              </li>
							<li>
								<NavLink to={`/dashboard/${this.props.currentProject.id}/settings/team`} activeClassName="active">
									<i class="fa-solid fa-people-group"></i> Team
								</NavLink>
							</li>
							<li>
								<NavLink to={`/dashboard/${this.props.currentProject.id}/settings/alerts`} activeClassName="active">
									<i class="fa-sharp fa-solid fa-bell"></i> Alerts
								</NavLink>
							</li>
							<li>
								<NavLink to={`/dashboard/${this.props.currentProject.id}/settings/password`} activeClassName="active">
									<i class="fa-solid fa-lock"></i> Change password
								</NavLink>
							</li>
								<li>
									<a href="#" onClick={this.signout} className="signout">
										<i class="fas fa-sign-out-alt"></i> Sign out
									</a>
	              </li>
            </ul>
						<div className="clearfix"></div>
          </div>
        </div>
        <div className="col-md-9">
				<div className="row">
					<h1>Invite people to your team</h1>
          <div className="card checkout imap">
						<p>Share this link to your team mates, so they can sign up and join your team</p>

						<div className="email-code copy-style copy-code" onClick={() =>  this.copyText(`https://${window.location.hostname}/invite?token=${this.state.project.join_project_team_token}`)}>
							<div className="code">
								https://{window.location.hostname}/invite?token={this.state.project.join_project_team_token}
							</div>
							<div className="copy">
								<i class="far fa-copy"></i>
							</div>
						</div>
					<div className="clearfix"></div>
					</div>


					{this.state.projectMembers.length > 0 &&
						<>
						<h1>Members</h1>
						<div className="card team-list">
							<ul>
								{this.state.projectMembers.map((projectMember, index) => {
									return (
										<li>
										  <div className="team-pp">
	                      <span>{projectMember.user.first_name ? projectMember.user.first_name.charAt(0).toUpperCase() : 'A'}</span>
											</div>
											<div className="user-infos">
										  	{projectMember.user.email} <button className="pull-right btn red" onClick={() => this.deleteProjectMember(projectMember.id)}>Remove</button>
											</div>
										</li>
									)
								})}
							</ul>
							<div className="clearfix"></div>
					  </div>

						</>
					}
        </div>
			</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		currentUser: state.currentUser,
		currentProject: state.currentProject,
		currentPricing: state.currentPricing
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardSettingsTeam));
