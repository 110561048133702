import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import {toast} from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import {loadStripe} from '@stripe/stripe-js';
import InjectedCheckoutForm from './checkoutForm';
import {Elements} from '@stripe/react-stripe-js';

import i18n from "../../../i18n";

import UserService from '../../../services/user.service';

import './settings.css';

class DashboardSettingsChangePassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {errors: [], user: {}};
		this.onChange = this.onChange.bind(this);
		this.submit = this.submit.bind(this);
	}

	componentWillMount() {
	}

  onChange(event) {
    const field = event.target.name;
    const user = this.state.user;
    user[field] = event.target.value;
    this.setState({user});
    return;
  }

  submit(event) {
    event.preventDefault();
    UserService.currentUpdate(this.state.user).then((response) => {
			toast.success('User updated');
    })
    .catch((error) => {
      this.setState({error: true, signupForm: false, loading: false});
      toast.error('Oops. Check email and password.');
    });
  }



	render() {

		return (
			<div className="app container add-credit-card team">
        <div className="col-md-3">
					<div className="title">
						<h1>Settings</h1>
					</div>
          <div className="card">
						<ul className="nav-left">
							<li>
								<NavLink to={`/dashboard/${this.props.currentProject.id}/settings/billing`} activeClassName="active">
									<i class="far fa-credit-card"></i> Billing
								</NavLink>
							</li>
							<li>
								<NavLink to={`/dashboard/${this.props.currentProject.id}/settings/api`} activeClassName="active">
									<i class="fas fa-key"></i> API
								</NavLink>
							</li>
							<li>
								<NavLink to={`/dashboard/${this.props.currentProject.id}/settings/team`} activeClassName="active">
									<i class="fa-solid fa-people-group"></i> Team
								</NavLink>
							</li>
							<li>
								<NavLink to={`/dashboard/${this.props.currentProject.id}/settings/alerts`} activeClassName="active">
									<i class="fa-sharp fa-solid fa-bell"></i> Alerts
								</NavLink>
							</li>
							<li>
								<NavLink to={`/dashboard/${this.props.currentProject.id}/settings/password`} activeClassName="active">
									<i class="fa-solid fa-lock"></i> Change password
								</NavLink>
							</li>
								<li>
									<a href="#" onClick={this.signout} className="signout">
										<i class="fas fa-sign-out-alt"></i> Signout
									</a>
								</li>
						</ul>
						<div className="clearfix"></div>
          </div>
        </div>
        <div className="col-md-9">
				<div className="row">
					<h1>Change your password</h1>
          <div className="card checkout imap">
            <form className="row" onSubmit={this.handleSubmit}>
              <div className="col-md-8">
                  <div className="fieldCont col-md-12">
                    <label>Current password</label>
                    <input type="password" name="current_password" onChange={this.onChange} required/>
                  </div>
                  <div className="fieldCont col-md-12">
                    <label>New password</label>
                    <input type="password" name="password" onChange={this.onChange} required/>
                  </div>
                  <div class="fieldCont col-md-12">
                    <label>New password confirmation</label>
                    <input type="password" name="password_confirmation" onChange={this.onChange} required/>
                  </div>


                  <div class="fieldCont col-md-12">
                    <button className="btn purple-btn" onClick={this.submit} >
                      Update password
                    </button>
                  </div>
                </div>
              </form>

					  <div className="clearfix"></div>
					</div>
        </div>
			</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		currentUser: state.currentUser,
		currentProject: state.currentProject,
		currentPricing: state.currentPricing
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardSettingsChangePassword));
