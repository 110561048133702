import NotificationService from '../services/notification.service';
import * as types from './actionTypes';

function notificationsGetSuccess(notifications) {
	return {type: types.NOTIFICATIONS_GET_SUCCESS, notifications};
}

function notificationsCloseOne(notificationsId) {
	return {type: types.NOTIFICATIONS_CLOSE_SUCCESS, notificationsId};
}

function loginError(errors) {
	return {type: types.LOG_IN_ERROR, errors};
}

export function closeOne(id) {
	return function (dispatch) {
	  dispatch(notificationsCloseOne(id));
	}
}


export function getAll() {
	return function (dispatch) {
		return NotificationService.all().then(response => {
			dispatch(notificationsGetSuccess(response.body));
		}).catch(error => {
			dispatch(loginError(['An error occured']))
			//throw (error);
		});
	};
}
