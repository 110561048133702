import {combineReducers} from 'redux';
import session from './session.reducer';
import errors from './errors.reducer';
import currentUser from './currentUser.reducer';
import currentProject from './currentProject.reducer';
import currentPricing from './currentPricing.reducer';
import notifications from './notifications.reducer';

const rootReducer = combineReducers({
	// Short hand property names
	session,
	errors,
	currentUser,
	currentProject,
	currentPricing,
	notifications,
});

export default rootReducer;
