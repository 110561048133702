import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function NotificationsReducer(state = initialState.notifications, action) {
	switch (action.type) {
		case types.NOTIFICATIONS_GET_SUCCESS:
			return action.notifications;
		case types.NOTIFICATIONS_CLOSE_SUCCESS:
			let notifications = state;
			state = state.filter(item => item.id !== action.notificationsId)
		  return state;
		default:
			return state;
	}
}
