import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast} from 'react-toastify';
import {Controlled as CodeMirror} from 'react-codemirror2'
import Tooltip from '@material-ui/core/Tooltip';
import Loader from 'react-loader-spinner';
import queryString from 'query-string';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

import { GeneratePricingPreview } from '../../../helpers/generatePricingPreview'

import * as projectsActions from '../../../actions/projects.actions';
import * as currentProjectActions from '../../../actions/currentProject.actions';
import * as notificationsActions from '../../../actions/notifications.actions';

import AccountService from '../../../services/account.service';
import StripeService from '../../../services/stripe.service';
import NotificationService from '../../../services/notification.service';
import TagService from '../../../services/tag.service';
import TaskService from '../../../services/task.service';

import Notifications from '../../../components/notifications/notifications.js';

import ConnectBtn from '../../../components/connectBtn/connectBtn.js';

import './home.scss';


const MailreachSlider = styled(Slider)({
  height: 8,
  '& .MuiSlider-thumbColorSecondary': {
    backgroundColor: "rgb(17 16 59)",
    height: "15px",
    width: "15px",
    position: "absolute",
    borderRadius: "50%",
    marginTop: "-3px",
    marginLeft: "-6px",
  },
});

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#11103b',
    },
    '&$checked + $track': {
      backgroundColor: '#11103b',
    },
  },
  checked: {},
  track: {},
})(Switch);



const onClickHandler = (url) => {
    window.location.replace(url);
}


const indexTxt = {
	'': 'Sort by',
	'Account_score_desc': 'Highest score',
	'Account_score_asc': 'Lowest score',
	'Account_created_at_desc': 'Last connected',
	'Account_created_at_asc': 'First connected',
	'Account_alive_asc': 'Status',
	'Account_alive_desc': 'Technical issues'
}

class DashboardHome extends React.Component {
	constructor(props) {
		super(props);
		this.state = {errors: [], loading: true, project: {}, pricePlan: 49, tags: [], accounts: [], accountPage: 0, notifications: [], index: '', filterTags: [], facetFilters: [], editTag: {}, compressedView: false, notificationsSummary: {details: {}}, accountIdsToEdit: [], tagsToBatchAdd: [], newSeats: 1}
		this.reload = this.reload.bind(this);
		this.closeNotification = this.closeNotification.bind(this);
		this.searchAccount = this.searchAccount.bind(this);
		this.nextPage = this.nextPage.bind(this);
		this.setAccountsOrder = this.setAccountsOrder.bind(this);
		this.handleNewTagNameChange = this.handleNewTagNameChange.bind(this);
		this.addTag = this.addTag.bind(this);
		this.removeTagFromAccount = this.removeTagFromAccount.bind(this);
		this.getTagColor = this.getTagColor.bind(this);
		this.handleEditTagNameChange = this.handleEditTagNameChange.bind(this);
		this.editTag = this.editTag.bind(this);
		this.deleteTag = this.deleteTag.bind(this);
		this.toggleCompressedView = this.toggleCompressedView.bind(this);
    this.selectAccountsToEdit = this.selectAccountsToEdit.bind(this);
    this.addTagToBatchEdit = this.addTagToBatchEdit.bind(this);
    this.batchUpdateAccounts = this.batchUpdateAccounts.bind(this);
    this.openUpgradeModal = this.openUpgradeModal.bind(this);
    this.buySeatsAndRedirect = this.buySeatsAndRedirect.bind(this);
    this.setSeats = this.setSeats.bind(this);
    this.handleCloseAddAccountModal = this.handleCloseAddAccountModal.bind(this);
    this.searchTimeout =  0;
	}

	componentWillMount() {
		let queryParams = queryString.parse(this.props.location.search);
		let success = queryParams.success;
		if(success){
			toast.success(success);
		}

		this.setState({compressedView: this.getCookie('compressedView') === 'true'});


		let error = queryParams.error;
		if(error){
			toast.error(error);
		}

    if(queryParams.addModalAccount){
      this.setState({showAddAccountModal: true});
    }

		if(queryParams.trackco){
			window.gtag("event", "accounts", {
							event_category: "accounts",
							event_label: "connected"
					});
			window.gtag('event', 'conversion', {
					      'send_to': 'AW-444511731/6eLmCPyZwvkBEPPr-tMB',
					      'transaction_id': ''
					 });
			window.$crisp.push(["set", "session:segments", [["account-connected"]]]);
			window.$crisp.push(["set", "session:event", [[["account-connected"]]]]);
		}
		this.props.currentProjectActions.getCurrent().then((response) => {
      if(this.props.currentProject.config_feature_stripe_payment_link_checkout_enabled && !this.props.currentProject.stripe_customer_id){
        this.props.history.push(`/dashboard/${this.props.currentProject.id}/settings/checkout/plans?hideNvgt=true`);
      }
		})
		.catch((error) => {
		});
		this.reload();

		NotificationService.summary().then((response) => {
			this.setState({notificationsSummary: response.body})
		})
		.catch((error) => {
		});

		TagService.all().then((response) => {
			this.setState({tags: response.body})
		})
		.catch((error) => {
		});

    if(queryParams.deleting){
      setTimeout(() => {this.reload();}, 4000);
    }
	}

	reload(){
		let index = this.getCookie('home_accounts_filter');
		let tags = [];
		if(index){
			this.setState({index});
			if(index == 'Account_alive_desc'){
				tags = ['has_notifications_true'];
			}
		}

    if(queryString.parse(this.props.location.search).tag){
      this.setAccountsOrder(this.state.index, queryString.parse(this.props.location.search).tag)
    }else{
      AccountService.all(queryString.parse(this.props.location.search).search, index, 0, tags).then((response) => {
        this.setState({accounts: response.body, loading: false, search: false, currentSearch: queryString.parse(this.props.location.search).search});
        if(response.body.length < 50){
          this.setState({endAccountsList: true});
        }
      })
      .catch((error) => {
        toast.error('Oops, we have an issue, try again');
      });
    }

		this.props.notificationsActions.getAll();
	}

	searchAccount(e, enter = false){
    e.preventDefault();

    if(enter == false){
      this.setState({currentSearch: e.target.value});
    }

    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout((e) => {
      AccountService.all(this.state.currentSearch, this.state.index).then((response) => {
        this.setState({accounts: response.body, loading: false, search: true})
      })
      .catch((error) => {
        toast.error('Oops, we have an issue, try again');
      });
    }, 450);
	}


	setAccountsOrder(index, tag = null, filter = null){
		let facetFilters = null;
    this.setState({tagsListLoader: true});
		if(filter == 'has_notifications_true'){
			if(this.state.facetFilters.includes('has_notifications_true')){
				facetFilters = [];
			}else{
				facetFilters = ['has_notifications_true'];
			}
		}else{
			facetFilters = this.state.facetFilters;
		}

		if(!index){
			index = 'Account_created_at_desc';
		}

		let filterTags = this.state.filterTags || [];

		if(tag){
			// Remove tag if is already present (Toggle)
			if(filterTags.includes(tag)){
				filterTags = filterTags.filter(a => a !== tag);
			}else{
				filterTags.push(tag);
			}
		}

		// Remove,duplicate
		filterTags = [...new Set(filterTags)];
		AccountService.all(this.state.currentSearch, index, 0, facetFilters, filterTags).then((response) => {
			this.setState({accounts: response.body, loading: false, search: true, index: index, filterTags: filterTags, facetFilters: facetFilters, tagsListLoader: false});
			this.setCookie('home_accounts_filter', index, 120);
		})
		.catch((error) => {
			toast.error('Oops, we have an issue, try again');
      this.setState({tagsListLoader: false});
		});
	}

	nextPage(pageNumber){
		this.setState({loadNextAccounts: true});
		let accounts = this.state.accounts;
		AccountService.all(null, null, pageNumber).then((response) => {
			accounts = accounts.concat(response.body);
			this.setState({accounts, loading: false, accountPage: pageNumber, loadNextAccounts: false});
			if(response.body.length < 50){
				this.setState({endAccountsList: true});
			}
		})
		.catch((error) => {
			toast.error('Oops, we have an issue, try again');
			this.setState({loadNextAccounts: false});
		});
	}

	closeNotification(id){
    this.props.notificationsActions.closeOne(id);
		NotificationService.close(id).then((response) => {
			this.props.notificationsActions.getAll();
		})
		.catch((error) => {
		});

		NotificationService.summary().then((response) => {
			this.setState({notificationsSummary: response.body})
		})
		.catch((error) => {
		});
	}

	componentWillReceiveProps(nextProps) {
		//this.reload();
	}

	setCookie(cname, cvalue, minutes) {
    var d = new Date();
    d.setTime(d.getTime() + (minutes*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; path=/ ;" + expires;
	}

	getCookie(name) {
	  const value = `; ${document.cookie}`;
	  const parts = value.split(`; ${name}=`);
	  if (parts.length === 2) return parts.pop().split(';').shift();
	}


	handleNewTagNameChange(event) {
		if(event.target.value.length > 18){
			toast.error('A tag name can\'t exceed 18 characters');
		}else{
		  this.setState({newTagName: event.target.value});
		}
	}

	addTag(event) {
    event.preventDefault();

		TagService.create(this.state.newTagName).then((response) => {
			//this.setState({notificationsSummary: response.body})
			this.setState({newTagName: null})
			toast.success('Tag added');

			TagService.all().then((response) => {
				this.setState({tags: response.body})
			})
			.catch((error) => {
			});

		})
		.catch((error) => {
			toast.error('Oops, we have an issue, try again');
		});
	}

	addTagToAccount(accountId, tag){
		TagService.link(accountId, tag.id).then((response) => {
			const accounts = this.state.accounts.map(obj => {
				if (obj.id == accountId) {
					let tags = obj.tags;
					tags.push(tag);
					// Remove,duplicate
					tags = [...new Set(tags)];

					obj.tags = tags;
					return {...obj};
				}
				return obj;
			});
			this.setState(accounts);
			toast.success('Tag added');
		})
		.catch((error) => {
			toast.error('Oops, we have an issue, try again');
		});
	}

	removeTagFromAccount(accountId, tagId){
		TagService.unlink(accountId, tagId).then((response) => {
			const accounts = this.state.accounts.map(obj => {
				if (obj.id == accountId) {
					let tags = obj.tags.filter(t => t.id != tagId);
					obj.tags = tags;
					return {...obj};
				}
				return obj;
			});
			this.setState(accounts);
			toast.success('Tag removed');
		})
		.catch((error) => {
			toast.error('Oops, we have an issue, try again');
		});
	}

	getTagColor(tag){
		let currentTag = this.state.tags.find(t => t.name === tag);
		return currentTag.color;
	}

	handleEditTagNameChange(e){
		let currentTagInEdition = this.state.editTag
		if(e.target.value.length > 18){
			toast.error('A tag name can\'t exceed 18 characters');
		}else{
			currentTagInEdition.name = e.target.value;
			this.setState({editTag: currentTagInEdition});
		}
	}

	toggleCompressedView(){
		let compressedView = !this.state.compressedView;
    this.setState({compressedView: compressedView});
		this.setCookie('compressedView', compressedView, 120);
	}

	editTag(){
		event.preventDefault();
		TagService.update(this.state.editTag).then((response) => {
			//this.setState({notificationsSummary: response.body})
			this.setState({newTagName: null})
			toast.success('Tag updated');

			TagService.all().then((response) => {
				this.setState({tags: response.body});
				this.setAccountsOrder(this.state.index);
			})
			.catch((error) => {
			});

		})
		.catch((error) => {
			toast.error('Oops, we have an issue, try again');
		});
	}

	deleteTag(tagId){
		TagService.delete(tagId).then((response) => {
			//this.setState({notificationsSummary: response.body})
			this.setState({newTagName: null})
			toast.success('Tag deleted');

			TagService.all().then((response) => {
				this.setState({tags: response.body, openEditTagModal: false, editTag: {}})
				this.setAccountsOrder(this.state.index);
			})
			.catch((error) => {
			});

		})
		.catch((error) => {
			toast.error('Oops, we have an issue, try again');
		});
	}

  selectAccountsToEdit(accountId){
    let accountIdsToEdit = this.state.accountIdsToEdit;
    if(accountIdsToEdit.includes(accountId)){
      accountIdsToEdit = accountIdsToEdit.filter(id => id != accountId);
    }else{
      accountIdsToEdit.push(accountId);
    }
    this.setState({accountIdsToEdit});
  }

  selectAllAccountsToEdit(e){
    let accountIdsToEdit = [];
    if(e.target.checked){
      accountIdsToEdit = this.state.accounts.map((account) => account.id);
    }
    this.setState({accountIdsToEdit});
  }

  addTagToBatchEdit(tag){
    let tagsToBatchAdd = this.state.tagsToBatchAdd;
    if(tagsToBatchAdd.includes(tag)){
      tagsToBatchAdd = tagsToBatchAdd.filter(t => t.id != tag.id);
    }else{
      tagsToBatchAdd.push(tag);
    }
    this.setState({tagsToBatchAdd});
  }

  openUpgradeModal(){
    let newSeats = (this.props.currentProject.used_seats + 1);
    this.setState({newSeats});
    let pricingPreview = GeneratePricingPreview(newSeats, false, {seatsAdded: 1});
    this.setState({showAddAccountModal: true, pricePerSeat: pricingPreview.pricePerSeat, seatsAdded: 1, totalPrice: pricingPreview.totalPrice, priceAdded: pricingPreview.priceAdded});
  }

  setSeats(e){
    let newSeats = (this.props.currentProject.used_seats + e.target.value);
    this.setState({newSeats});
    let pricingPreview = GeneratePricingPreview(newSeats, false, {seatsAdded: (this.state.newSeats - this.props.currentProject.used_seats)});
    this.setState({pricePerSeat: pricingPreview.pricePerSeat, totalPrice: pricingPreview.totalPrice, seatsAdded: e.target.value, priceAdded: pricingPreview.priceAdded});
  }

  handleCloseAddAccountModal(){
    this.setState({showAddAccountModal: false});
  }

  buySeatsAndRedirect(){
    let c = this;
    let data = {
      seats: this.state.newSeats,
      period: (this.props.currentProject.stripe_subscription_period == 'yearly') ? 12 : 1,
      use_card: true
    }
    c.setState({loadingModal: true});
    StripeService.createCustomer(data).then(function(value) {
      c.setState({loadingModal: false, success: true});
      toast.success('Thanks, you can now connect account(s) !');
      c.props.history.push(`/dashboard/${c.props.currentProject.id}/account/connect`);
    }).catch(function(err) {
        toast.error('Your card was rejected, try another card. Contact us if the problem persists.');
        c.setState({loading: false, loadingModal: false});
      });
  }

  monitoreBatchUpdate(taskId){
    TaskService.get(taskId).then((response) => {
      if(response.body.progress == 100){
        toast.success('Batch update completed');
        this.reload();
      }else{
        setTimeout(
          () => this.monitoreBatchUpdate(taskId),
          1500
        );
      }
    })
    .catch((error) => {
      toast.error('Oops, we have an issue, try again');
    });
  }

  batchUpdateAccounts(action){
    AccountService.batchUpdate(this.state.accountIdsToEdit, this.state.tagsToBatchAdd.map(tag => tag.id), action).then((response) => {
      // Close modal, re-init state, show update
      toast.success('Batch update processing');
      this.monitoreBatchUpdate(response.body.id);
      this.setState({openBatchAddTagModal: false, accountIdsToEdit: [], tagsToBatchAdd: []})
    })
    .catch((error) => {
      toast.error('Oops, we have an issue, try again');
    });
  }


	render() {
		if(this.state.loading){
			return(
				<div className="app container text-center">
					<div className="loader">
						<Loader
						 type="Oval"
						 color="#b7b7b7"
						 height="100"
						 width="100"
						/>
					</div>
				</div>
			)
		}

    if(this.props.currentProject.config_feature_stripe_payment_link_checkout_enabled && this.props.currentProject.stripe_customer_id && this.props.currentProject.seats == 0 && !this.props.currentProject.free_billing){
      return(
        <div className="app container text-center">
          <div className="card signup-warmer upgrade-spamchecker text-left">
            <div className="col-md-12">
              <h2>Sending B2B cold emails? Raise your sender reputation with our email warmer.</h2>
            </div>

            <div className="col-md-4 text-center">
              <img src="/images/warm_tease.png" />
            </div>

            <div className="col-md-8">
              <p>To reach and maintain 65%+ open rates, you need a constant high engagement rate.</p>
              <p>Our email warmer generates meaningful positive interactions to your email activity and teaches Gmail and Office365 to send your emails to your recipients' inbox and not in spam.</p>
              <p>Raise your sender reputation and deliverability, enjoy higher open rates and more revenue.</p>

              <NavLink to={this.props.currentProject.config_feature_stripe_payment_link_checkout_enabled ? `/dashboard/${this.props.match.params.project_id}/settings/checkout/plans` : `/dashboard/${this.props.match.params.project_id}/settings/billing?hide_signout=true`} className="btn btn-primary green-bck pull-right">
                Start warming >
              </NavLink>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      )
    }
		return (
			<div className="app container home">
				<div className="title">
					<h1 className="pull-left">Email Accounts</h1>
          <ConnectBtn currentProject={this.props.currentProject} currentPricing={this.props.currentPricing}/>
					<div className="clearfix"></div>
				</div>
				<div className="row accounts">

				<div className="col-md-12">
					{(!this.props.currentProject.stripe_customer_id && !this.props.currentProject.free_billing) &&
						<div className="alert status warning card">
							Add a payment method to start the warming process
							<NavLink to={this.props.currentProject.config_feature_stripe_payment_link_checkout_enabled ? `/dashboard/${this.props.match.params.project_id}/settings/checkout/plans` : `/dashboard/${this.props.match.params.project_id}/settings/billing?hide_signout=true`} className="btn pull-right" activeClassName="active">
								Add payment method
							</NavLink>
						</div>
					}

					<Notifications closeNotification={this.closeNotification} summary={this.state.notificationsSummary.details} notifications={this.props.notifications} currentProject={this.props.currentProject}/>

          {!this.props.currentProject.config_feature_new_billing_seats &&
            <>
  						{((this.props.currentProject.stripe_customer_id || this.props.currentProject.free_billing) && (this.state.accounts.length == 0) && !this.state.search) &&
  							<div className="alert status warning card">
  								You don't have any email account connected
  								<button onClick={() => this.setState({showAddAccountModal: true})} className="btn pull-right" activeClassName="active">
  									Connect account
  								</button>
  							</div>
  						}
            </>
          }

          {this.props.currentProject.config_feature_new_billing_seats &&
            <>
              {((this.props.currentProject.stripe_customer_id || this.props.currentProject.free_billing) && (this.state.accounts.length == 0) && !this.state.search) &&
                <div className="alert status warning card">
                  You don't have any email account connected

                  {(this.props.currentProject.seats >  this.props.currentProject.used_seats) &&
                    <NavLink to={`/dashboard/${this.props.match.params.project_id}/account/connect`} className="btn pull-right" activeClassName="active">
                      Connect account
                    </NavLink>
                  }

                  {(this.props.currentProject.seats <= this.props.currentProject.used_seats) &&
                    <button onClick={this.openUpgradeModal} className="btn pull-right" activeClassName="active">
                      Connect account
                    </button>
                  }

                </div>
              }
            </>
          }
				</div>

				{(this.props.currentProject.used_seats > 5 || this.state.search || this.state.index) &&
						<div className={"search w-compressed-v"}>

              <form onSubmit={(e) => this.searchAccount(e, true)}>
  							 <i class="fas fa-search"></i>
  			         <input onChange={this.searchAccount} value={this.state.currentSearch} placeholder="Search an account by email or domain" />
              </form>



               <div className="dropdown-date-filter pull-right dropdown filters search-actions-btn hidden-mobile">
                <Tooltip title={this.state.compressedView ? 'Switch to classic view' : 'Switch to condensed view'} placement="bottom">
    								<button className="btn dropdown-toggle btn btn-primary" onClick={() => this.toggleCompressedView()}>
    									<i class="fa-solid fa-list"></i>
    								</button>
                  </Tooltip>
                </div>


                <div className={"filters grid-view btn-batch-edit search-actions-btn hidden-mobile" + (this.state.compressedView ? "" : " dropdown-date-filter")}>

                {!this.state.compressedView &&
                    <Tooltip title="Bulk edit" placement="bottom">
      								<button className="btn dropdown-toggle btn btn-primary" onClick={() => this.toggleCompressedView()}>
      									 <i class="fa-solid fa-pen"></i>
      								</button>
                    </Tooltip>
                }

                  {this.state.compressedView &&
                    <Dropdown className="dropdown-date-filter pull-right last-days-filter">
                      <Tooltip title="Bulk edit" placement="top">
                        <Dropdown.Toggle className="btn" id="dropdown-basic">
                         <i class="fa-solid fa-pen"></i>
                        </Dropdown.Toggle>
                      </Tooltip>

                      {this.state.accountIdsToEdit.length > 0 &&
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => this.setState({openBatchAddTagModal: true})}>Add tag</Dropdown.Item>
                        </Dropdown.Menu>
                      }
                      {this.state.accountIdsToEdit.length == 0 &&
                         <Dropdown.Menu className="select-alert-batch-edit">
                          You need to select at least 1 account
                         </Dropdown.Menu>
                      }
                    </Dropdown>
                  }
                </div>


							 <div className="filters hidden-mobile">
								 <Dropdown className="dropdown-date-filter pull-right last-days-filter">
									 <Dropdown.Toggle className="btn" id="dropdown-basic">
										<i class="fas fa-sort-amount-down"></i> {indexTxt[this.state.index]}
									 </Dropdown.Toggle>

									 <Dropdown.Menu>
										 <Dropdown.Item onClick={() => this.setAccountsOrder('Account_score_desc')}>Highest score</Dropdown.Item>
										 <Dropdown.Item onClick={() => this.setAccountsOrder('Account_score_asc')}>Lowest score</Dropdown.Item>
										 <Dropdown.Item onClick={() => this.setAccountsOrder('Account_created_at_desc')}>Last connected</Dropdown.Item>
										 <Dropdown.Item onClick={() => this.setAccountsOrder('Account_created_at_asc')}>First connected</Dropdown.Item>
									 </Dropdown.Menu>
								 </Dropdown>
							 </div>

							 <div className="filters tags-filter hidden-mobile">
								 <Dropdown className="dropdown-date-filter pull-right last-days-filter">
									 <Dropdown.Toggle className="btn" id="dropdown-basic">
										 <i class="fa-solid fa-plus"></i> Filter
									 </Dropdown.Toggle>

									 <Dropdown.Menu>
											<Dropdown.Item onClick={() => this.setAccountsOrder(this.state.index, null, 'has_notifications_true')}>Technical issues</Dropdown.Item>
												{this.state.tags.map((tag, index) => {
													return (
													<Dropdown.Item onClick={() => this.setAccountsOrder(this.state.index, tag.name)}>{tag.name}</Dropdown.Item>
												)})}
									 </Dropdown.Menu>
								 </Dropdown>
							 </div>

							 <div className="criteria">
							 		<ul className="tags-list">
										{this.state.facetFilters.map((tag, index) => {
											return (
												<li className={'tag ' + tag}><i class="fa-solid fa-circle-exclamation"></i> Technical issues <span className="remove" onClick={() => this.setAccountsOrder(this.state.index, null, 'has_notifications_true')}><i class="fa-solid fa-xmark"></i></span></li>
										 )})}

										 {this.state.filterTags.map((tag, index) => {
											 return (
												<li className={'tag ' + this.getTagColor(tag)}><span>{tag}</span> <span className="remove" onClick={() => this.setAccountsOrder(this.state.index, tag)}><i class="fa-solid fa-xmark"></i></span></li>
											)})}
									</ul>
                  {this.state.tagsListLoader &&
                    <div className="loader">
                             <Loader
                              type="Oval"
                              color="#b7b7b7"
                              height="15"
                              width="15"
                             />
                    </div>
                  }
							 </div>
                           <div className="clearfix"></div>
						</div>
				}

				{(this.state.accounts.length == 0 && this.state.search) &&
					<div className="col-md-12">
						<div className="card text-center empty-results">
						  <img src="/images/empty.svg" />
							<div className="clearfix"></div>
							<h2>Oops, nothing found with your search</h2>
							<div className="clearfix"></div>
						</div>
					</div>
				}
<div className="clearfix"></div>

				<div className={this.state.compressedView ? "compressed-view" : ""}>
 <div className="clearfix"></div>
				{(this.state.compressedView && this.state.accounts.length > 0) &&
					<div className="col-md-12 table-head">
						<div className="card">
            <div className="selector">
              <input type="checkbox" onChange={(e) => this.selectAllAccountsToEdit(e)}/>
            </div>
							<div className="account">
								Email {this.state.accountIdsToEdit.length > 0 &&
                 <span className="edit-accounts-counter">
                  ({this.state.accountIdsToEdit.length} selected)
                 </span>

                }
							</div>
								{this.state.index == 'Account_score_asc' &&
									<div className="score" onClick={() => this.setAccountsOrder('Account_score_desc')}>
										Score
										<i class="fa-solid fa-sort"></i>
									</div>
								}
								{this.state.index != 'Account_score_asc' &&
									<div className="score" onClick={() => this.setAccountsOrder('Account_score_asc')}>
										Score
										<i class="fa-solid fa-sort"></i>
									</div>
								}
								{this.state.index == 'Account_alive_asc' &&
									<div className="status" onClick={() => this.setAccountsOrder('Account_alive_desc')}>
										Status
										<i class="fa-solid fa-sort"></i>
									</div>
								}
								{this.state.index != 'Account_alive_asc' &&
									<div className="status" onClick={() => this.setAccountsOrder('Account_alive_asc')}>
										Status
										<i class="fa-solid fa-sort"></i>
									</div>
								}
							<div className="tags">
							Tags
							</div>
						</div>
					</div>
				}

					{this.state.accounts.map((account, index) => {
						return (
								<div className="col-md-12" key={account.id}>
									<div className={"card" + (this.state.accountIdsToEdit.includes(account.id) ? ' selected' : '')}>

										{!this.state.compressedView &&
											<div className="status">
												{account.alive &&
													<Tooltip title='Running' placement="left">
														<span data-tip="Alive" className="stat alive"></span>
													</Tooltip>
												}
												{account.suspended &&
													<Tooltip title='Account suspended' placement="left">
														<span data-tip="Suspended" className="stat kok"></span>
													</Tooltip>
												}
												{(!account.alive && !account.suspended) &&
													<Tooltip title='Please connect this account again' placement="left">
														<span data-tip="Not alive" className="stat ko"></span>
													</Tooltip>
												}
											</div>
										}

											{this.state.compressedView &&
                        <>
                        <div className="selector" onClick={() => this.selectAccountsToEdit(account.id)}>
                          <input type="checkbox" checked={this.state.accountIdsToEdit.includes(account.id)}/>
                        </div>
                        <div className="account" >
  												<NavLink to={`/dashboard/${this.props.match.params.project_id}/accounts/${account.id}/show`} activeClassName="active">
  													{account.email}
  												</NavLink>
                        </div>
                        </>
											}

											{!this.state.compressedView &&
												<div className="account">
													{account.email}
												</div>
											}



									<div className={this.state.compressedView ? "score cursor-pointer" : ""} onClick={() => onClickHandler(`/dashboard/${this.props.match.params.project_id}/accounts/${account.id}/show`)}>
										{((account.score < 1) && this.state.compressedView) &&
											<Tooltip title='MailReach needs 72 hours of data' placement="left">
												<div className='pull-left sc'>
													<div className="deliverability-score average calculating">Waiting</div>
												</div>
											</Tooltip>
										}

										{ account.score > 0 &&
											 <div className='pull-left sc'>
											   {account.score <= 59 &&
                          <Tooltip title='Doing bad 🌡️' placement="left">
												    <div className="deliverability-score bad">{account.score}</div>
                          </Tooltip>
											   }
											   {(account.score >= 60 && account.score <= 89) &&
                          <Tooltip title='Not great 😐' placement="left">
												    <div className="deliverability-score average">{account.score}</div>
                          </Tooltip>
											   }
											  {(account.score > 89) &&
                          <Tooltip title='Doing good 🙌' placement="left">
												    <div className="deliverability-score good">{account.score}</div>
                          </Tooltip>
											  }
											</div>
										}
									</div>

										{!this.state.compressedView &&
											<div className="bot">
												{account.has_notifications &&
													<>
													  <Tooltip title='This email is facing one or several technical issues that can only be fixed on your end. Fix the issue(s) ASAP!' placement="right">
															<div>
																<NavLink to={`/dashboard/${this.props.match.params.project_id}/accounts/${account.id}/show`} activeClassName="active">
																	<i class="fa-solid fa-circle-exclamation"></i>
																</NavLink>
															</div>
														</Tooltip>
													</>
												}
											</div>
										}


										{this.state.compressedView &&
											<div className="status cursor-pointer" onClick={() => onClickHandler(`/dashboard/${this.props.match.params.project_id}/accounts/${account.id}/show`)}>
													{account.alive &&
                            <>
                              {account.has_notifications &&
                                <Tooltip title='This email is facing one or several technical issues that can only be fixed on your end. Fix the issue(s) ASAP!' placement="left">
                                  <div><span data-tip="Technical issue" className="stat kok"></span> Running</div>
                                </Tooltip>
                              }
                              {!account.has_notifications &&
                                <Tooltip title='Running' placement="left">
                                  <><span data-tip="Running" className="stat alive"></span> Running</>
                                </Tooltip>
                              }
                            </>
													}
													{account.suspended &&
														<Tooltip title='Account suspended' placement="left">
															<><span data-tip="Suspended" className="stat kok"></span> Suspended</>
														</Tooltip>
													}
													{(!account.alive && !account.suspended) &&
														<Tooltip title='Please connect this account again' placement="left">
															<><span data-tip="Not alive" className="stat ko"></span> Disconnected</>
														</Tooltip>
													}
											</div>
										}


											<div className="tags">

												{account.tags.length > 0 &&
													<div className="linked-tags">
															<ul className="list">
															{account.tags.map((tag, index) => {
																return (
																	<li className={'tag ' + tag.color}><span>{tag.name}</span> <span className="remove" onClick={() => this.removeTagFromAccount(account.id, tag.id)}><i class="fa-solid fa-xmark"></i></span></li>
																)})}
															</ul>
														</div>
													}
													{(this.state.accounts.length > 5) &&
														<div className="add">
														<Dropdown>
														 <Dropdown.Toggle variant="success" id="dropdown-basic">
															 <div className="app">
																 <i class="fa-solid fa-plus"></i> Add a tag
															 </div>
														 </Dropdown.Toggle>

														 <Dropdown.Menu className="dropdown-div-open">
																{this.state.tags.length == 0 &&
																	<div className="empty">
																		<img src="/images/empty.svg" /><br/>
																		<b>Add your first tag</b>
																	</div>
																}
																{this.state.tags.length > 0 &&
																	<ul className="list">
																		{this.state.tags.map((tag, index) => {
																			return (
																				<li className={'tag ' + tag.color}><span className="tag-name" onClick={() => this.addTagToAccount(account.id, tag)}>{tag.name}</span> <span className="open-edit-tag-modal" onClick={() => this.setState({openEditTagModal: true, editTag: tag})}><i class="fa-solid fa-ellipsis-vertical"></i></span><div className="clearfix"></div></li>
																			)})}
																	</ul>
																}
                                <div className="form">
                                  <form onSubmit={this.addTag}>
                                    <input placeholder="Tag name" name="tag_name" className="pull-left" value={this.state.newTagName} onChange={this.handleNewTagNameChange}/>
                                    <button name="Save" type="submit" className="btn btn-primary pull-left">Add</button>
                                  </form>
                                </div>
                              <div className="clearfix"></div>
												 </Dropdown.Menu>
												 </Dropdown>
												</div>
											}
											</div>

										{this.state.compressedView &&
											<NavLink to={`/dashboard/${this.props.match.params.project_id}/accounts/${account.id}/show`} target="_blank" className="pull-right open-link" activeClassName="active">
												<i class="fa-solid fa-arrow-up-right-from-square"></i>
											</NavLink>
										}

										{!this.state.compressedView &&
											<NavLink to={`/dashboard/${this.props.match.params.project_id}/accounts/${account.id}/show`} className="btn pull-right btn-secondary" activeClassName="active">
												Show
											</NavLink>
										}
										<div className="clearfix"></div>
									</div>
								</div>
							)
						})}

</div>
						{(!this.state.loadNextAccounts && this.state.accounts && !this.state.endAccountsList && !this.state.search) &&
							<div className="text-center load-next-tests">
							  <button className="load-next-tests-btn" onClick={() => this.nextPage(this.state.accountPage + 1)}>Load next accounts</button>
							</div>
						}

				</div>


        <Modal className="add-account-modal add-tag-modal" show={this.state.openBatchAddTagModal} onHide={() => this.setState({openBatchAddTagModal: false})}>
          <Modal.Body>
              <>
                <h1>Add a tag</h1>

                <div className="txt">

                  <div className="tags">


                  <div className="add">

                  <Dropdown>
                   <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn btn-secondary">
                     <div className="app">
                       <i class="fa-solid fa-plus"></i> Select a tag
                     </div>
                   </Dropdown.Toggle>

                   <Dropdown.Menu>
                      <div className="form">
                        <form onSubmit={this.addTag}>
                          <input placeholder="Tag name" name="tag_name" className="pull-left" value={this.state.newTagName} onChange={this.handleNewTagNameChange}/>
                          <button name="Save" type="submit" className="btn btn-primary pull-left">Create</button>
                        </form>
                      </div>

                      {this.state.tags.length == 0 &&
                        <div className="empty">
                          <img src="/images/empty.svg" />
                          <b>Add your first tag</b>
                        </div>
                      }
                      {this.state.tags.length > 0 &&
                        <ul className="list">
                          {this.state.tags.map((tag, index) => {
                            return (
                              <li className={'tag ' + tag.color}><span className="tag-name" onClick={() => this.addTagToBatchEdit(tag)}>{tag.name}</span><div className="clearfix"></div></li>
                            )})}
                        </ul>
                      }
               </Dropdown.Menu>
               </Dropdown>
               </div>

               {this.state.tagsToBatchAdd.length > 0 &&
                 <div className="linked-tags">
                     <ul className="list">
                     {this.state.tagsToBatchAdd.map((tag, index) => {
                       return (
                         <li className={'tag ' + tag.color}><span>{tag.name}</span> <span className="remove" onClick={() => this.addTagToBatchEdit(tag)}><i class="fa-solid fa-xmark"></i></span></li>
                       )})}
                     </ul>
                   </div>
                 }


                </div>
                </div>

                <div className="footer">
                <Button onClick={() => this.batchUpdateAccounts('ADD_TAGS')} className="primary btn" activeClassName="active">
                  Apply {this.state.tagsToBatchAdd.length} tag(s) to {this.state.accountIdsToEdit.length} selected accounts
                </Button>
                  <Button variant="secondary" onClick={() => this.setState({openBatchAddTagModal: false})}>
                    Close
                  </Button>
                </div>
              </>
          </Modal.Body>
        </Modal>



				<Modal className="add-account-modal edit-tag-modal" show={this.state.openEditTagModal} onHide={() => this.setState({openEditTagModal: false, editTag: {}})}>
					<Modal.Body>
							<>
								<h1>Edit tag</h1>

								<div className="txt imap">

									<form onSubmit={this.editTag} className="rows line">
										<div className="fieldCont col-md-9">
											<label>Rename</label>
											<input placeholder="Tag name" name="tag_name" className="pull-left" value={this.state.editTag.name} onChange={this.handleEditTagNameChange}/>
										</div>
										<div className="fieldCont col-md-3">
											<button name="Save" type="submit" className="btn btn-primary pull-left">Rename</button>
										</div>
										<div className="clearfix"></div>
									</form>

									<div className="col-md-12">
									<br /><br />
										<div className="col-md-9">
											<p>By deleting this tag, it will be removed from all the email accounts tagged with it.</p>
										</div>
										<div className="col-md-3 text-center">

										<Button className="btn purple red" variant="secondary" onClick={() => this.deleteTag(this.state.editTag.id)}>
											Delete
										</Button>

										<br /><br /><br />
										</div>
									</div>
								</div>

								<div className="footer">

									<Button variant="secondary" onClick={() => this.setState({openEditTagModal: false, editTag: {}})}>
										Close
									</Button>
								</div>
							</>
					</Modal.Body>
				</Modal>


				<Modal className={"add-account-modal " + (this.props.currentProject.config_feature_new_billing_seats ? 'new-seats' : '')} show={this.state.showAddAccountModal} onHide={this.handleCloseAddAccountModal}>
					<Modal.Body>
					{this.props.currentProject.free_billing &&
						<>
							<h1>You have a MailReach Enterprise plan.</h1>

							<div className="txt">
								<p>Ask the support if you need more information about your Enterprise plan.</p>
								<div className="line">
									Number of accounts : <div className="pull-right"><b>{(this.state.accounts.length+1)} accounts</b></div>
								</div>
							</div>

							<div className="footer">
								<NavLink to={`/dashboard/${this.props.match.params.project_id}/account/connect`} className="primary btn" activeClassName="active">
									Connect account
								</NavLink>
								<Button variant="secondary" onClick={this.handleCloseAddAccountModal}>
									Cancel
								</Button>
							</div>
						</>
					}

					{this.props.currentProject.config_feature_new_billing_seats &&
						<>

              {this.state.loadingModal &&
                <>
                <div className="loader text-center">
                  <Loader
                   type="Oval"
                   color="#b7b7b7"
                   height="100"
                   width="100"
                  />
                </div>
                </>
              }

              {!this.state.loadingModal &&
                <>
                <h1>You need at least one more seat</h1>

                <p className="usage-desc">You're currently using {this.props.currentProject.used_seats} seat(s) out of the {this.props.currentProject.seats} of your plan.
                Add at least one more seat to warm more mailboxes.</p>

                <div className="add-seats-slider">
                  <span className="pull-left desc-seat">Add {this.state.seatsAdded} email warming seat(s)</span>
                  <span className="pull-right">+${this.state.priceAdded} / month</span><br /><br />
                  <MailreachSlider
                  defaultValue={this.state.seatsAdded}
                  onChange={this.setSeats}
                  step={1}
                  min={1}
                  max={100}
                  color="secondary"
                  />
                </div>

                <p className="add-seats-desc">

                  What’s next for your billing :<br /><br />

                  1) You'll get a pro-rated charge today based on the number of days remaining in this billing cycle.<br />

                  2) Starting from your next billing cycle, your monthly payments will be ${this.state.totalPrice}/month. Additional taxes may apply depending on your country.<br /><br />
                </p>
  							<div className="footer">
                  <Button variant="secondary" onClick={this.handleCloseAddAccountModal}>
                    Cancel
                  </Button>
                  <Button onClick={this.buySeatsAndRedirect} className="primary btn" activeClassName="active">
                    Continue
                  </Button>
  							</div>
                </>
              }
						</>
					}

						{(!this.props.currentProject.free_billing && !this.props.currentProject.config_feature_new_billing_seats) &&
							<>
                {(this.state.accounts.length == 0) &&
                  <h1>Your subscription is about to start</h1>
                }
                {(this.state.accounts.length > 0) &&
								  <h1>Your monthly subscription will increase.</h1>
                }

								<div className="txt">
                  {(this.state.accounts.length == 0) &&
                  	<p className="text-left">By connecting your first email account, it will start your subscription.</p>
                  }
                  {(this.state.accounts.length > 0) &&
                    <p className="text-left">If you connect a new account, the amount of your monthly subscription will increase. </p>
                  }
									<h3 className="text-left">Your subscription if you connect an account</h3>
									<div className="line">
										Number of mailboxes (seats) : <div className="pull-right"><b>{(this.state.accounts.length+1)} seats</b></div>
									</div>
										{this.props.currentProject.stripe_subscription_id &&
																				<div className="line no-padding-left">
											  {(this.state.accounts.length+1) < 6 &&
													<>
											      Total : <div className="pull-right"><b>{((this.state.accounts.length+1)*this.props.currentPricing)}$</b> / month</div>
													</>
												}
												{((this.state.accounts.length+1) > 5 && (this.state.accounts.length+1) < 21)&&
													<>
														Total : <div className="pull-right"><b>${((this.state.accounts.length+1)*19.5)}</b> / month</div>
													</>
												}
												{((this.state.accounts.length+1) > 20 && (this.state.accounts.length+1) < 51)&&
													<>
														Total : <div className="pull-right"><b>${((this.state.accounts.length+1)*18)}</b> / month</div>
													</>
												}
												{((this.state.accounts.length+1) > 50)&&
													<>
														Total : <div className="pull-right"><b>${((this.state.accounts.length+1)*16)}</b> / month</div>
													</>
												}
											</div>
										}


                  {(this.state.accounts.length == 0) &&
                    <>
                      <div className="line no-padding-left">
                        Price : <div className="pull-right"><b>$25</b> / month</div>
                      </div>
                      <div className="line">
                        Total : <div className="pull-right"><b>$19.5 now</b></div>
                      </div>
                    </>
                  }

                  {(this.state.accounts.length == 0) &&
  									<p>
  										<br />
  									</p>
                  }

                  <p className="taxes">
                    Additional taxes may apply depending on your country
                  </p>
								</div>

								<div className="footer">
                  <Button variant="secondary" onClick={this.handleCloseAddAccountModal}>
                    Cancel
                  </Button>

									<NavLink to={`/dashboard/${this.props.match.params.project_id}/account/connect`} className="primary btn" activeClassName="active">
                    {(this.state.accounts.length == 0) &&
                      <>
                        I confirm that my subscription will start
                      </>
                    }
                    {(this.state.accounts.length > 0) &&
                      <>
										    Confirm
                      </>
                    }
									</NavLink>
								</div>
							</>
					  }

					</Modal.Body>
				</Modal>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		currentProject: state.currentProject,
		currentUser: state.currentUser,
		currentProject: state.currentProject,
		currentPricing: state.currentPricing,
		notifications: state.notifications
	};
}

function mapDispatchToProps(dispatch) {
	return {
		projectsActions: bindActionCreators(projectsActions, dispatch),
		currentProjectActions: bindActionCreators(currentProjectActions, dispatch),
		notificationsActions: bindActionCreators(notificationsActions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardHome));
