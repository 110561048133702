import StripeService from '../services/stripe.service';
import * as types from './actionTypes';

function currentPricingGetSuccess(currentPricing) {
	return {type: types.CURRENT_PRICING_GET_SUCCESS, currentPricing};
}

function loginError(errors) {
	return {type: types.LOG_IN_ERROR, errors};
}

export function getCurrent(stripePlanId) {
	return function (dispatch) {
		return StripeService.plan(stripePlanId).then(response => {
			dispatch(currentPricingGetSuccess((response.body.amount/100)));
		}).catch(error => {
			dispatch(loginError(['An error occured']))
			throw (error);
		});
	};
}
