import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function currentPricingReducer(state = initialState.currentPricing, action) {
	switch (action.type) {
		case types.CURRENT_PRICING_GET_SUCCESS:
			return action.currentPricing;
		default:
			return state;
	}
}
