import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import {ToastContainer, toast} from 'react-toastify';
import Loader from 'react-loader-spinner'
import { Button, Modal, Dropdown } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';
import { NavLink } from 'react-router-dom';
import Moment from 'react-moment';

import EmailWorkspaceService from '../../../../../../services/emailWorkspace.service';

import * as projectsActions from '../../../../../../actions/projects.actions';

class DashboardWorkspaceConnectV2 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {errors: [], workspaces: []};
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.convertJsonFileToString = this.convertJsonFileToString.bind(this);
    this.setJsonData = this.setJsonData.bind(this);
		this.redirectToConnectPage = this.redirectToConnectPage.bind(this);
		this.handleChangeName = this.handleChangeName.bind(this);
		this.reloadAllWorkspace = this.reloadAllWorkspace.bind(this);
	}

	componentWillMount() {

		let queryParams = queryString.parse(this.props.location.search);

		if(queryParams.spamchecker_only){
			this.setState({spamchecker_only: true});
		}

		if(queryParams.imap_only && queryParams.account_id){
			this.setState({imap_only: true, account_id: queryParams.account_id});
		}


		this.setState({loading: true});
		if(window.location.pathname.includes('/edit')){
			EmailWorkspaceService.get(this.props.match.params.workspace_id).then((response) => {
				this.setState({edit: true, loading: false, currentWorkspace: response.body});
			})
			.catch((error) => {
				console.log(error);
				this.setState({loading: false});
			});

		}else{
			// Load all workspaces
			this.reloadAllWorkspace();
		}
  }

	reloadAllWorkspace(){
		EmailWorkspaceService.all().then((response) => {
			this.setState({loading: false, workspaces: response.body});

			if(response.body.length == 1){
				// If there is only one workspace, set it as selected by default
				this.setState({selectedWorkspaceId: response.body[0].id})
			}
		})
		.catch((error) => {
			console.log(error);
			this.setState({loading: false});
		});
	}

  handleDrag(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      this.setState({dragActive: true});
    } else if (e.type === "dragleave") {
      this.setState({dragActive: false});
    }
  };


  setJsonData(content) {
    this.setState({file_key_json: JSON.parse(content)});
  }

  handleSubmit(){
    this.setState({loading: true});
    // create workspace and go to the next page
		if(this.state.edit){
			EmailWorkspaceService.edit(this.props.match.params.workspace_id, this.state.currentWorkspace.name, this.state.file_key_json).then((response) => {
				this.setState({loading: false});
				// Add notification and redirect user to add account
				this.props.history.push(`/dashboard/${this.props.currentProject.id}/account/workspaces/create`);
				toast.success('Workspace updated 🙌');
			})
			.catch((error) => {
				console.log(error);
	      this.setState({loading: false});
			});
		} else {
	    EmailWorkspaceService.create('Default workspace', this.state.file_key_json).then((response) => {
				this.setState({loading: false});
				// Add notification and redirect user to add account
				this.props.history.push(`/dashboard/${this.props.currentProject.id}/account/workspaces/${response.body.id}/connect${this.state.spamchecker_only ? '?spamchecker_only=true' : ''}${this.state.imap_only ? `?imap_only=true&account_id=${this.state.account_id}` : ''}`);
				toast.success('Workspace added 🙌');
			})
			.catch((error) => {
				console.log(error);
	      this.setState({loading: false});
			});
		}
  }

	redirectToConnectPage(){
		this.props.history.push(`/dashboard/${this.props.currentProject.id}/account/workspaces/${this.state.selectedWorkspaceId}/connect${this.state.spamchecker_only ? '?spamchecker_only=true' : ''}${this.state.imap_only ? `?imap_only=true&account_id=${this.state.account_id}` : ''}`);
	}

  handleChange(e) {
		this.setState({spreadsheet: e.target.files[0], currentSelectedFileName: e.target.files[0].name})
    this.convertJsonFileToString(e.target.files[0]);
  }

	handleChangeName(e){
		this.setState({currentWorkspace: {name: e.target.value}});
	}

  handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({dragActive: false});
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const fileInput = document.querySelector('#spreadsheet');
      fileInput.files = e.dataTransfer.files;
      this.setState({spreadsheet: fileInput.files[0], currentSelectedFileName: fileInput.files[0].name})
      this.convertJsonFileToString(fileInput.files[0]);
    }
  };

  triggerFileInputClick(){
    document.querySelector('#spreadsheet').click();
  }

  convertJsonFileToString(file){
    let that = this;
    let reader = new FileReader();
    reader.onload = function(e) {
      that.setJsonData(e.target.result);
    };
		if(file){
    	reader.readAsText(file);
		}
  }


	render() {


		if(this.state.loading){
			return(
				<div className="app container connect-v2 connect-workspace">
					<div className="row">

					<div className="progress-automated-test progress-automated-test-two">
						<ul>
							<li className="done"></li>
							<li className="selected"></li>
						</ul>
						<div className="bar"></div>
					</div>

					<div className="col-md-12 card text-center">
						<div className="loader">
							<Loader
							 type="Oval"
							 color="#b7b7b7"
							 height="100"
							 width="100"
							/>
						</div>
					</div>
</div>
					<style dangerouslySetInnerHTML={{
						__html: `
							footer, .sidebar {
								display: none;
							}
						`
					}} />
				</div>
			)
		}


		if(this.state.workspaces.length > 0){

			return (
				<div className="app container connect-v2 connect-workspace">
					<div className="row">

	        <div className="progress-automated-test progress-automated-test-two">
	          <ul>
	            <li className="selected"></li>
	            <li></li>
	          </ul>
	          <div className="bar"></div>
	        </div>

	        <div className="clearfix"></div>

					{this.state.imap_only &&
						<h2 className="text-center">Choose the workspace where the inbox to connect is located</h2>
					}

					{!this.state.imap_only &&
						<h2 className="text-center">Choose the workspace where the account(s) to connect are located</h2>
					}

	        <div>
	          <div className="col-md-12 card">
							<div className="col-md-12">
		           	<b className="title-workspace-linked">You have {this.state.workspaces.length} workspace(s) linked to MailReach.</b>
								<div className="clearfix"></div>
								<ul className="workspaces-list">
									{this.state.workspaces.map((workspace, index) => {
										return (
											<li className={(this.state.selectedWorkspaceId == workspace.id ? ' selected' : ' unselectedBtn')} onClick={() => this.setState({selectedWorkspaceId: workspace.id})}>
												<div className="line"><b>{workspace.name}</b> <NavLink className="edit-btn" to={`/dashboard/${this.props.match.params.project_id}/account/workspaces/${workspace.id}/edit`} activeClassName="active"><i class="fa-solid fa-pen"></i></NavLink></ div>
												<span className="createdAt"><Moment format="MMM DD, YYYY">{workspace.created_at}</Moment></span>
											</li>
										)
									})}

									<li className="add" onClick={() => this.setState({workspaces: [], linkNewWorkspace: true})}><b>Or link a new workspace</b></li>
								</ul>
							</div>
	        </div>
				</div>

				<div className="text-center automated-spamtest-btns">
					<NavLink to={`/dashboard/${this.props.match.params.project_id}/account/connect`} className="btn btn-primary grey" activeClassName="active">
						Cancel
					</NavLink>
				  <button className="btn btn-primary purple" disabled={!this.state.selectedWorkspaceId} onClick={this.redirectToConnectPage}>{this.state.edit ? 'Save >' :'Next >'}</button>
				</div>
	      </div>


					<ToastContainer />
				</div>
			);

		}
		return (
			<div className="app container connect-v2 connect-workspace">
				<div className="row">

        <div className="progress-automated-test progress-automated-test-two">
          <ul>
            <li className="selected"></li>
            <li></li>
          </ul>
          <div className="bar"></div>
        </div>

        <div className="clearfix"></div>
        <h2 className="text-center">Follow our step-by-step guide and upload your JSON file</h2>

        <div>
          <div className="col-md-12 card">

						{(this.state.edit && this.state.currentWorkspace) &&
							<input className="styled-input" value={this.state.currentWorkspace.name} onChange={this.handleChangeName}/>
						}
            <ul className="list-infos">
              <li>1. <a href="https://help.mailreach.co/en/article/how-to-connect-google-workspace-accounts-to-mailreach-using-domain-wide-delegation-1qw8qi4/" target="_blank">Open our guide</a> and follow all the steps</li>
              <li>2. Upload your JSON file by clicking on the dedicated zone below. (Our guide explains how to get it)</li>
            </ul>
            <div className="upload-block">
              <div className="col-md-6 first-col-upload" onClick={this.triggerFileInputClick}>
                <form onDragEnter={this.handleDrag}>
                  <input onChange={this.handleChange} type="file" className="hidden" id="spreadsheet" accept=".json,application/json"/>

                  <div className="file-upload-zone">
                    {!this.state.currentSelectedFileName &&
                      <p className="text-center"><i class="fas fa-cloud-upload"></i><br /> Drag & drop or <span className="choose-file">choose file</span> to upload</p>
                    }
                    {this.state.currentSelectedFileName &&
                      <>
                        <p className="text-center">
                          <i class="fas fa-cloud-upload"></i><br /> {this.state.currentSelectedFileName} <i onClick={this.reInitInputFile} class="fas fa-trash-alt"></i>
                        </p>
                      </>
                    }
                  </div>
                  { this.state.dragActive && <div className="drag-file-element" onDragEnter={this.handleDrag} onDragLeave={this.handleDrag} onDragOver={this.handleDrag} onDrop={this.handleDrop}></div> }
                </form>
              </div>
          </div>
        </div>
			</div>

			<div className="text-center automated-spamtest-btns">
				{this.state.linkNewWorkspace &&
					<button onClick={this.reloadAllWorkspace} className="btn btn-primary grey">
				    Back
				  </button>
				}
				{!this.state.linkNewWorkspace &&
				  <button onClick={() => { this.setState({currentStep: 2})}} className="btn btn-primary grey">
				    Cancel
				  </button>
				}
			  <button className="btn btn-primary purple" onClick={this.handleSubmit}>{this.state.edit ? 'Save >' :'Next >'}</button>
			</div>
      </div>


				<ToastContainer />
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		currentProject: state.currentProject,
		currentPricing: state.currentPricing
	};
}

function mapDispatchToProps(dispatch) {
	return {
		projectsActions: bindActionCreators(projectsActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardWorkspaceConnectV2));
