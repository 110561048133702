import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import { Button, Modal } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';

import InappMessageService from '../../services/inappMessage.service';


class GetProviderIco extends React.Component {

	constructor(props) {
		super(props);
		this.state = {smtp_server_icon: null};
	}


	componentWillReceiveProps(nextProps) {
    console.log(nextProps.smtp_server);

    let ico = null;
    let smtp_server_name = null;
    let provider = null;

		if(nextProps.smtp_server){
	    if(nextProps.smtp_server == 'smtp.gmail.com' || (nextProps.provider == 'gmail' && nextProps.smtp_server == '')){
	      ico = '/images/gmail_logo_m.png';
	      smtp_server_name = 'Gmail';
	      provider = 'Gmail';
	    }else if(nextProps.smtp_server == 'smtp.sendgrid.net'){
	      ico = '/images/logo_sendgrid.png';
	      smtp_server_name = 'SendGrid account';
	      provider = 'Sendgrid';
	    }else if(nextProps.provider == 'outlook' && nextProps.smtp_server == ''){
	      ico = '/images/logo_outlook.png';
	      smtp_server_name = 'Outlook';
	      provider = 'Outlook';
	    }else if(nextProps.smtp_server == 'smtp.mailgun.org' || nextProps.smtp_server == 'smtp.eu.mailgun.org'){
	      ico = '/images/logo_mailgun.png';
	      smtp_server_name = 'Mailgun account';
	      provider = 'Mailgun';
	    }else if(nextProps.smtp_server == 'smtp-relay.brevo.com'){
	      ico = '/images/logo_brevo.png';
	      smtp_server_name = 'Brevo';
	      provider = 'Brevo';
	    }else if(nextProps.smtp_server.includes('163.com')){
	     ico = '/images/logo_brevo.png';
	     smtp_server_name = '163.com';
	     provider = '163.com';
	   }else if(nextProps.smtp_server.includes('amazonaws.com')){
	     ico = '/images/logo_brevo.png';
	     smtp_server_name = 'AWS';
	     provider = 'AWS';
	   }else if(nextProps.smtp_server.includes('sparkpostmail.com')){
	     ico = '/images/logo_brevo.png';
	     smtp_server_name = 'SparkPost';
	     provider = 'SparkPost';
	   }else if(nextProps.smtp_server.includes('hostinger.com')){
	     ico = '/images/logo_brevo.png';
	     smtp_server_name = 'Hostinger';
	     provider = 'Hostinger';
	   }else if(nextProps.smtp_server.includes('hostgator.com')){
	     ico = '/images/logo_brevo.png';
	     smtp_server_name = 'Hostgator';
	     provider = 'Hostinger';
	   }else if(nextProps.smtp_server.includes('mailjet.com')){
	     ico = '/images/logo_brevo.png';
	     smtp_server_name = 'Mailjet';
	     provider = 'Mailjet';
	   }else if(nextProps.smtp_server.includes('privateemail.com')){
	     ico = '/images/logo_brevo.png';
	     smtp_server_name = 'Private Email';
	     provider = 'Private Email';
	   }else if(nextProps.smtp_server.includes('privateemail.com')){
	     ico = '/images/logo_brevo.png';
	     smtp_server_name = 'Private Email';
	     provider = 'Private Email';
	   }else if(nextProps.smtp_server.includes('gandi.net')){
	     ico = '/images/logo_brevo.png';
	     smtp_server_name = 'Gandi';
	     provider = 'Gandi';
	   }else if(nextProps.smtp_server.includes('ovh.net')){
	     ico = '/images/logo_brevo.png';
	     smtp_server_name = 'OVH';
	     provider = 'OVH';
	    }else if(nextProps.smtp_server == 'smtppro.zoho.eu' || nextProps.smtp_server == 'smtp.zoho.eu' || nextProps.smtp_server == 'smtppro.zoho.com'){
	      ico = '/images/logo_brevo.png';
	      smtp_server_name = 'Zoho';
	      provider = 'Zoho';
	    }else {
	      ico = '/images/logo_brevo.png';
	      smtp_server_name = 'Custom SMTP';
	      provider = 'Custom';
	    }
		}else{
			if(nextProps.provider == 'gmail'){
		 	 ico = '/images/gmail_logo_m.png';
		 	 smtp_server_name = 'Gmail';
		 	 provider = 'Gmail';
		 }else if(nextProps.provider == 'outlook'){
			 ico = '/images/logo_outlook.png';
			 smtp_server_name = 'Outlook';
			 provider = 'Outlook';
		  }
		}

    this.setState({smtp_server_icon: ico, smtp_server_name: smtp_server_name, provider: provider})
	}

	render() {
    if(this.state.smtp_server_icon){
  		return (
        <Tooltip title={'This address is connected to MailReach as a ' + this.state.smtp_server_name} placement="bottom">
          <div className="badge-provider">{this.state.provider}</div>
        </Tooltip>
  		)
    } else {
      return ('');
    }
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GetProviderIco));
