import React, { Suspense } from 'react';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import {ToastContainer, toast} from 'react-toastify';
import SessionService from '../../services/session.service';
import './signup.scss';
import 'react-toastify/dist/ReactToastify.css';
import * as Sentry from "@sentry/react";

import i18n from "../../i18n";

class Signup extends React.Component {
	constructor(props) {
		super(props);
		this.state = {credentials: {email: '', password: ''}, passwordShort: false, errors: [], isInvite: false, organization: {}, currentToken: null, buttonDisabled: false };
		this.onChange = this.onChange.bind(this);
		this.onSave = this.onSave.bind(this);
	}

	onChange(event) {
		const field = event.target.name;
		if(field === 'password'){
			if(event.target.value.length < 6){
				this.setState({passwordShort: true});
			}else{
				this.setState({passwordShort: false});
			}
		}
		const credentials = this.state.credentials;

		if(event.target.type == 'checkbox'){
			credentials[field] = event.target.checked;
		}else{
			credentials[field] = event.target.value;
		}
		return this.setState({credentials});
	}

	onSave(event) {
		event.preventDefault();
		this.setState({buttonDisabled: true});
		let c = this;
		if(!this.state.credentials.tos){
			toast.error("You need to agree to MailReach's terms of use and privacy policy");
			this.setState({buttonDisabled: false});
			return;
		}
		SessionService.emailSignup(this.state.credentials).then((response) => {
			localStorage.setItem('jwt', response.body.token);
			window.$crisp.push(["set", "user:email", this.state.credentials.email]);
			window.$crisp.push(["set", "session:segments", [["signup"]]]);
			window.$crisp.push(["set", "session:event", [[["signup"]]]])
			window.gtag("event", "user", {
							event_category: "user",
							event_label: "signup"
					});
      window.gtag('event', 'conversion', {'send_to': 'AW-444511731/wCzzCLOO4_QCEPPr-tMB'});

			window.$FPROM.trackSignup({
        email: this.state.credentials.email,
      });

			// BING Tracking ADS
			window.uetq = window.uetq || [];
			window.uetq.push('event', 'Signup', {});


			if(this.state.queryParams.spamCheckerPlan){
				this.props.history.push(`/redirect?spamCheckerPlan=${this.state.queryParams.spamCheckerPlan}`);
			}else{
				this.props.history.push('/redirect'  + this.props.location.search);
			}
			this.setState({buttonDisabled: false});
		})
  	.catch((error) => {
			toast.error('Ensure email is correct and password confirmation match Password and try to refresh the window (Press F5)');
			this.setState({buttonDisabled: false});
			Sentry.captureException(error);
		});
	}

	componentWillMount(){
		let queryParams = queryString.parse(this.props.location.search);
		if(localStorage.getItem('jwt')){
			if(queryParams.spamCheckerPlan){
				this.props.history.push(`/redirect?spamCheckerPlan=${queryParams.spamCheckerPlan}`);
			}else{
				this.props.history.push('/redirect' + this.props.location.search);
			}
		}

		this.setState({queryParams});
	}

	componentWillReceiveProps(nextProps) {
		const errors = nextProps.errors;
		if (errors && errors != this.props.errors) {
			toast.error('Oops');
		}
	}

	render() {
		return (
			<div>
				<div className="sign signup col-md-5">
					<div className="ct">
						<div className="market-container text-center">
							<img src="/images/logo_b.png" />
							<h1>{i18n.t('signup.head')}</h1>
						</div>
						{this.state.isInvite &&
							<div className="invite-b text-center">
								<img src={this.state.organization.logoReadable} />
								<p>{i18n.t('signup.invited')} <b>{this.state.organization.name}</b> {i18n.t('signup.on')}</p>
							</div>
						}
						{/*<form id="google_sign_in" action="/auth/google/sign_in" accept-charset="UTF-8" method="post">
							<input name="utf8" type="hidden" value="✓" />
						  <button type="submit">
						    Your Google account
						  </button>
						</form>*/}
						<form class="new_user" id="new_user">
						  {/*<div class="authSeparator">
								<hr />
						    <span>OR</span>
						  </div>*/}

						  <fieldset>
							<div class="fieldCont">
								<input value={this.state.credentials.first_name} className={this.state.credentials.first_name ? 'filled' : ''} required="required" type="text" name="first_name" id="user_first_name" onChange={this.onChange}/>
								<label class="over">{i18n.t('input.first_name')}</label>
							</div>
						    <div class="fieldCont">
						      <input value={this.state.credentials.email} className={this.state.credentials.email ? 'filled' : ''} required="required" type="email" name="email" id="user_email" onChange={this.onChange}/>
						      <label class="over">{i18n.t('input.email')}</label>
						    </div>
								<div class="fieldCont">
									<input value={this.state.credentials.password} className={this.state.credentials.password ? 'filled' : ''} autoComplete="off" required="required" type="password" name="password" id="user_password" onChange={this.onChange}/>
									<label class="over">{i18n.t('input.password')}</label>
								</div>

						    <div class="fieldCont last">
						      <input value={this.state.credentials.password_confirmation} className={this.state.credentials.password_confirmation ? 'filled' : ''} autoComplete="off" required="required" type="password" name="password_confirmation" id="user_password" onChange={this.onChange}/>
						      <label class="over">{i18n.t('input.passwordConfirmation')}</label>
						    </div>
						  </fieldset>
							{this.state.passwordShort &&
								<div className="red-alert">
									Password : 8 characters minimum
								</div>
							}
              <div class="formActions tosTxt">
								<label className="pointer">
									<input autoComplete="off" required="required" type="checkbox" name="tos" id="user_tos" onChange={this.onChange} />
								 &nbsp; I agree to MailReach's <a href="https://www.mailreach.co/terms-of-use/">terms of service</a> and <a href="https://www.mailreach.co/privacy-policy/">privacy policy</a>.
								</label>
								 <br /><br />
							</div>

						  <div class="formActions">
						    <div class="buttonCont">
						      <input class="submit purple-btn" type="submit" name="commit" value={i18n.t('signup.button')} onClick={this.onSave} disabled={this.state.buttonDisabled}/>
						    </div>
						  </div>
						</form>
						<div class="otherLinks">
							{i18n.t('signup.alreadyDesc')}
							<Link to={'/signin' + this.props.location.search}>{i18n.t('signin.button')}</Link>
						  {/*
								<a href="/amnesia">Forgot your password?</a>
							*/}
						</div>
						<ToastContainer />
					</div>
				</div>
				<div className="col-md-7 teaser">
					<img src="/images/pattern_bg.svg" loading="lazy" class="cta-pattern-bg" />
					<div className="col-md-12">

						<div className="col-md-5 review-card margin-left">
							<div className="star-icon-wrap">
								<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
								<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
								<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
								<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
								<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
							</div>
							<p class="paragraph-18px">“We’ve tested many solutions and MailReach is definitely the <strong>most efficient email deliverability tool.</strong> MailReach helps us raise and maintain the deliverability of 20 email accounts. <strong>A great deliverability booster.”</strong></p>
							<div class="author-contain">
								<img src="/images/jy.png" loading="lazy" alt="" class="author-img-small" />
								<div>
									<div class="author-name">Jean-Yves Delmotte</div>
									<div class="author-position">Co-Founder @ PaletteHQ</div>
								</div>
							</div>
						</div>

						<div className="col-md-5 review-card hidden-mobile">
							<div className="star-icon-wrap">
								<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
								<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
								<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
								<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
								<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
							</div>
							<p class="paragraph-18px">“MailReach is the most <strong>effective and user friendly cold email deliverability tool.</strong> Within a few weeks, we’ve seen great improvements on our outreach campaigns.”</p>
							<div class="author-contain">
								<img src="/images/marion.png" loading="lazy" alt="" class="author-img-small" />
								<div>
									<div class="author-name">Marion Gurtekin</div>
									<div class="author-position">Head of Sales @ Shine</div>
								</div>
							</div>
						</div>
					</div>

					<div className="col-md-12 hidden-light hidden-mobile">
						<div className="col-md-5 review-card margin-left">
							<div className="star-icon-wrap">
								<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
								<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
								<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
								<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
								<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
							</div>
							<p class="paragraph-18px">“That’s simple, MailReach has helped us <strong>multiply our cold emails open rates x2.</strong> It has became <strong>our life insurance.</strong> Their deliverability guide is a gold mine too.”</p>
							<div class="author-contain">
								<img src="/images/bastien.png" loading="lazy" alt="" class="author-img-small" />
								<div>
									<div class="author-name">Bastien Paul</div>
									<div class="author-position">Growth @ BigBlue</div>
								</div>
							</div>
						</div>

						<div className="col-md-5 review-card">
							<div className="star-icon-wrap">
								<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
								<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
								<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
								<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
								<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
							</div>
							<p class="paragraph-18px">“As a cold email expert, I always recommend using MailReach to maximize the reputation of an email address / domain. MailReach combines complex algorithms and email best practices to offer <strong>a very efficient deliverability solution.”</strong></p>
							<div class="author-contain">
								<img src="/images/anas.png" loading="lazy" alt="" class="author-img-small" />
								<div>
									<div class="author-name">Anas El-Mhamdi</div>
									<div class="author-position">Growth Engineer @ Gorgias</div>
								</div>
							</div>
						</div>

					</div>

						<div className="text-center">
						<div className="col-md-11 review-all text-center">
							<div className="review-hero-content">


								<div className="review-hero-item col-md-4">
									<div className="star-icon-wrap">
										<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
										<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
										<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
										<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
										<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
									</div>
									<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/667430364f853b83702fac8a_Capterra%20Logo%20Image.svg" loading="lazy" alt="" class="review-hero-item-image" />
									<div className="review-hero-item-text">5/5 on Capterra</div>
								</div>

								<div className="review-hero-item col-md-4 hidden-mobile">
									<div className="star-icon-wrap">
										<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
										<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
										<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
										<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
										<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
									</div>
									<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/66743036d8a496da50bd3727_SaaSHub%20Logo%20Image.svg" loading="lazy" alt="" class="review-hero-item-image" />
									<div className="review-hero-item-text">5/5 on SaaSHub</div>
								</div>

								<div className="review-hero-item col-md-4 hidden-mobile">
									<div className="star-icon-wrap">
										<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
										<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
										<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
										<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
										<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfe7c1b75517f1beaaac_Star.svg" loading="lazy" alt="" class="star-icon" />
									</div>
									<img src="https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/66743037734084ff623315b1_G2%20Logo%20Image.svg" loading="lazy" alt="" class="review-hero-item-image" />
									<div className="review-hero-item-text">4.6/5 on G2</div>
								</div>


							</div>
						</div>
						</div>


				</div>


				<div className="clearfix"></div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Signup));
