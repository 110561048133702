import React, { Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import {BrowserRouter, Route, NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {ToastContainer, toast} from 'react-toastify';

import i18n from "../../../i18n";
import './welcome.css';

import * as projectsActions from '../../../actions/projects.actions';
import * as currentProjectActions from '../../../actions/currentProject.actions';

class DashboardWelcome extends React.Component {
	constructor(props) {
		super(props);
		this.state = {errors: []};
	}

	componentWillMount() {
	}

	componentWillReceiveProps(nextProps) {
	}

	render() {
		return (
			<div className="app container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 welcome">
            <div className="card text-center">
              <div className="beer">
              	<img src="/images/beer.svg" />
              </div>

              <h2>Thank you {this.props.currentUser.first_name} !</h2>
              <br />
              <p>
							Now, to start warming, you need to fill in your payment information first.<br /><br />

							{this.props.currentProject.config_feature_new_billing_seats &&
								<>
									We're currently offering a 20% discount for the first month of each email account warmed with MailReach.
								</>
							}

							{!this.props.currentProject.config_feature_new_billing_seats &&
								<>
									 We're currently offering a 20% discount for the first month of each email account warmed with MailReach.
								</>
							}

							That's only $19.5 per email account warmed for one month.<br /><br />

							If you warm 6 email accounts or more, the price per seat decreases to help you scale. For more information, see our pricing page.<br /><br />

              There's no engagement, you're free to cancel whenever you want.<br /><br />

							Let's start raising your deliverability and open rates 🚀<br /><br />
              </p>

              <div className="text-center">
                <NavLink to={this.props.currentProject.config_feature_stripe_payment_link_checkout_enabled ? `/dashboard/${this.props.match.params.project_id}/settings/checkout/plans` : `/dashboard/${this.props.match.params.project_id}/settings/billing?hide_signout=true`} className="primary btn" activeClassName="active">
									{this.props.currentProject.config_feature_stripe_payment_link_checkout_enabled ? `Choose my plan` : `Add payment method`}
                </NavLink>
              </div>
              </div>
          </div>
        </div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		projects: state.projects,
		currentUser: state.currentUser,
		currentProject: state.currentProject,
		currentPricing: state.currentPricing,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		projectsActions: bindActionCreators(projectsActions, dispatch),
		currentProjectActions: bindActionCreators(currentProjectActions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardWelcome));
