const request = require('superagent');

// Todo, rename as test folder
class TaskService {

	static get(id) {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}tasks/${id}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}
}

export default TaskService;
