import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {ToastContainer, toast} from 'react-toastify';
import Loader from 'react-loader-spinner';
import Tooltip from '@material-ui/core/Tooltip';

import i18n from "../../../../i18n";
import AccountService from '../../../../services/account.service';
import GetProviderIco from '../../../../components/get-provider-ico/get-provider-ico.js';


class DashboardAccountsBase extends React.Component {
	constructor(props) {
		super(props);
    this.state = {errors: [], currentAccount: {}};
		this.loadAccount = this.loadAccount.bind(this);
	}

	componentWillMount() {
    this.loadAccount();
	}

	loadAccount(){
		AccountService.get(this.props.match.params.account_id).then(accountResponse => {
			this.setState({currentAccount: accountResponse.body});
		}).catch((error) => {
		 this.setState({error: true,});
		 toast.error('Oops, we have an error');
	 });
	}


	componentDidUpdate(prevProps) {
		if (this.props.match.params.account_id !== prevProps.match.params.account_id) {
		  this.loadAccount();
		}
	}


	render() {
		return (
			<div className="sub-nav">
        <div className="ct">
          <div className="account-name pull-left">
						<div className="status">
							{this.state.currentAccount.alive &&
								<Tooltip title='Alive' placement="left">
									<span data-tip="Alive" className="stat alive"></span>
								</Tooltip>
							}
							{this.state.currentAccount.suspended &&
								<Tooltip title='Account suspended' placement="left">
									<span data-tip="Suspended" className="stat kok"></span>
								</Tooltip>
							}
							{(!this.state.currentAccount.alive && !this.state.currentAccount.suspended) &&
								<Tooltip title='Please connect this account again' placement="left">
									<span data-tip="Not alive" className="stat ko"></span>
								</Tooltip>
							}
						</div>
            {this.state.currentAccount.email}


						<GetProviderIco smtp_server={this.state.currentAccount.smtp_server} provider={this.state.currentAccount.provider}/>
          </div>
          <ul className="pull-right">
            <li>
              <NavLink to={`/dashboard/${this.props.match.params.project_id}/accounts/${this.props.match.params.account_id}/show`} className="" activeClassName="active">
                <i class="fas fa-chart-line"></i> Stats
              </NavLink>
							<NavLink to={`/dashboard/${this.props.match.params.project_id}/accounts/${this.props.match.params.account_id}/checkups`} className="" activeClassName="active">
								<i class="fas fa-stethoscope"></i> Checks
							</NavLink>
              <NavLink to={`/dashboard/${this.props.match.params.project_id}/accounts/${this.props.match.params.account_id}/settings`} className="" activeClassName="active">
                <i class="fas fa-cog"></i> Settings
              </NavLink>
            </li>
          </ul>
          <div className="clearfix"></div>
        </div>
      </div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		currentProject: state.currentProject,
		currentPricing: state.currentPricing
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardAccountsBase));
