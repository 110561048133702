import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function currentUserTypesReducer(state = initialState.currentUser, action) {
	switch (action.type) {
		case types.CURRENT_USER_UPDATE_SUCCESS:
			return action.currentUser;
		case types.CURRENT_USER_GET_SUCCESS:
			return action.currentUser;
		default:
			return state;
	}
}
