import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import { Button, Modal } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';
import Moment from 'react-moment';
import { NavLink } from 'react-router-dom';
import Loader from 'react-loader-spinner';

class NewTestsList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {messageContentPreview: 'content_html', showTemplateModal: {conversation: {}, first_message_content: {}}};
	}


	componentWillReceiveProps(nextProps) {
	}

  getScoreClass(score){
    if(score < 5){
      return 'bad';
    } else if (score < 7.5) {
      return 'average'
    }else {
      return 'good'
    }
  }

	render() {
		if(this.props.tests == null){
			return(
				<div className="card text-center">
					<div className="loader">
						<Loader
						 type="Oval"
						 color="#b7b7b7"
						 height="100"
						 width="100"
						/>
					</div>
				</div>
			)
		}
		return (
      <>
      {this.props.tests.map((test, index) => {
				if(!test.first_message_content){
					return('');
				}
        return (
          <div className="card col-md-12 spam-result-item">
          <div className="status">
            {test.completed &&
              <Tooltip title='Test completed, score calculation completed' placement="left">
                <span data-tip="Alive" className="stat alive"></span>
              </Tooltip>
            }
            {(!test.completed) &&
              <Tooltip title='A test is currently running' placement="left">
                <span data-tip="Not alive" className="stat running"></span>
              </Tooltip>
            }
          </div>
					<Tooltip title='Click to preview' placement="bottom">
					<div className="account col-md-4 tit" onClick={() => this.setState({openShowTemplateModal:true, showTemplateModal: test})}>
						  <span className="subject">
								{test.automated &&
									<span className="auto">
										auto
									</span>
								}
								{test.first_message_content.subject ? test.first_message_content.subject.slice(0, 42) : ''}
							</span>
						  <p className="preview-hide">{test.first_message_content.content_text.slice(0, 40)} ...</p>
							{test.first_message_content &&
								<div className="test-from-email">
									{test.first_message_content.from_email}
								</div>
							}
					</div>
					</Tooltip>
          <div className="last-test-result col-md-2 text-center">
           <NavLink to={`/dashboard/${this.props.match.params.project_id}/spam-tests/results/${test.public_id}`}>
             {(test.completed && test.final_score != null) &&
                <Tooltip title="This score is 100% based on where your emails land as it's the best representation of your test email's deliverability. The formula is : [number of emails landed in inbox] / [number of email sent] x 10." placement="bottom">
                  <div className={'score ' + this.getScoreClass(test.final_score)}>
                    {test.final_score}/10
                  </div>
                </Tooltip>
              }
              {!test.completed &&
								<Tooltip title='We wait up to 6 minutes for the mailboxes to receive your email before displaying the score 🔍.' placement="bottom">
                  <div className="score running">
                    Searching...
                  </div>
								</Tooltip>
               }
            </NavLink>
            <div className="timeago hidden-mobile">
              <Moment fromNow>{test.created_at}</Moment>
            </div>
          </div>
          {test.summary &&
						<>
							<NavLink to={`/dashboard/${this.props.match.params.project_id}/spam-tests/results/${test.public_id}`}>
		            <div className="folder col-md-4 text-center hidden-mobile" onClick={() => this.setState({testIdToOpen: test.id})}>
		              {test.summary.INBOX &&
		                <div className="increase score inbox-badge-color">
		                  {test.summary.INBOX} Inbox
		                </div>
		              }
		              {test.summary.SPAM &&
		                <div className="increase score spam-badge-color">
		                  {test.summary.SPAM} Spam
		                </div>
		              }
		              {(test.summary.PROMOTIONS || test.summary.PERSONAL || test.summary.UPDATES) > 0 &&
		                <div className="increase score categorie-badge-color">
		                  {((test.summary.PROMOTIONS || 0) + (test.summary.PERSONAL || 0) + (test.summary.UPDATES || 0))} Categories
		                </div>
		              }
		              {test.summary[''] &&
		                <div className="increase score missing-badge-color">
		                  {test.summary['']} Missing
		                </div>
		              }
									<Tooltip title={'This test has been performed on  Seed list ' + test.seedlist} placement="bottom">
										<div className="used-seedlist">SL {test.seedlist}</div>
									</Tooltip>
		            </div>
							</NavLink>
						</>
          }

					<div className="pull-right hidden-mobile">
						<NavLink to={`/dashboard/${this.props.match.params.project_id}/spam-tests/results/${test.public_id}`} className="btn btn-secondary pull-right">
							Results
						</NavLink>
					</div>
					<div className="clearfix"></div>
          </div>
        )})}

				<Modal className="spam-results-modal message-design" show={this.state.openShowTemplateModal} onHide={() => this.setState({openShowTemplateModal: false})}>
					<Modal.Body>
						<>
							<h1 className="text-center">{this.state.showTemplateModal.first_message_content.subject}</h1>
								<div className='from'>
								  <b>{this.state.showTemplateModal.first_message_content.from_name}</b> {'<' + this.state.showTemplateModal.first_message_content.from_email + '>'}
									<ul className='format-selector pull-right'>
										<li className={"btn btn-secondary" + (this.state.messageContentPreview == 'content_html' ? ' selected' : '')} onClick={() => this.setState({messageContentPreview: 'content_html'})}>HTML</li>
										<li className={"btn btn-secondary" + (this.state.messageContentPreview == 'content_text' ? ' selected' : '')} onClick={() => this.setState({messageContentPreview: 'content_text'})}>TEXT</li>
									</ul>
								</div>
							<div className="txt">
							<div
	dangerouslySetInnerHTML={{__html: this.state.showTemplateModal.first_message_content[this.state.messageContentPreview]}}
/>
							</div>
							<div className="footer text-center">
								<button type="button" className="btn btn-secondary" onClick={() => this.setState({openShowTemplateModal: false})}>Close</button>
							</div>
						</>
					</Modal.Body>
				</Modal>
      </>
		)
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewTestsList));
