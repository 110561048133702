import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import {toast} from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';

import i18n from "../../../i18n";

import ProjectService from '../../../services/project.service';
import AlertService from '../../../services/alert.service';
import SlackChannelService from '../../../services/slackChannel.service';
  import AutomatedTestService from '../../../services/automatedTest.service';

import './settings.css';

const GreenSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#00d463',
    },
    '&$checked + $track': {
      backgroundColor: '#00d463',
    },
  },
  checked: {},
  track: {},
})(Switch);

class DashboardSettingsAlerts extends React.Component {
	constructor(props) {
		super(props);
		this.state = {errors: [], currentProject: {}, alerts: [], slackChannels: [], automatedTests: [], alert: { events: [], channel: 'ALL', active: true}}
		this.onChange = this.onChange.bind(this);
		this.addEventToAlert = this.addEventToAlert.bind(this);
		this.saveAlert = this.saveAlert.bind(this);
		this.toggleAlertActive = this.toggleAlertActive.bind(this);
    this.delete = this.delete.bind(this);
    this.addAutomatedTestAlert = this.addAutomatedTestAlert.bind(this);
	}

	componentWillMount() {

    let queryParams = queryString.parse(this.props.location.search);
    let error = queryParams.error;
    if(error){
      toast.error(error);
    }

    if(queryParams.success){
      toast.success(queryParams.success);
    }

    AutomatedTestService.all().then((response) => {
      this.setState({automatedTests: response.body})

    })
    .catch((error) => {
      this.setState({error: true, loading: false});
      toast.error('Oops. An error occured.');
    });

    SlackChannelService.all().then((response) => {
      this.setState({slackChannels: response.body})
    })
    .catch((error) => {
      this.setState({error: true,loading: false});
      toast.error('Oops. An error occured.');
    });

    ProjectService.get(this.props.match.params.project_id).then((response) => {
      this.setState({project: response.body});
      localStorage.setItem('x-api-key', response.body.api_key);
    })
    .catch((error) => {
      this.setState({error: true,});
      toast.error('Oops, we have an error');
    });

		AlertService.get().then((response) => {
			this.setState({alerts: response.body});
		})
		.catch((error) => {
			this.setState({error: true,});
			toast.error('Oops, we have an error');
		});
	}

	saveAlert(e){
		e.preventDefault();
		if(this.state.alert.id){
			// Edit

			AlertService.update(this.state.alert).then((response) => {
				this.setState({openAlerteModal: false, alert: { events: [], channel: 'ALL', active: true}});
				toast.success('Alert created');
			})
			.catch((error) => {
				this.setState({error: true,});
				toast.error('Oops, we have an error');
			});

		}else{
			// Create a new alert
			AlertService.create(this.state.alert).then((response) => {
				AlertService.get().then((response) => {
					this.setState({alerts: response.body});
					this.setState({openAlerteModal: false, alert: { events: [], channel: 'SLACK', active: true}});
					toast.success('Alert created');
				})
				.catch((error) => {
					this.setState({error: true,});
					toast.error('Oops, we have an error');
				});
	    })
	    .catch((error) => {
	      this.setState({error: true,});
	      toast.error('Oops, we have an error');
	    });
		}
	}

  delete(id){
    AlertService.delete(id).then((response) => {
      AlertService.get().then((response) => {
        this.setState({alerts: response.body});
        this.setState({openDeleteAlerteModal: false, alert: { events: [], channel: 'SLACK', active: true}});
        toast.success('Alert deleted');
      })
      .catch((error) => {
        this.setState({error: true,});
        toast.error('Oops, we have an error');
      });
    })
    .catch((error) => {
      this.setState({error: true,});
      toast.error('Oops, we have an error');
    });
  }

	onChange(event) {
		const field = event.target.name;
		const alert = this.state.alert;
		const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		alert[field] = value;
		return this.setState({alert});
	}

	addEventToAlert(e){
		let alert = this.state.alert;
		if(e.target.checked){
				alert.events.push(e.target.name);
		}else{
			alert.events = alert.events.filter(event => event !== e.target.name);
		}
		this.setState({alert});
	}

  addAutomatedTestAlert(e){
    let alert = this.state.alert;
    if(e.target.checked){
        alert.automated_test_ids.push(parseInt(e.target.name));
    }else{
      alert.automated_test_ids = alert.automated_test_ids.filter(event => event !== parseInt(e.target.name));
    }
    console.log(alert.automated_test_ids);
    this.setState({alert});
  }

	toggleAlertActive(alertId){

		let alert = this.state.alerts.find((element) => {
      return element.id === alertId;
    })

		alert.active = !alert.active;

		AlertService.update(alert).then((response) => {
			let objectToReplace = this.state.alerts.map(obj => obj.id === alertId.id ? response.body : obj);
			this.setState({alerts: objectToReplace});
			toast.success('Alert update');
		})
		.catch((error) => {
			this.setState({error: true,});
			toast.error('Oops, we have an error');
		});
	}


	render() {

		return (
			<div className="app container add-credit-card team alerts-page">
        <div className="col-md-3">
					<div className="title">
						<h1>Alerts <span className="beta">Beta</span></h1>
					</div>
          <div className="card">
            <ul className="nav-left">
              <li>
                <NavLink to={`/dashboard/${this.props.currentProject.id}/settings/billing`} activeClassName="active">
                  <i class="far fa-credit-card"></i> Billing
                </NavLink>
							</li>
              <li>
                <NavLink to={`/dashboard/${this.props.currentProject.id}/settings/api`} activeClassName="active">
                  <i class="fas fa-key"></i> API
                </NavLink>
              </li>
							<li>
								<NavLink to={`/dashboard/${this.props.currentProject.id}/settings/team`} activeClassName="active">
									<i class="fa-solid fa-people-group"></i> Team
								</NavLink>
							</li>
							<li>
								<NavLink to={`/dashboard/${this.props.currentProject.id}/settings/alerts`} activeClassName="active">
									<i class="fa-sharp fa-solid fa-bell"></i> Alerts
								</NavLink>
							</li>
							<li>
								<NavLink to={`/dashboard/${this.props.currentProject.id}/settings/password`} activeClassName="active">
									<i class="fa-solid fa-lock"></i> Change password
								</NavLink>
							</li>
								<li>
									<a href="#" onClick={this.signout} className="signout">
										<i class="fas fa-sign-out-alt"></i> Sign out
									</a>
	              </li>
            </ul>
						<div className="clearfix"></div>
          </div>
        </div>
        <div className="col-md-9">
				<div className="row">

					{(this.props.currentProject.id && !this.props.currentProject.slack_alive) &&
						<div className="slack-incentive card">
							<div className="col-md-10 text-left">
								<h2>Connect a Slack  channel to MailReach to get notifications in Slack</h2>
								<p>Receive Mailreach notifications directly inside your Slack Workspace in second</p>
			          <a className="btn btn-primary green-bck" href={`${process.env.REACT_APP_BACKEND_URL}slack_auth/redirect?project_id=${this.props.currentProject.join_project_team_token}`}>Install App</a>
							</div>
							<div className="col-md-2 text-center">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.8 122.8"><path d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z" fill="#e01e5a"></path><path d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z" fill="#36c5f0"></path><path d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z" fill="#2eb67d"></path><path d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z" fill="#ecb22e"></path></svg>
							</div>
							<div className="clearfix"></div>
						</div>
					}

					<h2 className="pull-left block-title">Your alerts</h2>
					<button className="pull-right btn purple-btn pull-right add-cb" onClick={() => this.setState({openAlerteModal: true})}>Add a new alert</button>

							<div className="card">

              {this.state.alerts.length == 0 &&
                <div className="no-results alerts">
                  <h3>You haven't set up any alerts</h3>
                  <img src="/images/no_tags.svg" />
                </div>
              }

              {this.state.alerts.length > 0 &&
                <>

    							<table class="table">
    								<thead>
    									<tr>
    										<th scope="col">Active</th>
    										<th scope="col">Hook url</th>
    										<th scope="col">Events</th>
    										<th scope="col">Actions</th>
    									</tr>
    								</thead>
    								<tbody>
    									{this.state.alerts.map((alert, index) => {
    										return (
    											<tr>
    												<td className="slider">
    												<GreenSwitch
    													checked={alert.active}
    													onChange={() => this.toggleAlertActive(alert.id)}
    													name="config_smtp_append_after_sent"
    													inputProps={{ 'aria-label': 'secondary checkbox' }}
    												/>

    												</td>
    												<td>
                              {alert.hook_url &&
                                <>
    													    {alert.hook_url.slice(0,50)}
                                </>
                              }
                              {!alert.hook_url &&
                                <>
                                  SLACK
                                </>
                              }
    												</td>
    												<td>
    													<div onClick={() => this.setState({openAlerteModal: true, alert: alert})}>{alert.events.length} events</div>
    												</td>
    												<td>
                              <button className="btn btn-primary red pull-left" onClick={() => this.setState({openDeleteAlerteModal: true, alert: alert})}>Delete</button>
    													<button className="btn btn-secondary pull-left" onClick={() => this.setState({openAlerteModal: true, alert: alert})}>Edit</button>
    												</td>
    											</tr>
    									)})}

    								</tbody>
    								</table>
                  </>
                }
								<div className="clearfix"></div>
							</div>



        </div>
			</div>

			<Modal className="add-account-modal edit-tag-modal alert-modal" show={this.state.openAlerteModal} onHide={() => this.setState({openAlerteModal: false, alert: { events: [], channel: 'ALL', active: true}})}>
				<Modal.Body>
						<>
							<h1>Alert settings</h1>

							<div className="txt imap">

								<form onSubmit={this.saveAlert} className="rows">


                {(this.state.alert.channel == 'ALL')  &&
                  <>
                    <div className="fieldCont col-md-12">
                      <label>Slack channel</label>
                      <select name="slack_channel_id" onChange={this.onChange} value={this.state.alert.slack_channel_id}>
                        <option value="" >Select a Slack channel</option>
                        {this.state.slackChannels.map((channel, index) => {
                          return (
                            <option value={channel.id}>{channel.channel_name}</option>
                          )})}
                      </select>
                    </div>

                    {!this.state.alert.slack_channel_id &&
                      <div className="fieldCont col-md-12">
                        <div className="slack-incentive card">
                          <div className="col-md-9 text-left">
                            <h2>Connect Slack to MailReach to get notifications in Slack</h2>
                            <a className="btn btn-primary green-bck" href={`${process.env.REACT_APP_BACKEND_URL}slack_auth/redirect?project_id=${this.props.currentProject.join_project_team_token}`}>Add to Slack</a>
                          </div>
                          <div className="col-md-3 text-center">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.8 122.8"><path d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z" fill="#e01e5a"></path><path d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z" fill="#36c5f0"></path><path d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z" fill="#2eb67d"></path><path d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z" fill="#ecb22e"></path></svg>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    }
                  </>
                }


									{(this.state.alert.channel == 'HOOK_URL' || this.state.alert.channel == 'ALL')  &&
										<div className="fieldCont col-md-12">
											<label>Webhook URL</label>
											<input placeholder="Enter the webhook URL" name="hook_url" value={this.state.alert.hook_url} className="pull-left" type="url" onChange={this.onChange}/>
										</div>
									}


                {(this.props.currentProject.slack_alive || this.state.alert.channel == 'HOOK_URL' || this.state.alert.channel == 'ALL') &&
									<div className="fieldCont col-md-12 alert-trigger">
										<label>Alert me when:</label><br />

										<div className="input-label">
                      <input className="pull-left" name="ACCOUNT_DISCONNECTED" type="checkbox" checked={this.state.alert.events.includes("ACCOUNT_DISCONNECTED")} onChange={this.addEventToAlert} />
											<div>
                        <span className="pull-left">An email account gets disconnected</span>
                        <span className="help-bubble">
                          <Tooltip title='ACCOUNT_DISCONNECTED' placement="bottom">
                            <div>
                              <i class="fas fa-info-circle"></i>
                            </div>
                          </Tooltip>
                        </span>
                        </div>
										</div>
										<div className="input-label">
                      <input className="pull-left" name="DOMAIN_EXPIRE_SOON" type="checkbox" checked={this.state.alert.events.includes("DOMAIN_EXPIRE_SOON")} onChange={this.addEventToAlert} />
											<div>
                        <span className="pull-left">A domain is expiring soon</span>
                        <span className="help-bubble">
                          <Tooltip title='DOMAIN_EXPIRE_SOON' placement="bottom">
                            <div>
                              <i class="fas fa-info-circle"></i>
                            </div>
                          </Tooltip>
                        </span>
                      </div>
										</div>
										<div className="input-label">
                      <input className="pull-left" name="REPUTATION_RECOVERY" type="checkbox" checked={this.state.alert.events.includes("REPUTATION_RECOVERY")} onChange={this.addEventToAlert} />
											<div>
                        <span className="pull-left">A new warming mode is proposed</span>
                        <span className="help-bubble">
                          <Tooltip title='REPUTATION_RECOVERY' placement="bottom">
                            <div>
                              <i class="fas fa-info-circle"></i>
                            </div>
                          </Tooltip>
                        </span>
                      </div>
										</div>

										<div className="input-label">
                      <input className="pull-left" name="REPUTATION_RECOVERY_DISABLED" type="checkbox" checked={this.state.alert.events.includes("REPUTATION_RECOVERY_DISABLED")} onChange={this.addEventToAlert} />
											<div>
                        <span className="pull-left">An account is back to a normal warming mode</span>
                        <span className="help-bubble">
                          <Tooltip title='REPUTATION_RECOVERY_DISABLED' placement="bottom">
                            <div>
                              <i class="fas fa-info-circle"></i>
                            </div>
                          </Tooltip>
                        </span>
                        </div>
										</div>

										<div className="input-label">
                      <input className="pull-left" name="ACCOUNT_MISSING_INBOUND_MESSAGES" type="checkbox" checked={this.state.alert.events.includes("ACCOUNT_MISSING_INBOUND_MESSAGES")} onChange={this.addEventToAlert} />
											<div>
                        <span className="pull-left">An email account has a problem of missing inbound emails</span>
                        <span className="help-bubble">
                          <Tooltip title='ACCOUNT_MISSING_INBOUND_MESSAGES' placement="bottom">
                            <div>
                              <i class="fas fa-info-circle"></i>
                            </div>
                          </Tooltip>
                        </span>
                      </div>
										</div>

                    <div className="input-label">
                      <input className="pull-left" name="ACCOUNT_THRESHOLD_BELLOW_GOOD" type="checkbox" checked={this.state.alert.events.includes("ACCOUNT_THRESHOLD_BELLOW_GOOD")} onChange={this.addEventToAlert} />
                      <div>
                        <span className="pull-left">An email account has a reputation score decrease (good > average)</span>
                        <span className="help-bubble">
                          <Tooltip title='ACCOUNT_THRESHOLD_BELLOW_GOOD' placement="bottom">
                            <div>
                              <i class="fas fa-info-circle"></i>
                            </div>
                          </Tooltip>
                        </span>
                      </div>
                    </div>

                    <div className="input-label">
                      <input className="pull-left" name="ACCOUNT_THRESHOLD_ABOVE_AVERAGE" type="checkbox" checked={this.state.alert.events.includes("ACCOUNT_THRESHOLD_ABOVE_AVERAGE")} onChange={this.addEventToAlert} />
                      <div>
                        <span className="pull-left">An email account has a reputation score increase (average > good)</span>
                        <span className="help-bubble">
                          <Tooltip title='ACCOUNT_THRESHOLD_ABOVE_AVERAGE' placement="bottom">
                            <div>
                              <i class="fas fa-info-circle"></i>
                            </div>
                          </Tooltip>
                        </span>
                      </div>
                    </div>

                    <div className="input-label">
                      <input className="pull-left" name="ACCOUNT_THRESHOLD_BELLOW_AVERAGE" type="checkbox" checked={this.state.alert.events.includes("ACCOUNT_THRESHOLD_BELLOW_AVERAGE")} onChange={this.addEventToAlert} />
                      <div>
                        <span className="pull-left">An email account has a reputation score decrease (average > bad)</span>
                        <span className="help-bubble">
                          <Tooltip title='ACCOUNT_THRESHOLD_BELLOW_AVERAGE' placement="bottom">
                            <div>
                              <i class="fas fa-info-circle"></i>
                            </div>
                          </Tooltip>
                        </span>
                      </div>
                    </div>

                    <div className="input-label">
                      <input className="pull-left" name="ACCOUNT_THRESHOLD_ABOVE_BAD" type="checkbox" checked={this.state.alert.events.includes("ACCOUNT_THRESHOLD_ABOVE_BAD")} onChange={this.addEventToAlert} />
                      <div>
                        <span className="pull-left">An email account has a reputation score increase (bad > average)</span>
                        <span className="help-bubble">
                          <Tooltip title='ACCOUNT_THRESHOLD_ABOVE_BAD' placement="bottom">
                            <div>
                              <i class="fas fa-info-circle"></i>
                            </div>
                          </Tooltip>
                        </span>
                      </div>
                    </div>

                    <div className="input-label">
                      <input className="pull-left" name="COPILOT_DALY_MAX_SENT_EXCEEDED" type="checkbox" checked={this.state.alert.events.includes("COPILOT_DALY_MAX_SENT_EXCEEDED")} onChange={this.addEventToAlert} />
                      <div>
                        <span className="pull-left">An email account has sent too many messages</span>
                        <span className="help-bubble">
                          <Tooltip title='COPILOT_DALY_MAX_SENT_EXCEEDED' placement="bottom">
                            <div>
                              <i class="fas fa-info-circle"></i>
                            </div>
                          </Tooltip>
                        </span>
                      </div>
                    </div>

                    <div className="input-label">
                      <input className="pull-left" name="COPILOT_DALY_MAX_SENT_EXCEEDED" type="checkbox" checked={this.state.alert.events.includes("ACCOUNT_SENDGRID_MISSING")} onChange={this.addEventToAlert} />
                      <div>
                        <span className="pull-left">An email account that use Sendgrid has a problem of missing emails</span>
                        <span className="help-bubble">
                          <Tooltip title='ACCOUNT_SENDGRID_MISSING' placement="bottom">
                            <div>
                              <i class="fas fa-info-circle"></i>
                            </div>
                          </Tooltip>
                        </span>
                      </div>
                    </div>

                    <div className="input-label">
                      <input className="pull-left" name="ACCOUNT_OUTLOOK_MISSING" type="checkbox" checked={this.state.alert.events.includes("ACCOUNT_OUTLOOK_MISSING")} onChange={this.addEventToAlert} />
                      <div>
                        <span className="pull-left">An Outlook account has a problem of missing email (Outgoing)</span>
                        <span className="help-bubble">
                          <Tooltip title='ACCOUNT_OUTLOOK_MISSING' placement="bottom">
                            <div>
                              <i class="fas fa-info-circle"></i>
                            </div>
                          </Tooltip>
                        </span>
                      </div>
                    </div>

                    {false &&
                      <div className="input-label">
                        <input className="pull-left" name="SPAMCHECK_AUTO_COMPLETED" type="checkbox" checked={this.state.alert.events.includes("SPAMCHECK_AUTO_COMPLETED")} onChange={this.addEventToAlert} />
                        <div>
                          <span className="pull-left">A relatead automated test is completed</span>
                          <span className="help-bubble">
                            <Tooltip title='ACCOUNT_THRESHOLD_BELLOW_AVERAGE' placement="bottom">
                              <div>
                                <i class="fas fa-info-circle"></i>
                              </div>
                            </Tooltip>
                          </span>
                        </div>
                      </div>
                    }

									</div>
                }

                {this.state.alert.events.includes("SPAMCHECK_AUTO_COMPLETED") &&
                  <div className="fieldCont col-md-12">
                    <label>Related automated tests:</label><br />

                    {this.state.automatedTests.map((automatedTest, index) => {
                      return (
                        <div className="input-label col-md-12">
                          <div className="col-md-9">
                            <span className="pull-left">Subject : {automatedTest.subject}</span>
                          </div>
                          <div className="col-md-3">
                            <input className="pull-left" name={automatedTest.id} checked={this.state.alert.automated_test_ids.includes(automatedTest.id)}  type="checkbox" onChange={this.addAutomatedTestAlert} />
                          </div>
                          </div>
                      )})}

                  </div>
                }

									<div className="fieldCont col-md-12">
										{(!this.props.currentProject.slack_alive && this.state.alert.channel == 'SLACK') &&
											<div className="alert status warning card bounce">
												Connect your Slack account before setting up an alert on Slack
											</div>
										}
									</div>
									<div className="clearfix"></div>
								</form>
							</div>

							<div className="footer">
                <button name="Save" type="submit" className="btn primary" onClick={this.saveAlert}>Save</button>
								<Button variant="secondary" onClick={() => this.setState({openAlerteModal: false, alert: { events: [], channel: 'SLACK', active: true}})}>
									Close
								</Button>
							</div>
						</>
				</Modal.Body>
			</Modal>

      <Modal className="add-account-modal edit-tag-modal" show={this.state.openDeleteAlerteModal} onHide={() => this.setState({openDeleteAlerteModal: false, alert: { events: [], channel: 'SLACK', active: true}})}>
        <Modal.Body>
            <>
              <h1>Are you sure to delete?</h1>

              <div className="footer">

                <Button className="btn purple red" variant="secondary" onClick={() => this.delete(this.state.alert.id)}>
                  Delete
                </Button>

                <Button variant="secondary" onClick={() => this.setState({openDeleteAlerteModal: false, alert: { events: [], channel: 'SLACK', active: true}})}>
                  Close
                </Button>
              </div>
            </>
        </Modal.Body>
      </Modal>

			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		currentUser: state.currentUser,
		currentProject: state.currentProject,
		currentPricing: state.currentPricing
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardSettingsAlerts));
