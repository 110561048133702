import React from 'react';
import {Provider} from 'react-redux';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore';

const store = configureStore();

Sentry.init({
  dsn: "https://9ff4e3c4b20d4163ade582f928d1b109@o475043.ingest.sentry.io/5512470",
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

if((window.self === window.top) || window.location.pathname == '/dssuccess') {
  ReactDOM.render(<Provider store={store}>
  		<App />
  	</Provider>,document.getElementById('root'));
} else{
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
