import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {Controlled as CodeMirror} from 'react-codemirror2'
import Tooltip from '@material-ui/core/Tooltip';
import Loader from 'react-loader-spinner'
import queryString from 'query-string';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import Moment from 'react-moment';
import Switch from '@material-ui/core/Switch';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import * as projectsActions from '../../../../../actions/projects.actions';
import * as currentProjectActions from '../../../../../actions/currentProject.actions';
import * as notificationsActions from '../../../../../actions/notifications.actions';

// New spamchecker
import AutomatedTestService from '../../../../../services/automatedTest.service';

import LaunchSpamCheckerNew from '../../../../../components/launch-spamchecker-new/launch-spamchecker-new.js';


const GreenSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#00d463',
    },
    '&$checked + $track': {
      backgroundColor: '#00d463',
    },
  },
  checked: {},
  track: {},
})(Switch);


class DashboardAutomatedTestsList  extends React.Component {
	constructor(props) {
		super(props);
		this.state = {errors: [], loading: true, automatedTests: [], latestAutomatedTestCreated: null, messageContentPreview: 'content_html', page: 0, showTemplateModal: { accounts: []}}
    this.deleteAutomatedTest = this.deleteAutomatedTest.bind(this);
    this.loadAutomatedTests = this.loadAutomatedTests.bind(this);
    this.loadNextPage = this.loadNextPage.bind(this);
	}

	componentWillMount() {

    let queryParams = queryString.parse(this.props.location.search);
    this.setState({latestAutomatedTestCreated: queryParams.last_automated_test_id})

    this.loadAutomatedTests();
	}

  loadAutomatedTests(page = 0){
    AutomatedTestService.all(page).then((response) => {

      if(page > 0){
        let automatedTests = this.state.automatedTests;
        automatedTests = automatedTests.concat(response.body);
        this.setState({automatedTests, loading: false, page: page, loadNextPage: response.body.length > 1})
      } else {
        this.setState({automatedTests: response.body, loading: false, page: page, loadNextPage: response.body.length > 1});
      }
    })
    .catch((error) => {
      this.setState({error: true, loading: false});
      toast.error('Oops. An error occured.');
    });
  }

  loadNextPage(){
    let page = this.state.page + 1;
    this.loadAutomatedTests(page);
  }

	toggleActiveAutomatedTest(test){

		let automatedTests = this.state.automatedTests;
		let objectIndex = automatedTests.indexOf(test);
		test.active = !test.active;
		AutomatedTestService.update(test).then((response) => {
			automatedTests[objectIndex] = test;
			this.setState({automatedTests});
		})
    .catch((error) => {
      this.setState({error: true, loading: false});
      toast.error('Oops. An error occured.');
    });
	}

  runAutomatedTestNow(){
    AutomatedTestService.runNow(this.state.latestAutomatedTestCreated).then((response) => {
      this.setState({latestAutomatedTestCreated: null});
      this.props.history.push(`/dashboard/${this.props.match.params.project_id}/spam-tests/home`);
    })
    .catch((error) => {
      this.setState({error: true, loading: false});
      toast.error('Oops. An error occured. ' + error.response.body.error);
    });
  }


  deleteAutomatedTest(){
    AutomatedTestService.delete(this.state.automatedTestToDelete.id).then((response) => {
      toast.success('Test deleted');
      this.setState({openDeleteAutomatedTest: false, automatedTestToDelete: null});
      this.loadAutomatedTests();
    })
    .catch((error) => {
      this.setState({error: true, loading: false});
      toast.error('Oops. An error occured.');
    });
  }

  runNow(automatedTestId){
    AutomatedTestService.runNow(automatedTestId).then((response) => {
      toast.success('A new test is running, it can take some seconds to process.');
      this.props.history.push(`/dashboard/${this.props.match.params.project_id}/spam-tests/home`);
    })
    .catch((error) => {
      this.setState({error: true, loading: false});
      toast.error('Oops. An error occured. ' + error.response.body.error);
    });
  }



	render() {
		if(this.state.loading){
			return(
				<div className="app container text-center">
					<div className="loader">
						<Loader
						 type="Oval"
						 color="#b7b7b7"
						 height="100"
						 width="100"
						/>
					</div>
				</div>
			)
		}

		return (
			<div className="app container home spamtest-auto">

        <div className="title top-margin">
          <h1 className="pull-left">Manage your automated tests</h1>

          <NavLink to={`/dashboard/${this.props.match.params.project_id}/spam-tests/auto/create`} className="btn btn-primary purple pull-right">
            Add new
          </NavLink>

          <NavLink to={`/dashboard/${this.props.match.params.project_id}/spam-tests/accounts`} className="btn btn-secondary grey pull-right">
            <i class="fa-solid fa-gear"></i> Settings
          </NavLink>

          <NavLink to={`/dashboard/${this.props.match.params.project_id}/spam-tests/home`} className="btn btn-secondary grey pull-right">
            <i class="fa-solid fa-arrow-left"></i> Go back to your latest tests
          </NavLink>
        </div>

				{this.state.automatedTests.length == 0 &&
					<div className="card col-md-12 spam-result-item spam-auto-item">
						<div className="no-results">
							<h3>You haven’t set up any automatic test yet.</h3>
							<img src="/images/no_data.svg" /><br />
							<NavLink to={`/dashboard/${this.props.match.params.project_id}/spam-tests/auto/create`} className="btn btn-primary purple">
								Set up an automatic test
							</NavLink>
						</div>
					</div>
				}

        <div className="templates spamtest">
          {this.state.automatedTests.map((test, index) => {
            return (
              <div className="card col-md-12 spam-result-item spam-auto-item">
							<div className="col-md-1 switch-off-on">
								<GreenSwitch
									checked={test.active}
									onChange={() => { this.toggleActiveAutomatedTest(test)}}
									name="alert_active"
									inputProps={{ 'aria-label': 'secondary checkbox' }}
								/>
							</div>
    					<Tooltip title='Click to preview' placement="bottom">
    					<div className="account col-md-4 tit" onClick={() => this.setState({openShowTemplateModal:true, showTemplateModal: test})}>
    						  <span className="subject">{test.subject}</span>
    						  <p className="preview-hide">{test.content_text ? (test.content_text.slice(0, 40)) : ''} ...</p>
    							{test.accounts.length > 0 &&
    								<div className="test-from-email">
    									{test.accounts[0].email}
    								</div>
    							}
    					</div>
    					</Tooltip>
              <div className="last-test-result col-md-2 text-center">
                  {!test.active &&
    								<Tooltip title='This automated test is currently paused and not running any test.' placement="bottom">
                      <div className="score running">
                        Paused
                      </div>
    								</Tooltip>
                   }

                   {test.active &&
                    <Tooltip title='This automated test is activated and will run test on defined date' placement="bottom">
                       <div className="score good">
                         Active
                       </div>
                    </Tooltip>
                    }
              </div>

              <div className="col-md-4 text-center last-run-at">
                {test.last_run_at &&
                  <>
                    Last run : <Moment fromNow>{test.last_run_at}</Moment>
                  </>
                }
                {test.created_at &&
                  <div className="automated-test-creation-date">
                    Created on <Moment format="MMM DD, YYYY">{test.created_at}</Moment><br />
                  </div>
                }
              </div>

    					<div className="pull-right hidden-mobile">
                <Dropdown className="dropdown-date-filter pull-right last-days-filter">
                  <Dropdown.Toggle className="btn" id="dropdown-basic">
                   <i class="fa-solid fa-ellipsis-vertical"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                  <NavLink to={`/dashboard/${this.props.match.params.project_id}/spam-tests/auto/${test.id}/frequency`}>
                    Edit
                  </NavLink>
                  <Dropdown.Item className="delete" onClick={() => this.setState({openDeleteAutomatedTest: true, automatedTestToDelete: test})}>Delete</Dropdown.Item>
                  <Dropdown.Item onClick={() => this.runNow(test.id)}>Run a test now</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

    					</div>
    					<div className="clearfix"></div>
              </div>
            )})}


            {(this.state.loadNextPage && this.state.automatedTests) &&
              <div className="text-center load-next-tests">
                <button className="load-next-tests-btn" onClick={this.loadNextPage}>Load next automated tests</button>
              </div>
            }

          </div>

      <div className="text-center load-next-tests">
			  {this.state.loadNextTest &&
					<div className="loader">
						<Loader
						 type="Oval"
						 color="#b7b7b7"
						 height="100"
						 width="100"
						/>
					</div>
				}
				{(!this.state.loadNextTest && this.state.lastTests && !this.state.endTestsList) &&
					<button className="load-next-tests-btn" onClick={() => this.nextPage(this.state.testPage + 1)}>Load older tests</button>
				}
			</div>

      <Modal className="spam-results-modal message-design" show={this.state.openShowTemplateModal} onHide={() => this.setState({openShowTemplateModal: false})}>
        <Modal.Body>
          <>
            <h1 className="text-center">{this.state.showTemplateModal.subject}</h1>
              <div className='from'>
                <b>{this.state.showTemplateModal.accounts.length > 0 ? this.state.showTemplateModal.accounts[0].first_name : ''}</b> {'<' + ((this.state.showTemplateModal.accounts.length > 0) ? this.state.showTemplateModal.accounts[0].email : 'undefined')+ '>'}
                <ul className='format-selector pull-right'>
                  <li className={"btn btn-secondary" + (this.state.messageContentPreview == 'content_html' ? ' selected' : '')} onClick={() => this.setState({messageContentPreview: 'content_html'})}>HTML</li>
                  <li className={"btn btn-secondary" + (this.state.messageContentPreview == 'content_text' ? ' selected' : '')} onClick={() => this.setState({messageContentPreview: 'content_text'})}>TEXT</li>
                </ul>
              </div>
            <div className="txt">
            <div
              dangerouslySetInnerHTML={{__html: this.state.showTemplateModal[this.state.messageContentPreview]}}
              />
            </div>
            <div className="footer text-center">
              <button type="button" className="btn btn-secondary" onClick={() => this.setState({openShowTemplateModal: false})}>Close</button>
            </div>
          </>
        </Modal.Body>
      </Modal>


      <Modal className="reputation-modal" show={this.state.openDeleteAutomatedTest} onHide={() => this.setState({openDeleteAutomatedTest: false, automatedTestToDelete: null})}>
        <Modal.Body>
          <>
            <h1 className="text-center">Are you 100% sure you want to delete this automatic test?</h1>
            <div className="txt text-center">
            </div>
            <div className="footer text-center del">
              <button type="button" className="btn purple red btn-primary" onClick={this.deleteAutomatedTest}>Yes, delete it</button>
              <button type="button" className="btn btn-secondary" onClick={() => this.setState({openDeleteAutomatedTest: false, automatedTestToDelete: null})}>Cancel</button>
            </div>
          </>
        </Modal.Body>
      </Modal>

      <Modal className="reputation-modal run-immediately-modal" show={this.state.latestAutomatedTestCreated} onHide={() => this.setState({latestAutomatedTestCreated: null})}>
        <Modal.Body>
              <h1 className="text-center">All set! Would you like to run a first test now?</h1>
              <div className="txt text-center">
                <p>Congrats, your Automatic Spam Test has been successfully set up 🥳</p>

                <p>Would you like to run a spam test now to get your first report?</p>
              </div>
              <div className="footer text-center del">
                <button type="button" className="btn btn-secondary" onClick={() => this.setState({latestAutomatedTestCreated: null})}>
                  Not now
                </button>
                <Button className="primary btn" onClick={() => this.runAutomatedTestNow()}>
                  Yes, run it now
                </Button>
            </div>
        </Modal.Body>
      </Modal>

			<div className="clearfix"></div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		currentProject: state.currentProject,
		currentUser: state.currentUser,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		projectsActions: bindActionCreators(projectsActions, dispatch),
		currentProjectActions: bindActionCreators(currentProjectActions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardAutomatedTestsList ));
