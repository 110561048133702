const request = require('superagent');

// Todo, rename as test folder
class TagService {

	static create(name) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}tags`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.send({name})
	}

	static get(id) {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}tags/${id}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}

	static delete(id) {
		return request
						.delete(`${process.env['REACT_APP_BACKEND_URL']}tags/${id}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}

	static update(data) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}tags/${data.id}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.send(data);
	}

	static link(accountId, tagId) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}tags/${tagId}/link`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.send({account_id: accountId});
	}

	static unlink(accountId, tagId) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}tags/${tagId}/unlink`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.send({account_id: accountId});
	}

	static all() {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}tags`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}

	static providers() {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}tags?is_provider=true`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}
}

export default TagService;
