const request = require('superagent');

class ConversationStatusService {
  static getGraph(id, provider, pastDays = 15) {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}conversation_statuses/${id}/graph?past_days=${pastDays}${provider != 'All' ? '&provider=' + provider : ''}`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }
}

export default ConversationStatusService;
