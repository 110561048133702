import CurrentProjectService from '../services/currentProject.service';
import * as types from './actionTypes';

function currentProjectGetSuccess(currentProject) {
	return {type: types.CURRENT_PROJECT_GET_SUCCESS, currentProject};
}

function updateSuccess() {
	return {type: types.PROJECT_UPDATE_SUCCESS};
}

function loginError(errors) {
	return {type: types.LOG_IN_ERROR, errors};
}

export function getCurrent() {
	return function (dispatch) {
		return CurrentProjectService.get().then(response => {
			dispatch(currentProjectGetSuccess(response.body));
		}).catch(error => {
			dispatch(loginError(['An error occured']))
			throw (error);
		});
	};
}

export function update(id, data) {
	return function (dispatch) {
		return CurrentProjectService.update(id, data).then(response => {
			dispatch(updateSuccess(response.body));
      return response;
		}).catch(error => {
			console.error(error);
			dispatch(error(['An error occured']))
      throw (error);
		});
	};
}
