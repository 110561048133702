import React from 'react';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import queryString from 'query-string';

import ProjectService from '../../../services/project.service';

import './redirect.scss';

class AppRedirect extends React.Component {
	constructor(props) {
		super(props);
		this.state = {errors: [], projects: [], error: false, loading: true};
		this.selectProject = this.selectProject.bind(this);
	}


	componentWillMount() {
		let queryParams = queryString.parse(this.props.location.search);

		// Set auth token
		if(queryParams.impersonate && queryParams.user_token){
			localStorage.clear();
			localStorage.setItem('jwt', queryParams.user_token);
			localStorage.setItem('admin', true);
			window.location = window.location.href.split("?")[0];
			return;
		}

		if (!localStorage.jwt) {
			this.props.history.push("/signin");
			return;
		}

		if(queryParams.token){
			this.props.history.push('/invite' + this.props.location.search);
			return;
		}

		ProjectService.all().then((response) => {
			this.setState({projects: response.body, loading: false});
			// Only one project, redirect directly to the right project
			if(response.body.length == 1){
				localStorage.setItem('x-api-key', response.body[0].api_key);
				if(response.body[0].config_feature_stripe_payment_link_checkout_enabled && !response.body[0].stripe_subscription_id && response.body[0].config_number_spamchecker_credits == 0){
					this.props.history.push(`/dashboard/${response.body[0].id}/settings/checkout/plans?hideNvgt=true`);
				} else {
					if(queryParams.spamCheckerPlan){
						this.props.history.push(`/dashboard/${response.body[0].id}/settings/billing?spamCheckerPlan=${queryParams.spamCheckerPlan}`);
					}else if (queryParams.updateCb) {
						this.props.history.push(`/dashboard/${response.body[0].id}/settings/billing?updateCb=true`);
					}else{
						this.selectProject(response.body[0]);
					}
				}
			}

			// Check if multi projects but only 1 with credit card
			let projectsWithCreditCard = response.body.filter(project => project.stripe_customer_id);

			if(projectsWithCreditCard.length == 1){
				localStorage.setItem('x-api-key', projectsWithCreditCard[0].api_key);
				if(queryParams.spamCheckerPlan){
					this.props.history.push(`/dashboard/${projectsWithCreditCard[0].id}/settings/billing?spamCheckerPlan=${queryParams.spamCheckerPlan}`);
				}else if (queryParams.updateCb) {
					this.props.history.push(`/dashboard/${projectsWithCreditCard[0].id}/settings/billing?updateCb=true`);
				}else{
					this.selectProject(projectsWithCreditCard[0]);
				}
			}
		})
		.catch((error) => {
			this.setState({error: true, loading: false});
			toast.error('Oops, we have an error');
		});
	}

	componentWillReceiveProps(nextProps) {
		const errors = nextProps.errors;
		if (errors && errors != this.props.errors) {
			toast.error('Oops, il y a un petit problème');
		}else{
			if(nextProps.projects.length === 0){
				this.props.history.push("/dashboard/getting-started/create");
			}else{
				this.props.history.push("/dashboard/requests");
			}
		}
	}

	selectProject(project){
		localStorage.setItem('x-api-key', project.api_key);
		if(project.stripe_customer_id){
			this.props.history.push(`/dashboard/${project.id}/home`);
		} else {
			this.props.history.push(`/dashboard/${project.id}/welcome`);
		}
	}


	render() {
		if(this.state.loading){
			return(
				<div className="app container text-center">
					<div className="loader">
						<Loader
						 type="Oval"
						 color="#b7b7b7"
						 height="100"
						 width="100"
						/>
					</div>
				</div>
			)
		}

		return (
			<>
			  {(this.state.projects.length < 2 && this.state.error) &&
					<div className="container">
						<div className="card text-center">
						<img src="/images/down.svg" className="down"/>
						 	A database upgrade is currently in progress, everything will be back soon.
					  </div>
					</div>
				}
				{this.state.projects.length > 1 &&
					<div className="app projects-selector">
					<h1>Select a project</h1>
						<ul>
						{this.state.projects.map((project, index) => {
		           return (
								<li key={project.id}>
									<div onClick={() => this.selectProject(project)}>
										<div className="projectLogo" style={{background: project.color}} ></div>
										<span className="name">{project.name}</span>
										<span className="desc">{project.main_user_email}</span>
									</div>
								</li>
							)
						})}
						</ul>
					</div>
				}
			</>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		//organization: state.organizations
	};
}

function mapDispatchToProps(dispatch) {
	return {
		//actions: bindActionCreators(organization, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AppRedirect));
