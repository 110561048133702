import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {Controlled as CodeMirror} from 'react-codemirror2'
import Tooltip from '@material-ui/core/Tooltip';
import Loader from 'react-loader-spinner'
import queryString from 'query-string';
import { Button, Modal } from 'react-bootstrap';

import * as projectsActions from '../../../../actions/projects.actions';
import * as currentProjectActions from '../../../../actions/currentProject.actions';
import * as notificationsActions from '../../../../actions/notifications.actions';

import AccountService from '../../../../services/account.service';
import TemplateService from '../../../../services/template.service';
import ConversationTestService from '../../../../services/conversationTest.service';

import TestsList from '../../../../components/tests-list/tests-list.js';
import NewTestsList from '../../../../components/new-tests-list/new-tests-list.js';
import LaunchSpamtest from '../../../../components/launch-spamtest/launch-spamtest.js';


// New spamchecker
import SpamCheckerService from '../../../../services/spamChecker.service';
import LaunchSpamCheckerNew from '../../../../components/launch-spamchecker-new/launch-spamchecker-new.js';

import './home.scss';


class DashboardTemplatesHome extends React.Component {
	constructor(props) {
		super(props);
		this.state = {errors: [], queryParams: {}, loading: true, accounts: [], templates: [], selectedAccounts: [], lastTests: null, testPage: 0, endTestsList: false, searchUsed: false}
		this.reload = this.reload.bind(this);
		this.reloadTests = this.reloadTests.bind(this);
		this.handleCloseAddTemplateModal = this.handleCloseAddTemplateModal.bind(this);
    this.onChange = this.onChange.bind(this);
		this.accountSelected = this.accountSelected.bind(this);
		this.selectAccount = this.selectAccount.bind(this);
		this.nextPage = this.nextPage.bind(this);
		this.loadAccounts = this.loadAccounts.bind(this);
		this.searchTests = this.searchTests.bind(this);
	}

	componentWillMount() {
		let queryParams = queryString.parse(this.props.location.search);
		let success = queryParams.success;
		if(success){
			toast.success(success);
		}

		let error = queryParams.error;
		if(error){
			toast.error(error);
		}
		this.props.currentProjectActions.getCurrent().then((response) => {
			this.reload();
		});

		setInterval(
      () => this.reloadTests(),
      20000
    );

		this.setState({queryParams});


		if(queryParams.events){
			window.gtag("event", "spamchecker", {
							event_category: "spamchecker",
							event_label: "buy-credits"
					});
				window.gtag('event', 'conversion', {
						'send_to': 'AW-444511731/aidcCIOXkaUDEPPr-tMB',
						'transaction_id': ''
				});

				window.gtag("event", "purchase", {
					currency: 'USD',
					value: (queryParams.spamchecker_credits * 0.2),
					transaction_id: Date.now(),
					items: [
						{item_iname: 'spamchecker_credits'}
					]
				});

				// BING ads
				window.uetq = window.uetq || [];
				window.uetq.push('event', 'buy spam test credits', {});
			}

	}


	loadAccounts(page = 0){
		let accounts = this.state.accounts;
		if(page == 0){
			this.setState({accounts: [], loading: false})
		}
		AccountService.all(null, null, page).then((response) => {
			accounts = accounts.concat(response.body);
			this.setState({accounts: accounts, loading: false})
			if(response.body.length == 50){
        this.loadAccounts(page +1);
			}
		})
		.catch((error) => {
			toast.error('Oops, we have an issue, try again');
		});
	}


	reload(){
		// Remove this when spamchecker cleaning
		if(!this.props.currentProject.config_feature_new_spamchecker_enabled){
	    this.loadAccounts();
			TemplateService.all().then((response) => {
				this.setState({templates: response.body, loading: false})
			})
			.catch((error) => {
				toast.error('Oops, we have an issue, try again');
			});
		}

		this.reloadTests();
	}

	reloadTests(){
		let lastTests = this.state.lastTests;

		if(this.props.currentProject.config_feature_new_spamchecker_enabled){
			SpamCheckerService.all(0, this.state.searchQuery).then((response) => {
				if(this.state.lastTests){
					lastTests = response.body.spam_tests.concat(lastTests);

					lastTests =  lastTests.filter((value, index, self) =>
												  index === self.findIndex((t) => (
												    t.public_id === value.public_id
												  ))
												);
					this.setState({lastTests: lastTests, loading: false});
				}else{
					this.setState({lastTests: response.body.spam_tests, loading: false});
				}
				if(response.body.spam_tests.length < 10){
					this.setState({endTestsList: true});
				}
			})
			.catch((error) => {
				this.setState({error: true});
				toast.error('Oops, we have an error');
			});

		}else{
			ConversationTestService.all(null).then((response) => {
				if(this.state.lastTests){
					lastTests = response.body.concat(lastTests.filter(value => !response.body.map(item => { return item.id}).includes(value.id)));
					this.setState({lastTests: lastTests, loading: false});
				}else{
					this.setState({lastTests: response.body, loading: false});
				}
				if(response.body.length < 10){
					this.setState({endTestsList: true});
				}
			})
			.catch((error) => {
				console.log(error);
				toast.error('Oops, we have an issue, try again');
			});
		}
	}

	nextPage(pageNumber){
		this.setState({loadNextTest: true});
		let lastTests = this.state.lastTests;
		if(this.props.currentProject.config_feature_new_spamchecker_enabled){
			if(this.state.searchQuery){
				SpamCheckerService.all(pageNumber, this.state.searchQuery).then((response) => {
					lastTests = lastTests.concat(response.body.spam_tests);
					this.setState({lastTests, loadNextTest: false, testPage: pageNumber, loadNextTest: false, endTestsList: (response.body.spam_tests.length < 10)});
				})
				.catch((error) => {
					toast.error('Oops, we have an issue, try again');
					this.setState({loadNextTest: false});
				});
			} else {
				SpamCheckerService.all(pageNumber).then((response) => {
					lastTests = lastTests.concat(response.body.spam_tests);
					this.setState({lastTests, loading: false, testPage: pageNumber, loadNextTest: false});
					if(response.body.spam_tests.length < 10){
						this.setState({endTestsList: true});
					}
				})
				.catch((error) => {
					toast.error('Oops, we have an issue, try again');
					this.setState({loadNextTest: false});
				});
			}
		}else{
			ConversationTestService.all(null, pageNumber).then((response) => {
				lastTests = lastTests.concat(response.body);
				this.setState({lastTests, loading: false, testPage: pageNumber, loadNextTest: false});
				if(response.body.length < 10){
					this.setState({endTestsList: true});
				}
			})
			.catch((error) => {
				toast.error('Oops, we have an issue, try again');
				this.setState({loadNextTest: false});
			});
		}
	}

  onChange(event) {
    let name = this.state.name;
    name = event.target.value;
    return this.setState({name});
  }

	handleCloseAddTemplateModal(){
		this.setState({showAddTemplateModal: false});
	}

	accountSelected(accountId){
		if(this.state.selectedAccounts.includes(accountId)){
			return 'selected';
		}
		return '';
	}

	selectAccount(accountId){
		let selectedAccounts = this.state.selectedAccounts;
		if(selectedAccounts.includes(accountId)) {
			var index = selectedAccounts.indexOf(accountId);
			if (index !== -1) {
			  selectedAccounts.splice(index, 1);
			}
		} else {
      selectedAccounts.push(accountId);
		}
		this.setState({selectedAccounts});
	}

	copyText(value){
		navigator.clipboard.writeText(value);
		toast.info(value + ' copied');
	}

	searchTests(e){
		e.preventDefault();
		this.setState({loadNextTest: true, lastTests: []});
		let lastTests = [];
		this.setState({searchQuery: e.target.value, searchUsed: true});

		let searchQuery = e.target.value;


		if(this.timeout) clearTimeout(this.timeout);
		this.timeout = setTimeout((e) => {
			SpamCheckerService.all(0, searchQuery).then((response) => {
				lastTests = lastTests.concat(response.body.spam_tests);
				this.setState({lastTests, loadNextTest: false, testPage: 0, loadNextTest: false, endTestsList: (response.body.spam_tests.length < 10)});
			})
			.catch((error) => {
				toast.error('Oops, we have an issue, try again');
				this.setState({loadNextTest: false});
			});
		}, 450);

	}


	render() {
		if(this.state.loading){
			return(
				<div className="app container text-center">
					<div className="loader">
						<Loader
						 type="Oval"
						 color="#b7b7b7"
						 height="100"
						 width="100"
						/>
					</div>
				</div>
			)
		}

		if((this.state.accounts.length == 0 && !this.props.currentProject.config_feature_new_spamchecker_enabled)){
			return(
				<div className="app container home">
				  <div className="row accounts templates">
			      <div className="card request-action">
						  <div className="col-md-2">
		            <img src="/images/mail_sent.svg" />
		          </div>
							<div className="col-md-10">
								<div className="txt">
						      To run spam tests, you need to connect at least one email account first.
								</div>
								<div className="pull-right">
									<NavLink to={`/dashboard/${this.props.match.params.project_id}/home`} className="btn btn-secondary pull-right">
										Add an account
									</NavLink>
								</div>
							</div>
							<div className="clearfix"></div>
			      </div>
					</div>
				</div>
			)
		}
		return (
			<div className="app container home">
      {(this.state.accounts.length > 0 && !this.props.currentProject.config_feature_new_spamchecker_enabled) &&
				<div className="card request-action spamtest-free">
					<div className="col-md-2 text-center">
						<img src="/images/free.svg" />
					</div>
					<div className="col-md-10">
						<div className="txt">
						  This new feature in Beta is included in your subscription and allows you to check where your marketing emails land. It helps you improve your copy and sending setup to maximize the deliverability of your campaigns.
						</div>
					</div>
					<div className="clearfix"></div>
				</div>
			}


				{(this.state.templates[0] && !this.props.currentProject.config_feature_new_spamchecker_enabled) &&
					<LaunchSpamtest templateKey={this.state.templates[0].key} folders={this.state.templates} accounts={this.state.accounts} lastTest={this.state.lastTests ? this.state.lastTests[0] : null} />
				}

				{this.props.currentProject.config_feature_new_spamchecker_enabled &&
					<LaunchSpamCheckerNew currentProject={this.props.currentProject} lastTest={this.state.lastTests ? this.state.lastTests[0] : null} />
				}

				{((this.state.lastTests && this.state.lastTests.length > 0) || this.state.searchQuery || this.state.searchUsed)  &&
					<>
						<div className="title top-margin">
							<h1 className="pull-left">Latest tests</h1>
						</div>

							<div className="search search-spamchecker">
								<form onSubmit={(e) => this.searchTests(e)}>
									 <i class="fas fa-search"></i>
									 <input onChange={this.searchTests} placeholder="Search by sender, domain or subject line" />
								</form>
							</div>


						{(this.state.lastTests.length == 0 && this.state.searchQuery && !this.state.loadNextTest) &&
							<div className="col-md-12 no-padding-left no-padding-right">
								<div className="card text-center empty-results">
									<img src="/images/empty.svg" />
									<div className="clearfix"></div>
									<h2>No results found for your search</h2>
									<div className="clearfix"></div>
								</div>
							</div>
						}


						<div className="templates spamtest">
							<div className="clearfix"></div>
							{!this.props.currentProject.config_feature_new_spamchecker_enabled &&
								<TestsList tests={this.state.lastTests} />
							}

							{this.props.currentProject.config_feature_new_spamchecker_enabled &&
								<NewTestsList tests={this.state.lastTests} />
							}
						</div>
					</>
				}

      <div className="text-center load-next-tests">
			  {this.state.loadNextTest &&
					<div className="loader">
						<Loader
						 type="Oval"
						 color="#b7b7b7"
						 height="100"
						 width="100"
						/>
					</div>
				}
				{(!this.state.loadNextTest && this.state.lastTests && !this.state.endTestsList) &&
					<button className="load-next-tests-btn" onClick={() => this.nextPage(this.state.testPage + 1)}>Load older tests</button>
				}
			</div>

			<div className="clearfix"></div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		currentProject: state.currentProject,
		currentUser: state.currentUser,
		currentPricing: state.currentPricing,
		notifications: state.notifications
	};
}

function mapDispatchToProps(dispatch) {
	return {
		projectsActions: bindActionCreators(projectsActions, dispatch),
		currentProjectActions: bindActionCreators(currentProjectActions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardTemplatesHome));
