const request = require('superagent');

// Todo, rename as test folder
class TemplateService {

	static create(name) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}test_folders`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.send({name})
	}

	static get(id) {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}test_folders/${id}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}

	static update(data) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}test_folders/${data.id}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.send(data);
	}

	static all() {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}test_folders`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}
}

export default TemplateService;
