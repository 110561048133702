const request = require('superagent');

class EmailWorkspaceService {
	static create(name, file_key_json) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}email_workspaces`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.send({name, file_key_json});
	}

	static all() {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}email_workspaces`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.set('Authorization', `Bearer ${localStorage.getItem('jwt')}`);
	}

  static get(id) {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}email_workspaces/${id}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.set('Authorization', `Bearer ${localStorage.getItem('jwt')}`);
	}

	static delete(id) {
		return request
						.delete(`${process.env['REACT_APP_BACKEND_URL']}email_workspaces/${id}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.set('Authorization', `Bearer ${localStorage.getItem('jwt')}`);
	}

	static get(id) {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}email_workspaces/${id}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.set('Authorization', `Bearer ${localStorage.getItem('jwt')}`);
	}

	static edit(id, name, file_key_json) {
		return request
			.post(`${process.env['REACT_APP_BACKEND_URL']}email_workspaces/${id} `)
			.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
			.send({name, file_key_json, name});
	}

	static addAccounts(id, accounts, spamcheckerOnly, imapOnly, imapOnlyAccountId) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}email_workspaces/${id}/accounts`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.send({accounts, spamchecker_only: spamcheckerOnly, imap_only: imapOnly, account_id: imapOnlyAccountId});
	}
}

export default EmailWorkspaceService;
