export const GeneratePricingPreview = (seats, yearly, opts = {}) => {

  let pricePerSeat = 25;
  let priceAdded = null;

  if(parseInt(seats) > 0 && parseInt(seats) < 6){
    pricePerSeat = 25;
  }else if (parseInt(seats) > 5 && parseInt(seats) < 21) {
    pricePerSeat = 19.5;
  }else if (parseInt(seats) > 20 && parseInt(seats) < 51) {
    pricePerSeat = 18;
  }else if (parseInt(seats) > 50) {
    pricePerSeat = 16;
  }

  if(opts['seatsAdded']){
    priceAdded = opts['seatsAdded']*pricePerSeat;
  }

  if(yearly){
    pricePerSeat = pricePerSeat*0.8;

    if(opts['seatsAdded']){
      priceAdded = priceAdded*0.8;
    }
  }

  pricePerSeat = pricePerSeat.toFixed(2);

  if(opts['seatsAdded']){
    priceAdded = priceAdded.toFixed(2);
  }
  // Todo : manage yearly
  let totalPrice = (pricePerSeat*parseInt(seats));

  let monthlyPricing = totalPrice;

  if(yearly){
    totalPrice = totalPrice*12;
  }

  return {pricePerSeat, totalPrice, priceAdded, monthlyPricing}
}
