import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import {ToastContainer, toast} from 'react-toastify';
import Loader from 'react-loader-spinner'
import { Button, Modal, Dropdown } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';
import { NavLink } from 'react-router-dom';

import EmailWorkspaceService from '../../../../../../services/emailWorkspace.service';
import TaskService from '../../../../../../services/task.service';

import * as projectsActions from '../../../../../../actions/projects.actions';

class DashboardWorkspaceAddAccountsConnectV2 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {errors: [], emails: []};
		this.handleChange = this.handleChange.bind(this);
		this.addAccounts = this.addAccounts.bind(this);
		this.reloadTaskProgress = this.reloadTaskProgress.bind(this);
	}

	componentWillMount() {
		let queryParams = queryString.parse(this.props.location.search);

		if(queryParams.spamchecker_only){
			this.setState({spamchecker_only: true});
		}

		if(queryParams.imap_only && queryParams.account_id){
			this.setState({imap_only: true, imap_only_account_id: queryParams.account_id});
		}

    // TOdo, fetchthe workspace
  }

	handleChange(e){
		let emails = e.target.value;
		this.setState({emails: emails.split('\n')});
	}

	reloadTaskProgress(task_id){
		TaskService.get(task_id).then((response) => {
			this.setState({errors: response.body.logs.errors ? response.body.logs.errors : [], loading: true})
			if(response.body.progress == 100){

				if(response.body.logs.errors.length == 0){
					toast.success('Accounts connected !');
					this.props.history.push(`/dashboard/${this.props.currentProject.id}/home`)
					this.setState({loading: false});
				}else{
					this.setState({showErrorsModal: true, loading: false});
				}


			} else {
				setTimeout(
					() => this.reloadTaskProgress(task_id),
					2000
				);
			}
			// Create emailvalidation task
		})
		.catch((error) => {
			console.log(error);
		});

	}

	addAccounts(){
		this.setState({loading: true});
			EmailWorkspaceService.addAccounts(this.props.match.params.workspace_id, this.state.emails, this.state.spamchecker_only, this.state.imap_only, this.state.imap_only_account_id).then((response) => {
				// Get task ID if processing a large import, or results if this is a quick one
				this.setState({loading: false});
				// Add notification and redirect user to add account

				if(response.body.task_id){
					// Todo : if task ID, monitore task progress
					this.reloadTaskProgress(response.body.task_id);

				}else{
					if(response.body.errors.length > 0){
						this.setState({showErrorsModal: true, errors: response.body.errors});
					} else {
						toast.success('Account(s) connected !');
						if(this.state.spamchecker_only){
							this.props.history.push(`/dashboard/${this.props.currentProject.id}/spam-tests/auto/create`);
						}else{
							this.props.history.push(`/dashboard/${this.props.currentProject.id}/home`);
						}
					}
				}
			})
			.catch((error) => {
				console.log(error);
				toast.error('Oops, there is an error');
				this.setState({loading: false});
			});
	}



	render() {
		if(this.state.loading){
			return(
				<div className="app container connect-v2 connect-workspace">
					<div className="row">

	        <div className="progress-automated-test progress-automated-test-two">
	          <ul>
	            <li className="done"></li>
	            <li className="selected"></li>
	          </ul>
	          <div className="bar"></div>
	        </div>

					<div className="col-md-12 card text-center">
						<div className="loader">
							<Loader
							 type="Oval"
							 color="#b7b7b7"
							 height="100"
							 width="100"
							/>
						</div>
					</div>
</div>
					<style dangerouslySetInnerHTML={{
						__html: `
							footer, .sidebar {
								display: none;
							}
						`
					}} />
				</div>
			)
		}

		return (
			<div className="app container connect-v2 connect-workspace">
				<div className="row">

        <div className="progress-automated-test progress-automated-test-two">
          <ul>
            <NavLink className="done" to={`/dashboard/${this.props.match.params.project_id}/account/workspaces/create`}></NavLink>
            <li className="selected"></li>
          </ul>
          <div className="bar"></div>
        </div>


				{this.state.imap_only &&
					<>
						<div className="clearfix"></div>
						<h2 className="text-center">Enter the mailbox you'd like to use for IMAP</h2>
						<div>
							<div className="col-md-12 card">
								<div className="col-md-12 guide-txt">
									<p>Now, MailReach needs you to specify which mailbox in your workspace should be used for IMAP.</p>
									<p>Important: this mailbox has to belong to the Google Workspace you've previously set up.</p>
									<p>Please enter below the mailbox to connect as IMAP.</p>
									<form>
										<div className="imap">
											<div className="fieldCont col-md-6 no-padding-left">
												<input placeholder="Email to use as imap" onChange={this.handleChange} />
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>

					</>
				}


				{!this.state.imap_only &&
					<>
		        <div className="clearfix"></div>
		          <h2 className="text-center">List the email account(s) you'd like to connect to MailReach</h2>

		          <div>
		            <div className="col-md-12 card">
									<div className="col-md-6 guide-txt">
			              <p>On the right field below, <b>enter the email address(es)</b> you'd like to warm on MailReach.</p><br />
										<p>You can bulk connect them.</p><br />
										<p>Don't put aliases, only the real users. you'll be able to choose the aliases later.</p>
									</div>
									<div className="col-md-6 block-textarea-emails">
										<p>Type or paste one or more email addresses. One email address per line.</p>
										<textarea className="emails-field" onChange={this.handleChange}></textarea>
									</div>
		            </div>
		        </div>
					</>
				}

        <div className="text-center automated-spamtest-btns">
					<NavLink to={`/dashboard/${this.props.match.params.project_id}/account/connect`} className="btn btn-primary grey" activeClassName="active">
						Cancel
					</NavLink>
					{this.state.emails.length > 0 &&
          	<button className="btn btn-primary purple" onClick={this.addAccounts}>Connect</button>
					}
					{this.state.emails.length == 0 &&
						<Tooltip title='You need to put at least one address to connect to MailReach' placement="bottom">
							<button className="btn btn-primary purple" disabled="true">Connect</button>
						</Tooltip>
					}
        </div>
        </div>


				<ToastContainer />

				<Modal className="reputation-modal help-modal progress-error-modal-workspace" show={this.state.showErrorsModal} onHide={() => this.setState({showErrorsModal: false})}>
					<Modal.Body>
						<div>
							<div class="modal-body">
								<div className="imap checkout imap team">
									<h1 className="text-center">MailReach couldn't connect to these mailboxes</h1>
								</div>
								<div className="errors">
									<ul>
										{this.state.errors.map((error, index) => {
											return (
												<li >
													<span className="email">{error.email}</span>
													<Tooltip title={error.error} placement="bottom">
														<span className="status">
															<i class="far fa-times-circle" aria-hidden="true"></i> Error
														</span>
													</Tooltip>
												</li>
											)
										})}
									</ul>
								</div>
							</div>
						</div>
						<div className="footer text-center">
							<Button variant="secondary" onClick={() => this.setState({showErrorsModal: false})}>
								Back to MailReach
							</Button>
						</div>
					</Modal.Body>
				</Modal>

        <style dangerouslySetInnerHTML={{
          __html: `
            footer, .sidebar {
              display: none;
            }
          `
        }} />
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		currentProject: state.currentProject,
		currentPricing: state.currentPricing
	};
}

function mapDispatchToProps(dispatch) {
	return {
		projectsActions: bindActionCreators(projectsActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardWorkspaceAddAccountsConnectV2));
