const request = require('superagent');

class ConversationMessageLogService {
  static getSentGraph(id, provider, pastDays = 15) {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}conversation_message_logs/${id}/sent/graph?past_days=${pastDays}${provider != 'All' ? '&provider=' + provider : ''}`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }

  static getRecievedGraph(id, provider, pastDays = 15) {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}conversation_message_logs/${id}/received/graph?past_days=${pastDays}${provider != 'All' ? '&provider=' + provider : ''}`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }

  static getSentsByCategorie(id, category, provider, pastDays = 15) {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}conversation_message_logs/${id}/sent/categories/graph/${category}?past_days=${pastDays}${provider != 'All' ? '&provider=' + provider : ''}`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }
}

export default ConversationMessageLogService;
