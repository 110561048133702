import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import { Button, Modal } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';
import Moment from 'react-moment';
import { NavLink } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import {ToastContainer, toast} from 'react-toastify';

import * as notificationsActions from '../../actions/notifications.actions';

import NotificationService from '../../services/notification.service';
import RecipeService from '../../services/recipe.service';

const modalTitle = {
	'ACCOUNT_DISCONNECTED': 'Disconnected accounts',
	'ACCOUNT_BOUNCE': 'Configuration problem',
	'ACCOUNT_OUTLOOK_MISSING': 'Missing emails on Outlook',
	'ACCOUNT_SENDINBLUE_SMTP_ACTIVATION': 'Sendinblue SMTP problem',
	'ACCOUNT_MISSING_INBOUND_MESSAGES': 'Missing inbound warming emails',
	'ACCOUNT_GMAIL_MISSING_ALIAS': 'Setup problem with your Gmail aliases.',
	'ACCOUNT_SENDGRID_SMTP_ACTIVATION': 'SendGrid sender identity issue',
	'ACCOUNT_GMAIL_MAIL_SERVICE_NOT_ENABLED': 'Google sending issue',
	'GMAIL_INVALID_SENDER_ALIASE': 'Google sender issue',
	'OUTLOOK_INVALID_SENDER_ALIASE': 'Outlook sender issue',
	'REPUTATION_RECOVERY_DISABLED': 'Going back to normal warming mode',
	'DOMAIN_EXPIRE_SOON': 'You have domains expiring in the next days',
	'DOMAIN_INVALID_SPF': 'Missing or Invalid SPF record',
	'DOMAIN_INVALID_DKIM': 'Missing or Invalid DKIM record',
	'ACCOUNT_SENDGRID_MISSING': 'Missing emails',
	'REPUTATION_RECOVERY': 'Better warming modes to review'
}

const notificationsTypes = [{
		'type': 'ACCOUNT_BOUNCE',
		'modal_title': 'Configuration problems',
		'title': 'a configuration problem',
		'modal_text': 'These email accounts have a configuration problem. This has to be fixed on your end.',
		'link': 'https://help.mailreach.co/en/article/how-to-fix-email-configuration-problems-3rh7ex/'
	},
	{
		'type': 'ACCOUNT_OUTLOOK_MISSING',
		'modal_title': 'Missing emails on Outlook',
		'title': 'a problem of missing emails on Outlook',
		'modal_text': 'These email accounts have a configuration problem. This has to be fixed on your end.',
		'link': 'https://help.mailreach.co/en/article/how-to-fix-bounces-notifications-and-missing-messages-on-mailreach-emails-outlookoffice-365-18zjs68/?bust=1635859343466'
	},
	{
		'type': 'ACCOUNT_SENDINBLUE_SMTP_ACTIVATION',
		'modal_title': 'Sendinblue SMTP problem',
		'title': 'the Sendinblue SMTP issue',
		'modal_text': 'These email accounts have a Sendinblue SMTP issue. It has to be fixed on your end',
		'link': 'https://help.mailreach.co/en/article/how-to-get-your-sendinblue-smtp-account-activated-1v0cj4y/'
	},
	{
		'type': 'ACCOUNT_MISSING_INBOUND_MESSAGES',
		'modal_title': 'Missing inbound warming emails',
		'title': 'a problem of inbound warming emails (IMAP)',
		'modal_text': 'MailReach can\'t find inbound warming emails on these accounts. It has to be fixed on your end.',
		'link': 'https://help.mailreach.co/en/article/fix-mailreach-cant-find-inbound-warming-emails-in-your-inbox-imap-1n8dv34/'
	},
	{
		'type': 'ACCOUNT_GMAIL_MISSING_ALIAS',
		'modal_title': 'Setup problem with your Gmail alias(es)',
		'title': 'a problem with your Gmail alias(es)',
		'modal_text': 'There is a setup problem with your Gmail alias(es). It has to be fixed on your end.',
		'link': 'https://help.mailreach.co/en/article/how-to-fix-misconfigured-gmail-google-workspace-aliases-18kahog/'
	},
	{
		'type': 'ACCOUNT_SENDGRID_SMTP_ACTIVATION',
		'modal_title': 'SendGrid sender identity issues',
		'title': 'the SendGrid sender identity issue',
		'modal_text': 'These email accounts have a SendGrid sender identity issue. It has to be fixed on your end.',
		'link': 'https://docs.sendgrid.com/for-developers/sending-email/sender-identity'
	},
	{
		'type': 'ACCOUNT_GMAIL_MAIL_SERVICE_NOT_ENABLED',
		'modal_title': 'Google sending issue',
		'title': 'a Google sending issue',
		'modal_text': 'These Gmail accounts are not able to send warming emails. It has to be fixed on your end.',
		'link': 'https://help.mailreach.co/en/article/fix-mailreach-cant-send-emails-from-your-google-account-16f7n26/'
	},
	{
		'type': 'GMAIL_INVALID_SENDER_ALIASE',
		'modal_title': 'Google sender issue',
		'title': 'a sending address problem',
		'modal_text': 'These Gmail accounts are not able to send emails from the address you want to warm. It has to be fixed on your end.',
		'link': 'https://help.mailreach.co/en/article/fix-problem-of-sending-address-1mo16dy/'
	},
	{
		'type': 'OUTLOOK_INVALID_SENDER_ALIASE',
		'modal_title': 'Outlook sender issue',
		'title': 'a sending address problem',
		'modal_text': 'These Outlook accounts are not able to send emails from the address you want to warm. It has to be fixed on your end.',
		'link': 'https://help.mailreach.co/en/article/fix-problem-of-sending-address-1mo16dy/'
	},
	{
		'type': 'ACCOUNT_DISCONNECTED',
		'modal_title': 'Disconnected accounts',
		'title': 'Disconnected accounts',
		'modal_text': 'These Gmail accounts are not able to send warming emails. It has to be fixed on your end.',
		'link': 'https://help.mailreach.co/en/article/fix-mailreach-cant-send-emails-from-your-google-account-16f7n26/'
	},
	{
		'type': 'REPUTATION_RECOVERY',
		'modal_title': 'Better warming mode identified',
		'title': 'Better warming recipe identified',
		'modal_text': 'These Gmail accounts are not able to send warming emails. It has to be fixed on your end.',
		'link': 'https://help.mailreach.co/en/article/fix-mailreach-cant-send-emails-from-your-google-account-16f7n26/'
	},
	{
		'type': 'REPUTATION_RECOVERY_DISABLED',
		'modal_title': 'Better warming mode worked',
		'title': 'Better warming recipe worked',
		'modal_text': 'he recovery mode worked for X accounts, the volume of MailReach emails will gradually go back to normal for them.',
		'link': 'https://help.mailreach.co/en/article/fix-mailreach-cant-send-emails-from-your-google-account-16f7n26/'
	},
	{
		'type': 'DOMAIN_EXPIRE_SOON',
		'modal_title': 'Expired',
		'title': 'Better warming recipe identified',
		'modal_text': 'he recovery mode worked for X accounts, the volume of MailReach emails will gradually go back to normal for them.',
		'link': 'https://help.mailreach.co/en/article/fix-mailreach-cant-send-emails-from-your-google-account-16f7n26/'
	},
	{
		'type': 'DOMAIN_INVALID_SPF',
		'modal_title': 'Missing or Invalid SPF record',
		'title': 'Missing or wrong SPF record',
		'modal_text': '"X email accounts have a missing or invalid SPF record',
		'link': 'https://help.mailreach.co/en/article/how-to-properly-set-up-spf-and-dkim-records-1hlbcuq/'
	},
	{
		'type': 'DOMAIN_INVALID_DKIM',
		'modal_title': 'Missing or wrong DKIM record',
		'title': 'Missing or Invalid DKIM record',
		'modal_text': 'X email accounts have a missing or invalid DKIM record',
		'link': 'https://help.mailreach.co/en/article/how-to-properly-set-up-spf-and-dkim-records-1hlbcuq/'
	},
	{
		'type': 'ACCOUNT_SENDGRID_MISSING',
		'modal_title': 'Missing emails',
		'title': 'a problem of missing emails',
		'modal_text': 'These email accounts have a problem of missing emails. This has to be fixed on your end',
		'link': 'https://help.mailreach.co/en/article/how-to-fix-the-problem-of-missing-emails-on-sendgrid-1upe8b2/'
	},
]

class Notifications extends React.Component {

	constructor(props) {
		super(props);
		this.state = {affectedAccounts: [], onboardingScoreStep: 0, onboardingStep: 1};
		this.openAffectedAccountsModal = this.openAffectedAccountsModal.bind(this);
		this.handleCloseAffectedAccountsModal = this.handleCloseAffectedAccountsModal.bind(this);
		this.openLink = this.openLink.bind(this);
		this.reloadNotifications = this.reloadNotifications.bind(this);
		this.applyAllRecipes = this.applyAllRecipes.bind(this);
	}


	componentWillReceiveProps(nextProps) {
	}


	handleCloseAffectedAccountsModal() {
		this.setState({showAffectedAccountsModal: false})
	}

	reloadNotifications(){
		this.props.notificationsActions.getAll();
	}

	openAffectedAccountsModal(event) {
		NotificationService.by_event(event).then((response) => {
			this.setState({affectedAccounts: response.body, showAffectedAccountsModal: true, currentAffectedModalEvent: event, showAffectedAccountsModalTitle: modalTitle[event]})
		})
		.catch((error) => {
			toast.error('Oops, we have an issue, try again');
		});
	}

	applyAllRecipes() {
		let c = this;
		RecipeService.enableAll().then((response) => {
			toast.success('New warming mode successfully applied to all concerned accounts 🙌');
			setInterval(function() {
				c.props.notificationsActions.getAll();
			}, 5000 );
		})
		.catch((error) => {
			toast.error('Oops, we have an issue, try again');
		});
	}

	openLink(url){
		if(this.props.cockpit){
			window.open(url, "_blank", "noreferrer");
		}
	}


	findArrayElementByEdit(type) {
	 	return notificationsTypes.find((element) => {
		 	if(element.type === type){
				return element
			}
	 	})
 	}

	render() {
		return (
      <>

			{this.props.summary &&
				<>
					{Object.keys(this.props.summary).length > 0 &&
						<>
							{(this.props.summary.ACCOUNT_DISCONNECTED > 1) &&

								<div className="alert status warning card" onClick={() => this.openAffectedAccountsModal('ACCOUNT_DISCONNECTED') }>

									<div className="txt grouped">
										{this.props.summary.ACCOUNT_DISCONNECTED} email accounts are disconnected from MailReach. Reconnect them ASAP!

										{!this.props.cockpit &&
											<button onClick={() => this.openAffectedAccountsModal('ACCOUNT_DISCONNECTED') } className="btn pull-right" activeClassName="active">
												See affected accounts
											</button>
										}

										{this.props.cockpit &&
											<div className="severity">
												High
											</div>
										}
									</div>

								</div>
							}

							{(this.props.summary.DOMAIN_EXPIRE_SOON > 1) &&
								<div className="alert status warning card bounce" onClick={() => this.openAffectedAccountsModal('DOMAIN_EXPIRE_SOON') }>

									<div className="txt grouped">
										{this.props.summary.DOMAIN_EXPIRE_SOON} domains are expiring in the next days - make sure they get renewed!

										{!this.props.cockpit &&
											<button onClick={() => this.openAffectedAccountsModal('DOMAIN_EXPIRE_SOON') } className="btn pull-right" activeClassName="active">
												See affected domains
											</button>
										}

										{this.props.cockpit &&
											<div className="severity">
												High
											</div>
										}
									</div>
								</div>
							}

							{(this.props.summary.DOMAIN_INVALID_SPF > 1) &&
								<div className="alert status warning card bounce" onClick={() => this.openAffectedAccountsModal('DOMAIN_INVALID_SPF') }>

									<div className="txt grouped">
										{this.props.summary.DOMAIN_INVALID_SPF} email accounts have a missing or invalid SPF record

										{!this.props.cockpit &&
											<button onClick={() => this.openAffectedAccountsModal('DOMAIN_INVALID_SPF') } className="btn pull-right" activeClassName="active">
												See affected accounts
											</button>
										}

										{this.props.cockpit &&
											<div className="severity">
												High
											</div>
										}
									</div>
								</div>
							}

							{(this.props.summary.DOMAIN_INVALID_DKIM > 1) &&
								<div className="alert status warning card bounce" onClick={() => this.openAffectedAccountsModal('DOMAIN_INVALID_DKIM') }>

									<div className="txt grouped">
										{this.props.summary.DOMAIN_INVALID_DKIM} email accounts have a missing or invalid DKIM record

										{!this.props.cockpit &&
											<button onClick={() => this.openAffectedAccountsModal('DOMAIN_INVALID_DKIM') } className="btn pull-right" activeClassName="active">
												See affected accounts
											</button>
										}

										{this.props.cockpit &&
											<div className="severity">
												High
											</div>
										}
									</div>
								</div>
							}


							{(this.props.summary.REPUTATION_RECOVERY > 1 && this.props.currentProject.config_feature_recipes_enabled) &&

								<div className="alert status warning card recipe" onClick={() => this.openAffectedAccountsModal('REPUTATION_RECOVERY') }>
									<div className="txt grouped">
										MailReach has identified better warming modes for {this.props.summary.REPUTATION_RECOVERY} accounts, please review them for better results!

										{!this.props.cockpit &&
											<button onClick={() => this.openAffectedAccountsModal('REPUTATION_RECOVERY') } className="btn pull-right" activeClassName="active">
												See affected accounts
											</button>
										}

										{this.props.cockpit &&
											<div className="severity">
												High
											</div>
										}
									</div>

								</div>
							}

							{(this.props.summary.COPILOT_DALY_MAX_SENT_EXCEEDED > 1) &&

								<div className="alert status warning card" onClick={() => this.openAffectedAccountsModal('COPILOT_DALY_MAX_SENT_EXCEEDED') }>
								{this.props.summary.COPILOT_DALY_MAX_SENT_EXCEEDED} email accounts are sending too many campaign emails per day. Reduce that!
									<button onClick={() => this.openAffectedAccountsModal('COPILOT_DALY_MAX_SENT_EXCEEDED') } className="btn pull-right" activeClassName="active">
										More info
									</button>
								</div>
							}

							{(this.props.summary.COPILOT_DALY_MAX_SENT_EXCEEDED_NEW > 1) &&

								<div className="alert status warning card" onClick={() => this.openAffectedAccountsModal('COPILOT_DALY_MAX_SENT_EXCEEDED_NEW') }>
									{this.props.summary.COPILOT_DALY_MAX_SENT_EXCEEDED} email accounts are sending emails while their domain is new. Stop to protect their reputation!
									<button onClick={() => this.openAffectedAccountsModal('COPILOT_DALY_MAX_SENT_EXCEEDED_NEW') } className="btn pull-right" activeClassName="active">
										More info
									</button>
								</div>
							}

							{(this.props.summary.REPUTATION_RECOVERY_DISABLED > 1 && this.props.currentProject.config_feature_recipes_enabled) &&

								<div className="alert status warning card recipe" onClick={() => this.openAffectedAccountsModal('REPUTATION_RECOVERY_DISABLED') }>
									The recovery mode worked for {this.props.summary.REPUTATION_RECOVERY_DISABLED} accounts, the volume of MailReach emails will gradually go back to normal for them.

									{!this.props.cockpit &&
										<button onClick={() => this.openAffectedAccountsModal('REPUTATION_RECOVERY_DISABLED') } className="btn pull-right" activeClassName="active">
											More info
										</button>
									}
									{this.props.cockpit &&
										<div className="severity">
											High
										</div>
									}
								</div>
							}

							{notificationsTypes.map((type, index) => {
								if((this.props.summary[type.type] > 1) && (!['ACCOUNT_DISCONNECTED', 'REPUTATION_RECOVERY', 'REPUTATION_RECOVERY_DISABLED', 'DOMAIN_EXPIRE_SOON', 'DOMAIN_INVALID_SPF', 'DOMAIN_INVALID_DKIM'].includes(type.type))){
									return(
											<div className="alert status warning card bounce" onClick={() => this.openAffectedAccountsModal(type.type) }>
												<div className="txt grouped">
													{this.props.summary[type.type]} email accounts have {type.title}
													{!this.props.cockpit &&
														<button onClick={() => this.openAffectedAccountsModal(type.type) } className="btn pull-right" activeClassName="active">
															See affected accounts
														</button>
													}

													{this.props.cockpit &&
														<div className="severity">
															High
														</div>
													}
												</div>
											</div>
										)}
							})}
						</>
					}
				</>
			}

      {(this.props.notifications.length > 0) &&
        <>
          {this.props.notifications.map((notification, index) => {
            if(notification.event === 'ACCOUNT_DISCONNECTED'){
							if((this.props.summary.ACCOUNT_DISCONNECTED < 2 || this.props.showAccount)){
								return (
									<div key={notification.id} className="alert status warning card">
										<i onClick={() => this.props.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>

										 <div className="txt" onClick={() => this.openLink(`/dashboard/${this.props.match.params.project_id}/account/connect?edit_account_id=${notification.account_id}&reconnect=true`) }>
												Your address {notification.account_email} is disconnected from MailReach. Please reconnect it.

												{!this.props.cockpit &&
													<NavLink to={`/dashboard/${this.props.match.params.project_id}/account/connect?edit_account_id=${notification.account_id}&reconnect=true`} className="btn pull-right" activeClassName="active">
														Reconnect
													</NavLink>
												}


												{this.props.cockpit &&
													<div className="severity">
														High
													</div>
												}
											</div>
									</div>
								)
							}
            }else if (notification.event === 'ACCOUNT_FIRST_CONNECT'){
							if((this.props.summary.ACCOUNT_FIRST_CONNECT < 2 || this.props.showAccount) && !this.props.cockpit){
	              return(
	                <div key={notification.id} className="alert status infos card">
	                  <i onClick={() => this.props.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
	                  <div className="txt" onClick={() => this.openLink("https://help.mailreach.co/en/article/ive-connected-my-email-account-to-mailreach-what-happens-next-11g5m9q/") }>
	                    Congrats 🎉, You've successfully connected {notification.account_email}, to find out more about what happens next, <a target="_blank" href="https://help.mailreach.co/en/article/ive-connected-my-email-account-to-mailreach-what-happens-next-11g5m9q/">read this page</a>.
	                  </div>
	                </div>
	              )
							}
						}else if (notification.event === 'DOMAIN_EXPIRE_SOON'){
							if((this.props.summary.DOMAIN_EXPIRE_SOON < 2 || this.props.showAccount) && !this.props.cockpit){
								return(
									<div key={notification.id} className="alert status warning card bounce">
										<i onClick={() => this.props.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
										<div className="txt" onClick={() => this.setState({showDomainExpireationModal: notification}) }>
											Your domain {notification.domain} is expiring on <Moment format="MMM, D, Y">{notification.domain_expire_on}</Moment> - make sure it gets renewed!
											<button onClick={() => this.setState({showDomainExpireationModal: notification}) } className="btn pull-right" activeClassName="active">
												Show
											</button>
										</div>
									</div>
								)
							}
						}else if (notification.event === 'ACCOUNT_FIRST_CONNECT_QUICK_SIGNIN'){
              return(
                <div key={notification.id} className="alert status infos card">
                  <i onClick={() => this.props.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
                  <div className="txt">
                    Congrats 🎉, {notification.account_email} has been successfully connected to MailReach.
                  </div>
                </div>
              )
            }else if (notification.event === 'ACCOUNT_RECONNECTED'){
              return(
                <div key={notification.id} className="alert green status infos card">
                  <i onClick={() => this.props.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
                  <div className="txt">
                    You account {notification.account_email} has been automatically reconnected 💪
                  </div>
                </div>
              )
            }else if (notification.event === 'ADMIN_ADD'){
              return(
                <div key={notification.id} className="alert status infos card">
                  <i onClick={() => this.props.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
                  <div className="txt" dangerouslySetInnerHTML={{__html: notification.content}}>
                  </div>
                </div>
              )
            }else if ((notification.event === 'CREDIT_CARD_ADDED') && !this.props.cockpit){
              return(
                <div key={notification.id} className="alert status infos card">
                  <i onClick={() => this.props.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
                  <div className="txt">
                    Thanks for adding your payment information. You can now connect your first account to MailReach to start improving its reputation 🚀.
                  </div>
                </div>
              )
            }else if (notification.event === 'ACCOUNT_BOUNCE'){
							if((this.props.summary.ACCOUNT_BOUNCE < 2 || this.props.showAccount)){
	              return(
	                <div key={notification.id} className="alert status warning card bounce">
	                  <i onClick={() => this.props.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
	                  <div className="txt" onClick={() => this.openLink("https://help.mailreach.co/en/article/how-to-fix-email-configuration-problems-3rh7ex/")}>
	                    There's a configuration problem on {notification.account_email}.

											{(notification.account_email.length < 40 && !this.props.cockpit) &&
		                    <a href="https://help.mailreach.co/en/article/how-to-fix-email-configuration-problems-3rh7ex/" target="_blank" className="btn pull-right" activeClassName="active">
		                      Fix it
		                    </a>
											}

											{this.props.cockpit &&
												<div className="severity">
													High
												</div>
											}
	                  </div>
	                </div>
              	)
							}
            }else if (notification.event === 'ONBOARDING_SCORE'){
							// Open modal ONBOARDING_SCORE
	              return(
									<Modal className="reputation-modal settings-modal recpt modal-onboarding-score" show={true}>
										<Modal.Body>
											<>
												<h1 className="text-center">Your reputation score is ready, read this first ✨</h1>
												<div className="txt text-center">
												<h2 className="text-center">
													{this.state.onboardingScoreStep == 0 &&
														<>What does this score mean?</>
													}
													{this.state.onboardingScoreStep == 1 &&
														<>How to have the highest score?</>
													}
													{this.state.onboardingScoreStep == 2 &&
														<>You need to both warm AND use the Spam Checker</>
													}
												</h2>
													 {this.state.onboardingScoreStep == 0 &&
														 <>
															<ul>
															  <li className="one-line"><span className="ico">✅ </span><span className="txt">It’s the best indicator of your sender reputation (inbox, domain, associated IP).</span></li>
															  <li><span className="ico">✅ </span><span className="txt">It’s like your sender’s health. The higher your score, the better your sender reputation. The more likely to land in inbox.</span></li>
															  <li><span className="ico">✅ </span><span className="txt">It’s mostly based on where your warming emails land. The more warming emails landing in inbox, the higher your reputation.</span></li>
															</ul>

															<div className="clearfix"></div>

															<h2>What this score is NOT</h2>

															<ul>
																<li><span className="ico">❌ </span><span className="txt">It’s NOT a progression status of the warm up phase. MailReach is a long run reputation booster, not just a temporary launcher.</span>  </li>
																<li><span className="ico">❌ </span><span className="txt">It’s NOT an indicator of the deliverability of your own emails. MailReach doesn’t check your own activity.</span></li>
															</ul>
														</>
														}


														{this.state.onboardingScoreStep == 1 &&
															<>
																<ul>
																	<li className="one-line"><span className="ico">✅ </span><span className="txt">For new emails / domains / IPs, start sending email campaigns after at least 14 days of warm up. </span></li>
																  <li><span className="ico">✅ </span><span className="txt">Maintain email warming over time - this is what maintains your reputation. You need to have a high engagement rate, constantly.</span> </li>
																  <li><span className="ico">✅ </span><span className="txt">Very important: not following the best practices on your end will make your score drop.</span></li>
																</ul>
<div className="clearfix"></div>
																<h2>Here are our top guides to read to maximize your results :</h2>

																<ul>
																	<li>📘 <a href="https://www.mailreach.co/blog/how-to-prevent-emails-from-going-to-spam/" target="_blank">The Ultimate Email Deliverability Guide - How to prevent emails from going to spam</a></li>
																	<li>📒 <a href="https://uploads-ssl.webflow.com/6336c0ecc4a07d5c2a6b36c2/6373c5d51fc8289b3c17ffb2_The%20Ultimate%20Cold%20Email%20Deliverability%20Checklist%20-%20Updated.pdf" target="_blank">The Ultimate Cold Email Deliverability Checklist</a></li>
																	<li>📙 <a href="https://www.mailreach.co/blog/cold-email-deliverability-sending-strategy/" target="_blank">The Best Cold Email Deliverability Sending Strategy</a></li>
																</ul>
															</>
														}



														{this.state.onboardingScoreStep == 2 &&
															<ul>
																<li><span className="ico"><i class="fa-solid fa-circle-info"></i> </span><span className="txt">The warmer raises and maintains your reputation. That’s crucial to land in inbox, but it doesn’t make everything.  </span></li>
																<li><span className="ico"><i class="fa-solid fa-circle-info"></i> </span><span className="txt">You absolutely have to make sure your OWN emails don’t contain any spammy element.   </span></li>
																<li><span className="ico"><i class="fa-solid fa-circle-info"></i> </span><span className="txt">Once ready to send your campaigns, run a spam test in real sending conditions and find out your real inbox placement. </span> </li>
															</ul>
														}


												</div>
												<div className="clearfix"></div>
												<div className="footer text-center">
													{this.state.onboardingScoreStep < 2 &&
														<>
															<Button variant="secondary" onClick={() => this.props.closeNotification(notification.id)}>
																Close
															</Button>
															<Button className="primary btn" variant="secondary" onClick={() => this.setState({onboardingScoreStep: this.state.onboardingScoreStep + 1})}>
																Next
															</Button>
														</>
													}
													{this.state.onboardingScoreStep == 2 &&
														<>
															<Button className="primary btn" variant="secondary" onClick={() => this.props.closeNotification(notification.id)}>
																Finish
															</Button>
														</>
													}
												</div>
											</>
										</Modal.Body>
									</Modal>
              	)
            }else if (notification.event === 'ONBOARDING_COPILOT'){
							// Open modal ONBOARDING_SCORE
	              return(
									<Modal className="reputation-modal settings-modal recpt modal-onboarding-copilot" show={true}>
										<Modal.Body>
											<>
												<h1 className="text-center">MailReach Volume Checker is active on Beta ⚡️</h1>
												<div className="txt">
													<p>We’ve just released the "Volume Checker" to help you maximize your deliverability by monitoring your email volume.</p>

													<p>The goal is simple: alert you when you send too many emails to save your sender reputation.</p>

													<p>If you don’t want MailReach to monitor your email volume, you can disable the Volume Checker for all email accounts (<NavLink to={`/dashboard/${this.props.match.params.project_id}/settings/others`}>in the main settings</NavLink>) or for specific ones:</p>

													<ul>
														<li>1. Go on the Email Warmer menu</li>
														<li>2. Find your email account and click on ‘Show'</li>
														<li>3. Click on Settings > Toggle 'Volume Checker’</li>
													</ul>

													<p>We advise keeping it on for optimal sender reputation.</p>

													<p>Take care ❤️</p>

													<p>The Team.</p>
												</div>
												<div className="clearfix"></div>
												<div className="footer text-center">
														<>
															<Button variant="secondary" onClick={() => this.props.closeNotification(notification.id)}>
																Close
															</Button>
														</>
												</div>
											</>
										</Modal.Body>
									</Modal>
              	)
            }else if (notification.event === 'ONBOARDING_INIT'){
							// Open modal ONBOARDING_SCORE
	              return(
									<Modal className="reputation-modal settings-modal recpt modal-onboarding-init" show={true}>
										<Modal.Body>
											<>
												{this.state.onboardingStep == 1 &&
													<>
														<h1 className="text-left">Your first email account is successfully connected! 🎉</h1>
														<div className="txt">
															<p>MailReach is currently setting up automatic conversations with trusted contacts to raise its sender reputation.</p>
															<p>Check out our quick one-minute tour for more info.</p>
															<p className="text-center">
																<img src="/images/giphy_hey.gif" height="200" frameBorder="0" class="giphy-embed" />
															</p>
														</div>
													</>
												}
												{this.state.onboardingStep == 2 &&
													<>
														<h1 className="text-left">This inbox is going to receive emails, it’s normal 💌</h1>
														<div className="txt">
															<p>Addresses from our network will send emails to your inbox. Don’t be surprised, that’s part of the warming process.</p>
															<p>You can spot these warming emails by a code at the end of their body (e.g. 4903a-d5Ur-947b-).</p>
															<p>MailReach will automatically move them to a folder named “To Follow”, but there might be a delay. For instant filtering, you can <a className="underline" href="https://help.mailreach.co/en/article/how-to-filter-and-hide-mailreach-emails-in-your-inbox-gmail-office-365-1sxy72f/" target="_blank">set up a filter with your custom code</a>.</p>
														</div>
													</>
												}
												{this.state.onboardingStep == 3 &&
													<>
														<h1 className="text-left">Time and volume of warming emails ⏳📈</h1>
														<div className="txt">
														<p>By default, MailReach will gradually raise the number of daily warming emails to around 45.</p>
														<p>New email accounts/domains should stay on this mode to prevent any damage.</p>
														<p>It takes 14 days of warming, without sending emails on your end, to build a solid sender reputation.</p>
														<p>🚨 Email warming is crucial before, during and between campaigns to keep a high sender reputation!</p>
														<p>Only if your email account/domain is older and has a history of sending emails, you can change the warming volume in its settings.</p>
														</div>
													</>
												}
												{this.state.onboardingStep == 4 &&
													<>
														<h1 className="text-left">That’s crucial to follow the best sending practices 🏆🏎️</h1>
														<div className="txt">
															<p>MailReach will act as a boost to your car. But if your car has flat tires, your results will be limited.</p>

															<p>You have to make sure to follow the best sending practices. That’s a game changer for your open rates (and growth, as a result).</p>

															<p>Here is the best read to maximize your deliverability: <a className="underline" target="_blank" href="https://www.mailreach.co/blog/improve-email-deliverability">MailReach’s Ultimate Deliverability Guide</a></p>
														</div>
													</>
												}
												{this.state.onboardingStep == 5 &&
													<>
														<h1 className="text-left">What’s next ✨</h1>
														<div className="txt">
															<p>Come back in 24 hours to make sure there’s no issue with your setup. MailReach is going to check your authentication and ability to send and receive emails.</p>
															<p>If there’s any anomaly, a message will be displayed on your dashboard.</p>
															<p>In 72 hours, MailReach will display the Reputation Score of your inbox. This will be the best indicator of your sender health.</p>
															<p>That’s it, thanks for reading and welcome to the gang of smart emailers 🏆 🕶</p>
														</div>
													</>
												}
												<div className="clearfix"></div>
												<div className="footer text-center">
														<>
															{this.state.onboardingStep == 5 &&
																<Button onClick={() => this.props.closeNotification(notification.id)} className="primary btn margin-left-t" variant="secondary">
																	Close
																</Button>
															}

															{this.state.onboardingStep < 5 &&
																<>
																	<Button variant="secondary" className="close-red" onClick={() => this.props.closeNotification(notification.id)}>
																		Skip
																	</Button>

																	{this.state.onboardingStep == 1 &&
																		<Button onClick={() => this.setState({onboardingStep: (this.state.onboardingStep + 1)})} className="primary btn margin-left-t" variant="secondary">
																			Take the tour
																		</Button>
																	}

																	{(this.state.onboardingStep > 1 && this.state.onboardingStep < 5) &&
																		<Button onClick={() => this.setState({onboardingStep: (this.state.onboardingStep + 1)})} className="primary btn margin-left-t" variant="secondary">
																			Next >
																		</Button>
																	}
																</>
															}
														</>
												</div>
											</>
										</Modal.Body>
									</Modal>
              	)
            }else if (notification.event === 'ACCOUNT_OUTLOOK_MISSING'){
							if((this.props.summary.ACCOUNT_OUTLOOK_MISSING < 2 || this.props.showAccount)){
	                return(
	                  <div key={notification.id} className="alert status warning card bounce">
	                    <i onClick={() => this.props.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
	                    <div className="txt" onClick={() => this.openLink("https://help.mailreach.co/en/article/how-to-fix-bounces-notifications-and-missing-messages-on-mailreach-emails-outlookoffice-365-18zjs68/?bust=1635859343466") }>
	                      There's a problem of missing emails on {notification.account_email}.

												{!this.props.cockpit &&
													<a href="https://help.mailreach.co/en/article/how-to-fix-bounces-notifications-and-missing-messages-on-mailreach-emails-outlookoffice-365-18zjs68/?bust=1635859343466" target="_blank" className="btn pull-right">Fix it</a>
												}

												{this.props.cockpit &&
													<div className="severity">
														High
													</div>
												}
	                    </div>
	                  </div>
	                )
								}
							}else if (notification.event === 'ACCOUNT_GMAIL_MISSING_ALIAS'){
								if((this.props.summary.ACCOUNT_GMAIL_MISSING_ALIAS < 2 || this.props.showAccount)){
		                return(
		                  <div key={notification.id} className="alert status warning card bounce">
		                    <i onClick={() => this.props.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
		                    <div className="txt" onClick={() => this.openLink("https://help.mailreach.co/en/article/how-to-fix-misconfigured-gmail-google-workspace-aliases-18kahog/") }>
		                      There is a setup problem with your Gmail aliases. {notification.account_email}.

													{!this.props.cockpit &&
														<a href="https://help.mailreach.co/en/article/how-to-fix-misconfigured-gmail-google-workspace-aliases-18kahog/" target="_blank" className="btn pull-right">Fix it</a>
													}

													{this.props.cockpit &&
														<div className="severity">
															High
														</div>
													}
		                    </div>
		                  </div>
		                )
									}
	            }else if (notification.event === 'ACCOUNT_SENDINBLUE_SMTP_ACTIVATION'){
							if((this.props.summary.ACCOUNT_SENDINBLUE_SMTP_ACTIVATION < 2 || this.props.showAccount)){
	              return(
	                <div key={notification.id} className="alert status warning card bounce">
										<i onClick={() => this.props.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
	                  <div className="txt" onClick={() => this.openLink("https://help.mailreach.co/en/article/how-to-get-your-sendinblue-smtp-account-activated-1v0cj4y/") }>
	                   There's a problem of Sendinblue SMTP for {notification.account_email}.

										{!this.props.cockpit &&
										 	<a href="https://help.mailreach.co/en/article/how-to-get-your-sendinblue-smtp-account-activated-1v0cj4y/" target="_blank" className="btn pull-right">Fix it</a>
										}

										{this.props.cockpit &&
											<div className="severity">
												High
											</div>
										}
	                  </div>
	                </div>
	              )
							}
            }else if (notification.event === 'ACCOUNT_SENDGRID_SMTP_ACTIVATION'){
							if((this.props.summary.ACCOUNT_SENDGRID_SMTP_ACTIVATION < 2 || this.props.showAccount)){
	              return(
	                <div key={notification.id} className="alert status warning card bounce">
										<i onClick={() => this.props.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
	                  <div className="txt" onClick={() => this.openLink("https://docs.sendgrid.com/for-developers/sending-email/sender-identity") }>
	                    There's a problem with the SendGrid sender identity for {notification.account_email}.

											{!this.props.cockpit &&
												<a href="https://docs.sendgrid.com/for-developers/sending-email/sender-identity" target="_blank" className="btn pull-right">Fix it</a>
											}

											{this.props.cockpit &&
												<div className="severity">
													High
												</div>
											}
	                  </div>
	                </div>
	              )
							}
            }else if (notification.event === 'ACCOUNT_SENDGRID_MISSING'){
							if((this.props.summary.ACCOUNT_SENDGRID_MISSING < 2 || this.props.showAccount)){
	              return(
	                <div key={notification.id} className="alert status warning card bounce">
										<i onClick={() => this.props.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
	                  <div className="txt" onClick={() => this.openLink("https://help.mailreach.co/en/article/how-to-fix-the-problem-of-missing-emails-on-sendgrid-1upe8b2/") }>
	                    There's a problem of missing emails for {notification.account_email}.

											{!this.props.cockpit &&
												<a href="https://help.mailreach.co/en/article/how-to-fix-the-problem-of-missing-emails-on-sendgrid-1upe8b2/" target="_blank" className="btn pull-right">Fix it</a>
											}

											{this.props.cockpit &&
												<div className="severity">
													High
												</div>
											}
	                  </div>
	                </div>
	              )
							}
            }else if (notification.event === 'DOMAIN_INVALID_SPF'){
							if((this.props.summary.DOMAIN_INVALID_SPF < 2 || this.props.showAccount)){
	              return(
	                <div key={notification.id} className="alert status warning card bounce">
										<i onClick={() => this.props.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
	                  <div className="txt" onClick={() => this.openLink("https://help.mailreach.co/en/article/how-to-properly-set-up-spf-and-dkim-records-1hlbcuq/") }>
	                    The SPF record of {notification.account_email} is missing or misconfigured

											{!this.props.cockpit &&
												<a href="https://help.mailreach.co/en/article/how-to-properly-set-up-spf-and-dkim-records-1hlbcuq/" target="_blank" className="btn pull-right">Fix it</a>
											}

											{this.props.cockpit &&
												<div className="severity">
													High
												</div>
											}
	                  </div>
	                </div>
	              )
							}
            }else if (notification.event === 'DOMAIN_INVALID_DKIM'){
							if((this.props.summary.DOMAIN_INVALID_DKIM < 2 || this.props.showAccount)){
	              return(
	                <div key={notification.id} className="alert status warning card bounce">
										<i onClick={() => this.props.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
	                  <div className="txt" onClick={() => this.openLink("https://help.mailreach.co/en/article/how-to-properly-set-up-spf-and-dkim-records-1hlbcuq/") }>
	                    The DKIM record of {notification.account_email} is missing or misconfigured

											{!this.props.cockpit &&
												<a href="https://help.mailreach.co/en/article/how-to-properly-set-up-spf-and-dkim-records-1hlbcuq/" target="_blank" className="btn pull-right">Fix it</a>
											}

											{this.props.cockpit &&
												<div className="severity">
													High
												</div>
											}
	                  </div>
	                </div>
	              )
							}
            }else if (notification.event === 'ACCOUNT_MISSING_INBOUND_MESSAGES'){
							if((this.props.summary.ACCOUNT_MISSING_INBOUND_MESSAGES < 2 || this.props.showAccount)){
	              return(
	                <div key={notification.id} className="alert status warning card bounce">
	                  <i onClick={() => this.props.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
	                  <div className="txt" onClick={() => this.openLink("https://help.mailreach.co/en/article/fix-mailreach-cant-find-inbound-warming-emails-in-your-inbox-imap-1n8dv34/") }>
	                    MailReach can't find inbound warming emails in your inbox {notification.account_email} (IMAP).

											{!this.props.cockpit &&
												<>
													{notification.account_email.length < 40 &&
														<a href="https://help.mailreach.co/en/article/fix-mailreach-cant-find-inbound-warming-emails-in-your-inbox-imap-1n8dv34/" target="_blank" className="btn pull-right" activeClassName="active">
															Fix it
														</a>
													}
												</>
											}

											{this.props.cockpit &&
												<div className="severity">
													High
												</div>
											}
	                  </div>
	                </div>
	              )
							}
            }else if (notification.event === 'GMAIL_INVALID_SENDER_ALIASE'){
							if((this.props.summary.GMAIL_INVALID_SENDER_ALIASE < 2 || this.props.showAccount)){
	              return(
	                <div key={notification.id} className="alert status warning card bounce">
	                  <i onClick={() => this.props.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
	                  <div className="txt" onClick={() => this.openLink("https://help.mailreach.co/en/article/fix-problem-of-sending-address-1mo16dy/") }>
	                    There's a problem of sending address on {notification.account_email}. Fix it ASAP!

											{!this.props.cockpit &&
												<>
													{notification.account_email.length < 40 &&
														<a href="https://help.mailreach.co/en/article/fix-problem-of-sending-address-1mo16dy/" target="_blank" className="btn pull-right" activeClassName="active">
															Fix it
														</a>
													}
												</>
											}

											{this.props.cockpit &&
												<div className="severity">
													High
												</div>
											}
	                  </div>
	                </div>
	              )
							}
            }else if (notification.event === 'OUTLOOK_INVALID_SENDER_ALIASE'){
							if((this.props.summary.OUTLOOK_INVALID_SENDER_ALIASE < 2 || this.props.showAccount)){
	              return(
	                <div key={notification.id} className="alert status warning card bounce">
	                  <i onClick={() => this.props.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
	                  <div className="txt" onClick={() => this.openLink("https://help.mailreach.co/en/article/fix-problem-of-sending-address-1mo16dy/") }>
	                    There's a problem of sending address on {notification.account_email}. Fix it ASAP!

											{!this.props.cockpit &&
												<>
													{notification.account_email.length < 40 &&
														<a href="https://help.mailreach.co/en/article/fix-problem-of-sending-address-1mo16dy/" target="_blank" className="btn pull-right" activeClassName="active">
															Fix it
														</a>
													}
												</>
											}

											{this.props.cockpit &&
												<div className="severity">
													High
												</div>
											}

	                  </div>
	                </div>
	              )
							}
            }else if (notification.event === 'ACCOUNT_GMAIL_MAIL_SERVICE_NOT_ENABLED'){
							if((this.props.summary.ACCOUNT_MISSING_INBOUND_MESSAGES < 2 || this.props.showAccount)){
	              return(
	                <div key={notification.id} className="alert status warning card bounce">
	                  <i onClick={() => this.props.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
	                  <div className="txt" onClick={() => this.openLink("https://help.mailreach.co/en/article/fix-mailreach-cant-send-emails-from-your-google-account-16f7n26/") }>
	                    MailReach can't send warming emails from {notification.account_email}.

											{!this.props.cockpit &&
												<a href="https://help.mailreach.co/en/article/fix-mailreach-cant-send-emails-from-your-google-account-16f7n26/" target="_blank" className="btn pull-right">Fix it</a>
											}

											{this.props.cockpit &&
												<div className="severity">
													High
												</div>
											}
	                  </div>
	                </div>
	              )
							}
            }else if (notification.event === 'REPUTATION_RECOVERY' && this.props.currentProject.config_feature_recipes_enabled){
							if((this.props.summary.REPUTATION_RECOVERY < 2 || this.props.showAccount)){
	              return(
	                <div key={notification.id} className="alert status warning card recipe">
	                  <div className="txt" onClick={() => this.openLink(`/dashboard/${this.props.match.params.project_id}/accounts/${notification.account_id}/show?recipe=true&recipe_closed=true`) }>
											MailReach has identified a better warming mode for {notification.account_email}, please review it for better results!

											{!this.props.showAccount &&
												<NavLink to={`/dashboard/${this.props.match.params.project_id}/accounts/${notification.account_id}/show?recipe=true&recipe_closed=true`} className="btn pull-right" activeClassName="active">
													Review
												</NavLink>
											}

											{this.props.showAccount &&
												<button onClick={() => this.props.showWarmupRecipeModal()} className="btn pull-right" activeClassName="active">
													Review
												</button>
											}
	                  </div>
	                </div>
	              )
							}
            }else if (notification.event === 'REPUTATION_RECOVERY_DISABLED' && this.props.currentProject.config_feature_recipes_enabled){
							if((this.props.summary.REPUTATION_RECOVERY_DISABLED < 2 || this.props.showAccount)){
	              return(
	                <div key={notification.id} className="alert status warning card recipe">
										 <i onClick={() => this.props.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
	                  <div className="txt" onClick={() => this.openLink(`/dashboard/${this.props.match.params.project_id}/accounts/${notification.account_id}/show?recipe=true&recipe_closed=true`) }>
											The recovery mode worked for {notification.account_email}, the volume of MailReach emails will gradually go back to normal.

											{!this.props.showAccount &&
												<NavLink to={`/dashboard/${this.props.match.params.project_id}/accounts/${notification.account_id}/show?recipe=true&recipe_closed=true`} className="btn pull-right" activeClassName="active">
													Review
												</NavLink>
											}

											{this.props.showAccount &&
												<button onClick={() => this.setState({showWarmupRecipeDisabledModal: notification})} className="btn pull-right" activeClassName="active">
													More info
												</button>
											}

	                  </div>
	                </div>
	              )
							}
            }else if (notification.event === 'COPILOT_DALY_MAX_SENT_EXCEEDED'){
							if((this.props.summary.COPILOT_DALY_MAX_SENT_EXCEEDED < 2 || this.props.showAccount)){
	              return(
	                <div key={notification.id} className="alert status warning card">
	                  <div className="txt" onClick={() => this.openLink(`/dashboard/${this.props.match.params.project_id}/accounts/${notification.account_id}/show?recipe=true&recipe_closed=true`) }>

											The sending volume of
											{!this.props.cockpit &&
												<>
													<NavLink to={`/dashboard/${this.props.match.params.project_id}/accounts/${notification.account_id}/show?recipe=true&recipe_closed=true`}>{notification.account_email}</NavLink>
												</>
											}

											is too high. Reduce it to protect its reputation!

											{this.props.cockpit &&
												<div className="severity">
													High
												</div>
											}
	                  </div>
	                </div>
	              )
							}
            }else if (notification.event === 'COPILOT_DALY_MAX_SENT_EXCEEDED_NEW'){
							if((this.props.summary.COPILOT_DALY_MAX_SENT_EXCEEDED_NEW < 2 || this.props.showAccount)){
	              return(
	                <div key={notification.id} className="alert status warning card">
	                  <div className="txt" onClick={() => this.openLink(`/dashboard/${this.props.match.params.project_id}/accounts/${notification.account_id}/show?recipe=true&recipe_closed=true`) }>
											{!this.props.cockpit &&
												<>
													<NavLink to={`/dashboard/${this.props.match.params.project_id}/accounts/${notification.account_id}/show?recipe=true&recipe_closed=true`}>{notification.account_email}</NavLink>
												</>
											}

											is sending emails while the domain is new. Stop sending to save its reputation!

											{this.props.cockpit &&
												<div className="severity">
													High
												</div>
											}
	                  </div>
	                </div>
	              )
							}
            }
          }
          )}
        </>
      }

			<div className="clearfix"></div>

			<Modal className="add-account-modal fix-issue-modal" show={this.state.showAffectedAccountsModal} onHide={this.handleCloseAffectedAccountsModal}>
				<Modal.Body>
					<>
						<h1>{this.state.showAffectedAccountsModalTitle}</h1>

						{this.state.currentAffectedModalEvent &&
							<>
								{this.state.currentAffectedModalEvent == 'ACCOUNT_DISCONNECTED' &&
									<div className={this.state.currentAffectedModalEvent == 'ACCOUNT_DISCONNECTED' ? "alert status warning card" : "alert status warning card bounce"}>
										<span>
											You have {this.props.summary.ACCOUNT_DISCONNECTED} email accounts disconnected from MailReach. Reconnect them ASAP!
										</span>
									</div>
								}
								{this.state.currentAffectedModalEvent == 'REPUTATION_RECOVERY' &&
									<div className="alert status warning card recipe">
										<span>
											MailReach has identified better warming modes for {this.props.summary.REPUTATION_RECOVERY} accounts, please review them for better results!
										</span>
									</div>
								}
								{this.state.currentAffectedModalEvent == 'REPUTATION_RECOVERY_DISABLED' &&
									<div className="alert status warning card recipe">

										 The recovery mode worked for {this.props.summary.REPUTATION_RECOVERY_DISABLED} accounts, the volume of MailReach emails will gradually go back to normal for them.

									</div>
								}
								{this.state.currentAffectedModalEvent == 'COPILOT_DALY_MAX_SENT_EXCEEDED' &&
									<div className="alert status warning card">
										⚠️ Excessive Sending Volume - Reduce the daily volume of your own campaigns from {this.props.summary.COPILOT_DALY_MAX_SENT_EXCEEDED} emails accounts to save their sender reputation.
									</div>
								}

								{this.state.currentAffectedModalEvent == 'DOMAIN_EXPIRE_SOON' &&
									<div className="alert status warning card bounce">
										{this.props.summary.DOMAIN_EXPIRE_SOON} domains expiring in the next days - make sure they get renewed!
									</div>
								}

								{this.state.currentAffectedModalEvent == 'DOMAIN_INVALID_SPF' &&
									<div className="alert status warning card bounce">
										The SPF records for {this.props.summary.DOMAIN_INVALID_SPF} email accounts are missing or misconfigured.
									</div>
								}

								{this.state.currentAffectedModalEvent == 'DOMAIN_INVALID_DKIM' &&
									<div className="alert status warning card bounce">
										The DKIM records for {this.props.summary.DOMAIN_INVALID_DKIM} email accounts are missing or misconfigured.
									</div>
								}

								{!['ACCOUNT_DISCONNECTED', 'REPUTATION_RECOVERY', 'REPUTATION_RECOVERY_DISABLED', 'COPILOT_DALY_MAX_SENT_EXCEEDED', 'DOMAIN_EXPIRE_SOON', 'DOMAIN_INVALID_SPF', 'DOMAIN_INVALID_DKIM'].includes(this.state.currentAffectedModalEvent) &&
									<div className={this.state.currentAffectedModalEvent == 'ACCOUNT_DISCONNECTED' ? "alert status warning card" : "alert status warning card bounce"}>
										<span>
											{this.findArrayElementByEdit(this.state.currentAffectedModalEvent)['modal_text']}
										</span>

										<a href={`${this.findArrayElementByEdit(this.state.currentAffectedModalEvent)['link']}`}  target="_blank" target="_blank" className="btn pull-right">Fix</a>
									</div>
								}
							</>
						}


						{(this.state.currentAffectedModalEvent == 'REPUTATION_RECOVERY') &&
							<Button onClick={this.applyAllRecipes} className="btn btn-primary pull-right purple-btn" activeClassName="active">
								Apply to all
							</Button>
						}

						<table class="table">
							<thead>
								{this.state.currentAffectedModalEvent != 'DOMAIN_EXPIRE_SOON' &&
									<tr>
										<th scope="col">Account</th>
										<th scope="col"></th>
									</tr>
								}
								{this.state.currentAffectedModalEvent == 'DOMAIN_EXPIRE_SOON' &&
									<tr>
										<th scope="col">Domain</th>
										<th scope="col">Expires on</th>
										<th scope="col"></th>
									</tr>
								}
							</thead>
							<tbody>
							{this.state.affectedAccounts.map((notification, index) => {
								return (
									<tr>
										<td className="text-left account">
											{notification.account_email}
											{!notification.account_email ? notification.domain : ''}
										</td>
										{this.state.currentAffectedModalEvent == 'DOMAIN_EXPIRE_SOON' &&
											<td className="text-left account">
												<Moment format="MMM, D, Y">{notification.domain_expire_on}</Moment>
											</td>
										}
										<td>
											{this.state.currentAffectedModalEvent == 'ACCOUNT_DISCONNECTED' &&
												<NavLink to={`/dashboard/${this.props.match.params.project_id}/account/connect?edit_account_id=${notification.account_id}&reconnect=true`} className="btn btn-secondary pull-right" activeClassName="active">
													Reconnect
												</NavLink>
											}
											{this.state.currentAffectedModalEvent == 'REPUTATION_RECOVERY' &&
												<NavLink to={`/dashboard/${this.props.match.params.project_id}/accounts/${notification.account_id}/show?recipe=true`} className="btn btn-secondary pull-right" activeClassName="active">
													Review
												</NavLink>
											}
											{['DOMAIN_INVALID_SPF'].includes(this.state.currentAffectedModalEvent) &&
												<NavLink to={`/dashboard/${this.props.match.params.project_id}/accounts/${notification.account_id}/checkups?spf_invalid_modal=true`} className="btn btn-secondary pull-right" activeClassName="active">
													Fix it
												</NavLink>
											}
											{['DOMAIN_INVALID_DKIM'].includes(this.state.currentAffectedModalEvent) &&
												<NavLink to={`/dashboard/${this.props.match.params.project_id}/accounts/${notification.account_id}/checkups?dkim_invalid_modal=true`} className="btn btn-secondary pull-right" activeClassName="active">
													Fix it
												</NavLink>
											}
											{!['ACCOUNT_DISCONNECTED', 'REPUTATION_RECOVERY', 'DOMAIN_EXPIRE_SOON', 'DOMAIN_INVALID_SPF', 'DOMAIN_INVALID_DKIM'].includes(this.state.currentAffectedModalEvent) &&
												<NavLink to={`/dashboard/${this.props.match.params.project_id}/accounts/${notification.account_id}/show`} className="btn btn-secondary pull-right" activeClassName="active">
													Dashboard
												</NavLink>
											}
										</td>
									</tr>
							)})}
							</tbody>
						</table>

						<div className="footer">
							<Button variant="secondary" onClick={this.handleCloseAffectedAccountsModal}>
								Close
							</Button>
						</div>
					</>
				</Modal.Body>
			</Modal>

			<Modal className="reputation-modal settings-modal recpt" show={this.state.showWarmupRecipeDisabledModal} onHide={() => this.setState({showWarmupRecipeDisabledModal: false})}>
				<Modal.Body>
					<>
						<h1 className="text-center">
							Going back to normal warming mode
							<>
								<Tooltip title='This feature is currently in beta. If something looks wrong, please give us your feedback using the chat :)' placement="right">
									<div className="beta">
										Beta <i class="fa-solid fa-info-circle"></i>
									</div>
								</Tooltip>
							</>
						</h1>
						<div className="txt text-center">

							<div className="text-center">
								<p className="head-p">The sending reputation of {this.state.showWarmupRecipeDisabledModal ? this.state.showWarmupRecipeDisabledModal.account_email : ''} is doing much better. That's great! 🎉</p>

								<div className="col-md-offset-4 col-md-4 datac">
									<h1>>90%</h1>
									<span>of warming emails in inbox <br />in the last 7 days</span>
								</div>

								<div className="clearfix"></div>

								<div className="col-md-12">
									<p className="text-left">As the recovery mode worked, MailReach will now gradually decrease the warming volume to go back to the normal mode.</p>

									<p className="text-left">To avoid having reputation problems again, make sure to keep warming and follow the best practices!</p>
								</div>

							</div>
						</div>
						<div className="footer text-center">
							<Button variant="secondary" onClick={() => this.setState({showWarmupRecipeDisabledModal: false})}>
								Close
							</Button>
						</div>
					</>
				</Modal.Body>
			</Modal>

			<Modal className="reputation-modal settings-modal recpt" show={this.state.showDomainExpireationModal} onHide={() => this.setState({showDomainExpireationModal: false})}>
				<Modal.Body>
					{this.state.showDomainExpireationModal &&
						<>
							<h1 className="text-center">
								{this.state.showDomainExpireationModal.domain} is expiring on <Moment format="MMM, D, Y">{this.state.showDomainExpireationModal.domain_expire_on}</Moment>
							</h1>
							<div className="txt text-center">
								<p>We recommend you to make sure {this.state.showDomainExpireationModal.domain} gets renewed to avoid interrupting your sending.</p>
							</div>
							<div className="footer text-center">
								<Button className="primary btn" onClick={() => this.props.closeNotification(notification.id)}>
									It's renewed, don't show again
								</Button>

								<Button variant="secondary" onClick={() => this.setState({showDomainExpireationModal: false})}>
									Close
								</Button>
							</div>
						</>
					}
				</Modal.Body>
			</Modal>
      </>
		)
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		notificationsActions: bindActionCreators(notificationsActions, dispatch),
	};
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notifications));
