import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    // Have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    debug: false,
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    }
  });

// Get user only if have a jwt set
/*
if (localStorage.jwt) {
  UserService.get().then(response => {
    i18n.changeLanguage(response.locale, (err, t) => {
    if (err) return console.log('something went wrong loading', err);
    });
  }).catch(error => {
    console.error(error);
  });
}
*/

export default i18n;
