const request = require('superagent');

class CurrentProjectService {

  static get(id) {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}projects/current`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }

  static update(id, data) {
    return request
            .post(`${process.env['REACT_APP_BACKEND_URL']}projects/${id}`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
            .send(data);
  }
}

export default CurrentProjectService;
