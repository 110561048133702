import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import {toast} from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import {loadStripe} from '@stripe/stripe-js';
import InjectedCheckoutForm from './checkoutForm';
import {Elements} from '@stripe/react-stripe-js';

import i18n from "../../../i18n";

import ProjectService from '../../../services/project.service';


import './settings.css';

class DashboardSettingsApi extends React.Component {
	constructor(props) {
		super(props);
		this.state = {errors: [], project: {}, keyFieldState: 'password'}
		this.toggleField = this.toggleField.bind(this);
	}

	componentWillMount() {
    ProjectService.get(this.props.match.params.project_id).then((response) => {
      this.setState({project: response.body});
      localStorage.setItem('x-api-key', response.body.api_key);
    })
    .catch((error) => {
      this.setState({error: true,});
      toast.error('Oops, we have an error');
    });
	}

	toggleField(e){
		e.preventDefault();
		if(this.state.keyFieldState == 'password'){
      this.setState({keyFieldState: 'text'});
		} else {
			this.setState({keyFieldState: 'password'});
		}
	}

	signout(){
		localStorage.clear();
		//this.props.history.push(`/redirect`);
	}

	render() {
		return (
			<div className="app container add-credit-card">
        <div className="col-md-3">
					<div className="title">
						<h1>API</h1>
					</div>
          <div className="card">
						<ul className="nav-left">
							<li>
								<NavLink to={`/dashboard/${this.props.currentProject.id}/settings/billing`} activeClassName="active">
									<i class="far fa-credit-card"></i> Billing
								</NavLink>
							</li>
							<li>
								<NavLink to={`/dashboard/${this.props.currentProject.id}/settings/api`} activeClassName="active">
									<i class="fas fa-key"></i> API
								</NavLink>
							</li>
							<li>
								<NavLink to={`/dashboard/${this.props.currentProject.id}/settings/team`} activeClassName="active">
									<i class="fa-solid fa-people-group"></i> Team
								</NavLink>
							</li>
							<li>
								<NavLink to={`/dashboard/${this.props.currentProject.id}/settings/alerts`} activeClassName="active">
									<i class="fa-sharp fa-solid fa-bell"></i> Alerts
								</NavLink>
							</li>
							<li>
								<NavLink to={`/dashboard/${this.props.currentProject.id}/settings/password`} activeClassName="active">
									<i class="fa-solid fa-lock"></i> Change password
								</NavLink>
							</li>
								<li>
									<a href="#" onClick={this.signout} className="signout">
										<i class="fas fa-sign-out-alt"></i> Sign out
									</a>
								</li>
						</ul>
						<div className="clearfix"></div>
          </div>
        </div>
        <div className="col-md-9">
				<div className="row">
					<h1>Project API key</h1>
          <div className="card checkout imap">
					  <form>
						<br />
					    <div className="fieldCont col-md-9">
                <input type={this.state.keyFieldState} name="city" placeholder="API secret key" onChange={this.onChange} value={this.state.project.api_key} required/>
              </div>
							<div className="fieldCont col-md-3 text-center">
								 <button onClick={this.toggleField} className="btn purple-btn">
								 {this.state.keyFieldState == 'password' &&
								  <>
										Show
									</>
							   }
								 {this.state.keyFieldState == 'text' &&
									<>
										Hide
									</>
								 }
								 </button>
							</div>
						</form>
						<div className="fieldCont col-md-9">
						  <p>😎 Contact us on the chat and get the API doc for free.</p>
						</div>
													<div className="clearfix"></div>
					</div>
        </div>
			</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		currentUser: state.currentUser,
		currentProject: state.currentProject,
		currentPricing: state.currentPricing
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardSettingsApi));
