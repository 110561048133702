import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {ToastContainer, toast} from 'react-toastify';
import Loader from 'react-loader-spinner';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import queryString from 'query-string';


import AccountService from '../../../../../services/account.service';
import AutomatedTestService from '../../../../../services/automatedTest.service';

import DashboardAccountsConnect from '../../../accounts/connect/connect.js';


class DashboardSpamTestReconnectAccount extends React.Component {

	constructor(props) {
		super(props);
    this.state = {loading: false};
	}

	render() {
		if(this.state.loading){
			return(
				<div className="app container text-center">
					<div className="loader">
						<Loader
						 type="Oval"
						 color="#b7b7b7"
						 height="100"
						 width="100"
						/>
					</div>
				</div>
			)
		}

		return(
			<>
        <div className="app container home automated-test-accounts spamtest-auto">
          <div className="title">
            <h1 className="pull-left">Reconnect Spamchecker sending Accounts</h1>
            <NavLink to={`/dashboard/${this.props.match.params.project_id}/spam-tests/autos/lists`} className="btn btn-secondary grey pull-right">
              <i class="fa-solid fa-arrow-left"></i> Go back to your automated tests
            </NavLink>
            <div className="clearfix"></div>
          </div>

          <div className="row accounts">
            <DashboardAccountsConnect location={this.props.location} match={this.props.match} spamtestConnect={true} spamcheckerAddAccountSuccess={this.spamcheckerAddAccountSuccess}/>
          </div>
        </div>

      </>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		currentProject: state.currentProject,
		currentPricing: state.currentPricing
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardSpamTestReconnectAccount));
