import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {ToastContainer, toast} from 'react-toastify';
import Loader from 'react-loader-spinner';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import queryString from 'query-string';
import Switch from '@material-ui/core/Switch';
import { withStyles, makeStyles } from '@material-ui/core/styles';


import AlertService from '../../../../../services/alert.service';
import AutomatedTestService from '../../../../../services/automatedTest.service';
import SlackChannelService from '../../../../../services/slackChannel.service';


const GreenSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#3d46fb',
    },
    '&$checked + $track': {
      backgroundColor: '#3d46fb',
    },
  },
  checked: {},
  track: {},
})(Switch);


class DashboardSpamTestAutomatedAlerts extends React.Component {
	constructor(props) {
		super(props);
    this.state = {spamLogs: [], automatedTest: {}, slackChannels: [], addSlack: true, addWebhooks: false, alerts: [], alert: { active: true}};
    //this.createAutomatedTest = this.createAutomatedTest.bind(this);
    this.onChangeAlert = this.onChangeAlert.bind(this);
    this.createWebhookAlert = this.createWebhookAlert.bind(this);
	}

	componentWillMount() {

    let queryParams = queryString.parse(this.props.location.search);
    let error = queryParams.error;
    if(error){
      toast.error(error);
    }

    if(queryParams.success){
      toast.success(queryParams.success);
    }

    this.setState({queryParams});

		SlackChannelService.all().then((response) => {
      this.setState({slackChannels: response.body})
    })
    .catch((error) => {
      this.setState({error: true,loading: false});
      toast.error('Oops. An error occured.');
    });

		AlertService.get({automated_test_id: this.props.match.params.automated_test_id}).then((response) => {
      this.setState({alerts: response.body})

      if(response.body.length == 1){
        let currentAlert = response.body[0];
        if(currentAlert.hook_url){
          this.setState({addWebhooks: true})
        }

        if(currentAlert.slack_channel_id){
          this.setState({addSlack: true});
        }else{
          this.setState({addSlack: false});
        }

        if(response.body[0]){
          if(queryParams.selected_slack_channel_id){
            response.body[0].slack_channel_id = queryParams.selected_slack_channel_id;
          }
        }


        if(response.body[0]){
          this.setState({alert: response.body[0]});
        }
      }else{
        if(queryParams.selected_slack_channel_id){
          this.setState({alert: {active: true, slack_channel_id: queryParams.selected_slack_channel_id}});
        }
      }
    })
    .catch((error) => {
      this.setState({error: true, signupForm: false, loading: false});
      toast.error('Oops. An error occured.');
    });

    AutomatedTestService.get(this.props.match.params.automated_test_id).then((response) => {

      this.setState({automatedTest: response.body})

    })
    .catch((error) => {
      this.setState({error: true, signupForm: false, loading: false});
      toast.error('Oops. An error occured.');
    });
	}

	onChangeAlert(event) {
		let alert = this.state.alert;
		const field = event.target.name;
		const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		alert[field] = value
		return this.setState({alert});
	}

	createWebhookAlert(){

		let automatedTest = this.state.automatedTest;
		automatedTest.active = true;

		if(this.state.alert.active && (this.state.addSlack || this.state.addWebhooks)){
      if(this.state.alert.id){

        // Create a webhook alert
        AlertService.update({id: this.state.alert.id, channel: 'ALL', hook_url: this.state.alert.hook_url, automated_test_ids: [this.props.match.params.automated_test_id], active: true, slack_channel_id: this.state.alert.slack_channel_id, events: ['SPAMCHECK_AUTO_COMPLETED']}).then((response) => {
          AlertService.get({automated_test_id: this.props.match.params.automated_test_id}).then((response) => {

            AutomatedTestService.update(automatedTest).then((response) => {

                toast.success('Well done, automated spam test successfully set up 🙌');
                if(this.state.automatedTest.can_show_run_now){
                  this.props.history.push(`/dashboard/${this.props.currentProject.id}/spam-tests/autos/lists?last_automated_test_id=${this.props.match.params.automated_test_id}`);
                }else{
                  this.props.history.push(`/dashboard/${this.props.currentProject.id}/spam-tests/autos/lists`);
                }
            })
            .catch((error) => {
              this.setState({error: true, signupForm: false, loading: false});
              toast.error('Oops. An error occured.');
            });
          })
          .catch((error) => {
            this.setState({error: true,});
            toast.error('Oops, we have an error');
          });
        })
        .catch((error) => {
          this.setState({error: true,});
          toast.error('Oops, we have an error');
        });

      }else{
  			// Create a webhook alert
  			AlertService.create({channel: 'ALL', hook_url: this.state.alert.hook_url, automated_test_ids: [this.props.match.params.automated_test_id], active: true, slack_channel_id: this.state.alert.slack_channel_id, events: ['SPAMCHECK_AUTO_COMPLETED']}).then((response) => {
  				AlertService.get({automated_test_id: this.props.match.params.automated_test_id}).then((response) => {

  					AutomatedTestService.update(automatedTest).then((response) => {

  							toast.success('Well done, automated spam test successfully set up 🙌');
                if(this.state.automatedTest.can_show_run_now){
                  this.props.history.push(`/dashboard/${this.props.currentProject.id}/spam-tests/autos/lists?last_automated_test_id=${this.props.match.params.automated_test_id}`);
                }else{
                  this.props.history.push(`/dashboard/${this.props.currentProject.id}/spam-tests/autos/lists`);
                }

  			    })
  			    .catch((error) => {
  			      this.setState({error: true, signupForm: false, loading: false});
  			      toast.error('Oops. An error occured.');
  			    });
  				})
  				.catch((error) => {
  					this.setState({error: true,});
  					toast.error('Oops, we have an error');
  				});
  			})
  			.catch((error) => {
  				this.setState({error: true,});
  				toast.error('Oops, we have an error');
  			});
      }
		}else{
      console.log('zzzz')
			// Skip and redirect
			AutomatedTestService.update(automatedTest).then((response) => {
					toast.success('Well done, automated spam test successfully set up 🙌');
          if(this.state.automatedTest.can_show_run_now){
            this.props.history.push(`/dashboard/${this.props.currentProject.id}/spam-tests/autos/lists?last_automated_test_id=${this.props.match.params.automated_test_id}`);
          }else{
            this.props.history.push(`/dashboard/${this.props.currentProject.id}/spam-tests/autos/lists`);
          }

			})
			.catch((error) => {
				this.setState({error: true, signupForm: false, loading: false});
				toast.error('Oops. An error occured.');
			});
		}
	}


	render() {
		if(this.state.loading){
			return(
				<div className="app container text-center">
					<div className="loader">
						<Loader
						 type="Oval"
						 color="#b7b7b7"
						 height="100"
						 width="100"
						/>
					</div>
				</div>
			)
		}


		return(
			<>
        <div className="app container home">

          <div className="progress-automated-test">
            <ul>
              <NavLink to={`/dashboard/${this.props.currentProject.id}/spam-tests/auto/${this.state.automatedTest.id}/accounts`} className="done"></NavLink>
              <NavLink to={`/dashboard/${this.props.currentProject.id}/spam-tests/auto/${this.state.automatedTest.id}/template`} className="done"></NavLink>
              <NavLink to={`/dashboard/${this.props.currentProject.id}/spam-tests/auto/${this.state.automatedTest.id}/target`} className="done"></NavLink>
              <NavLink to={`/dashboard/${this.props.currentProject.id}/spam-tests/auto/${this.state.automatedTest.id}/frequency`} className="done"></NavLink>
							<li className="selected"></li>
            </ul>
            <div className="bar"></div>
          </div>

          <h1>Set up notifications</h1>

					{this.state.alerts.length > 1 &&
						<div className="card alerts-test-card">
							Manage alert on your alerts page - <NavLink to={`/dashboard/${this.props.currentProject.id}/settings/alerts`}>Manage</NavLink>
						</div>
					}

					{this.state.alerts.length < 2 &&
						<div className="card alerts-test-card">

						{this.state.alert.active &&
							<>

							<div className="notif-chan">

							<div className="col-md-1">
								<GreenSwitch
									checked={this.state.addSlack}
									onChange={() => { this.setState({addSlack: !this.state.addSlack})}}
									name="alert_active"
									inputProps={{ 'aria-label': 'secondary checkbox' }}
								/>
							</div>
							<div className="col-md-11">
								<h4>Slack</h4>
								<p>Receive a notification on Slack each time this test is completed</p>
								{this.state.addSlack &&
									<>
                    {this.state.slackChannels.length > 0 &&
                      <>
    										<div className="fieldCont col-md-6">
    											<form>
    												<select onChange={this.onChangeAlert} name="slack_channel_id">
    												<option value="">Select a Slack channel</option>
    												{this.state.slackChannels.map((channel, index) => {
    													return (
    														<option selected={((this.state.alert.slack_channel_id == channel.id))} value={channel.id}>{channel.channel_name}</option>
    													)})}
    												</select>
    												</form>
    										</div>

    										<div className="fieldCont col-md-6">
    										<a href={`${process.env.REACT_APP_BACKEND_URL}slack_auth/redirect?automated_spamtest_id=${this.props.match.params.automated_test_id}&project_id=${this.props.currentProject.join_project_team_token}`} className="small-slack-btn">
    										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.8 122.8"><path d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z" fill="#e01e5a"></path><path d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z" fill="#36c5f0"></path><path d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z" fill="#2eb67d"></path><path d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z" fill="#ecb22e"></path></svg>
    											Link to another Slack Channel
    										</a>
    										<div className="clearfix"></div>
    										</div>
                      <div className="clearfix"></div>
                      </>
                    }

                    {(this.state.slackChannels.length == 0) &&
                      <div className="fieldCont col-md-6">
                      <a href={`${process.env.REACT_APP_BACKEND_URL}slack_auth/redirect?automated_spamtest_id=${this.props.match.params.automated_test_id}&project_id=${this.props.currentProject.join_project_team_token}`} className="small-slack-btn">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.8 122.8"><path d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z" fill="#e01e5a"></path><path d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z" fill="#36c5f0"></path><path d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z" fill="#2eb67d"></path><path d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z" fill="#ecb22e"></path></svg>
                        Link a Slack Channel
                      </a>
                      <div className="clearfix"></div>
                      </div>
                    }
							</>
							}
							</div>
              <div className="clearfix"></div>
							</div>


							<div className="col-md-1">
								<GreenSwitch
									checked={this.state.addWebhooks}
									onChange={() => { this.setState({addWebhooks: !this.state.addWebhooks})}}
									name="alert_active"
									inputProps={{ 'aria-label': 'secondary checkbox' }}
								/>
							</div>

							<div className="col-md-11">
								<h4>Webhook</h4>
								<p>Post to webhook URL each time this test is completed</p>
								<div className="col-md-6">
									{this.state.addWebhooks &&
										<div className="imap">
											<form>
												<div className="fieldCont">
													<input placeholder="Webhook url" type="text" name="hook_url" value={this.state.alert.hook_url} onChange={this.onChangeAlert}/>
												</div>
											</form>
										</div>
									}
									</div>
									<div className="clearfix"></div>
								</div>
							</>
						}
																<div className="clearfix"></div>
						</div>
						}



          <div className="text-center automated-spamtest-btns">
            <NavLink to={`/dashboard/${this.props.currentProject.id}/spam-tests/auto/${this.state.automatedTest.id}/frequency`} className="btn btn-primary grey">
              Go back
            </NavLink>
            <button disabled={((this.state.addSlack && !this.state.alert.slack_channel_id && (this.state.alerts.length < 2)) || (this.state.addWebhooks && !this.state.alert.hook_url && (this.state.alerts.length < 2)))} className="btn btn-primary purple" onClick={this.createWebhookAlert}>Save & Finish</button>
          </div>

          </div>

      </>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		currentProject: state.currentProject,
		currentPricing: state.currentPricing
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardSpamTestAutomatedAlerts));
