import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {ToastContainer, toast} from 'react-toastify';
import Loader from 'react-loader-spinner';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import queryString from 'query-string';


import AccountService from '../../../../../services/account.service';
import AutomatedTestService from '../../../../../services/automatedTest.service';

import DashboardAccounts from '../../../accounts/connect/connect.js';


class DashboardSpamTestListAccounts extends React.Component {

	constructor(props) {
		super(props);
    this.state = {loading: true, accounts: [], accountPage: 0};
    this.delete = this.delete.bind(this);
    this.reloadAccounts = this.reloadAccounts.bind(this);
		this.nextPage = this.nextPage.bind(this);
	}

	componentWillMount() {
    this.setState({automatedTestId: this.props.match.params.automated_test_id});
    this.reloadAccounts();
	}

  reloadAccounts(){
    AccountService.all({spamchecker_only: true}, null, 0).then((response) => {
        this.setState({loading: false, accounts: response.body});
				if(response.body.length < 50){
					this.setState({endAccountsList: true});
				}
    })
    .catch((error) => {
      toast.error('Oops, we have an issue, try again');
    });
  }

	nextPage(pageNumber){
		this.setState({loadNextAccounts: true});
		let accounts = this.state.accounts;
		AccountService.all({spamchecker_only: true}, null, pageNumber).then((response) => {
			accounts = accounts.concat(response.body);
			this.setState({accounts, loading: false, accountPage: pageNumber, loadNextAccounts: false});
			if(response.body.length < 50){
				this.setState({endAccountsList: true});
			}
		})
		.catch((error) => {
			toast.error('Oops, we have an issue, try again');
			this.setState({loadNextAccounts: false});
		});
	}

  delete(accountId){
    AccountService.delete(accountId, 'spamtest').then((response) => {
      this.setState({loading: false, openDeleteAccountModal: false});
      this.reloadAccounts();
      toast.success("Account successfully deleted");
    }).catch((error) => {
      toast.error(i18n.t('alert.generic'));
    });
  }

	render() {
		if(this.state.loading){
			return(
				<div className="app container text-center">
					<div className="loader">
						<Loader
						 type="Oval"
						 color="#b7b7b7"
						 height="100"
						 width="100"
						/>
					</div>
				</div>
			)
		}

		return(
			<>
        <div className="app container home automated-test-accounts spamtest-auto">

        <div className="title">
          <h1 className="pull-left">Accounts linked to the spam test</h1>

          <NavLink to={`/dashboard/${this.props.match.params.project_id}/spam-tests/autos/lists`} className="btn btn-secondary grey pull-right">
            <i class="fa-solid fa-arrow-left"></i> Go back to your automated tests
          </NavLink>
          <div className="clearfix"></div>
        </div>

			{this.state.accounts.length == 0 &&
				<div className="card cockpit">
					<div className="cockpit-block-container">
						<div className="no-results no-account-issues">
							<h3>You don't have any accounts linked to MailReach for automated spam tests only.</h3>
							<img src="/images/no_data.svg" />
						</div>
					</div>
				</div>
			}

			{this.state.accounts.length > 0 &&
      <div className="row accounts">
        <div className="compressed-view">
        <div className="clearfix"></div>

        <div className="col-md-12 table-head">
          <div className="card">
            <div className="account">
              Email
            </div>
            <div className="status">
              Status
            </div>
          </div>
        </div>

          {this.state.accounts.map((account, index) => {
            return (
                <div className="col-md-12" key={account.id}>
                  <div className="card">
                    <div className="account pull-left" >
                      {account.email}

											{!this.state.compressedView &&
												<>
													{account.has_notifications &&
														<>
															<Tooltip title='This email is facing one or several technical issues that can only be fixed on your end. Fix the issue(s) ASAP!' placement="right">
																<div>
																	<NavLink to={`/dashboard/${this.props.match.params.project_id}/accounts/${account.id}/show`} activeClassName="active">
																		<i class="fa-solid fa-circle-exclamation"></i>
																	</NavLink>
																</div>
															</Tooltip>
														</>
													}
												</>
											}

                    </div>


                    <div className="status cursor-pointer">
                        {account.alive &&
                          <Tooltip title='Running' placement="left">
                            <><span data-tip="Running" className="stat alive"></span></>
                          </Tooltip>
                        }
                        {account.suspended &&
                          <Tooltip title='Account suspended' placement="left">
                            <><span data-tip="Suspended" className="stat kok"></span></>
                          </Tooltip>
                        }
                        {(!account.alive && !account.suspended) &&
                          <Tooltip title='Please connect this account again' placement="left">
                            <><span data-tip="Not alive" className="stat ko"></span></>
                          </Tooltip>
                        }
                    </div>


                                        <div className="pull-left linked-test-number">
                                          Linked to {account.automated_tests_count} tests
                                        </div>



                    <div className="pull-right">

											{!account.warming_enabled &&
												<>
												{account.automated_tests_count > 0 &&
		                      <button type="submit" class="btn btn-primary red pull-right" onClick={() => this.setState({openDeleteAccountModal: true, selectAccount: account})}>
		                        Disconnect
		                      </button>
												}
												{account.automated_tests_count == 0 &&
													<button type="submit" class="btn btn-primary red pull-right" onClick={() => this.delete(account.id)}>
														Disconnect
													</button>
												}
												</>
											}
											{account.warming_enabled &&

												<button class="btn btn-primary grey pull-right" disabled="true">
													<Tooltip title='This account is being warmed, to disconnect it, please go in its warming dashboard.' placement="left">
														<span>Disconnect</span>
													</Tooltip>
												</button>

											}

											{!account.alive &&
												<NavLink to={`/dashboard/${this.props.match.params.project_id}/spam-tests/account/${account.id}/reconnect?edit_account_id=${account.id}&reconnect=true`} className="btn btn-secondary pull-right" activeClassName="active">
													Reconnect
												</NavLink>
											}
                    </div>

                    <div className="clearfix"></div>
                  </div>
                </div>
              )
            })}

						</div>
            {(!this.state.loadNextAccounts && this.state.accounts && !this.state.endAccountsList && !this.state.search) &&
              <div className="text-center load-next-tests">
                <button className="load-next-tests-btn" onClick={() => this.nextPage(this.state.accountPage + 1)}>Load next accounts</button>
              </div>
            }

						</div>
					}

					<Modal className="add-account-modal edit-tag-modal" show={this.state.openDeleteAccountModal} onHide={() => this.setState({openDeleteAccountModal: false})}>
					  <Modal.Body>
					      <>
					        <h1>Are you sure?</h1>
					        <div className="txt">
					          <div className="col-md-12">
					            <p>This email is currently linked to an automated spam test. By disconnecting this account, it will prevent the spam test from running again</p>
					          </div>
					        </div><br /><br />

					        <div className="footer">
					          <Button variant="secondary" onClick={() => this.setState({openDeleteAccountModal: false})}>
					            Cancel
					          </Button>
					          <Button className="btn purple red" variant="secondary" onClick={() => this.delete(this.state.selectAccount.id)}>
					            Confirm
					          </Button>
					        </div>
					      </>
					  </Modal.Body>
					</Modal>

        </div>

      </>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		currentProject: state.currentProject,
		currentPricing: state.currentPricing
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardSpamTestListAccounts));
