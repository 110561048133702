import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {ToastContainer, toast} from 'react-toastify';
import Loader from 'react-loader-spinner';
import Tooltip from '@material-ui/core/Tooltip';
import Moment from 'react-moment';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import {CreatedInDayAgo} from "../../../../helpers/time"

import i18n from "../../../../i18n";

import ConversationTestService from '../../../../services/conversationTest.service';
import ConversationService from '../../../../services/conversation.service';
import TemplateService from '../../../../services/template.service';
import AccountService from '../../../../services/account.service';
import InappMessageService from '../../../../services/inappMessage.service';

import SpamCheckerService from '../../../../services/spamChecker.service';

import WordsAnalyzer from '../../../../components/words-analyzer/words-analyzer.js';
import CheckupDomain from '../../../../components/checkup-domain/checkup-domain.js';
import SpamcheckerAutofixAi from '../../../../components/spamchecker-autofix-ai/spamcheckerAutofixAi.js';

import SpamcheckerNextSteps from '../../../../components/spamchecker-next-steps/spamchecker-next-steps.js';


const testTypes = {
	btob: 'Professional',
	btoc: 'Personal',
	all: 'All'
};

const getTypeIco = {
		btob: '👔 Professional inboxes',
		btoc: '🙎 Personal inboxes',
		all: '‍🌎 All types of inboxes'
	}


class DashboardSpamTestResults extends React.Component {
	constructor(props) {
		super(props);
    this.state = {spamLogs: [], template: {}, loading: true, currentPage: 'test', error: {setup: 0}, currentTest:{account: {}}, test_type: 'all', messageContentPreview: 'content_html', headersToShow: []};
		this.reloadTest = this.reloadTest.bind(this);
		this.loadInsight = this.loadInsight.bind(this);
	}

	componentWillMount() {
    this.setState({testId: this.props.match.params.test_id});
    if(this.props.match.params.test_id){
			this.reloadTest(true);
    }
	}

	reloadTest(initLoading){
		if(!initLoading){
			this.setState({reloadTests: true});
		}

    SpamCheckerService.get(this.props.match.params.test_id).then((response) => {
			if(response.body.error){
				this.setState({missing: true});
			}

			if(response.body.analysis_results){
				if(response.body.analysis_results.nextsteps){
					if(!response.body.domain.spf_value){
						response.body.analysis_results.nextsteps.push('spamtest_domain_no_spf');
					}

					if(!response.body.domain.dkim_value){
						response.body.analysis_results.nextsteps.push('spamtest_domain_no_dkim');
					}

					if(!response.body.domain.dmarc_value){
						response.body.analysis_results.nextsteps.push('spamtest_domain_no_dmarc');
					}

					// Remove duplicate values
					response.body.analysis_results.nextsteps = [ ...new Set(response.body.analysis_results.nextsteps) ];
				}
			}

      this.setState({currentTest: response.body});
      this.filterAccounts(response.body.test_type);
      if(!response.body.completed && initLoading){
        // Reload test every 8s if not completed
        setInterval(
          () => this.reloadTest(),
          8000
        );
      }else{
				if(this.state.currentTest.domain){
					this.calculeNumberSetupAlert(this.state.currentTest);
				}
				this.loadInsight();
			}
      this.setState({loading: false, reloadTests: false});
    })
    .catch((error) => {
      console.error(error);
      //this.setState({error: true});
      toast.error('Oops, we have an error');
    });
	}

	//
	calculeNumberSetupAlert(currentTest){
		let errors = 0;
		// Calculate Setup error
		if(currentTest.domain){
			if(!currentTest.domain.spf_valid){
				errors = errors + 1;
			}
			if(!currentTest.domain.dkim_valid){
				errors = errors + 1;
			}


			if(currentTest.domain.creation_date){
				if(CreatedInDayAgo(currentTest.domain.creation_date) < 32){
					errors = errors + 1;
				}
			}

			SpamCheckerService.get_blacklists(currentTest.public_id).then((response) => {
				if(response.body){
					if(response.body.blacklists.length > 0){
						let blacklistsFounds = response.body.blacklists.filter((item) => item.listed).length;
						if(blacklistsFounds > 0){
							errors = errors + 1;
						}
						this.setState({error: {setup: errors}})
					}
				}
			});
		}
	}

	loadInsight(){
		let key = 'spamtest_good_deli_score_good_test';
		if(this.state.currentTest.final_score == 10){
			key = 'spamtest_perfect_test';
		}else if (this.state.currentTest.account.score >= 90 && this.state.currentTest.final_score >= 5 && this.state.currentTest.final_score < 10){
			key = 'spamtest_good-rep-score_imperfect-test';
		}else if (this.state.currentTest.account.score <= 59 && this.state.currentTest.final_score > 5 && this.state.currentTest.final_score < 10){
			key = 'spamtest_bad-rep-score_imperfect-test';
		}else if (this.state.currentTest.account.score >= 60 && this.state.currentTest.account.score <= 89 && this.state.currentTest.final_score <= 5){
			key = 'spamtest_damaged-rep-score_bad-test';
		}else if (this.state.currentTest.account.score >= 60  && this.state.currentTest.account.score <= 89 && this.state.currentTest.final_score > 5 && this.state.currentTest.final_score < 10){
			key = 'spamtest_damaged-rep-score_imperfect-test';
		}else if (this.state.currentTest.account.score <= 59 && this.state.currentTest.final_score <= 5){
			key = 'spamtest_bad-rep-score_bad-test';
		}else if (this.state.currentTest.account.score >= 90 && this.state.currentTest.final_score <= 5){
			key = 'spamtest_good-rep-score_bad-test';
		}else if (!this.state.currentTest.account){
			key = 'spamtest_sender_not_connected';
		}else if (this.state.currentTest.final_score == 10){
			key = 'spamtest_perfect-test';
		}

		if (!this.state.currentTest.account.id){
			key = 'spamtest_sender-not-connected';
		}

		if (this.state.currentTest.account.score == 0){
		 	key = 'spamtest_sender-connected_no-rep-score';
	 	}

		InappMessageService.get(key).then((response) => {
			if(response.body){
				this.setState({insightMessage: response.body.content});
			}
		})
		.catch((error) => {
			toast.error('Oops, we have an issue, try again');
		});
	}

	getScoreClass(score){
		if(score < 5){
			return 'bad';
		} else if (score < 7.5) {
			return 'average'
		}else {
			return 'good'
		}
	}

	filterAccounts(type){
		let freeMails = ['gmail.com', 'outlook.com', 'outlook.fr', 'hotmail.com', 'yahoo.com'];
		let spamLogs = this.state.currentTest.results;
		let test = this.state.currentTest;
		if(type == 'btob'){
			spamLogs.map(spamLog => spamLog.hidden = freeMails.includes(spamLog.email.split('@')[1]));
		}
		if(type == 'btoc'){
			spamLogs.map(spamLog => spamLog.hidden = !freeMails.includes(spamLog.email.split('@')[1]));
		}
		if(type == 'all'){
			spamLogs.map(spamLog => spamLog.hidden = false);
		}
		test.results = spamLogs;
		this.setState({currentTest: test, spamTestInboxFilter: type});
	}

	getInboxTypelabel(email){
		let freeMails = ['gmail.com', 'outlook.com', 'outlook.fr', 'hotmail.com', 'yahoo.com'];
		let domain = email.split('@')[1];
		if(freeMails.includes(domain)){
			return '<span class="inbox-type-label perso">Personal</span>'
		}else{
			return '<span class="inbox-type-label pro">Professional</span>'
		}
	}

	getInboxType(email){
		let freeMails = ['gmail.com', 'outlook.com', 'outlook.fr', 'hotmail.com', 'yahoo.com'];
		let domain = email.split('@')[1];
		if(freeMails.includes(domain)){
			return 'perso'
		}else{
			return 'pro'
		}
	}

	setTestType(type){
		// Set test type
		SpamCheckerService.update(this.props.match.params.test_id, type).then((response) => {
			// Reload test and show restults
			this.reloadTest();
			toast.success('Test type saved !');
		})
		.catch((error) => {
			console.error(error);
			toast.error('Oops, we have an issue, try again');
		});
	}

	copyText(value){
		navigator.clipboard.writeText(value);
		toast.success('Share link successfully copied 🙌');
	}

	openHeadersModal(log){
		if(Array.isArray(log.raw_headers)){
			this.setState({openShowHeadersModal: true, headersToShow: log.raw_headers});
		}
	}



	render() {
		if(this.state.loading){
			return(
				<div className="app container text-center">
					<div className="loader">
						<Loader
						 type="Oval"
						 color="#b7b7b7"
						 height="100"
						 width="100"
						/>
					</div>
				</div>
			)
		}

		if(this.state.missing){
			return(
				<div className="app container home">
					<div className="card upgrade-spamchecker">
						<div className="col-md-9">
							<h2>We didn't find your email</h2>
							<p>Please try again and make sure to include the custom code inside your email.</p>
						</div>

						<div className="col-md-3 text-center">
							<NavLink to={`/dashboard/${this.props.match.params.project_id}/spam-tests/home`} className="btn btn-primary green-bck" activeClassName="active">
								Start a new test
							</NavLink>
						</div>
						<div className="clearfix"></div>
					</div>
				</div>
			)
		}

		if(!this.state.currentTest.domain.id && !this.state.currentTest.completed){
			return(
				<div className="app container home">
					<div className="card upgrade-spamchecker">
						<div className="col-md-9">
							<h2>MailReach is waiting to receive your email</h2>
							<p>
							It usually takes between 20 and 60 seconds to load your result. If nothing shows up after 60 seconds :<br /><br />
							1. Are you sure you added the custom code in your email?<br />
							2. Are you sure your email has been sent?<br />
							</p>
						</div>

						<div className="col-md-3 text-center">
							<div className="loader">
								<Loader
								 type="Oval"
								 color="#b7b7b7"
								 height="100"
								 width="100"
								/>
							</div>
						</div>
						<div className="clearfix"></div>
					</div>
				</div>
			)
		}

		if(!this.state.currentTest.domain.id && this.state.currentTest.completed && !this.state.currentTest.score){
			return(
				<div className="app container home">
					<div className="card upgrade-spamchecker">
						<div className="col-md-9">
							<h2>MailReach is waiting to receive your email</h2>
							<p>
							It usually takes between 20 and 60 seconds to load your result. If nothing shows up after 60 seconds :<br /><br />
							1. Are you sure you added the custom code in your email?<br />
							2. Are you sure your email has been sent?<br />
							</p>
						</div>

						<div className="col-md-3 text-center">
							<div className="loader">
								<Loader
								 type="Oval"
								 color="#b7b7b7"
								 height="100"
								 width="100"
								/>
							</div>
						</div>
						<div className="clearfix"></div>
					</div>
				</div>
			)
		}

		if(!this.state.currentTest.domain && this.state.currentTest.completed){
			return(
				<div className="app container home">
					<div className="card upgrade-spamchecker">
						<div className="col-md-9">
							<h2>We didn't find your email</h2>
							<p>Please try again and make sure to include the custom code inside your email.</p>
						</div>

						<div className="col-md-3 text-center">
							<NavLink to={`/dashboard/${this.props.match.params.project_id}/spam-tests/home`} className="btn btn-primary green-bck" activeClassName="active">
								Start a new test
							</NavLink>
						</div>
						<div className="clearfix"></div>
					</div>
				</div>
			)
		}

		return (
      <>
      <div className="sub-nav">
        <div className="ct ct-spamtest">
          <div className="account-name pull-left hidden-mobile">
					  <div className="ar-nav">
							<NavLink to={`/dashboard/${this.props.currentProject.id}/spam-tests/home`}>
								Spam Tests
							</NavLink>
		            > Results
						</div>
          </div>
          <ul className="pull-right">
            <li>
              <a onClick={() => this.setState({currentPage: 'test'})} className={this.state.currentPage == 'test' ? 'active' : ''}><i class="fas fa-envelope-open-text"></i> <span className="hidden-mobile">Deliverability</span></a>
              <a onClick={() => this.setState({currentPage: 'content'})} className={(this.state.currentPage == 'content' ? 'active' : '')  + (this.state.currentTest.analysis_results.error_count > 0 ? ' has-notif' : '')}>
								<i class="fas fa-spell-check"></i>
								<span className="hidden-mobile">
									Content
									{this.state.currentTest.analysis_results &&
										<>
											{this.state.currentTest.analysis_results.error_count > 0 &&
												<span className="nav-error-count">
												  {this.state.currentTest.analysis_results.error_count}
												</span>
											}
										</>
									}
								</span>
							</a>
							<a onClick={() => this.setState({currentPage: 'technical'})} className={(this.state.currentPage == 'technical' ? 'active' : '') + (this.state.error.setup > 0 ? ' has-notif' : '')}>
								<i class="fas fa-stethoscope"></i>
									<span className="hidden-mobile">
										Setup
										{this.state.error &&
											<>
											{this.state.error.setup > 0 &&
												<span className="nav-error-count">
													{this.state.error.setup}
												</span>
											}
											</>
										}
									</span>
							</a>
							{this.state.currentTest.analysis_results.nextsteps &&
								<>
									{((localStorage.getItem('admin') || this.props.currentProject.config_feature_new_spamchecker_nextsteps_enabled) && this.state.currentTest.analysis_results.nextsteps.length > 0) &&
										<a onClick={() => this.setState({currentPage: 'nextsteps'})} className={(this.state.currentPage == 'nextsteps' ? 'active' : '') + (this.state.currentTest.analysis_results.nextsteps.length > 0 ? ' has-notif' : '')}>
											<i class="fa-solid fa-list-check"></i>
												Next steps
													{this.state.currentTest.analysis_results.nextsteps.length > 0 &&
														<span className="nav-error-count">
															{this.state.currentTest.analysis_results.nextsteps.length}
														</span>
													}
										</a>
									}
								</>
							}

							{localStorage.getItem('admin') &&
								<a onClick={() => this.setState({currentPage: 'autofixai'})} className={this.state.currentPage == 'autofixai' ? 'active' : ''}><i class="fa-solid fa-wand-magic-sparkles"></i> <span className="hidden-mobile">Autofix AI</span></a>
							}
            </li>
          </ul>
          <div className="clearfix"></div>
        </div>
      </div>


			<div className="app container home spamtest-results spam-results-modal template-home">

			{(!this.state.currentTest.test_type) &&
				<div className="requested-type-selector">
				  <p>To see your result, select the type of inboxes you target :</p>

					<button className="selector blue" onClick={() => this.setTestType('btob')}>👔 Professional inboxes</button>
					<button className="selector yellow" onClick={() => this.setTestType('btoc')}>🙎 Personal inboxes</button>
					<button className="selector red" onClick={() => this.setTestType('all')}>‍🌎 All types of inboxes</button>
				<div className="clearfix"></div>

					<div>
						<p>
						  Important : spam filters work differently between professional and personal inboxes. To avoid biased results, choose the type of inboxes used by the recipients of your real email campaigns.
							<div className="help-bubble-dropdown">
								<Tooltip title="It's not relevant to check your deliverability towards personal addresses if you're targeting professionals and vice versa.
								Google and Microsoft understand your emails and may consider suspicious the fact that you're speaking business to a personal inbox which is not supposed to happen. Result ? They put you in spam.
								Conclusion : your true deliverability has to be checked with the inbox type used of your real recipients." placement="bottom">
									<div>
										<i class="fas fa-info-circle"></i>
									</div>
								</Tooltip>
							</div>
						</p>
					</div>
				</div>
			}

				<div className="row accounts templates col-md-12">

				{((this.state.currentTest.btob_score < 7.5) && this.state.currentTest.account.score <= 59  && this.state.currentTest.account.score > 0 && this.state.currentTest.completed) &&
							<div  className="alert status warning card recipe">
								<div className="txt">
									This sender has a bad reputation. It decreases your deliverability.
									<a href="https://help.mailreach.co/en/article/how-to-restore-a-bad-sender-reputation-and-fix-a-low-score-with-mailreach-3egzf6/?utm_source=spam-test-one-off&utm_medium=top-bar&utm_campaign=smart-diagnostic&utm_content=bad-rep-score" target="_blank" className="btn pull-right" activeClassName="active">
										Read our guide to fix.
									</a>
								</div>
							</div>
				}

				{((this.state.currentTest.btob_score < 7.5) && this.state.currentTest.account.score >= 60 && this.state.currentTest.account.score <= 84 && this.state.currentTest.completed) &&
							<div  className="alert status warning card recipe">
								<div className="txt">
									This sender has a damaged reputation. It decreases your deliverability.
									<a href="https://help.mailreach.co/en/article/how-to-get-the-highest-reputation-score-success-guide-vfivvo/?utm_source=spam-test-one-off&utm_medium=top-bar&utm_campaign=smart-diagnostic&utm_content=damaged-rep-score" target="_blank" className="btn pull-right" activeClassName="active">
										Read our guide to fix.
									</a>
								</div>
							</div>
				}

				{((this.state.currentTest.btob_score < 7.5) && this.state.currentTest.account.score > 90 && this.state.currentTest.completed) &&
							<div  className="alert status warning card recipe">
								<div className="txt">
									This sender has a Reputation Score of 90+, it has the potential to land more in inbox. Follow our instructions to improve that ASAP
									<a href="https://help.mailreach.co/en/article/why-i-am-still-landing-in-spam-while-i-have-a-high-reputation-score-on-mailreach-and-how-to-fix-it-c8eip5/?utm_source=spam-test-one-off&utm_medium=top-bar&utm_campaign=smart-diagnostic" target="_blank" className="btn pull-right" activeClassName="active">
										Read
									</a>
								</div>
							</div>
				}

				{(this.state.currentTest.sending_provider && this.state.currentTest.account.detected_sending_provider) &&
					<>
					{this.state.currentTest.sending_provider != this.state.currentTest.account.detected_sending_provider &&
							<div  className="alert status warning card bounce">
								<div className="txt">
									This test has been sent from a different sending setup than the one being warmed. This can lead to inconsistent results!
									<a href="https://help.mailreach.co/en/article/why-it-is-important-to-run-spam-tests-using-the-same-sending-setup-as-the-one-being-warmed-u0mba8/" target="_blank" className="btn pull-right" activeClassName="active">
										More info
									</a>
								</div>
							</div>
						}
					</>
				}

					{this.state.currentPage == 'test' &&
						<h2>
							Result
						</h2>
					}

					{(this.state.currentPage == 'test' && this.state.currentTest.test_type) &&
						<Dropdown className="dropdown-date-filter last-days-filter dropdown-inbox-type-selector">
							<Dropdown.Toggle className={"btn text-type-badge " + this.state.currentTest.test_type} id="dropdown-basic">
								{!this.state.currentTest.automated &&
			            <div className="pull-left help-bubble-dropdown">
										<Tooltip title="It is not relevant to check your deliverability to personal addresses if you are targeting professionals and vice versa. Google and Microsoft understand your emails and may consider it suspicious if you are talking business to a personal inbox, which is not supposed to happen. The result? They put you in spam. Conclusion: your true deliverability should be checked with the type of inbox used by your real recipients." placement="bottom">
											<div>
												<i class="fas fa-info-circle"></i>
											</div>
										</Tooltip>
									</div>
								}

								{this.state.currentTest.automated &&
									<div className="pull-left help-bubble-dropdown">
										<Tooltip title={"This automated test is only performed on " + (getTypeIco[this.state.spamTestInboxFilter ? this.state.spamTestInboxFilter : 'all']) + ", this is how it has been setup." } placement="bottom">
											<div>
												<i class="fas fa-info-circle"></i>
											</div>
										</Tooltip>
									</div>
								}

								{this.state.currentTest.automated &&
									<Tooltip title={"This automated test is only run towards " + (getTypeIco[this.state.spamTestInboxFilter ? this.state.spamTestInboxFilter : 'all']) + ' inboxes according to its settings.'} placement="bottom">
										<span>
											{getTypeIco[this.state.spamTestInboxFilter ? this.state.spamTestInboxFilter : 'all']}
										</span>
									</Tooltip>
								}

								{!this.state.currentTest.automated &&
									<>
										{getTypeIco[this.state.spamTestInboxFilter ? this.state.spamTestInboxFilter : 'all']}
										<i class="fas fa-angle-down" aria-hidden="true"></i>
									</>
								}
							</Dropdown.Toggle>

							{!this.state.currentTest.automated &&
								<Dropdown.Menu className="test-type-dropdown">
										<Dropdown.Item onClick={() => this.setTestType('all')}>‍🌎 All types of inboxes</Dropdown.Item>
										<Dropdown.Item onClick={() => this.setTestType('btob')}>👔 Professional inboxes</Dropdown.Item>
										<Dropdown.Item onClick={() => this.setTestType('btoc')}>🙎 Personal inboxes</Dropdown.Item>
								</Dropdown.Menu>
							}
						</Dropdown>
					}

					{this.state.currentPage == 'content' &&
						<h2>Content Analysis</h2>
					}

					{this.state.currentPage == 'technical' &&
						<h2>Setup Analysis</h2>
					}

					{this.state.currentPage == 'nextsteps' &&
						<h2>Next steps</h2>
					}

					{this.state.currentPage == 'autofixai' &&
						<h2>Fix with AI (beta)</h2>
					}

					{(!this.state.currentTest.completed && this.state.currentPage == 'test') &&
						<Tooltip title='We wait up to 6 minutes for the mailboxes to receive your email before displaying the score 🔍.' placement="bottom">
						  <div className="score running pulse">
							  Searching...
						  </div>
						</Tooltip>
					 }

  				<div className="pull-right">
  					<button type="submit" disabled={this.state.loading} className="btn btn-header btn-secondary pull-right" onClick={() => this.setState({openShowTemplateModal: true})}>See my email</button>
						{this.state.currentTest.account &&
							<>
								{this.state.currentTest.account.email &&
									<NavLink to={`/dashboard/${this.props.match.params.project_id}/accounts/${this.state.currentTest.account.id}/show`} className="btn btn-header btn-secondary pull-right margin-right" activeClassName="active">
			  						{this.state.currentTest.account.email} dashboard
			  					</NavLink>
								}
							</>
						}
  				</div>

					<div className="clearfix"></div>
          <div className={'txt text-center ' + (this.state.spamTestInboxFilter ? '' : 'blured')}>
					{this.state.currentPage == 'test' &&
						<>
						{this.state.currentTest.completed &&
							<>
								<div className="col-md-3 no-padding-left text-left">
									<div className="card">
									  <div className="title">
										Score

										<div className="help-bubble pull-right">
											<Tooltip title="This score is 100% based on where your emails land as it's the best representation of your test email's deliverability. The formula is : [number of emails landed in inbox] / [number of email sent] x 10." placement="bottom">
												<div>
													<i class="fas fa-info-circle"></i>
												</div>
											</Tooltip>
										</div>
										</div>
										<div className="text-center">
									    <div className={'inbox-rate-nb score ' + this.getScoreClass(this.state.currentTest.final_score)}>{this.state.currentTest.final_score}/10</div>
										</div>
									</div>
								</div>
								<div className="col-md-9 no-padding-right text-left">
									<div className="card">
									  <div className="title">Insight</div>
										  <p className="insight-content">
											<div dangerouslySetInnerHTML={{__html: this.state.insightMessage}} />
											</p>
									</div>
								</div>
							</>
						}
						<div className="clearfix"></div>
						<h2 className="second-title">
							Where it landed

							<span className="seedlist-raw">Seed list {this.state.currentTest.seedlist}</span>
						</h2>
						{this.state.reloadTests &&
							<div className="pull-left spamtests-loading top-margin">
								<Loader
								 type="Oval"
								 color="#b7b7b7"
								 height="25"
								 width="25"
								/>
							</div>
						}
						<div className="share-link hidden-mobile" onClick={() => this.copyText('https://spamchecker.mailreach.co/tests/' + this.state.currentTest.public_id)}>
							<div className="code">
								https://spamchecker.mailreach.co/tests/{this.state.currentTest.public_id}
							</div>
							<div className="copy">
								<i className="far fa-copy" aria-hidden="true"></i>
							</div>
						</div>
						<div className="share-txt hidden-mobile">
							Share this report
						</div>
						<div className="clearfix"></div>
						</>
					}
					  {!['technical', 'content', 'nextsteps', 'autofixai'].includes(this.state.currentPage) &&
	            <div className="card">
							{this.state.currentPage == 'test' &&
								<>
									{(this.state.currentTest.results.length == 0 && !this.state.loading) &&
										<div className="empty text-center">
											<img src="/images/instat_analysis.svg" />
											<p>No results at the moment.</p>
										</div>
									}
									<table class="table table-results">
										<tbody>
										{this.state.currentTest.results.map((log, index) => {
											if(log.hidden){
												return;
											}
											return (
												<tr>
													<td>
														<div>
															{log.provider == 'custom' &&
																 <img className="avatar pull-left" src={'/images/custom.svg'} />
															}
															{log.provider != 'custom' &&
																 <img className="avatar pull-left" src={'/images/logo_' + log.provider + '.png'} />
															}
														</div>
														<span className="hide-responsive cursor-pointer" onClick={() => this.openHeadersModal(log)}>{log.email}</span><br />
														<span className="account-desc hidden-mobile">{log.desc}</span>
													</td>
													<td className="inbox-type">
														<span className={"providerbadge hidden-mobile " + log.provider}>
															{log.provider == 'gmail' &&
																<>Google</>
															}
															{log.provider == 'outlook' &&
																<>Microsoft</>
															}
															{!['gmail','outlook'].includes(log.provider) &&
																<>{log.provider}</>
															}
														</span>

														<div dangerouslySetInnerHTML={{__html: this.getInboxTypelabel(log.email)}} />
													</td>
													<td className="hour hidden-mobile">
													<Moment fromNow>{this.state.currentTest.created_at}</Moment>
													</td>
													<td>
														{(!log.received_in && !this.state.currentTest.completed) &&
															<span className={"badge pending"}>Pending</span>
														}
														{(!log.received_in && this.state.currentTest.completed) &&
															<Tooltip title="Missing means MailReach doesn't find your email in this mailbox. It can mean your email has not landed yet OR it can mean the mailbox / provider didn't let you enter. In the latter, that's worst than spam." placement="bottom">
																<span className={"badge missing-badge-color"}>Missing</span>
															</Tooltip>
														}
														{log.received_in &&
															<>
																{log.received_in == 'INBOX' &&
																	<Tooltip title="Well done 🙌" placement="bottom">
																		<span className={"badge " + log.received_in}>{log.received_in.toLowerCase()}</span>
																	</Tooltip>
																}
																{(log.received_in == 'SPAM' && this.getInboxType(log.email) == 'perso')  &&
																	<Tooltip title="Not good BUT the question is : do you really target personal inboxes? Because if not, it's not relevant to take it into account. Spam filters work differently between Professional and Personal inboxes." placement="bottom">
																		<span className={"badge " + log.received_in}>{log.received_in.toLowerCase()}</span>
																	</Tooltip>
																}
																{(log.received_in == 'SPAM' && this.getInboxType(log.email) == 'pro')  &&
																	<Tooltip title="Not good BUT the question is : do you really target professional inboxes? Because if not, it's not relevant to take it into account. Spam filters work differently between Professional and Personal inboxes." placement="bottom">
																		<span className={"badge " + log.received_in}>{log.received_in.toLowerCase()}</span>
																	</Tooltip>
																}
																{!['INBOX', 'SPAM', 'MISSING'].includes(log.received_in) &&
																	<Tooltip title="Better than spam, but not the best 😐" placement="bottom">
																		<span className="badge categorie">{log.received_in.toLowerCase()}</span>
																	</Tooltip>
																}
															</>
														}
													</td>
												</tr>
											)})}
										</tbody>
									</table>
								</>
							}
	            </div>
						}
						{this.state.currentPage == 'content' &&
							<div className="checkups">
								<WordsAnalyzer title={false} currentTest={this.state.currentTest} contentSpamwordsResponse={this.state.currentTest.analysis_results} />
								<div className="clearfix"></div>
							</div>
						}
						{this.state.currentPage == 'technical' &&
							<div className="checkups">
								<CheckupDomain currentTest={this.state.currentTest} domain={this.state.currentTest.domain} currentAccount={this.state.currentTest.currentAccount || {domain: this.state.currentTest.domain}} spamTest={true} />
								<div className="clearfix"></div>
							</div>
						}

						{this.state.currentPage == 'nextsteps' &&
							<div className="checkups">
								<SpamcheckerNextSteps currentTest={this.state.currentTest} spamTest={true} />
								<div className="clearfix"></div>
							</div>
						}

						{this.state.currentPage == 'autofixai' &&
							<div className="checkups">
								<SpamcheckerAutofixAi currentTest={this.state.currentTest} spamTest={true} />
								<div className="clearfix"></div>
							</div>
						}
          </div>
        </div>
      </div>


			<Modal className="spam-results-modal message-design" show={this.state.openShowHeadersModal} onHide={() => this.setState({openShowHeadersModal: false})}>
				<Modal.Body>
					<>
						<h1 className="text-center">Message Raw Headers</h1>
						<div className='headers'>
							<table>
								<tbody>
									{this.state.headersToShow.map((header, index) => {
										return (
											<tr>
												<td>{header.name} :</td>
												<td className="break-word">{header.unparsed_value}</td>
											</tr>
										)})}

								</tbody>
							</table>
						</div>
						<div className="footer text-center">
							<button type="button" className="btn btn-secondary" onClick={() => this.setState({openShowHeadersModal: false})}>Close</button>
						</div>
					</>
				</Modal.Body>
			</Modal>

			<Modal className="spam-results-modal message-design" show={this.state.openShowTemplateModal} onHide={() => this.setState({openShowTemplateModal: false})}>
				<Modal.Body>
					<>
						<h1 className="text-center">{this.state.currentTest.first_message_content.subject}</h1>
						<div className='from'>
							<b>{this.state.currentTest.first_message_content.from_name}</b> {'<' + this.state.currentTest.first_message_content.from_email + '>'}

							<ul className='format-selector pull-right'>
								<li className={"btn btn-secondary" + (this.state.messageContentPreview == 'content_html' ? ' selected' : '')} onClick={() => this.setState({messageContentPreview: 'content_html'})}>HTML</li>
								<li className={"btn btn-secondary" + (this.state.messageContentPreview == 'content_text' ? ' selected' : '')} onClick={() => this.setState({messageContentPreview: 'content_text'})}>TEXT</li>
							</ul>
						</div>
						<div className="txt">
						<div
dangerouslySetInnerHTML={{__html: this.state.currentTest.first_message_content[this.state.messageContentPreview]}}
/>
						</div>
						<div className="footer text-center">
							<button type="button" className="btn btn-secondary" onClick={() => this.setState({openShowTemplateModal: false})}>Close</button>
						</div>
					</>
				</Modal.Body>
			</Modal>
      </>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		currentProject: state.currentProject,
		currentPricing: state.currentPricing
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardSpamTestResults));
