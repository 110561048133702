import React, { Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import {BrowserRouter, Route, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {ToastContainer, toast} from 'react-toastify';

import i18n from "../../../../i18n";
import '../getting-started.css';

import * as projectsActions from '../../../../actions/projects.actions';
import * as currentProjectActions from '../../../../actions/currentProject.actions';

class DashboardAppsSelect extends React.Component {
	constructor(props) {
		super(props);
		this.state = {errors: [], createForm: { provider_id: null}, technologies: []};
		this.onChange = this.onChange.bind(this);
		this.create = this.create.bind(this);
	}

	componentWillMount() {
	}

	componentWillReceiveProps(nextProps) {
	}

	onChange(event) {
		const field = event.target.name;
		const createForm = this.state.createForm;
		createForm[field] = event.target.value;
		return this.setState({createForm});
	}

	create(event){
		event.preventDefault();
		console.log(this.state.createForm);
		this.props.projectsActions.create(this.state.createForm).then((response) => {
			toast.success(i18n.t('alert.projectCreated'));
			localStorage.setItem('x-api-key', response.body.api_key);
			this.props.currentProjectActions.getCurrent();
			this.props.history.push(`/dashboard/${response.body.id}/home`);
		})
  	.catch((error) => {
			this.setState({error: true,});
			toast.error(i18n.t('alert.generic'));
		});
	}

	render() {
		return (
			<div className="app container">
				<div className="title">
					<h1>{i18n.t('getting-started.title')}</h1>
				</div>
				<div className="row">
					<div className="card col-md-12">
						 <form className="full-width">
							 <div className="form-group">
									 <label for="exampleInputEmail1">Project Name</label>
									 <input type="text" class="form-control" placeholder="Enter project name" name="name" onChange={this.onChange}/>
									 <small id="emailHelp" class="form-text text-muted">Set a name for this project</small>
								</div>
								<button type="submit" class="btn btn-primary" onClick={this.create}>Submit</button>
						 </form>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		projects: state.projects,
		currentProject: state.currentProject
	};
}

function mapDispatchToProps(dispatch) {
	return {
		projectsActions: bindActionCreators(projectsActions, dispatch),
		currentProjectActions: bindActionCreators(currentProjectActions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardAppsSelect));
