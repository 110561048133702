import UserService from '../services/user.service';
import * as types from './actionTypes';

function currentUserUpdateSuccess(currentUser) {
	return {type: types.CURRENT_USER_UPDATE_SUCCESS, currentUser};
}

function currentUserGetSuccess(currentUser) {
	return {type: types.CURRENT_USER_GET_SUCCESS, currentUser};
}

function loginError(errors) {
	return {type: types.LOG_IN_ERROR, errors};
}

export function update(currentUser) {
	return function (dispatch) {
		return UserService.update({currentUser}).then(response => {
			dispatch(currentUserUpdateSuccess(response));
		}).catch(error => {
			dispatch(loginError(['An error occured']))
			//throw (error);
		});
	};
}

export function getCurrent() {
	return function (dispatch) {
		return UserService.current().then(response => {
			dispatch(currentUserGetSuccess(response.body));
		}).catch(error => {
			dispatch(loginError(['An error occured']))
			//throw (error);
		});
	};
}
