import React from 'react';
import * as Sentry from "@sentry/react";
import { NavLink } from 'react-router-dom';
import { CardElement, ElementsConsumer} from '@stripe/react-stripe-js';
import Loader from 'react-loader-spinner'
import {ToastContainer, toast} from 'react-toastify';
import StripeService from '../../../services/stripe.service';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { Button, Modal } from 'react-bootstrap';

import { styled } from '@mui/material/styles';

import { GeneratePricingPreview } from '../../../helpers/generatePricingPreview'


const MailreachSlider = styled(Slider)({
  height: 8
});

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#11103b',
    },
    '&$checked + $track': {
      backgroundColor: '#11103b',
    },
  },
  checked: {},
  track: {
      backgroundColor: '#11103b',
  },
})(Switch);


const euCountries = ["AT","BE", "BG","HR", "CY", "CZ", "DK","EE","FI","FR","DE","EL","HU","IE","IT","LV","LT","LU","MT","NL","PL","PT","RO","SK","SI","ES","SE","NO","IS","LI","GR"];

class CheckoutForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {email: this.props.email, loading: false, success: false, checkoutData: { seats: 1}, yearly: false, hideAddCardFrom: this.props.hideAddCardFrom, pricePerSeat: 25, totalPrice: 25,  monthlyPricing: 25, selectedPlan: false, openCancelSubscriptionModal: false}
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.refreshInvoice = this.refreshInvoice.bind(this);
    this.billingPeriodChanges = this.billingPeriodChanges.bind(this);
    this.refreshPricePreview = this.refreshPricePreview.bind(this);
    this.setSeats = this.setSeats.bind(this);
    this.selectWarmerPlan = this.selectWarmerPlan.bind(this);
    this.openCancelSubscription = this.openCancelSubscription.bind(this);
    this.cancelSubscription = this.cancelSubscription.bind(this);
    this.submitBilling = this.submitBilling.bind(this);
    this.submitChange3ds = this.submitChange3ds.bind(this);
  }


  componentWillReceiveProps(nextProps) {

    if(this.props.checkoutV3){

      if(this.props.queryString.seats){
        this.setState({ checkoutData: { seats: this.props.queryString.seats || nextProps.project.seats || 1}});
      }

      this.refreshPricePreview();
      this.selectWarmerPlan();

    } else {
      if(!this.props.queryString.spamCheckerPlan){
        if(this.state.hideAddCardFrom != nextProps.hideAddCardFrom){
          this.setState({hideAddCardFrom: nextProps.hideAddCardFrom});
        }

        if(this.state.checkoutData.seats != nextProps.project.seats){
          this.setState({ checkoutData: { seats: nextProps.project.seats || 1}});
        }

        if(nextProps.project.stripe_subscription_period != null){
          this.setState({yearly: nextProps.project.stripe_subscription_period == 'yearly' ? true : false});
        }

        this.setState({euCountry: false});

        if(nextProps.project.config_feature_new_billing_seats){
          this.refreshPricePreview();
          this.selectWarmerPlan();
        }
      }
    }

  }

  componentDidMount(){
    let c = this;
    window.addEventListener('message', function(ev) {
      if (ev.data === '3DS-authentication-complete') {
        c.submitChange3ds();

        document.getElementById('dsauth').style.display = 'none';
        document.getElementById('dsauth-overlay').style.display = 'none';
        document.getElementById("dsauth").innerHTML = '';
      }
    }, false);
  }

  submitChange3ds(){
    const {stripe, elements} = this.props;
    let c = this;

    if(c.state.currentSetupIntentIdSecret){
      stripe.retrieveSetupIntent(c.state.currentSetupIntentIdSecret)
       .then(function(result) {
         if(result.setupIntent){
           if (result.setupIntent.last_setup_error) {
             // PaymentIntent client secret was invalid
             toast.error('Authentication error : ' + result.setupIntent.last_setup_error.message);
             c.setState({loading: false});
           } else {
             if (result.setupIntent.status === 'succeeded') {
               toast.success('Payment processing... Wait a second.');
               c.submitBilling();
             } else {
               toast.error('Authentication error');
               c.setState({loading: false});
               // Authentication failed, prompt the customer to enter another payment method
             }
           }
         }else{
           // Submit billing if no setup intent
           c.submitBilling();
         }
       }).catch(function(err) {
         Sentry.captureException(err);
         c.submitBilling();
      })
    }else{
      c.submitBilling();
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const {stripe, elements} = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    var error, paymentMethod;

    if(!this.state.hideAddCardFrom){
      if(this.state.checkoutData['country'] == '' || !this.state.checkoutData['country']){
        toast.error('You should select a country');
        return;
      }
      const cardElement = elements.getElement(CardElement);
      error, paymentMethod = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          address: {
            city: this.state.checkoutData['city'],
            country: this.state.checkoutData['country'],
            line1: this.state.checkoutData['address'],
            postal_code: this.state.checkoutData['postal_code']
          },
          email: this.props.email,
          name: this.state.checkoutData['card_name']
        }
      });
    }

    this.setState({loading: true});

    if(paymentMethod){
      error = paymentMethod.error;
    }

    if (error) {
      window.$crisp.push(["set", "session:segments", [["cb-refused"]]]);
      toast.error(error.message);
      console.error(error);
      this.setState({loading: false});
    } else {

      await paymentMethod;
      let data = {
        email: this.props.email,
        planId: this.props.planId,
        discountCode: this.props.discountCode,
        card_name: this.state.checkoutData['card_name'],
        city: this.state.checkoutData['city'],
        vat_id: this.state.checkoutData['vat_id'],
        cardId: paymentMethod ? paymentMethod.paymentMethod.id : null,
        postal_code: this.state.checkoutData['postal_code'],
        address: this.state.checkoutData['address'],
        country: this.state.checkoutData['country'],
        phone: this.state.checkoutData['phone'],
        full_name: this.state.checkoutData['full_name'],
        company: this.state.checkoutData['company'],
        spam_checker_plan: this.props.queryString.spamCheckerPlan,
        seats: this.state.checkoutData.seats,
        period: this.state.yearly ? 12 : 1,
        spamchecker_period: this.props.queryString.monthly || false,
        use_card: this.state.hideAddCardFrom,
        credits: this.props.queryString.credits
      }


      let c = this;



      // test 3DS

      let active3ds = [true, true, true, true, true];

      let random = Math.floor(Math.random() * active3ds.length);

      if(active3ds[random] && !this.state.hideAddCardFrom){
        StripeService.request3ds(data).then(function(res) {
          if (res.body.status == "requires_confirmation") {

            c.setState({currentSetupIntentIdSecret: res.body.client_secret});
            // We perform 3D Secure authentication
            stripe.confirmSetupIntent(
              res.body.client_secret, {return_url: window.location.protocol + '//' + window.location.host + '/dssuccess'}
            ).then(function(res) {
              // Setup intent already at succeeded
              if(res.setupIntent.status == 'succeeded'){
                c.submitBilling();
              }else{
                var iframe = document.createElement('iframe');
                iframe.src = res.setupIntent.next_action.redirect_to_url.url;
                iframe.width = 600;
                iframe.height = 700;
                document.getElementById('dsauth').appendChild(iframe);
                document.getElementById('dsauth').style.display = 'block';
                document.getElementById('dsauth-overlay').style.display = 'block';
              }
            }).catch(function(err) {
              console.log(err);
              Sentry.captureException(err);
                window.$crisp.push(["set", "session:segments", [["cb-refused"]]]);
                toast.error('Your card was rejected, try another card. Contact us if the problem persists.');
                c.setState({loading: false});
              })
          } else {
            c.submitBilling();
          }
        }).catch(function(err) {
          console.log(err);
          Sentry.captureException(err);
            window.$crisp.push(["set", "session:segments", [["cb-refused"]]]);
            toast.error('Your card was rejected, try another card. Contact us if the problem persists.');
            c.setState({loading: false});
          });
      }else{
        StripeService.createCustomer(data).then(function(value) {
        c.setState({loading: false, success: true});
        window.gtag("event", "billing", { event_category: "credit_card", event_label: "added"});
        window.$crisp.push(["set", "session:segments", [["cb-added"]]]);
        window.$crisp.push(["set", "session:event", [[["cb-added"]]]])


        if(c.state.checkoutData.seats){

          if(c.state.checkoutData.seats > 0){
            window.gtag("event", "purchase", {
              currency: 'USD',
              value: (c.state.checkoutData.seats*25),
              transaction_id: Date.now(),
              items: [
                {item_iname: 'warmer_seats'}
              ]
            });
          }
        } else {
          window.gtag("event", "purchase", {
            currency: 'USD',
            value: 25,
            transaction_id: Date.now(),
            items: [
              {item_iname: 'warmer_seats'}
            ]
          });
        }

        if(c.props.queryString.spamCheckerPlan){
          toast.success('Thanks, you can use the Spamchecker');

          window.gtag("event", "spamchecker", {
                  event_category: "spamchecker",
                  event_label: "buy-credits"
              });
            window.gtag('event', 'conversion', {
                'send_to': 'AW-444511731/aidcCIOXkaUDEPPr-tMB',
                'transaction_id': ''
            });

            // BING ads
            window.uetq = window.uetq || [];
            window.uetq.push('event', 'buy spam test credits', {});

          c.props.history.push(`/dashboard/${c.props.projectId}/spam-tests/home`);
        }else{
          if(c.props.project.config_feature_new_billing_seats){
            toast.success('All good, you can now connect your email account(s) 🙌');
            c.props.history.push(`/dashboard/${c.props.projectId}/account/connect`);
          }else{
            toast.success('Thanks, you can now connect account(s) ! You will be charged for each accounts');
            c.props.history.push(`/dashboard/${c.props.projectId}/home?addModalAccount=true`);
          }
        }
      }).catch(function(err) {
          window.$crisp.push(["set", "session:segments", [["cb-refused"]]]);
          toast.error('Your card was rejected, try another card. Contact us if the problem persists.');
          Sentry.captureException(err);
          c.setState({loading: false});
        });

      }

    }
  };


  submitBilling() {

      let data = {
        email: this.props.email,
        planId: this.props.planId,
        discountCode: this.props.discountCode,
        card_name: this.state.checkoutData['card_name'],
        city: this.state.checkoutData['city'],
        vat_id: this.state.checkoutData['vat_id'],
        cardId: null,
        postal_code: this.state.checkoutData['postal_code'],
        address: this.state.checkoutData['address'],
        country: this.state.checkoutData['country'],
        phone: this.state.checkoutData['phone'],
        full_name: this.state.checkoutData['full_name'],
        company: this.state.checkoutData['company'],
        spam_checker_plan: this.props.queryString.spamCheckerPlan || this.props.queryString.selectedSpamcheckerPlan,
        spam_checker_priod: this.props.queryString.selectedSpamcheckerPeriod,
        seats: this.state.checkoutData.seats,
        period: (this.state.yearly || this.props.queryString.period == '12')  ? 12 : 1,
        use_card: true,
        spamchecker_period: this.props.queryString.monthly || false,
        credits: this.props.queryString.credits
      }


      let c = this;

    StripeService.createCustomer(data).then(function(value) {
      c.setState({loading: false, success: true});
      window.gtag("event", "billing", { event_category: "credit_card", event_label: "added"});
      window.$crisp.push(["set", "session:segments", [["cb-added"]]]);
      window.$crisp.push(["set", "session:event", [[["cb-added"]]]])

      if(c.props.queryString.spamCheckerPlan){
        toast.success('Thanks, you can use the Spamchecker');

        window.gtag("event", "spamchecker", {
                event_category: "spamchecker",
                event_label: "buy-credits"
            });
          window.gtag('event', 'conversion', {
              'send_to': 'AW-444511731/aidcCIOXkaUDEPPr-tMB',
              'transaction_id': ''
          });

          window.gtag("event", "purchase", {
            currency: 'USD',
            value: 12,
            transaction_id: Date.now(),
            items: [
              {item_iname: 'spamchecker_credits'}
            ]
          });

          // BING ads
          window.uetq = window.uetq || [];
          window.uetq.push('event', 'buy spam test credits', {});

        c.props.history.push(`/dashboard/${c.props.projectId}/spam-tests/home`);
      }else{
        if(c.props.project.config_feature_new_billing_seats){

          if(c.state.checkoutData.seats){
            if(c.state.checkoutData.seats > 0){
              window.gtag("event", "purchase", {
                currency: 'USD',
                value: (c.state.checkoutData.seats*25),
                transaction_id: Date.now(),
                items: [
                  {item_iname: 'warmer_seats'}
                ]
              });
            }
          } else {
            window.gtag("event", "purchase", {
              currency: 'USD',
              value: 25,
              transaction_id: Date.now(),
              items: [
                {item_iname: 'warmer_seats'}
              ]
            });
          }


          toast.success('All good, you can now connect your email account(s) 🙌');
          c.props.history.push(`/dashboard/${c.props.projectId}/account/connect`);
        }else{
          if(c.props.project.seats > c.state.checkoutData.seats){
            toast.success('Number of seats successfully downgraded');
            c.props.history.push(`/dashboard/${c.props.projectId}/home`);
          }else{
            toast.success('Thanks, you can now connect account(s) ! You will be charged for each accounts');
            c.props.history.push(`/dashboard/${c.props.projectId}/home?addModalAccount=true`);
          }
        }
      }
    }).catch(function(err) {
        window.$crisp.push(["set", "session:segments", [["cb-refused"]]]);
        toast.error('Your card was rejected, try another card. Contact us if the problem persists.');
        Sentry.captureException(err);
        c.setState({loading: false});
      });
}

  onChange(event) {
    const field = event.target.name;

    if(field == 'postal_code'){
      const {stripe, elements} = this.props;
      if(elements){
        let cardElement = elements.getElement(CardElement);
        cardElement.update({value: {postalCode: event.target.value}});
      }
    }

    if(field == 'country'){
      if(euCountries.includes(event.target.value)){
        this.setState({euCountry: true});
      }
    }

    const checkoutData = this.state.checkoutData;
    checkoutData[field] = event.target.value;
    this.setState({checkoutData});


    if(!['full_name', 'company', 'card_name', 'address', 'postal_code'].includes(field)){
      this.refreshInvoice();

      // Refresh warmer plan, only if we are not buying seats
      if(this.props.project.config_feature_new_billing_seats && !this.props.queryString.spamCheckerPlan){
        this.selectWarmerPlan();
      }
    }

    return;
  }

  refreshPricePreview(){
    this.setState({selectedPlan: false});

    let pricingPreview = GeneratePricingPreview(this.state.checkoutData.seats, this.state.yearly);

    this.setState({pricePerSeat: pricingPreview.pricePerSeat, totalPrice: pricingPreview.totalPrice, monthlyPricing: pricingPreview.monthlyPricing});
  }

  selectWarmerPlan(e = null){
    if(e){
      e.preventDefault();
    }

    if(this.props.checkoutV3){
      // Checkout v3, preview mixed subscription, seats + warmer one time for eg
      let c = this;
      StripeService.previewAll(this.props.queryString, this.state.checkoutData.country, this.state.checkoutData.vat_id, this.state.yearly ? 12 : 1, this.state.checkoutData.coupon).then(function(response) {
        c.setState({pricePreview: response.body, selectedPlan: true});
      })
      .catch((error) => {
        c.setState({error: true});
        toast.error('Oops, we have an error');
        console.error(error);
      });

    } else {
      this.setState({selectedPlan: true});

      let c = this;
      StripeService.previewSeats(this.state.checkoutData.seats, this.state.checkoutData.country, this.state.checkoutData.vat_id, this.state.yearly ? 12 : 1, this.state.checkoutData.coupon).then(function(response) {
        c.setState({pricePreview: response.body});
      })
      .catch((error) => {
        c.setState({error: true});
        toast.error('Oops, we have an error');
        console.error(error);
      });
    }
  }

  cancelSubscription(){
    let c = this;
    StripeService.cancel().then(function(response) {
      toast.error('Subscription cancelled');
      c.props.history.push(`/dashboard/${c.props.projectId}/home`);
    })
    .catch((error) => {
      c.setState({error: true});
      toast.error('Oops, we have an error');
      console.error(error);
    });

  }

  billingPeriodChanges(){
    this.setState({yearly: !this.state.yearly, selectedPlan: false});
    setTimeout(() => { this.refreshPricePreview(); this.selectWarmerPlan(); }, 50);
  }

  setSeats(value) {
    this.setState({selectedplan: false});
    let checkoutData = this.state.checkoutData;

    checkoutData.seats = value.target.value;
    this.refreshPricePreview();
  }

  componentWillMount() {
    StripeService.customer().then((response) => {
      response.body.address.address = response.body.address.line1;
      response.body.address.seats = this.props.project.seats || 1;
      response.body.address.country = null;
      this.setState({checkoutData: response.body.address})
      this.refreshPricePreview();
    })
    .catch((error) => {
      this.setState({error: true});
      toast.error('Oops, we have an error');
      console.error(error);
    });

    this.refreshInvoice();
  }

  refreshInvoice(){
    if(this.props.checkoutV3){
      // Checkout v3, preview mixed subscription

    } else {
      if(this.props.queryString.spamCheckerPlan || this.props.queryString.emailvalidationPlan){
        let c = this;
        let product = this.props.queryString.spamCheckerPlan ? 'spamchecker' : 'emailvalidation';
        let planId = this.props.queryString.spamCheckerPlan || this.props.queryString.emailvalidationPlan;
        StripeService.preview(planId, this.state.checkoutData.country, this.state.checkoutData.vat_id, null, product, this.props.queryString.monthly, this.props.queryString.credits).then(function(response) {
          c.setState({pricePreview: response.body});
        })
        .catch((error) => {
          c.setState({error: true});
          toast.error('Oops, we have an error');
          console.error(error);
        });
      }
    }
  }

  openCancelSubscription(e){
    e.preventDefault();
    this.setState({openCancelSubscriptionModal: true});
  }

  render() {
    const {stripe} = this.props;
    if(this.state.success){
      return (
        <>
          <br />
          <p className="success-p">Thank you {this.props.firstName}, <br />
          Welcome to the clan of smart emailers. You can now connect an email account so MailReach can start raising your reputation.<br />
          <b>Important: if you connect several accounts it will increase the amount of your subscription.</b></p>

          <div className="text-center">
            <NavLink to={`/dashboard/${this.props.projectId}/account/connect`} className="btn purple-btn" activeClassName="active">
              Connect account
            </NavLink>
          </div>
          <div className="clearfix"></div>
        </>
      )
    }
    if(this.state.loading){
      return(
        <div className="loader text-center">
        <style>{`
          .background-wth-one{
            display: none;
          }
          .loader{
            margin-top: 40px;
          }
        `}</style>
          <Loader
           type="Oval"
           color="#b7b7b7"
           height="100"
           width="100"
          />
        </div>
      )
    }
    return (
      <div className="imap checkout">


      <form className="row" onSubmit={this.handleSubmit}>

      <div className="col-md-8">
      {(this.props.project.config_feature_new_billing_seats && !this.props.queryString.spamCheckerPlan && !this.props.checkoutV3 && !this.props.project.config_feature_stripe_payment_link_checkout_enabled) &&
        <>
        <h1>Choose your plan</h1>
        <div className="card seats-checkout">
          <div className="explain-billing">
            <h2 className="full-width">Enter the number of mailboxes you'd like to warm</h2><br />
            <p>Starting at 6 mailboxes, the price per seat decreases. You can add or remove seats later.</p>
          </div>

            <div className="select-billing-period text-center">
              {this.props.project.stripe_subscription_period != 'yearly' &&
              <>
                <span className={!this.state.yearly ? 'selected' : ''} onClick={this.billingPeriodChanges}>Monthly</span>
                <FormGroup>
                    <FormControlLabel control={<PurpleSwitch
                    checked={this.state.yearly}
                    onChange={this.billingPeriodChanges}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />} label="" />
                </FormGroup>
                  <span className={this.state.yearly ? 'selected' : ''} onClick={this.billingPeriodChanges}>Yearly <span className="discount">Save 20%</span></span>
                  </>
                }
            </div>

          <div className="plans">
          <div className={"plan col-md-4 col-offset-md-3"}>
            <h2>
              {this.state.checkoutData.seats < 6 &&
                <>Starter</>
              }

              {this.state.checkoutData.seats > 5 &&
                <>Scale</>
              }
            </h2>
            <div className="pricing">
              <span><span className="money">$</span>{(Number(this.state.monthlyPricing.toFixed(2)))}</span>
              <span className="period">/ month</span>
            </div>
            {this.state.yearly &&
              <div className="yearly-total">
               ${(Number(this.state.totalPrice.toFixed(2)))} billed yearly
              </div>
            }
            <div className="mailboxes-increase">
              <span>Mailboxes</span> <input type="number" min="0" onChange={this.setSeats} value={this.state.checkoutData.seats} />
            </div>
              <div className="range-slider col-md-12">
              <MailreachSlider
              defaultValue={this.props.project.seats || 1}
              onChange={this.setSeats}
              step={1}
              min={1}
              max={100}
              color="secondary"
              />
              <div>
              </div>
              <p>Price per seat : {this.state.pricePerSeat}$</p>
              </div>
            <ul className="features">
              <li><i class="fas fa-check-circle"></i> Fully automated warm up</li>
              <li><i class="fas fa-check-circle"></i> Up to 90 emails / day</li>
              <li><i class="fas fa-check-circle"></i> Smart warming algorithm</li>
              <li><i class="fas fa-check-circle"></i> Deliverability correction</li>
              <li><i class="fas fa-check-circle"></i> Advanced Spam tester with insights</li>
              <li><i class="fas fa-check-circle"></i> Multiple ESP warm up (Google, Outlook, etc.)</li>
              <li><i class="fas fa-check-circle"></i> Monitoring dashboard</li>
              <li><i class="fas fa-check-circle"></i> Live blacklists and DNS check ups</li>
              <li><i class="fas fa-check-circle"></i> Chat support</li>
            </ul>

            <div className="text-center selectplan">
              <button onClick={this.selectWarmerPlan} className={"btn purple-btn "} activeClassName="active">
                {this.props.project.seats == 0 &&
                  <>
                    Select <i class="fa-solid fa-arrow-right"></i>
                  </>
                }

                {this.props.project.seats > 0 &&
                  <>
                    Select <i class="fa-solid fa-arrow-right"></i>
                  </>
                }
              </button>
            </div>
          </div>

          </div>
          <div className="clearfix"></div>
        </div>
</>
      }

      {(!this.state.hideAddCardFrom || !this.props.project.config_feature_new_billing_seats) &&
        <>
        {this.props.checkoutV3 &&
          <>
            <NavLink to={`/dashboard/${this.props.projectId}/settings/checkout/plans`} className="checkoutv3-back-btn" activeClassName="active">
              {'< Back'}
            </NavLink>
          </>
        }
        <h1>Enter payment information</h1>
        <div className="card">
          <div className="fieldCont col-md-6">
            <label>Full name</label>
            <input type="text" name="full_name" placeholder='Full name' onChange={this.onChange} value={this.state.checkoutData.full_name} required/>
          </div>
          <div className="fieldCont col-md-6">
            <label>Company</label>
            <input type="text" name="company" placeholder='Company' onChange={this.onChange} value={this.state.checkoutData.company} required/>
          </div>
          <div class="fieldCont col-md-12">
            <label>Name on card</label>
            <input type="text" name="card_name" placeholder='Name on card' value={this.state.checkoutData.card_name} onChange={this.onChange} required/>
          </div>
          <div class="fieldCont col-md-12">
            <label>Card details</label>
            <CardElement options={{ hidePostalCode: true }} />
          </div>
          <div class="fieldCont col-md-12">
            <label>Address</label>
            <input type="text" name="address" placeholder='Address' onChange={this.onChange} value={this.state.checkoutData.address} required/>
          </div>

          <div className="fieldCont col-md-4">
            <label>Postal code</label>
            <input type="text" name="postal_code" placeholder='Postal code' onChange={this.onChange} value={this.state.checkoutData.postal_code} required/>
          </div>
            <div class="fieldCont col-md-4">
            <label>Country</label>
            <select name="country" onChange={this.onChange} placeholder='Country' value={this.state.checkoutData.country} required>
              <option value="">Country</option>
              <option value="AF">Afghanistan</option>
              <option value="AX">Åland Islands</option>
              <option value="AL">Albania</option>
              <option value="DZ">Algeria</option>
              <option value="AS">American Samoa</option>
              <option value="AD">Andorra</option>
              <option value="AO">Angola</option>
              <option value="AI">Anguilla</option>
              <option value="AQ">Antarctica</option>
              <option value="AG">Antigua and Barbuda</option>
              <option value="AR">Argentina</option>
              <option value="AM">Armenia</option>
              <option value="AW">Aruba</option>
              <option value="AU">Australia</option>
              <option value="AT">Austria</option>
              <option value="AZ">Azerbaijan</option>
              <option value="BS">Bahamas</option>
              <option value="BH">Bahrain</option>
              <option value="BD">Bangladesh</option>
              <option value="BB">Barbados</option>
              <option value="BY">Belarus</option>
              <option value="BE">Belgium</option>
              <option value="BZ">Belize</option>
              <option value="BJ">Benin</option>
              <option value="BM">Bermuda</option>
              <option value="BT">Bhutan</option>
              <option value="BO">Bolivia, Plurinational State of</option>
              <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
              <option value="BA">Bosnia and Herzegovina</option>
              <option value="BW">Botswana</option>
              <option value="BV">Bouvet Island</option>
              <option value="BR">Brazil</option>
              <option value="IO">British Indian Ocean Territory</option>
              <option value="BN">Brunei Darussalam</option>
              <option value="BG">Bulgaria</option>
              <option value="BF">Burkina Faso</option>
              <option value="BI">Burundi</option>
              <option value="KH">Cambodia</option>
              <option value="CM">Cameroon</option>
              <option value="CA">Canada</option>
              <option value="CV">Cape Verde</option>
              <option value="KY">Cayman Islands</option>
              <option value="CF">Central African Republic</option>
              <option value="TD">Chad</option>
              <option value="CL">Chile</option>
              <option value="CN">China</option>
              <option value="CX">Christmas Island</option>
              <option value="CC">Cocos (Keeling) Islands</option>
              <option value="CO">Colombia</option>
              <option value="KM">Comoros</option>
              <option value="CG">Congo</option>
              <option value="CD">Congo, the Democratic Republic of the</option>
              <option value="CK">Cook Islands</option>
              <option value="CR">Costa Rica</option>
              <option value="CI">Côte d'Ivoire</option>
              <option value="HR">Croatia</option>
              <option value="CU">Cuba</option>
              <option value="CW">Curaçao</option>
              <option value="CY">Cyprus</option>
              <option value="CZ">Czech Republic</option>
              <option value="DK">Denmark</option>
              <option value="DJ">Djibouti</option>
              <option value="DM">Dominica</option>
              <option value="DO">Dominican Republic</option>
              <option value="EC">Ecuador</option>
              <option value="EG">Egypt</option>
              <option value="SV">El Salvador</option>
              <option value="GQ">Equatorial Guinea</option>
              <option value="ER">Eritrea</option>
              <option value="EE">Estonia</option>
              <option value="ET">Ethiopia</option>
              <option value="FK">Falkland Islands (Malvinas)</option>
              <option value="FO">Faroe Islands</option>
              <option value="FJ">Fiji</option>
              <option value="FI">Finland</option>
              <option value="FR">France</option>
              <option value="GF">French Guiana</option>
              <option value="PF">French Polynesia</option>
              <option value="TF">French Southern Territories</option>
              <option value="GA">Gabon</option>
              <option value="GM">Gambia</option>
              <option value="GE">Georgia</option>
              <option value="DE">Germany</option>
              <option value="GH">Ghana</option>
              <option value="GI">Gibraltar</option>
              <option value="GR">Greece</option>
              <option value="GL">Greenland</option>
              <option value="GD">Grenada</option>
              <option value="GP">Guadeloupe</option>
              <option value="GU">Guam</option>
              <option value="GT">Guatemala</option>
              <option value="GG">Guernsey</option>
              <option value="GN">Guinea</option>
              <option value="GW">Guinea-Bissau</option>
              <option value="GY">Guyana</option>
              <option value="HT">Haiti</option>
              <option value="HM">Heard Island and McDonald Islands</option>
              <option value="VA">Holy See (Vatican City State)</option>
              <option value="HN">Honduras</option>
              <option value="HK">Hong Kong</option>
              <option value="HU">Hungary</option>
              <option value="IS">Iceland</option>
              <option value="IN">India</option>
              <option value="ID">Indonesia</option>
              <option value="IR">Iran, Islamic Republic of</option>
              <option value="IQ">Iraq</option>
              <option value="IE">Ireland</option>
              <option value="IM">Isle of Man</option>
              <option value="IL">Israel</option>
              <option value="IT">Italy</option>
              <option value="JM">Jamaica</option>
              <option value="JP">Japan</option>
              <option value="JE">Jersey</option>
              <option value="JO">Jordan</option>
              <option value="KZ">Kazakhstan</option>
              <option value="KE">Kenya</option>
              <option value="KI">Kiribati</option>
              <option value="KP">Korea, Democratic People's Republic of</option>
              <option value="KR">Korea, Republic of</option>
              <option value="KW">Kuwait</option>
              <option value="KG">Kyrgyzstan</option>
              <option value="LA">Lao People's Democratic Republic</option>
              <option value="LV">Latvia</option>
              <option value="LB">Lebanon</option>
              <option value="LS">Lesotho</option>
              <option value="LR">Liberia</option>
              <option value="LY">Libya</option>
              <option value="LI">Liechtenstein</option>
              <option value="LT">Lithuania</option>
              <option value="LU">Luxembourg</option>
              <option value="MO">Macao</option>
              <option value="MK">Macedonia, the former Yugoslav Republic of</option>
              <option value="MG">Madagascar</option>
              <option value="MW">Malawi</option>
              <option value="MY">Malaysia</option>
              <option value="MV">Maldives</option>
              <option value="ML">Mali</option>
              <option value="MT">Malta</option>
              <option value="MH">Marshall Islands</option>
              <option value="MQ">Martinique</option>
              <option value="MR">Mauritania</option>
              <option value="MU">Mauritius</option>
              <option value="YT">Mayotte</option>
              <option value="MX">Mexico</option>
              <option value="FM">Micronesia, Federated States of</option>
              <option value="MD">Moldova, Republic of</option>
              <option value="MC">Monaco</option>
              <option value="MN">Mongolia</option>
              <option value="ME">Montenegro</option>
              <option value="MS">Montserrat</option>
              <option value="MA">Morocco</option>
              <option value="MZ">Mozambique</option>
              <option value="MM">Myanmar</option>
              <option value="NA">Namibia</option>
              <option value="NR">Nauru</option>
              <option value="NP">Nepal</option>
              <option value="NL">Netherlands</option>
              <option value="NC">New Caledonia</option>
              <option value="NZ">New Zealand</option>
              <option value="NI">Nicaragua</option>
              <option value="NE">Niger</option>
              <option value="NG">Nigeria</option>
              <option value="NU">Niue</option>
              <option value="NF">Norfolk Island</option>
              <option value="MP">Northern Mariana Islands</option>
              <option value="NO">Norway</option>
              <option value="OM">Oman</option>
              <option value="PK">Pakistan</option>
              <option value="PW">Palau</option>
              <option value="PS">Palestinian Territory, Occupied</option>
              <option value="PA">Panama</option>
              <option value="PG">Papua New Guinea</option>
              <option value="PY">Paraguay</option>
              <option value="PE">Peru</option>
              <option value="PH">Philippines</option>
              <option value="PN">Pitcairn</option>
              <option value="PL">Poland</option>
              <option value="PT">Portugal</option>
              <option value="PR">Puerto Rico</option>
              <option value="QA">Qatar</option>
              <option value="RE">Réunion</option>
              <option value="RO">Romania</option>
              <option value="RU">Russian Federation</option>
              <option value="RW">Rwanda</option>
              <option value="BL">Saint Barthélemy</option>
              <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
              <option value="KN">Saint Kitts and Nevis</option>
              <option value="LC">Saint Lucia</option>
              <option value="MF">Saint Martin (French part)</option>
              <option value="PM">Saint Pierre and Miquelon</option>
              <option value="VC">Saint Vincent and the Grenadines</option>
              <option value="WS">Samoa</option>
              <option value="SM">San Marino</option>
              <option value="ST">Sao Tome and Principe</option>
              <option value="SA">Saudi Arabia</option>
              <option value="SN">Senegal</option>
              <option value="RS">Serbia</option>
              <option value="SC">Seychelles</option>
              <option value="SL">Sierra Leone</option>
              <option value="SG">Singapore</option>
              <option value="SX">Sint Maarten (Dutch part)</option>
              <option value="SK">Slovakia</option>
              <option value="SI">Slovenia</option>
              <option value="SB">Solomon Islands</option>
              <option value="SO">Somalia</option>
              <option value="ZA">South Africa</option>
              <option value="GS">South Georgia and the South Sandwich Islands</option>
              <option value="SS">South Sudan</option>
              <option value="ES">Spain</option>
              <option value="LK">Sri Lanka</option>
              <option value="SD">Sudan</option>
              <option value="SR">Suriname</option>
              <option value="SJ">Svalbard and Jan Mayen</option>
              <option value="SZ">Swaziland</option>
              <option value="SE">Sweden</option>
              <option value="CH">Switzerland</option>
              <option value="SY">Syrian Arab Republic</option>
              <option value="TW">Taiwan, Province of China</option>
              <option value="TJ">Tajikistan</option>
              <option value="TZ">Tanzania, United Republic of</option>
              <option value="TH">Thailand</option>
              <option value="TL">Timor-Leste</option>
              <option value="TG">Togo</option>
              <option value="TK">Tokelau</option>
              <option value="TO">Tonga</option>
              <option value="TT">Trinidad and Tobago</option>
              <option value="TN">Tunisia</option>
              <option value="TR">Turkey</option>
              <option value="TM">Turkmenistan</option>
              <option value="TC">Turks and Caicos Islands</option>
              <option value="TV">Tuvalu</option>
              <option value="UG">Uganda</option>
              <option value="UA">Ukraine</option>
              <option value="AE">United Arab Emirates</option>
              <option value="GB">United Kingdom</option>
              <option value="US">United States</option>
              <option value="UM">United States Minor Outlying Islands</option>
              <option value="UY">Uruguay</option>
              <option value="UZ">Uzbekistan</option>
              <option value="VU">Vanuatu</option>
              <option value="VE">Venezuela, Bolivarian Republic of</option>
              <option value="VN">Viet Nam</option>
              <option value="VG">Virgin Islands, British</option>
              <option value="VI">Virgin Islands, U.S.</option>
              <option value="WF">Wallis and Futuna</option>
              <option value="EH">Western Sahara</option>
              <option value="YE">Yemen</option>
              <option value="ZM">Zambia</option>
              <option value="ZW">Zimbabwe</option>
              </select>
            </div>

            <div className="fieldCont col-md-4">
              <label>City</label>
              <input type="text" name="city" placeholder='City' onChange={this.onChange} value={this.state.checkoutData.city} required/>
            </div>

            {this.state.euCountry &&
              <div class="fieldCont col-md-12">
                <label>VAT Number (For EU countries)</label>
                <input type="text" name="vat_id" value={this.state.checkoutData.vat_id} onChange={this.onChange}/>
              </div>
            }

            <div className="clearfix"></div>
          <div className="col-md-12">

                    </div>

                </div>
                </>
                }
</div>
                <div className="col-md-4 sticky-payment">
                  <div className="card invoice">

                  {this.props.project.config_feature_stripe_payment_link_checkout_enabled &&
                    <>
                      <h1>Summary</h1>
                    </>
                  }

                  {(!this.state.pricePreview && this.props.project.config_feature_new_billing_seats) &&
                    <div className="text-center">
                      <Loader
                       type="Oval"
                       color="#b7b7b7"
                       height="100"
                       width="100"
                      />
                    </div>
                  }

                  {this.state.checkoutData.seats > 0 &&
                    <>
                    {(this.props.queryString.spamCheckerPlan || this.props.queryString.emailvalidationPlan || (this.state.pricePreview && this.props.project.config_feature_new_billing_seats))  &&
                      <>
                      {this.state.pricePreview &&
                        <>
                          <div className="details">

                            {!this.state.pricePreview.items &&
                              <div className="line">
                                {this.state.pricePreview.name}
                                <span className="pull-right">
                                  ${this.state.pricePreview.price.toFixed(2)}
                                </span>
                              </div>
                            }

                            {this.state.pricePreview.items &&
                              <>
                                {this.state.pricePreview.items.map((item, index) => {
                                  if(item.price > 0){
              											return (
                                      <div className="line">
                                        {item.name}
                                        <span className="pull-right">
                                          ${item.price.toFixed(2)}
                                        </span>
                                      </div>
              										)}
                                })}
                              </>
                            }


                            {this.state.pricePreview.coupon &&
                              <>
                              {this.state.pricePreview.coupon > 0 &&
                                <div className="line">
                                  Current discount
                                  <span className="pull-right">
                                    - ${this.state.pricePreview.coupon}
                                  </span>
                                </div>
                              }
                              </>
                            }

                            {this.state.pricePreview.balance &&
                              <>
                                {(this.state.pricePreview.balance != 0) &&
                                  <div className="line">
                                    Credits balance
                                    <span className="pull-right">
                                      {this.state.pricePreview.balance}$
                                    </span>
                                  </div>
                                }
                              </>
                            }

                            <div className="line">
                              Tax ({this.state.pricePreview.tax_percentage}%)
                              <span className="pull-right">
                                ${this.state.pricePreview.tax_total.toFixed(2)}
                              </span>
                            </div>
                          </div>
                          <div className="total">
                            Total
                            <span className="pull-right">
                              ${this.state.pricePreview.total.toFixed(2)}
                            </span>
                          </div>
                        </>
                      }
                     {!this.state.pricePreview &&
                       <Loader
                        type="Oval"
                        color="#b7b7b7"
                        height="100"
                        width="100"
                       />
                     }
                     </>
                    }

                    {(this.props.project.stripe_subscription_id && this.props.checkoutV3) &&
                      <>
                        <div className="secure-payment">
                          As this is a prorated charge based on your renewal date, this will be the maximum amount that you will pay today.
                        </div>
                        <br />
                      </>
                    }

                    <div className="secure-payment">
                      <i class="fas fa-lock"></i> This is a secure 128-bit SSL encrypted payment, secured by Stripe.
                      <div className="pull-right">
                        <i class="fab fa-cc-visa"></i> <i class="fab fa-cc-mastercard"></i> <i class="fab fa-cc-amex"></i>
                      </div>
                    </div>

                    </>
                  }

                  {this.state.checkoutData.seats == 0 &&
                    <div className="secure-payment yellow">
                      <i class="fas fa-info-circle"></i> Cancelling your subscription will disconnect all your email accounts and the warming activity will stop immediately
                    </div>
                  }

                    {this.state.openCouponField &&
                      <input placeholder="Coupon" name="coupon" onChange={this.onChange} value={this.state.checkoutData.coupon}/>
                    }


                    {(this.props.project.config_feature_new_billing_seats && !this.props.queryString.spamCheckerPlan) &&
                      <>
                      {!this.state.selectedPlan &&
                        <button className="btn purple-btn" onClick={this.submit} disabled={true}>
                          Select your plan first
                        </button>
                      }
                        {(this.state.pricePreview && this.state.selectedPlan) &&
                          <>

                        {this.props.project.seats == 0 &&
                          <button className="btn purple-btn" onClick={this.submit} disabled={(!stripe || this.state.checkoutData.seats == 0)}>
                            Pay ${this.state.pricePreview.total.toFixed(2)}
                          </button>
                        }
                        {this.props.project.seats > 0 &&
                          <>
                          {((this.props.project.stripe_subscription_period == 'yearly') && !this.state.yearly) &&
                            <button className="btn purple-btn" onClick={this.submit} disabled={true}>
                              You can't switch from a yearly to a monthly plan
                            </button>
                          }

                            {((this.props.project.seats == this.state.checkoutData.seats) && ((this.props.project.stripe_subscription_period == 'yearly') == this.state.yearly )) &&
                              <>
                                {this.props.hideAddCardFrom &&
                                  <button className="btn purple-btn" onClick={this.submit} disabled={true}>
                                    Already in this plan
                                  </button>
                                }
                                {!this.props.hideAddCardFrom &&
                                  <button className="btn purple-btn" onClick={this.submit} disabled={(!stripe)}>
                                    Save card details
                                  </button>
                                }
                              </>
                            }

                            {((this.props.project.seats < this.state.checkoutData.seats) || ((this.props.project.stripe_subscription_period != 'yearly') &&  this.state.yearly)) &&
                              <button className="btn purple-btn" onClick={this.submit} disabled={(!stripe || this.state.checkoutData.seats == 0)}>
                                Pay ${this.state.pricePreview.total.toFixed(2)}
                              </button>
                            }

                            {((this.props.project.seats > this.state.checkoutData.seats) && this.state.checkoutData.seats > 0) &&
                              <>
                                {this.state.checkoutData.seats < this.props.project.used_seats &&
                                  <div className="alert status warning card bounce">
                                    To reduce your number of seats, you have to disconnect {this.props.project.used_seats - this.state.checkoutData.seats} email accounts from MailReach first.
                                  </div>
                                }

                                {this.state.checkoutData.seats >= this.props.project.used_seats &&
                                  <button className="btn btn-primary red" onClick={this.submit} disabled={!stripe}>
                                    Downgrade
                                  </button>
                                }

                              </>
                            }

                            {this.state.checkoutData.seats == 0 &&
                              <button className="btn btn-primary red" onClick={this.openCancelSubscription}>
                                Cancel subscription
                              </button>
                            }
                          </>
                          }
                          </>
                        }
                      </>

                    }


                    {(!this.props.project.config_feature_new_billing_seats || this.props.queryString.spamCheckerPlan || this.props.queryString.emailvalidationPlan) &&
                      <button className="btn purple-btn" onClick={this.submit} disabled={(!stripe || this.state.checkoutData.seats == 0)}>
                        {this.state.pricePreview &&
                          <>
                            Pay ${this.state.pricePreview.total.toFixed(2)}
                          </>
                        }
                        {!this.state.pricePreview &&
                          <>
                            Save card details
                          </>
                        }
                      </button>
                    }
                  </div>
                </div>
                </form>
              	<ToastContainer />

                <Modal className="reputation-modal" show={this.state.openCancelSubscriptionModal} onHide={() => this.setState({openCancelSubscriptionModal: false})}>
                  <Modal.Body>
                    <>
                      <h1 className="text-center">Are you 100% sure?</h1>
                      <div className="txt text-center">
                          <h4 className="prevent-goodby">
                            When stopping email warming, it causes your engagement rate to drop, decrease your sender reputation, deliverability, open rates and simply your sales.
                          </h4>
                          <img className="prevent-stop" src="/images/stop_warming.png"/>
                      </div>
                      <div className="footer text-center del">
                        <Button variant="secondary" onClick={() => this.setState({showDisconnectModal: false})}>
                          Stay
                        </Button>
                        <Button className="btn purple red" onClick={this.cancelSubscription}>
                          I'm sure to stop. Cancel
                        </Button>
                      </div>
                    </>
                  </Modal.Body>
                </Modal>

              </div>
    );
  }
}

const InjectedCheckoutForm = (props) => {
  return (
    <ElementsConsumer>
      {({elements, stripe}) => (
        <CheckoutForm elements={elements} stripe={stripe} queryString={props.queryString} hideAddCardFrom={props.hideAddCardFrom} projectId={props.projectId} project={props.project} history={props.history} checkoutV3={props.checkoutV3} />
      )}
    </ElementsConsumer>
  );
};

export default InjectedCheckoutForm
