import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import {toast} from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import {loadStripe} from '@stripe/stripe-js';
import InjectedCheckoutForm from './checkoutForm';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {Elements} from '@stripe/react-stripe-js';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Slider, { SliderThumb } from '@mui/material/Slider';

import { styled } from '@mui/material/styles';

import i18n from "../../../i18n";

import ProjectService from '../../../services/project.service';

import StripeService from '../../../services/stripe.service';

import './settings.scss';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const MailreachSlider = styled(Slider)({
  height: 8
});

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#11103b',
    },
    '&$checked + $track': {
      backgroundColor: '#11103b',
    },
  },
  checked: {},
  track: {
      backgroundColor: '#11103b',
  },
})(Switch);

const Pricing = [
  {credits: 5000, unitPrice: 0.006, payAsYouGoPrice: 0.0078},
  {credits: 10000, unitPrice: 0.0057, payAsYouGoPrice: 0.0074},
  {credits: 20000, unitPrice: 0.0055, payAsYouGoPrice: 0.0072},
  {credits: 50000, unitPrice: 0.005, payAsYouGoPrice: 0.0065},
  {credits: 75000, unitPrice: 0.00430, payAsYouGoPrice: 0.0056},
  {credits: 100000, unitPrice: 0.00380, payAsYouGoPrice: 0.0049},
  {credits: 200000, unitPrice: 0.00340, payAsYouGoPrice: 0.0044},
  {credits: 500000, unitPrice: 0.00280, payAsYouGoPrice: 0.0036},
  {credits: 1000000, unitPrice: 0.00240, payAsYouGoPrice: 0.0031},
]

class DashboardSettingsBillingEmailvalidation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {errors: [], invoices: [], monthly: true, totalMonthlyTests: 150, sliderPricing: 46, emailvalidationPlan: 1, startStep: 1, selectedCreditsNumber:10000}
		this.signout = this.signout.bind(this);
    this.setPricingPlan = this.setPricingPlan.bind(this);
    this.cancel = this.cancel.bind(this);
    this.selectDisconnectReason = this.selectDisconnectReason.bind(this);
    this.billingPeriodChanges = this.billingPeriodChanges.bind(this);
    this.refreshCreditsInput = this.refreshCreditsInput.bind(this);
	}

	componentWillMount() {
		window.gtag("event", "billing", {
            event_category: "access",
            event_label: "billing_page_emailvalidation"
        });
    ProjectService.get(this.props.match.params.project_id).then((response) => {
      this.setState({project: response.body});
      localStorage.setItem('x-api-key', response.body.api_key);
    })
    .catch((error) => {
      this.setState({error: true,});
      toast.error('Oops, we have an error');
    });

		StripeService.subscriptions().then((response) => {
      let subscription = response.body.data.filter(subscription => subscription.emailvalidation)[0];
      // Get current quantity
      if(subscription){
			  this.setState({subscription: subscription, selectedCreditsNumber: subscription.quantity})
      }
		})
		.catch((error) => {
      console.log(error);
			this.setState({error: true,});
			toast.error('Oops, we have an error');
		});

		let queryParams = queryString.parse(this.props.location.search);
		let hideSignout = queryParams.hide_signout;
		this.setState({hideSignout});
	}

	componentWillReceiveProps(nextProps) {
	}

	signout(){
		localStorage.clear();
		//this.props.history.push(`/redirect`);
	}

	submitVat(e){
		e.preventDefault();
		StripeService.setVat(this.state.vat_id).then((response) => {
			toast.success('VAT number saved !');
		})
		.catch((error) => {
			this.setState({error: true,});
			toast.error('Invalid VAT number');
		});
	}

  refreshCreditsInput(event){
    event.preventDefault();

    let creditsNumber = event.target.value;

    if(creditsNumber < 2000){
      this.setState({disabledSelect: true});
    }else{
      this.setState({disabledSelect: false});
    }

    let pricingTarget = 0;
    if(creditsNumber < 5001){
      pricingTarget = 0;
    }else if(creditsNumber < 7500){
      pricingTarget = 1;
    }else if (creditsNumber < 15000) {
      pricingTarget = 2;
    }else if (creditsNumber < 35000) {
      pricingTarget = 3;
    }else if (creditsNumber < 57500) {
      pricingTarget = 4;
    }else if (creditsNumber < 87500) {
      pricingTarget = 5;
    }else if (creditsNumber < 15000) {
      pricingTarget = 6;
    }else if (creditsNumber < 350000) {
      pricingTarget = 7;
    }else if (creditsNumber < 750000) {
      pricingTarget = 8;
    }

    this.setPricingPlan({target: {value: pricingTarget}}, null, creditsNumber)

  }

  setPricingPlan(value, index, creditsNumber = null) {
    let unitPrice = Pricing[parseInt(value.target.value)].unitPrice;
    if(!this.state.monthly){
      unitPrice = Pricing[parseInt(value.target.value)].payAsYouGoPrice;
    }

    if(!creditsNumber){
      creditsNumber = Pricing[parseInt(value.target.value)].credits;
    }

    let totalMonthlyPricing = (creditsNumber*unitPrice);
    let totalMonthlyTests = creditsNumber;

    this.setState({totalMonthlyPricing, totalMonthlyTests, selectedCreditsNumber: totalMonthlyTests, startStep: parseInt(value.target.value), sliderPricing: totalMonthlyPricing, spamCheckerPlan: parseInt(value.target.value)});
  }

  billingPeriodChanges(){


    this.setState({monthly: !this.state.monthly},
        function() {
          this.setPricingPlan({target: {value: this.state.startStep}});
        }
    );

  }

  valuetext(value) {
    return `${value}`;
  }

  thumbComponent(props) {
    const { children, ...other } = props;
    return (
      <SliderThumb {...other}>
        {children}
        <i class="fas fa-angle-left"></i>
        <i class="fas fa-angle-right"></i>
      </SliderThumb>
    );
  }

  getButtonClass(toCompare) {
    if(this.props.currentProject.config_spamchecker_plan > toCompare){
      return 'downgrade'
    }

    if(this.props.currentProject.config_spamchecker_plan < toCompare){
      return 'green'
    }

    if(this.props.currentProject.config_spamchecker_plan == toCompare){
      return 'current'
    }

    if(!this.props.currentProject.config_spamchecker_plan){
      return 'green'
    }
  }

  cancel(){

    StripeService.cancel(this.state.disconnectReason).then((response) => {
			this.setState({showCancelModal: false});
      toast.success('Subscription cancelled');
      // reload project
      c.props.history.push(`/dashboard/${c.props.projectId}/spam-tests/home`);
		})
		.catch((error) => {
			this.setState({error: true,});
			toast.error('Oops, we have an error');
		});
  }

  selectDisconnectReason(e){
    e.preventDefault();
    this.setState({disconnectReason: e.target.value});
  }


	render() {
		return (
			<div className="app container add-credit-card emailvalidation-billing-page">


        <div className="col-md-12">
    				<div className="row">
    					<h1>Select the number of credits you need</h1>

              <div className="select-billing-period text-center">
                {this.props.stripe_subscription_period != 'yearly' &&
                <>
                  <span className={!this.state.monthly ? 'selected' : ''} onClick={this.billingPeriodChanges}>Pay as you go</span>
                  <FormGroup>
                      <FormControlLabel control={<PurpleSwitch
                      checked={this.state.monthly}
                      onChange={this.billingPeriodChanges}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />} label="" />
                  </FormGroup>
                    <span className={this.state.monthly ? 'selected' : ''} onClick={this.billingPeriodChanges}>Monthly</span>
                    </>
                  }
                  <div className="clearfix"></div>
              </div>
              <div className="clearfix"></div>

              <div className="card">
                <div className="plans">
                  <div className={"plan col-md-4"} onClick={() => { this.setState({selectedPlan: 'custom',  showSlider: true}) }}>
                    <h2>How many emails to verify per month?</h2>
                      <div className="imap">
                        <form>
                          <div class="fieldCont col-md-12">
                            <input value={this.state.selectedCreditsNumber} type="number" onChange={this.refreshCreditsInput}/>
                          </div>
                        </form>
                      </div>
                      <div className="range-slider col-md-12">
                          <MailreachSlider
                          value={this.state.startStep}
                          components={{ Thumb: this.thumbComponent }}
                          defaultValue={1}
                          onChange={this.setPricingPlan}
                          step={1}
                          marks
                          min={0}
                          max={8}
                          color="secondary"
                          />
                      </div>

                      <div className="pricing">
                        <span>
                          ${this.state.sliderPricing.toFixed(2)}
                          {this.state.monthly &&
                            <>
                              &nbsp;/ mo
                            </>
                          }
                        </span>

                        {this.state.monthly &&
                          <>
                            <br />
                            <span className="cancel-anytime">Cancel anytime</span>
                          </>
                        }
                      </div>

                    <div className="text-center selectplan">
                      <NavLink to={`/dashboard/${this.props.currentProject.id}/settings/billing?emailvalidationPlan=${this.state.emailvalidationPlan}&monthly=${this.state.monthly}&credits=${this.state.selectedCreditsNumber}`} className={"btn purple-btn " + this.getButtonClass(this.state.emailvalidationPlan)} activeClassName="active">
                        Select
                      </NavLink>
                    </div>
                  </div>
                </div>

                <div className="col-md-5 col-md-offset-1">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Emails</th>
                        <th scope="col">Price per email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Pricing.map((pricing, index) => {
              					return (
                          <tr onClick={() => this.setPricingPlan({target: {value: index}})}>
                            <td>Up to <b>{pricing.credits.toLocaleString().replaceAll(",", " ")}</b></td>
                            {this.state.monthly &&
                              <td><b>${pricing.unitPrice.toFixed(4)}</b></td>
                            }
                            {!this.state.monthly &&
                              <td><b>${pricing.payAsYouGoPrice.toFixed(4)}</b></td>
                            }
                          </tr>
                        )})}
                    </tbody>
                  </table>
                </div>

                <div className="clearfix"></div>
              </div>
					  </div>
        </div>

        <Modal className="reputation-modal" show={this.state.showCancelModal} onHide={() => this.setState({showCancelModal: false})}>
          <Modal.Body>
              <>
                <h1 className="text-center">Can you give us more info ?</h1>
                <div className="txt text-center">
                  <h4>
                    As we're putting a lot of energy to improve MailReach, could you please give us more info about the reason you want to stop warming your inbox ?
                  </h4><br /><br />
                  <form>
                  <select onChange={this.selectDisconnectReason} value={this.state.disconnectReason}>
                    <option value="">--Please choose a reason--</option>
                    <option value="think_inbox_warmed_up">I think my inbox has been warmed up enough</option>
                    <option value="i_will_come_back_later">I'm done for now but I will come back later for sure</option>
                    <option value="any_improvement">I didn't notice any improvement on the deliverability of this inbox</option>
                    <option value="other_warmup_solution">I'm leaving for another warming solution</option>
                    <option value="no_reason">I just don't want to give any reason</option>
                    <option value="other">Other</option>
                  </select>
                  </form>
                  <br />
                </div>
                <div className="footer text-center del">
                  <Button variant="secondary" onClick={() => this.setState({showCancelModal: false})}>
                    Cancel
                  </Button>
                  <Button className="btn purple red" onClick={this.cancel} disabled={!this.state.disconnectReason}>
                    Disconnect my inbox
                  </Button>
                </div>
              </>
          </Modal.Body>
        </Modal>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		currentUser: state.currentUser,
		currentProject: state.currentProject,
		currentPricing: state.currentPricing
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardSettingsBillingEmailvalidation));
