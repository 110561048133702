import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {Elements} from '@stripe/react-stripe-js';


import './settings.css';

class DsSuccess extends React.Component {
	constructor(props) {
		super(props);
		this.state = {}
	}

	componentDidMount() {
    //window.top.postMessage('3DS-authentication-complete');
	}

	render() {
    window.top.postMessage('3DS-authentication-complete');
		return (
			<div className="app container add-credit-card">
        Success, this window will be closed
			</div>
		);
	}
}


export default DsSuccess;
