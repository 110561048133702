import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import {toast} from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import {loadStripe} from '@stripe/stripe-js';
import InjectedCheckoutForm from '../checkoutForm';
import {Elements} from '@stripe/react-stripe-js';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Slider, { SliderThumb } from '@mui/material/Slider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Tooltip from '@material-ui/core/Tooltip';
import Loader from 'react-loader-spinner';

import ProjectService from '../../../../services/project.service';
import StripeService from '../../../../services/stripe.service';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);


import { styled } from '@mui/material/styles';


import { GeneratePricingPreview } from '../../../../helpers/generatePricingPreview'


const MailreachSlider = styled(Slider)({
  height: 8
});

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#11103b',
    },
    '&$checked + $track': {
      backgroundColor: '#11103b',
    },
  },
  checked: {},
  track: {
      backgroundColor: '#11103b',
  },
})(Switch);


import './pay.scss';


let spamcheckerPricingConfig = {
  1: {
    totalMonthlyPricing: 12,
    totalSubPricing: 9.6,
    totalMonthlyTests: 25
  },
  2: {
    totalMonthlyPricing: 23,
    totalSubPricing: 18.4,
    totalMonthlyTests: 50
  },
  3: {
    totalMonthlyPricing: 35,
    totalSubPricing: 28,
    totalMonthlyTests: 100
  },
  4: {
    totalMonthlyPricing: 46,
    totalSubPricing: 36.8,
    totalMonthlyTests: 150
  },
  5: {
    totalMonthlyPricing: 54,
    totalSubPricing: 43.2,
    totalMonthlyTests: 200
  },
  6: {
    totalMonthlyPricing: 68,
    totalSubPricing: 54.4,
    totalMonthlyTests: 300
  },
  7: {
    totalMonthlyPricing: 92,
    totalSubPricing: 73.6,
    totalMonthlyTests: 500
  },
  8: {
    totalMonthlyPricing: 135,
    totalSubPricing: 108,
    totalMonthlyTests: 1000
  },
  9: {
    totalMonthlyPricing: 180,
    totalSubPricing: 144,
    totalMonthlyTests: 1500
  },
  10: {
    totalMonthlyPricing: 200,
    totalSubPricing: 160,
    totalMonthlyTests: 2000
  }
};


class DashboardSettingsCheckoutV2Pay extends React.Component {
	constructor(props) {
		super(props);
		this.state = {errors: []}

	}

	componentWillMount() {
    ProjectService.get(this.props.match.params.project_id).then((response) => {
      this.setState({project: response.body});
      localStorage.setItem('x-api-key', response.body.api_key);
    })
    .catch((error) => {
      this.setState({error: true,});
      toast.error('Oops, we have an error');
    });

    let queryParams = queryString.parse(this.props.location.search);
    this.setState({queryString: queryParams});
	}

	render() {


    if(this.state.loading){
      return(
        <div className="app container text-center">
          <div className="loader">
            <Loader
             type="Oval"
             color="#b7b7b7"
             height="100"
             width="100"
            />
          </div>
          <style>{`
            .sidebar{
              display: none;
            }
          `}</style>
        </div>
      )
    }

		return (
      <>
        <div className="background-wth-one"></div>

        <div className="pricing-pay-v2">
    			<div className="app container">
            <Elements stripe={stripePromise}>
              <InjectedCheckoutForm checkoutV3={true} queryString={this.state.queryString} hideAddCardFrom={this.state.hideAddCardForm} projectId={this.props.match.params.project_id} project={this.props.currentProject} history={this.props.history} />
            </Elements>
          </div>
        </div>

        <style>{`
          .sidebar{
            display: none;
          }
          body{
            overflow: hidden;
          }
        `}</style>
  		</>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		currentUser: state.currentUser,
		currentProject: state.currentProject,
		currentPricing: state.currentPricing
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardSettingsCheckoutV2Pay));
