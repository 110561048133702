import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import {toast} from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import {loadStripe} from '@stripe/stripe-js';
import InjectedCheckoutForm from './checkoutForm';
import {Elements} from '@stripe/react-stripe-js';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

import i18n from "../../../i18n";

import ProjectService from '../../../services/project.service';


import './settings.css';


const PurpleSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#11103b',
    },
    '&$checked + $track': {
      backgroundColor: '#11103b',
    },
  },
  checked: {},
  track: {},
})(Switch);


class DashboardSettingsOthers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {errors: [], currentProject: {}, keyFieldState: 'password'}
		this.toggleField = this.toggleField.bind(this);
    this.toggleAccountCopilot = this.toggleAccountCopilot.bind(this);
	}

	componentWillMount() {
    ProjectService.get(this.props.match.params.project_id).then((response) => {
      console.log(response.body);
      this.setState({currentProject: response.body});
    })
    .catch((error) => {
      this.setState({error: true,});
      toast.error('Oops, we have an error');
    });
	}

	toggleField(e){
		e.preventDefault();
		if(this.state.keyFieldState == 'password'){
      this.setState({keyFieldState: 'text'});
		} else {
			this.setState({keyFieldState: 'password'});
		}
	}

  toggleAccountCopilot(){
    ProjectService.toggleCopilot(!this.state.currentProject.config_copilot_default_value).then((response) => {
      this.setState({currentProject: response.body});
    })
    .catch((error) => {
      this.setState({error: true,});
      toast.error('Oops, we have an error');
    });
  }

	signout(){
		localStorage.clear();
		//this.props.history.push(`/redirect`);
	}

	render() {
		return (
			<div className="app container add-credit-card">
        <div className="col-md-3">
					<div className="title">
						<h1></h1>
					</div>
          <div className="card">
						<ul className="nav-left">
							<li>
								<NavLink to={`/dashboard/${this.state.currentProject.id}/settings/billing`} activeClassName="active">
									<i class="far fa-credit-card"></i> Billing
								</NavLink>
							</li>
							<li>
								<NavLink to={`/dashboard/${this.state.currentProject.id}/settings/api`} activeClassName="active">
									<i class="fas fa-key"></i> API
								</NavLink>
							</li>
							<li>
								<NavLink to={`/dashboard/${this.state.currentProject.id}/settings/team`} activeClassName="active">
									<i class="fa-solid fa-people-group"></i> Team
								</NavLink>
							</li>
							<li>
								<NavLink to={`/dashboard/${this.state.currentProject.id}/settings/alerts`} activeClassName="active">
									<i class="fa-sharp fa-solid fa-bell"></i> Alerts
								</NavLink>
							</li>
							<li>
								<NavLink to={`/dashboard/${this.state.currentProject.id}/settings/password`} activeClassName="active">
									<i class="fa-solid fa-lock"></i> Change password
								</NavLink>
							</li>
								<li>
									<a href="#" onClick={this.signout} className="signout">
										<i class="fas fa-sign-out-alt"></i> Sign out
									</a>
								</li>
						</ul>
						<div className="clearfix"></div>
          </div>
        </div>
        <div className="col-md-9">
				<div className="row">
					<h1>Other settings</h1>

            {this.state.currentProject.config_feature_copilot_enabled &&
              <div className="card col-md-12">
                <p className="delete pull-left">
                  Volume Checker
                  <div className="help-bubble pull-right">
                    <Tooltip title="MailReach's Volume Checker analyzes your own sending activity and give you insights to maximize your reputation and deliverability." placement="bottom">
                      <div>
                        <i class="fas fa-info-circle"></i>
                      </div>
                    </Tooltip>
                  </div>
                </p>
                <div className="pull-right">
                  <PurpleSwitch
                    checked={this.state.currentProject.config_copilot_default_value}
                    onChange={this.toggleAccountCopilot}
                    name="config_copilot_default_value"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </div>
                <div className="clearfix"></div>
              </div>
            }
													<div className="clearfix"></div>
					</div>
        </div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		currentUser: state.currentUser,
		currentPricing: state.currentPricing
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardSettingsOthers));
