import React from 'react';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import queryString from 'query-string';

import './invite.scss'

import ProjectService from '../../services/project.service';
import ProjectMemberService from '../../services/projectMember.service';


class AppInvite extends React.Component {
	constructor(props) {
		super(props);
		this.state = {errors: [], projects: [], error: false, loading: true, signedIn: false};
		this.join = this.join.bind(this);
	}


	componentWillMount() {

    if (localStorage.jwt) {
			this.setState({signedIn: true})
		}else{
			// Redirect to signin/signup with the token
			this.props.history.push("/signup" + this.props.location.search)
		}

		let queryParams = queryString.parse(this.props.location.search);
		// Set auth token
		if(queryParams.token){
      ProjectService.by_invite_token(queryParams.token).then((response) => {
        this.setState({project: response.body, loading: false})
      })
      .catch((error) => {
        this.setState({error: true, loading: false});
        toast.error('Oops, we have an error. This invitation link can be outdated.');
				this.props.history.push("/redirect")
      });
      // Todo, find the project
      // Redirect to the project with a notification
      // Afficher (Ndd du project)
      // Si pas signed up, redirect
		}
	}

	componentWillReceiveProps(nextProps) {
		const errors = nextProps.errors;
		if (errors && errors != this.props.errors) {
			toast.error('Oops, il y a un petit problème');
		}else{
			if(nextProps.projects.length === 0){
				//this.props.history.push("/dashboard/getting-started/create");
			}else{
				//this.props.history.push("/dashboard/requests");
			}
		}
	}

	join(){
    let queryParams = queryString.parse(this.props.location.search);
    ProjectMemberService.create(queryParams.token).then((response) => {
      localStorage.setItem('x-api-key', this.state.project.api_key);
      this.props.history.push(`/dashboard/${this.state.project.id}/home`);
      toast.success('Project joined !');
    })
    .catch((error) => {
      this.setState({error: true, loading: false});
      toast.error('Oops, we have an error. It seem you are already on this project.');
			this.props.history.push(`/redirect`);
    });
	}


	render() {
		if(this.state.loading){
			return(
				<div className="app container text-center">
					<div className="loader">
						<Loader
						 type="Oval"
						 color="#b7b7b7"
						 height="100"
						 width="100"
						/>
					</div>
				</div>
			)
		}

    if(!this.state.signedIn){
      return(
        <div className="container">
          <div className="card text-center">
          <img src="/images/team.svg" className="down"/>
            You have to signup on Mailreach before joining a project. Signup, then follow your invitation link.
          </div>
        </div>
      )
    }

		return (
			<>
      {(!this.state.project && this.state.error) &&
        <div className="container">
          <div className="card text-center">
          <img src="/images/down.svg" className="down"/>
          Oops, it looks like we're having a little problem. Please try refreshing the page in a moment.
          </div>
        </div>
      }
      {this.state.project &&
        <div className="app projects-selector">
        <h1>Your invited to join</h1>
          <ul>
          <li key={this.state.project.id}>
            <div>
              <div className="projectLogo" style={{background: this.state.project.color}} ></div>
              <span className="name">{this.state.project.name}</span>
              <span className="desc">{this.state.project.main_user_email}</span>
            </div>
          </li>
            <button className="btn joinbutton" onClick={this.join}>
              <i className="fas fa-plus-circle"></i> Join now
            </button>
          </ul>
        </div>
      }
			</>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		//organization: state.organizations
	};
}

function mapDispatchToProps(dispatch) {
	return {
		//actions: bindActionCreators(organization, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AppInvite));
