import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {NavLink, withRouter} from 'react-router-dom';
import {toast} from 'react-toastify';
import { Dropdown } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';
import Moment from 'react-moment';
import queryString from 'query-string';

import './sidebar.scss';
import * as currentUserActions from '../../actions/currentUser.actions';
import * as currentProjectActions from '../../actions/currentProject.actions';
import * as currentPricingActions from '../../actions/currentPricing.actions';
import * as notificationsActions from '../../actions/notifications.actions';

import ProjectService from '../../services/project.service';
import NotificationService from '../../services/notification.service';

import i18n from "../../i18n";

class Sidebar extends React.Component {

	constructor(props) {
		super(props);
		this.state = {errors: [], projects: [], currentUser: {}, notifications: [], total_notifications: 0, queryParams: {}};
	}

	componentWillMount() {
		if (!localStorage.jwt) {
			this.props.history.push("/redirect");
		}

		let queryParams = queryString.parse(this.props.location.search);

		this.setState({queryParams})
		this.props.currentUserActions.getCurrent();
		this.props.currentProjectActions.getCurrent().then((response) => {
			this.props.currentPricingActions.getCurrent(this.props.currentProject.stripe_default_plan_id);
		})

		ProjectService.all().then((response) => {
			this.setState({projects: response.body})
		})
		.catch((error) => {
			this.setState({error: true,});
			toast.error('Oops, we have an error');
		});

		NotificationService.summary().then((response) => {
			this.setState({total_notifications: response.body.total})
		})

		this.props.notificationsActions.getAll();
	}

	componentWillReceiveProps(nextProps) {
		if (!localStorage.jwt) {
			this.props.history.push("/redirect");
		}
		if(nextProps.currentUser && !localStorage.getItem('admin')){
			if(nextProps.currentUser.email){
				window.$crisp.push(["set", "user:email", nextProps.currentUser.email]);
				if(nextProps.currentUser.first_name){
					window.$crisp.push(["set", "user:nickname", nextProps.currentUser.first_name]);
				}
			}
		}
		if(window.location.host == 'localhost:3001'){
			window.$crisp.push(["set", "session:segments", [["dev-mode"]]]);
		}
		this.setState({currentUser: nextProps.currentUser, loading: false});
	}

	closeNotification(id){
		NotificationService.close(id).then((response) => {
			this.props.notificationsActions.getAll();
		})
		.catch((error) => {
		});
	}

	setKey(api_key){
		localStorage.setItem('x-api-key', api_key);
		this.props.currentProjectActions.getCurrent().then((response) => {
			window.location.href = `/dashboard/${this.props.currentProject.id}/home`;
			window.location.reload();
		});
	}

	signout(){
		localStorage.clear();
		window.location.href = "https://www.mailreach.co/";
	}


	render() {
		return (
			<>

			{localStorage.getItem('admin') &&
				<div className="admin-bar">
					ADMIN MODE
				</div>
			}

			{this.props.currentProject.declined_card_alert &&
				<div className="admin-bar declined-card">
					💳 Your last payment didn't go through.
					<NavLink to={`/dashboard/${this.props.currentProject.id}/settings/billing`}>
											Please update your payment information here.
					</NavLink>
				</div>
			}
				<div className="sidebar">
					<div className="ct">
						<div className="logo">
							<div className="bck" >
								<NavLink to={`/dashboard/${this.props.currentProject.id}/home`}>
									<img src="/images/logo.png" />
								</NavLink>
							</div>
						</div>
						{ this.state.projects.length > 1 &&
							<div>
								<Dropdown>
									<Dropdown.Toggle variant="success" id="dropdown-basic">
										<div className="currentProject">
											<div className="projectLogo" style={{background: this.props.currentProject.color}} ></div> <span>{this.props.currentProject.name}</span>
										</div>
										<div className="app">
											<i className="fas fa-angle-down"></i>
										</div>
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<ul>
										{this.state.projects.map((project, index) => {
											 return (
												<li key={project.id}>
													<NavLink onClick={() => this.setKey(project.api_key)} to={`/dashboard/${project.id}/home`}>
														<div className="projectLogo" style={{background: project.color}} ></div>
														<span className="name">{project.name}</span>
														<span className="desc">{project.domain}</span>
													</NavLink>
												</li>
											)
										})}
										</ul>
									</Dropdown.Menu>
									</Dropdown>
							</div>
						}
						{(this.props.currentProject.config_feature_template_enabled && !this.state.queryParams.hideNvgt) &&
							<ul className={"main-nav" + (this.props.currentProject.used_seats > 9 && localStorage.getItem('admin') ? ' cockpit' : '') + (this.state.projects.length > 1 ? ' m-projects': '')}>
							{(this.props.currentProject.used_seats > 5) &&
								<li>
									<NavLink to={`/dashboard/${this.props.currentProject.id}/overview`} className={window.location.href.includes("/overview") ? 'active' : ''}>
										Overview
									</NavLink>
								</li>
							}
							  <li>
								  <NavLink to={`/dashboard/${this.props.currentProject.id}/home`} className={(!window.location.href.includes("/spam") && !window.location.href.includes("/overview") && !window.location.href.includes("/settings")) ? 'active' : ''}>
									  Email Warmer
									</NavLink>
								</li>
								<li>
									<NavLink to={`/dashboard/${this.props.currentProject.id}/spam-tests/home`} className={window.location.href.includes("spam-tests/home") ? 'active' : ''}>
										Spam Checker
									</NavLink>
								</li>
							</ul>
						}
						{ this.state.projects.length > 0 &&
							<ul>

							<li className={"settings help notifications hidden-mobile" + (this.state.queryParams.hideNvgt ? ' hidden' : '')}>
								<Dropdown className='dropdown-caret-right ntf'>
									<Dropdown.Toggle variant="success" id="dropdown-basic-settings">
									  <i class="far fa-bell"></i>
										{this.state.total_notifications > 0 &&
											<span className="notificationstick">{this.state.total_notifications}</span>
										}
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<ul>
										{(this.props.notifications.length == 0) &&
										  <div className="empty text-center">
											  <img src="/images/hiring.svg" />
											  <p>You don't have any notification.</p>
										  </div>
										}
										{(this.props.notifications.length > 0) &&
											<>
												{this.props.notifications.map((notification, index) => {
													if(notification.event === 'ACCOUNT_DISCONNECTED'){
														return (
															<li key={notification.id} className="item">
																<i onClick={() => this.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
																Your address {notification.account_email} is disconnected.
																<NavLink to={`/dashboard/${notification.project_id}/account/connect?edit_account_id=${notification.account_id}&reconnect=true`} className="btn pull-right" activeClassName="activeunac">
																	Reconnect
																</NavLink>
															</li>
														)
													}else if (notification.event === 'ACCOUNT_FIRST_CONNECT'){
														return(
															<li key={notification.id} className="item">
																<i onClick={() => this.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
																<div className="txt">
																	Congrats 🎉, You've successfully connected {notification.account_email}.
																</div>
															</li>
														)
													}else if (notification.event === 'ACCOUNT_FIRST_CONNECT_QUICK_SIGNIN'){
														return(
															<li key={notification.id} className="item">
																<i onClick={() => this.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
																<div className="txt">
																	Congrats 🎉, {notification.account_email} has been successfully connected to MailReach.
																</div>
															</li>
														)
													}else if (notification.event === 'ACCOUNT_RECONNECTED'){
														return(
															<li key={notification.id} className="item">
																<i onClick={() => this.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
																<div className="txt">
																	You account {notification.account_email} has been automatically reconnected 💪
																</div>
															</li>
														)
													}
													else if (notification.event === 'ADMIN_ADD'){
														return(
															<div key={notification.id} className="item">
																<i onClick={() => this.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
																<div className="txt" dangerouslySetInnerHTML={{__html: notification.content}}>
																</div>
															</div>
														)
													}else if (notification.event === 'CREDIT_CARD_ADDED'){
														return(
															<div key={notification.id} className="item">
																<i onClick={() => this.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
																<div className="txt">
																	Thanks for adding your payment information. You can now connect your first account to MailReach to start improving its reputation 🚀.
																</div>
															</div>
														)
													}else if (notification.event === 'ACCOUNT_BOUNCE'){
														return(
															<div key={notification.id} className="item">
																<i onClick={() => this.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
																<div className="txt">
																	There's a configuration problem on {notification.account_email}. Follow <a href="https://help.mailreach.co/en/article/how-to-fix-email-configuration-problems-3rh7ex/" target="_blank">our guide</a> to fix this.
																</div>
															</div>
														)
													}
													else if (notification.event === 'ACCOUNT_OUTLOOK_MISSING'){
															return(
																<div key={notification.id} className="item">
																	<i onClick={() => this.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
																	<div className="txt">
																		There's a problem of missing emails on {notification.account_email}. Please <a href="https://help.mailreach.co/en/article/how-to-fix-bounces-notifications-and-missing-messages-on-mailreach-emails-outlookoffice-365-18zjs68/?bust=1635859343466" target="_blank">check our guide</a> to solve it.
																	</div>
																</div>
															)
														}
														else if (notification.event === 'ACCOUNT_SENDINBLUE_SMTP_ACTIVATION'){
															return(
																<div key={notification.id} className="item">
																	<div className="txt">
																	  There's a problem of Sendinblue SMTP for {notification.account_email}. Please <a href="https://help.mailreach.co/en/article/how-to-get-your-sendinblue-smtp-account-activated-1v0cj4y/" target="_blank">check our guide</a> to solve it.
																	</div>
																</div>
															)
														}else if (notification.event === 'ACCOUNT_SENDGRID_SMTP_ACTIVATION'){
															return(
																<div key={notification.id} className="item">
																	<div className="txt">
																	 There's a problem of SendGrid sender identity for {notification.account_email}. Please <a href="https://docs.sendgrid.com/for-developers/sending-email/sender-identity" target="_blank">read their guide</a> to fix it.
																	</div>
																</div>
															)
														}
														else if (notification.event === 'GMAIL_INVALID_SENDER_ALIASE'){
															return(
																<div key={notification.id} className="item">
																	<div className="txt">
																	  There's a problem of sending address on {notification.account_email}. Please <a href="https://help.mailreach.co/en/article/fix-problem-of-sending-address-1mo16dy/" target="_blank">read their guide</a> to fix it.
																	</div>
																</div>
															)
														}else if (notification.event === 'ACCOUNT_GMAIL_MISSING_ALIAS'){
															return(
																<div key={notification.id} className="item">
																	<div className="txt">
																		There's a problem of sending alias on {notification.account_email}. Please <a href="https://help.mailreach.co/en/article/how-to-fix-misconfigured-gmail-google-workspace-aliases-18kahog/" target="_blank">read their guide</a> to fix it.
																	</div>
																</div>
															)
														}else if (notification.event === 'ACCOUNT_MISSING_INBOUND_MESSAGES'){
															return(
																<div key={notification.id} className="item">
																	<i onClick={() => this.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
																	<div className="txt">
																		MailReach can't find inbound warming emails in your inbox {notification.account_email} (IMAP). Follow <a href="https://help.mailreach.co/en/article/fix-mailreach-cant-find-inbound-warming-emails-in-your-inbox-imap-1n8dv34/" target="_blank">our guide</a> to fix this.
																	</div>
																</div>
															)
														}else if (notification.event === 'ACCOUNT_SENDGRID_MISSING'){
															return(
																<div key={notification.id} className="item">
																	<i onClick={() => this.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
																	<div className="txt">
																		There's a problem of missing emails for {notification.account_email}. Follow <a href="https://help.mailreach.co/en/article/how-to-fix-the-problem-of-missing-emails-on-sendgrid-1upe8b2/" target="_blank">our guide</a> to fix this.
																	</div>
																</div>
															)
														}else if (notification.event === 'DOMAIN_EXPIRE_SOON'){
															return(
																<div key={notification.id} className="item">
																	<i onClick={() => this.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
																	<div className="txt">
																		Your domain {notification.domain} is expiring on <Moment format="MMM, D, Y">{notification.domain_expire_on}</Moment> - make sure it gets renewed!
																	</div>
																</div>
															)
															}else if (notification.event === 'ACCOUNT_GMAIL_MAIL_SERVICE_NOT_ENABLED'){
															return(
																<div key={notification.id} className="item">
																	<i onClick={() => this.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
																	<div className="txt">
																	  MailReach can't send warming emails from {notification.account_email}. Please {notification.account_email}. Please <a href="https://help.mailreach.co/en/article/fix-mailreach-cant-send-emails-from-your-google-account-16f7n26/" target="_blank">check our guide</a> to fix it.
																	</div>
																</div>
															)
														}else if (notification.event === 'DOMAIN_INVALID_SPF'){
															return(
																<div key={notification.id} className="item">
																	<i onClick={() => this.closeNotification(notification.id)} className="close fas fa-times pull-left"></i>
																	<div className="txt">
																		The SPF record of {notification.account_email} is missing or misconfigured. 	<a href="https://help.mailreach.co/en/article/how-to-properly-set-up-spf-and-dkim-records-1hlbcuq/" target="_blank">Fix it</a>
																	</div>
																</div>
															)
														}
												}
												)}
											</>
										}
										</ul>
									</Dropdown.Menu>
									</Dropdown>
							</li>

							<li className="settings help hidden-mobile">
								<Dropdown className='dropdown-caret-right'>
									<Dropdown.Toggle variant="success" id="dropdown-basic-settings">
										<i class="fa-solid fa-question"></i>
									</Dropdown.Toggle>

									<Dropdown.Menu className='hpm'>
										<ul>
											<li>
												<a href="https://help.mailreach.co/" target="_blank">
													Helpdesk
												</a>
											</li>
											<li>
												<a onClick={() => window.$crisp.push(['do', 'chat:open'])}>
													Support
												</a>
											</li>
											<li>
												<a href="https://status.mailreach.co/" target="_blank">
													Service status <span className="statusok"></span>
												</a>
											</li>
										</ul>
									</Dropdown.Menu>
									</Dropdown>
							</li>

									<li className="settings">
										<Dropdown className='dropdown-caret-right'>
											<Dropdown.Toggle variant="success" id="dropdown-basic-settings">
												<span>{this.props.currentUser.first_name ? this.props.currentUser.first_name.charAt(0).toUpperCase() : 'A'}</span>
											</Dropdown.Toggle>

											<Dropdown.Menu>
												<ul>
													{(this.props.currentProject.config_feature_new_billing_seats && this.props.currentProject.seats > 0) &&
														<li className="seats-progress">
															{this.props.currentProject.seats == 1 &&
																<span>{this.props.currentProject.used_seats}/1 seat used</span>
															}

															{this.props.currentProject.seats > 1 &&
																<span>{this.props.currentProject.used_seats}/{this.props.currentProject.seats} seats used</span>
															}
															<div class="progress">
																<div class="progress-bar" role="progressbar" style={{width: (((this.props.currentProject.used_seats/this.props.currentProject.seats)*100) + '%')}} aria-valuenow={((this.props.currentProject.used_seats/this.props.currentProject.seats)*100)} aria-valuemin="0" aria-valuemax="100"></div>
															</div>
														</li>
													}
													{!this.state.queryParams.hideNvgt &&
														<>
														  <li>
														    <NavLink to={`/dashboard/${this.props.currentProject.id}/settings/billing`} activeClassName="active">
															    Billing
														    </NavLink>
														  </li>
															<li>
																<NavLink to={`/dashboard/${this.props.currentProject.id}/settings/team`} activeClassName="active">
																	Settings
																</NavLink>
															</li>
														</>
													}
													<li>
													  <a onClick={this.signout} className="out">
														  Sign out
													  </a>
													</li>
												</ul>
											</Dropdown.Menu>
											</Dropdown>
									</li>
								</ul>
							}
					</div>
					</div>
				</>
				)
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		projects: state.projects,
		currentUser: state.currentUser,
		currentProject: state.currentProject,
		notifications: state.notifications
	};
}

function mapDispatchToProps(dispatch) {
	return {
		currentUserActions: bindActionCreators(currentUserActions, dispatch),
		currentProjectActions: bindActionCreators(currentProjectActions, dispatch),
		currentPricingActions: bindActionCreators(currentPricingActions, dispatch),
		notificationsActions: bindActionCreators(notificationsActions, dispatch),
	};
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));
