const request = require('superagent');

class ConversationTestService {
  static all(folderId, page = 0) {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}conversation_tests?page=${page}${folderId ? '&test_folder_id=' + folderId : ''}`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }

  static get(testId) {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}conversation_tests/${testId}`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }

  static logs(testId) {
    return request
            .get(`${process.env['REACT_APP_BACKEND_URL']}conversation_tests/${testId}/logs`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
  }

  static setType(testId, type) {
    return request
            .post(`${process.env['REACT_APP_BACKEND_URL']}conversation_tests/${testId}`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
            .send({type});
  }
}

export default ConversationTestService;
