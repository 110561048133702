import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function currentProjectReducer(state = initialState.currentProject, action) {
	switch (action.type) {
		case types.CURRENT_PROJECT_GET_SUCCESS:
			return action.currentProject;
		default:
			return state;
	}
}
