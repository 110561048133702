import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import {toast} from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import Moment from 'react-moment';
import {Elements} from '@stripe/react-stripe-js';
import Loader from 'react-loader-spinner';
import Tooltip from '@material-ui/core/Tooltip';

import UploaderService from '../../../services/uploader.service';
import EmailvalidationService from '../../../services/emailvalidation.service';


import './emailvalidation.scss';



class DashboardEmailvalidationHome extends React.Component {
	constructor(props) {
		super(props);
		this.state = {uploadField: null, rors: [], loading: true, emails: [], openUploadVerifyModal: false, modalAlert: {}, tasks: []}
		this.handleChange = this.handleChange.bind(this);
		this.uploadToAWS = this.uploadToAWS.bind(this);
		this.handleDrag = this.handleDrag.bind(this);
		this.handleDrop = this.handleDrop.bind(this);
		this.onChangeEmails = this.onChangeEmails.bind(this);
		this.reloadTasks = this.reloadTasks.bind(this);
		this.checkDisabled = this.checkDisabled.bind(this);
		this.reInitInputFile = this.reInitInputFile.bind(this);
		this.popErrorModal = this.popErrorModal.bind(this);
		this.directlySubmitFile = this.directlySubmitFile.bind(this);
	}

	componentWillMount() {
		this.reloadTasks();
		//this.setState({uploadField: React.useRef(null)})
		setTimeout(
			() => this.reloadTasks(),
			20000
		);
	}

	reloadTasks(){
		EmailvalidationService.tasks().then((response) => {
			this.setState({tasks: response.body.tasks, loading: false})
			// Create emailvalidation task
		})
		.catch((error) => {
			console.log(error);
		});
	}

  handleChange(e) {
    this.setState({spreadsheet: e.target.files[0], currentSelectedFileName: e.target.files[0].name});
		this.checkDisabled(e.target.files[0].name, this.state.emails);
  }

	onChangeEmails(event) {
		const field = event.target.name;
		let emails = event.target.value.split("\n").filter(e => e === 0 ? true : e);
		this.setState({emails});
		this.checkDisabled(this.state.currentSelectedFileName, emails);
	}

	reInitInputFile(){
		const fileInput = document.querySelector('#spreadsheet');
		this.setState({spreadsheet: null, currentSelectedFileName: null})
		this.checkDisabled(null, this.state.emails);
	}


	handleDrag(e) {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === "dragenter" || e.type === "dragover") {
			this.setState({dragActive: true});
		} else if (e.type === "dragleave") {
			this.setState({dragActive: false});
		}
	};

	checkDisabled(filename, emails){
		if(filename && emails.length > 0){
			this.setState({submitDisabled: true});
		}else{
			this.setState({submitDisabled: false});
		}
	}


	handleDrop(e) {
		e.preventDefault();
		e.stopPropagation();
		this.setState({dragActive: false});
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {

			const fileInput = document.querySelector('#spreadsheet');
			fileInput.files = e.dataTransfer.files;
			this.setState({spreadsheet: fileInput.files[0], currentSelectedFileName: fileInput.files[0].name})

			console.log(this.state);
			// handleFiles(e.dataTransfer.files);
			this.checkDisabled(fileInput.files[0].name, this.state.emails);
		}
	};

	triggerFileInputClick(){
		document.querySelector('#spreadsheet').click();
	}


	popErrorModal(error){
		if(!error.response.body.enought_credits){
			this.setState({openUploadVerifyModal: true, modalAlert: {title: 'You need to buy more credits!', content: `Your list contains ${error.response.body.credits_to_use} emails but you only have ${error.response.body.available_credits} credits left. Please buy more credits to validate your list.`}});
		}else if (!error.response.body.email_col_detected) {
			this.setState({openUploadVerifyModal: true, modalAlert: {title: 'Make sure you have a column named "email"', content: `To indicate MailReach where to find the emails to verify, please name the column "email" and try again uploading your file. Thank you 😇`}});
		}else if (error.response.body.sheets > 1) {
			this.setState({openUploadVerifyModal: true, modalAlert: {title: 'We have detected multiple sheets in your file', content: `MailReach will verify the first sheet containing ${error.response.body.credits_to_use} emails. Do you confirm that's the right one to verify?`, sheets_submit: true}});
		}
	}


  uploadToAWS(e, auth_token, file, directory){

		e.preventDefault();

		this.setState({submitDisabled: true, submitLoading: true});

		if(this.state.emails.length > 0){
			EmailvalidationService.testInput(null, 'email-verification-' + Math.random().toString(36).slice(2, 7) + '-mailreach-' + (new Date()).toLocaleString('en-us', {month: 'long'}) + '-' + (new Date()).toLocaleString('en-us', {weekday: 'long'}) + '-' + (new Date()).getDay() + '', this.state.emails).then((response) => {
				EmailvalidationService.createTaskRaw(this.state.emails, 'email-verification-' + Math.random().toString(36).slice(2, 7) + '-mailreach-' + (new Date()).toLocaleString('en-us', {month: 'long'}) + '-' + (new Date()).toLocaleString('en-us', {weekday: 'long'}) + '-' + (new Date()).getDay() + '').then((response) => {
					toast.success('Validation started !');
					// Create emailvalidation task
					this.reloadTasks();
					this.reInitInputFile();
					this.setState({submitDisabled: false, submitLoading: false});
				})
				.catch((error) => {
					console.log(error);
					this.setState({submitDisabled: false, submitLoading: false});
				});
			})
			.catch((error) => {
				this.popErrorModal(error)
				this.setState({submitDisabled: false, submitLoading: false});
			});
		}else{

			let spreadsheet = this.state.spreadsheet;

	    UploaderService.getSignedUrl(this.state.spreadsheet).then((response) => {
	      const options = {
	        headers: {"Content-Type": spreadsheet.type, 'acl': 'public-read'},
	      }

				let fileKey = response.body.file_key;

	      UploaderService.put(response.body.post_url, spreadsheet, options).then((response) => {
					// Create emailvalidation tasks
					EmailvalidationService.testInput(fileKey, spreadsheet.name).then((response) => {

						if(response.body.sheets > 1){
							this.popErrorModal({response: response});
							this.setState({fileKey: fileKey, spreadsheetName: spreadsheet.name});
							this.setState({submitDisabled: false, submitLoading: false});
							return;
						}

						EmailvalidationService.createTask(fileKey, spreadsheet.name).then((response) => {
							toast.success('Validation started !');
							// Create emailvalidation task
							this.reloadTasks();
							this.reInitInputFile();
							this.setState({submitDisabled: false, submitLoading: false});
						})
						.catch((error) => {
							console.log(error);
							this.setState({submitDisabled: false, submitLoading: false});
						});
					})
					.catch((error) => {
						this.popErrorModal(error);
						this.setState({submitDisabled: false, submitLoading: false});
					});
	      })
	      .catch((error) => {
					this.setState({submitDisabled: false, submitLoading: false});
	      });

	    })
	    .catch((error) => {
				console.log(error);
				this.setState({submitDisabled: false, submitLoading: false});
	    });
		}
}

directlySubmitFile(){
	EmailvalidationService.createTask(this.state.fileKey, this.state.spreadsheetName).then((response) => {
		this.setState({openUploadVerifyModal: false})
		toast.success('Validation started !');
		// Create emailvalidation task
		this.reloadTasks();
		this.reInitInputFile();
	})
	.catch((error) => {
		console.log(error);
	});
}



	render() {
		return (
			<div className="app container home emailvalidation">
				<div class="title">
					<h1 class="pull-left">Validate a new list</h1>
					<div class="pull-right">
						<div class="remaining-credits">{this.props.currentProject.config_number_emailvalidation_credits} remaining credits</div>
							<NavLink to={`/dashboard/${this.props.match.params.project_id}/settings/emailvalidation`} className="btn pull-right">
								Add credits
							</NavLink>
					</div>
				</div>
				<div className="clearfix"></div>

				{this.props.currentProject.config_number_emailvalidation_credits == 0 &&
					<>
						<div className="card upgrade-spamchecker">
							<div className="col-md-8 text-left">
								<h2>You have used all your validation credits</h2>
								<p>To continue using the validation tool, you need a refill ⛽</p>
								<NavLink to={`/dashboard/${this.props.match.params.project_id}/settings/emailvalidation`} className="btn btn-primary green-bck" activeClassName="active">
									Buy credits
								</NavLink>
							</div>
							<div className="col-md-4 text-center">
								<img src="/images/upgrade.svg" />
							</div>
							<div className="clearfix"></div>
						</div>
					</>
				}



				{this.props.currentProject.config_number_emailvalidation_credits > 0 &&
					<div className="card upload-block">
						<div className="col-md-6 first-col-upload" onClick={this.triggerFileInputClick}>
							<h2>Upload your file</h2>
							<span>Supports: CSV, XLS, XLSX, TXT</span>

							<form onDragEnter={this.handleDrag}>
								<input onChange={this.handleChange} type="file" className="hidden" id="spreadsheet" accept=".csv, .xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"/>

								<div className="file-upload-zone">
									{!this.state.currentSelectedFileName &&
										<p className="text-center"><i class="fas fa-cloud-upload"></i><br /> Drag & drop or <span className="choose-file">choose file</span> to upload</p>
									}
									{this.state.currentSelectedFileName &&
										<>
											<p className="text-center">
												<i class="fas fa-cloud-upload"></i><br /> {this.state.currentSelectedFileName} <i onClick={this.reInitInputFile} class="fas fa-trash-alt"></i>
											</p>
										</>
									}
								</div>
								{ this.state.dragActive && <div className="drag-file-element" onDragEnter={this.handleDrag} onDragLeave={this.handleDrag} onDragOver={this.handleDrag} onDrop={this.handleDrop}></div> }
							</form>
						</div>
						<div className="or">OR</div>
						<div className="col-md-6 last-col-upload">
							<h2>Enter one or more addresses</h2>
							<span>Type or paste one or more addresses. One email per line.</span>
							<textarea onChange={this.onChangeEmails}/>
						</div>
						<div className="col-md-12 text-center submit-btn">
							{this.state.submitDisabled &&
								<>
								{this.state.submitLoading &&
									<Loader
									 type="Oval"
									 color="#b7b7b7"
									 height="20"
									 width="20"
									/>
								}
									<Tooltip title="You can't both upload a file and enter one or more addresses, you have to make a choice 😇" placement="bottom">
										<div>
											<button className="btn btn-primary green-bck" onClick={this.uploadToAWS} disabled={this.state.submitDisabled}>
												Validate
											</button>
										</div>
									</Tooltip>
								</>
							}
							{!this.state.submitDisabled &&
								<button className="btn btn-primary green-bck" onClick={this.uploadToAWS} disabled={this.state.submitDisabled}>
									Validate
								</button>
							}
						</div>

							<div className="clearfix"></div>
					</div>
				}


				<div className="title top-margin">
					<h1 className="pull-left no-margin-bottom">Your verifications</h1>
				</div>
				<div className="templates spamtest">

				{this.state.loading &&
					<>
						<div class="clearfix"></div>
						<div className="card text-center">
							<div className="loader">
								<Loader
								 type="Oval"
								 color="#b7b7b7"
								 height="100"
								 width="100"
								/>
							</div>
						</div>
					</>
				}

				{(!this.state.loading && this.state.tasks.length == 0) &&
					<div className="card col-md-12 spam-result-item spam-auto-item">
						<div className="no-results">
							<h3>You haven't verified any lists yet.</h3>
							<img src="/images/no_data.svg" /><br />
						</div>
					</div>
				}


				{this.state.tasks.map((task, index) => {
					return (
					<div class="card col-md-12 spam-result-item emailvalidation-tasks">
						<div class="account col-md-4 tit" title="Click to preview">
							<span class="subject">
								{task.original_filename &&
									<>
										{task.original_filename.length > 40 &&
											<>
											<Tooltip title={task.original_filename} placement="bottom">
												<div>{task.original_filename.slice(0, 40)}</div>
											</Tooltip>
											</>
										}
										{task.original_filename.length <= 40 &&
											<>
											{task.original_filename}
											</>
										}
									</>
								}
							</span>
							<p><Moment format="D MMM YYYY" withTitle>{task.created_at}</Moment></p>
							</div>
							<div class="last-test-result col-md-2 text-center">
								{task.total_emails &&
									<>
										<b className="emails-nb ">{task.total_emails}</b>
										<div class="timeago hidden-mobile">emails</div>
									</>
								}
							</div>

							<div className="col-md-4">

							{task.errored &&
								<Tooltip title={"Error: " + (task.error_message) + ' '} placement="bottom">
									<div className="errored">
										Errored
									</div>
								</Tooltip>
							}

							{!task.errored &&
								<>
									{!task.completed &&
										<div className="text-center">
											<div class="progress">
												<div class="progress-bar pulse" role="progressbar" style={{width: task.progress +"%"}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<div className="progress-nb">{task.progress}% completed</div>
										</div>
									}

									{task.completed &&
										<div className="text-center">
											<div class="progress progress-bar-valid">
												<div class="progress-bar progress-bar-green green-bck" role="progressbar" style={{width: task.percentage_status.valid +"%"}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<div className="progress-nb">{Math.round(task.percentage_status.valid)}% valid</div>
										</div>
									}
								</>
							}

							</div>

								<div className="col-md-2 pull-right">
									{task.completed &&
										<a href={task.readable_final_file_url} className="btn pull-right btn-secondary">Download</a>
									}
								</div>

									<div class="clearfix"></div>
							</div>
						)})}
				</div>

				<Modal className="add-account-modal edit-tag-modal emailvalidation-alert-modal" show={this.state.openUploadVerifyModal} onHide={() => this.setState({openUploadVerifyModal: false})}>
					<Modal.Body>
							<>
								<h1>{this.state.modalAlert.title}</h1>

								<div className="txt imap">

									<p>{this.state.modalAlert.content}</p>
								</div>

								<div className="footer">

								{!this.state.modalAlert.sheets_submit &&
									<Button variant="secondary" onClick={() => this.setState({openUploadVerifyModal: false})}>
										Close
									</Button>
								}

								{this.state.modalAlert.sheets_submit &&
									<>
										<Button className="primary btn" onClick={this.directlySubmitFile}>
											Submit
										</Button>
										<Button variant="secondary" onClick={() => this.setState({openUploadVerifyModal: false})}>
											Close
										</Button>
									</>
								}
								</div>
							</>
					</Modal.Body>
				</Modal>

			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		currentUser: state.currentUser,
		currentProject: state.currentProject,
		currentPricing: state.currentPricing
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardEmailvalidationHome));
