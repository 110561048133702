import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import {toast} from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import {loadStripe} from '@stripe/stripe-js';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Slider, { SliderThumb } from '@mui/material/Slider';
import {Elements} from '@stripe/react-stripe-js';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Tooltip from '@material-ui/core/Tooltip';
import Loader from 'react-loader-spinner';


import * as currentProjectActions from '../../../../actions/currentProject.actions';

import ProjectService from '../../../../services/project.service';
import StripeService from '../../../../services/stripe.service';


import { styled } from '@mui/material/styles';


import { GeneratePricingPreview } from '../../../../helpers/generatePricingPreview'


const MailreachSlider = styled(Slider)({
  height: 8
});

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#11103b',
    },
    '&$checked + $track': {
      backgroundColor: '#11103b',
    },
  },
  checked: {},
  track: {
      backgroundColor: '#11103b',
  },
})(Switch);


import './checkout.scss';


let spamcheckerPricingConfig = {
  1: {
    totalMonthlyPricing: 12,
    totalSubPricing: 9.6,
    totalMonthlyTests: 25
  },
  2: {
    totalMonthlyPricing: 23,
    totalSubPricing: 18.4,
    totalMonthlyTests: 50
  },
  3: {
    totalMonthlyPricing: 35,
    totalSubPricing: 28,
    totalMonthlyTests: 100
  },
  4: {
    totalMonthlyPricing: 46,
    totalSubPricing: 36.8,
    totalMonthlyTests: 150
  },
  5: {
    totalMonthlyPricing: 54,
    totalSubPricing: 43.2,
    totalMonthlyTests: 200
  },
  6: {
    totalMonthlyPricing: 68,
    totalSubPricing: 54.4,
    totalMonthlyTests: 300
  },
  7: {
    totalMonthlyPricing: 92,
    totalSubPricing: 73.6,
    totalMonthlyTests: 500
  },
  8: {
    totalMonthlyPricing: 135,
    totalSubPricing: 108,
    totalMonthlyTests: 1000
  },
  9: {
    totalMonthlyPricing: 180,
    totalSubPricing: 144,
    totalMonthlyTests: 1500
  },
  10: {
    totalMonthlyPricing: 200,
    totalSubPricing: 160,
    totalMonthlyTests: 2000
  }
};


class DashboardSettingsCheckoutV2 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {errors: [], checkoutData: { seats: null}, invoiceTotalMo: null, yearly: false, hideAddCardFrom: this.props.hideAddCardFrom, pricePerSeat: 25, totalPrice: 25,  monthlyPricing: 0, selectedPlan: false, openCancelSubscriptionModal: false, spamcheckPricingConfig: 'totalSubPricing', spamcheckerPricingConfig: spamcheckerPricingConfig, spamcheckerPlanSlider: 4, selectedSpamcheckerPlan: null}
		this.toggleField = this.toggleField.bind(this);
    this.billingPeriodChanges = this.billingPeriodChanges.bind(this);
    this.setSeats = this.setSeats.bind(this);
    this.refreshPricePreview = this.refreshPricePreview.bind(this);
    this.startBilling = this.startBilling.bind(this);
    this.refreshTotalBilling = this.refreshTotalBilling.bind(this);
    this.billingSpamcheckerPeriodChanges = this.billingSpamcheckerPeriodChanges.bind(this);
    this.setSpamcheckerPlan = this.setSpamcheckerPlan.bind(this);
    this.selectSpamcheckerPlan = this.selectSpamcheckerPlan.bind(this);
    this.openModal = this.openModal.bind(this);
    this.resetSpamcheckerPlan = this.resetSpamcheckerPlan.bind(this)
	}

	componentWillMount() {
    ProjectService.get(this.props.match.params.project_id).then((response) => {
      this.setState({project: response.body, checkoutData: {seats: (response.body.seats > 0) ? response.body.seats : 0}});
      localStorage.setItem('x-api-key', response.body.api_key);
      this.refreshPricePreview();
    })
    .catch((error) => {
      this.setState({error: true,});
      toast.error('Oops, we have an error');
    });

    let queryParams = queryString.parse(this.props.location.search);
    this.setState({queryString: queryParams});
	}

	toggleField(e){
		e.preventDefault();
		if(this.state.keyFieldState == 'password'){
      this.setState({keyFieldState: 'text'});
		} else {
			this.setState({keyFieldState: 'password'});
		}
	}

  billingPeriodChanges(){
    this.setState({yearly: !this.state.yearly, selectedPlan: false});
    setTimeout(() => { this.refreshPricePreview();}, 10);
  }

  billingSpamcheckerPeriodChanges(){
    this.setState({spamcheckPricingConfig: (this.state.spamcheckPricingConfig == 'totalSubPricing') ? 'totalMonthlyPricing' : 'totalSubPricing'});
  }

  setSeats(value) {
    this.setState({selectedplan: false});
    let checkoutData = this.state.checkoutData;

    checkoutData.seats = value.target.value;
    this.refreshPricePreview();
  }

  refreshPricePreview(){
    this.setState({selectedPlan: false});

    let pricingPreview = GeneratePricingPreview(this.state.checkoutData.seats, this.state.yearly);
    this.setState({pricePerSeat: pricingPreview.pricePerSeat, totalPrice: pricingPreview.totalPrice, monthlyPricing: pricingPreview.monthlyPricing});

    setTimeout(() => { this.refreshTotalBilling();}, 10);
  }

  setPricingPlan(value) {

    let totalMonthlyPricing = pricingConfig[parseInt(value.target.value)].totalMonthlyPricing;

    if(this.state.monthly){
      totalMonthlyPricing = pricingConfig[parseInt(value.target.value)].totalSubPricing;
    }

    let totalMonthlyTests = pricingConfig[parseInt(value.target.value)].totalMonthlyTests;

    if(!this.props.project.stripe_customer_id){
      totalMonthlyPricing
    }

    this.setState({totalMonthlyPricing, totalMonthlyTests, sliderPricing: totalMonthlyPricing, spamCheckerPlan: parseInt(value.target.value)});

  }

  setSpamcheckerPlan(value){
    this.setState({spamcheckerPlanSlider: parseInt(value.target.value)})
  }

	startBilling(){

    this.setState({loading: true});

    let checkoutData = this.state.checkoutData;

    checkoutData.selectedSpamcheckerPlan = this.state.selectedSpamcheckerPlan;
    checkoutData.selectedSpamcheckerPeriod = this.state.spamcheckPricingConfig;
    checkoutData.period = this.state.yearly ? 12 : 1;


    StripeService.generatePaymentLink(this.state.checkoutData).then((response) => {
      // Redirect when the link is generate
      if(response.body.redirect_url){
        window.location.replace(response.body.redirect_url);
      } else {

        window.gtag("event", "billing", { event_category: "credit_card", event_label: "added"});
        window.$crisp.push(["set", "session:segments", [["cb-added"]]]);
        window.$crisp.push(["set", "session:event", [[["cb-added"]]]])


        if(this.state.checkoutData.seats){

          if(this.state.checkoutData.seats > 0){
            window.gtag("event", "purchase", {
              currency: 'USD',
              value: (this.state.checkoutData.seats*25),
              transaction_id: Date.now(),
              items: [
                {item_iname: 'warmer_seats'}
              ]
            });
          }
        } else {
          window.gtag("event", "purchase", {
            currency: 'USD',
            value: 25,
            transaction_id: Date.now(),
            items: [
              {item_iname: 'warmer_seats'}
            ]
          });
        }

        this.props.currentProjectActions.getCurrent();

        if(this.state.queryString.spamCheckerPlan){
          toast.success('Thanks, you can use the Spamchecker');

          window.gtag("event", "spamchecker", {
                  event_category: "spamchecker",
                  event_label: "buy-credits"
              });
            window.gtag('event', 'conversion', {
                'send_to': 'AW-444511731/aidcCIOXkaUDEPPr-tMB',
                'transaction_id': ''
            });

            // BING ads
            window.uetq = window.uetq || [];
            window.uetq.push('event', 'buy spam test credits', {});

          this.props.history.push(`/dashboard/${this.props.projectId}/spam-tests/home`);
        }else{
          if(this.props.currentProject.config_feature_new_billing_seats){
            if(this.props.currentProject.seats > this.state.checkoutData.seats){
              this.props.history.push(`/dashboard/${this.props.currentProject.id}/home`);
            }else{
              toast.success('All good, you can now connect your email account(s) 🙌');
              this.props.history.push(`/dashboard/${this.props.currentProject.id}/account/connect`);
            }

          }else{
            toast.success('Thanks, you can now connect account(s) ! You will be charged for each accounts');
            this.props.history.push(`/dashboard/${this.props.currentProject.id}/home?addModalAccount=true`);
          }
        }
      }
    })
    .catch((error) => {

      window.$crisp.push(["set", "session:segments", [["cb-refused"]]]);
      toast.error('Oops, there is an issue. Contact us if the problem persists.');
      // Of carte if declined, redirect to add a card on Stripe portal
      console.error(error);
      this.setState({error: true, loading: false});


      Sentry.captureException(err);
    });
	}


  selectSpamcheckerPlan(planId){
    this.setState({showSpamcheckerCreditsModal: false, selectedSpamcheckerPlan: planId});
    setTimeout(() => { this.refreshTotalBilling()}, 10);
  }

  resetSpamcheckerPlan(){
    this.setState({selectedSpamcheckerPlan: null});
    setTimeout(() => { this.refreshTotalBilling()}, 10);
  }

  refreshTotalBilling(){
    let invoiceTotalMo = this.state.monthlyPricing;

    if(this.state.selectedSpamcheckerPlan && this.state.spamcheckPricingConfig == 'totalSubPricing'){
      invoiceTotalMo = invoiceTotalMo + this.state.spamcheckerPricingConfig[this.state.selectedSpamcheckerPlan][this.state.spamcheckPricingConfig];
    }
    this.setState({invoiceTotalMo: invoiceTotalMo});
  }

  openModal(type){
    if(type == 'warmer'){
      this.setState({showWarmingSeatsModal: true})
      if(this.state.invoiceTotalMo == 0){
        this.setState({checkoutData: {seats: 1}, invoiceTotalMo: 25});

        setTimeout(() => { this.refreshPricePreview();}, 10);
      }
    }
  }

	render() {


    if(this.state.loading){
      return(
        <div className="app container text-center">
          <div className="loader">
            <Loader
             type="Oval"
             color="#b7b7b7"
             height="100"
             width="100"
            />
          </div>
        </div>
      )
    }

		return (
			<div className="app container add-credit-card billing-ananas">

      {this.state.queryString.signup &&

        <style>{`
          .sidebar{
            display: none;
          }
        `}</style>
      }

        <div className="col-md-offset-1 col-md-10">
				<div className="row">
          <div className="card checkout imap">
          	<h1 className="plan-title">
              {this.props.currentProject.stripe_subscription_id &&
                <>
                  Edit your plan
                </>
              }
              {!this.props.currentProject.stripe_subscription_id &&
                <>
                  Compose your plan
                </>
              }
            </h1>
            <ul className="checkount-main-items">
              <li>
                <div className="col-md-6">
                  <h3>
                    Email Warmer &nbsp;
                    {(this.props.currentProject.id && !this.props.currentProject.stripe_customer_id) &&
                      <Tooltip title='This is our current 20% discount for your first mailbox warmed during 30 days' placement="bottom">
                        <span className="discount">$5.50 off for 30 days</span>
                      </Tooltip>
                    }
                  </h3>
                  {(this.state.checkoutData.seats > 0) &&
                    <span className="plan-desc">{this.state.checkoutData.seats} mailboxe(s) - ${this.state.pricePerSeat} / mailbox</span>
                  }
                  {(this.state.checkoutData.seats == 0) &&
                    <span className="plan-desc">Repair, raise and maintain your sender reputation</span>
                  }
                </div>

                <div className="col-md-3 text-center">
                  {this.state.checkoutData.seats > 0 &&
                    <>
                      <span className="pull-left col-md-12 bold big bln-color text-center">${Number(this.state.monthlyPricing.toFixed(2))} / mo</span>
                      <span>{!this.state.yearly ? 'Billed monthly' : 'Billed yearly'}</span>
                    </>
                  }
                </div>

                <div className="col-md-3 text-align-right">
                  <button className={"btn btn-primary green-bck pull-right" + ((this.state.checkoutData.seats > 0) ? ' edit-btn-selected' : '')} onClick={() => this.openModal('warmer')}>{(((this.state.checkoutData.seats > 0) && this.state.checkoutData.seats > 0) ? 'Edit >' : 'Add mailboxes >')}</button>
                </div>
              </li>

              <li>
                <div className={this.state.selectedSpamcheckerPlan ? "col-md-6" : "col-md-8"}>
                  <h3>Spam Tester</h3>
                  <span className="plan-desc">
                  {this.state.selectedSpamcheckerPlan &&
                    <>
                      {this.state.spamcheckerPricingConfig[this.state.selectedSpamcheckerPlan]['totalMonthlyTests']} test credits {(this.state.spamcheckPricingConfig == 'totalSubPricing') ? '/ mo' : ''}
                    </>
                  }
                  {!this.state.selectedSpamcheckerPlan &&
                    <>
                      {this.state.checkoutData.seats > 0 &&
                        <>20 test credits / mo - offered</>
                      }
                      {(this.state.checkoutData.seats == 0) &&
                        <>
                          Run manual or automated spam tests to track your inbox placement
                        </>
                      }
                    </>
                  }
                  </span>
                </div>
                <div className={this.state.selectedSpamcheckerPlan ? "col-md-3 text-center" : 'col-md-1'}>
                  {this.state.selectedSpamcheckerPlan &&
                    <>
                      <span className={"pull-left col-md-12 bold big bln-color text-center"}>${this.state.spamcheckerPricingConfig[this.state.selectedSpamcheckerPlan][this.state.spamcheckPricingConfig]} {(this.state.spamcheckPricingConfig == 'totalSubPricing') ? '/ mo' : ''}</span>
                      <span>{(this.state.spamcheckPricingConfig == 'totalSubPricing') ? 'Billed monthly' : 'Billed once'}</span>
                    </>
                  }
                </div>
                <div className="col-md-3 text-align-right">
                  <button className={"btn btn-primary green-bck pull-right" + ((this.state.selectedSpamcheckerPlan) ? ' edit-btn-selected' : '')} onClick={() => this.setState({showSpamcheckerCreditsModal: true})}>{(((this.state.selectedSpamcheckerPlan) && this.state.selectedSpamcheckerPlan) ? 'Edit >' : 'Add credits >')}</button>
                </div>
              </li>
            </ul>

            <div className="clearfix"></div>

              {((this.state.invoiceTotalMo > 0) || this.state.selectedSpamcheckerPlan) &&
                <div className="col-md-12 billing-summary">
                  <div className="col-md-6 no-padding">
                    <span className="pull-left col-md-12 bold big bln-color">Total</span>
                    <span className="col-md-12">without taxes</span>
                  </div>

                  <div className={"col-md-3 text-center"}>

                    {(this.state.invoiceTotalMo > 0) &&
                      <>
                        {(this.state.spamcheckPricingConfig == 'totalMonthlyPricing' && this.state.selectedSpamcheckerPlan) &&
                          <>
                            <span className="pull-left col-md-12 bold big bln-color">${Number(this.state.invoiceTotalMo.toFixed(2)) + Number(this.state.spamcheckerPricingConfig[this.state.selectedSpamcheckerPlan][this.state.spamcheckPricingConfig])}</span>
                            <span>Due now</span>
                          </>
                        }

                        {(this.state.spamcheckPricingConfig == 'totalSubPricing') &&
                          <>
                            <span className="pull-left col-md-12 bold big bln-color">${Number(this.state.invoiceTotalMo.toFixed(2))} {(this.state.invoiceTotalMo > 0) ? '/ mo' : ''}</span>
                            <span>{!this.state.yearly ? 'billed monthly' : 'billed yearly'}</span>
                          </>
                        }
                      </>
                    }


                    {this.state.invoiceTotalMo == 0 &&
                      <>
                        <span className="pull-left col-md-12 bold big bln-color">${Number(this.state.spamcheckerPricingConfig[this.state.selectedSpamcheckerPlan][this.state.spamcheckPricingConfig])} {(this.state.invoiceTotalMo > 0) ? '/ mo' : ''}</span>
                        <span>One time</span>
                      </>
                    }
                  </div>


                  <Button className="btn btn-primary green-bck pull-right" onClick={this.startBilling}>
                    {this.props.currentProject.stripe_subscription_id &&
                      <>Confirm ></>
                    }
                    {!this.props.currentProject.stripe_subscription_id &&
                      <>Check out ></>
                    }
                  </Button>
                </div>
              }

					<div className="clearfix"></div>
					</div>
        </div>
			</div>

      <Modal className="checkout-seats-modal checkout-seats-modal-v2" show={this.state.showWarmingSeatsModal} onHide={() => this.setState({showWarmingSeatsModal: false})}>
        <Modal.Body>
            <>
              <h1 className="text-left">Choose the number of mailboxes to warm</h1>
              <div className="txt text-center">
              <>

              <div className="seats-checkout">

                <div className="plans">
                <div className={"plan col-md-12 no-border"}>

                <div className="col-md-12 no-padding-left">
                  <div className="select-billing-period">
                    {this.props.currentProject.stripe_subscription_period != 'yearly' &&
                    <>
                      <span className={!this.state.yearly ? 'selected' : ''} onClick={this.billingPeriodChanges}>Monthly</span>
                      <FormGroup>
                          <FormControlLabel control={<PurpleSwitch
                          checked={this.state.yearly}
                          onChange={this.billingPeriodChanges}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />} label="" />
                      </FormGroup>
                        <span className={this.state.yearly ? 'selected' : ''} onClick={this.billingPeriodChanges}>Yearly <span className="discount">Save 20%</span></span>
                        </>
                      }
                  </div>
                </div>

                <div className="clearfix"></div>


                  <div className="mailboxes-increase col-md-5">
                    <span className="bln-color">Mailboxes to warm</span> <input type="number" min="0" onChange={this.setSeats} value={this.state.checkoutData.seats} />
                  </div>

                  <div className="pricing col-md-5">
                    <div className="col-md-5">
                        <span><span className="money bln-color">$</span>{Number(this.state.pricePerSeat)}</span>
                    </div>
                    <div className="col-md-7 text-left">
                      <span className="period bln-color">per mailbox / month</span><br />
                      <span className="all-sum">Total : ${this.state.yearly ? (Number(this.state.totalPrice.toFixed(2))) : (Number(this.state.monthlyPricing.toFixed(2)))} {!this.state.yearly ? '/ month' : '/ year'}</span>
                    </div>
                  </div>

                  <div className="clearfix"></div>
                  <div className="range-slider col-md-12">
                      <p className="desc-txt no-margin-left">The more mailboxes you warm, the lower the price per mailbox. Move the cursor to see the changes.</p>
                      <MailreachSlider
                      defaultValue={this.props.currentProject.seats || 1}
                      value={this.state.checkoutData.seats || this.props.currentProject.seats || 1}
                      onChange={this.setSeats}
                      step={1}
                      min={1}
                      max={100}
                      color="secondary"
                      />
                    <div>
                    </div>
                    </div>

                  <div className="col-md-12">
                    <ul className="features col-md-6">
                      <li><i class="fas fa-check-circle"></i> Fully automated warm up</li>
                      <li><i class="fas fa-check-circle"></i> Up to 100 warming emails daily</li>
                      <li><i class="fas fa-check-circle"></i> Smart warming algorithm</li>
                      <li><i class="fas fa-check-circle"></i> Slack & Webhooks alerts</li>
                    </ul>
                    <ul className="features col-md-6">
                      <li><i class="fas fa-check-circle"></i> Multiple ESP warm up (Google, Outlook, etc.)</li>
                      <li><i class="fas fa-check-circle"></i> Monitoring dashboard</li>
                      <li><i class="fas fa-check-circle"></i> Live blacklists and DNS check ups</li>
                      <li><i class="fas fa-check-circle"></i> Chat support</li>
                    </ul>
                  </div>
                </div>

                </div>
                <div className="clearfix"></div>
              </div>
      </>
              </div>
              <div className="footer text-center del">
                <Button variant="secondary" onClick={() => this.setState({showWarmingSeatsModal: false})}>
                  Close
                </Button>
                <Button className="btn purple" onClick={() => {this.setState({showWarmingSeatsModal: false}); this.refreshTotalBilling();}}>
                  Save
                </Button>
              </div>
            </>
        </Modal.Body>
      </Modal>

      <Modal className="checkout-seats-modal checkout-spamchecker-modal" show={this.state.showSpamcheckerCreditsModal} onHide={() => this.setState({showSpamcheckerCreditsModal: false})}>
        <Modal.Body>
            <>
              <h1 className="text-left">Choose your spam test plan</h1>

              <div className="col-md-12 seats-checkout">
                <div className="select-billing-period">
                  {this.props.currentProject.stripe_spamchecker_subscription_period != 'monthly' &&
                  <>
                    <span className={!(this.state.spamcheckPricingConfig == 'totalSubPricing') ? 'selected' : ''} onClick={this.billingSpamcheckerPeriodChanges}>Pay as you go</span>
                    <FormGroup>
                        <FormControlLabel control={<PurpleSwitch
                        checked={this.state.spamcheckPricingConfig == 'totalSubPricing'}
                        onChange={this.billingSpamcheckerPeriodChanges}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />} label="" />
                    </FormGroup>
                      <span className={(this.state.spamcheckPricingConfig == 'totalSubPricing')  ? 'selected' : ''} onClick={this.billingSpamcheckerPeriodChanges}>Monthly <span className="discount">Save 20%</span></span>
                      </>
                    }
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="txt text-center col-md-12">
              <>

              <div className="spamcheck-checkout seats-checkout">

                <div className="plans">

                  <div className={"plan col-md-4"}>
                    <h3>Lite</h3>
                    <div className="pricing-bck">
                      <span>
                        <span className="price-style currency">$</span>
                        <span className="price-style big">{this.state.spamcheckerPricingConfig[1][this.state.spamcheckPricingConfig]}</span>
                        <span className="price-style">{(this.state.spamcheckPricingConfig == 'totalSubPricing') ? ' / mo' : ''}</span>
                      </span>
                    </div>
                    <ul className="features col-md-12 features-top-maging">
                      <li><i class="fas fa-check-circle"></i> {this.state.spamcheckerPricingConfig[1]['totalMonthlyTests']} spam tests {(this.state.spamcheckPricingConfig == 'totalSubPricing') ? 'per month' : ''}</li>
                      <li><i class="fas fa-check-circle"></i> Inbox placement by provider</li>
                      <li><i class="fas fa-check-circle"></i> Automatic spam tests</li>
                      <li><i class="fas fa-check-circle"></i> DKIM, SPF & DMARC checker</li>
                      <li><i class="fas fa-check-circle"></i> Blacklists checker</li>
                      <li><i class="fas fa-check-circle"></i> Links checker</li>
                      <li><i class="fas fa-check-circle"></i> Spam words checker</li>
                      <li><i class="fas fa-check-circle"></i> HTML checker</li>
                    </ul>

                    <div className="col-md-12 select-plan-btn">
                      {this.state.selectedSpamcheckerPlan == 1 &&
                        <Button className="btn btn-primary red" onClick={this.resetSpamcheckerPlan}>
                          Remove
                        </Button>
                      }
                      {this.state.selectedSpamcheckerPlan != 1 &&
                        <Button className="btn btn-primary green-bck" onClick={() => {this.selectSpamcheckerPlan(1)}}>
                          Select >
                        </Button>
                      }
                    </div>
                  </div>

                  <div className={"plan col-md-4"}>
                    <h3>Growth</h3>
                    <div className="pricing-bck">
                      <span>
                        <span className="price-style currency">$</span>
                        <span className="price-style big">{this.state.spamcheckerPricingConfig[3][this.state.spamcheckPricingConfig]}</span>
                        <span className="price-style">{(this.state.spamcheckPricingConfig == 'totalSubPricing') ? ' / mo' : ''}</span>
                      </span>
                    </div>
                    <ul className="features col-md-12 features-top-maging">
                      <li><i class="fas fa-check-circle"></i> {this.state.spamcheckerPricingConfig[3]['totalMonthlyTests']} spam tests {(this.state.spamcheckPricingConfig == 'totalSubPricing') ? 'per month' : ''}</li>
                      <li><i class="fas fa-check-circle"></i> Inbox placement by provider</li>
                      <li><i class="fas fa-check-circle"></i> Automatic spam tests</li>
                      <li><i class="fas fa-check-circle"></i> DKIM, SPF & DMARC checker</li>
                      <li><i class="fas fa-check-circle"></i> Blacklists checker</li>
                      <li><i class="fas fa-check-circle"></i> Links checker</li>
                      <li><i class="fas fa-check-circle"></i> Spam words checker</li>
                      <li><i class="fas fa-check-circle"></i> HTML checker</li>
                    </ul>

                    <div className="col-md-12 select-plan-btn">
                      {this.state.selectedSpamcheckerPlan == 3 &&
                        <Button className="btn btn-primary red" onClick={this.resetSpamcheckerPlan}>
                          Remove
                        </Button>
                      }
                      {this.state.selectedSpamcheckerPlan != 3 &&
                        <Button className="btn btn-primary green-bck" onClick={() => {this.selectSpamcheckerPlan(3)}}>
                          Select >
                        </Button>
                      }
                    </div>
                  </div>

                <div className={"plan col-md-4"}>


                  <h3>Scale</h3>
                  <div className="pricing-bck">
                    <span>
                      <span className="price-style currency">$</span>
                      <span className="price-style big">{this.state.spamcheckerPricingConfig[this.state.spamcheckerPlanSlider][this.state.spamcheckPricingConfig]}</span>
                      <span className="price-style">{(this.state.spamcheckPricingConfig == 'totalSubPricing') ? ' / mo' : ''}</span>
                    </span>
                </div>

                  <div className="clearfix"></div>
                  <div className="range-slider col-md-12">
                      <MailreachSlider
                      defaultValue={this.state.spamcheckerPlan || 1}
                      onChange={this.setSpamcheckerPlan}
                      step={1}
                      min={4}
                      max={10}
                      color="secondary"
                      />
                    <div>
                    </div>
                    </div>
                    <ul className="features col-md-12">
                      <li><i class="fas fa-check-circle"></i> {this.state.spamcheckerPricingConfig[this.state.spamcheckerPlanSlider]['totalMonthlyTests']} spam tests {(this.state.spamcheckPricingConfig == 'totalSubPricing') ? 'per month' : ''}</li>
                      <li><i class="fas fa-check-circle"></i> Inbox placement by provider</li>
                      <li><i class="fas fa-check-circle"></i> Automatic spam tests</li>
                      <li><i class="fas fa-check-circle"></i> DKIM, SPF & DMARC checker</li>
                      <li><i class="fas fa-check-circle"></i> Blacklists checker</li>
                      <li><i class="fas fa-check-circle"></i> Links checker</li>
                      <li><i class="fas fa-check-circle"></i> Spam words checker</li>
                      <li><i class="fas fa-check-circle"></i> HTML checker</li>
                    </ul>

                  <div className="col-md-12 select-plan-btn">
                    {(this.state.selectedSpamcheckerPlan > 3) &&
                      <Button className="btn btn-primary red" onClick={this.resetSpamcheckerPlan}>
                        Remove
                      </Button>
                    }
                    {(!this.state.selectedSpamcheckerPlan || this.state.selectedSpamcheckerPlan < 4)  &&
                      <Button className="btn btn-primary green-bck" onClick={() => {this.selectSpamcheckerPlan(this.state.spamcheckerPlanSlider)}}>
                        Select >
                      </Button>
                    }
                  </div>
                </div>

                </div>
                <div className="clearfix"></div>
              </div>
      </>
              </div>
            </>
        </Modal.Body>
      </Modal>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		currentUser: state.currentUser,
		currentProject: state.currentProject,
		currentPricing: state.currentPricing
	};
}

function mapDispatchToProps(dispatch) {
	return {
    currentProjectActions: bindActionCreators(currentProjectActions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardSettingsCheckoutV2));
