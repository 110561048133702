import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import Tooltip from '@material-ui/core/Tooltip';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import { NavLink } from 'react-router-dom';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { GeneratePricingPreview } from '../../helpers/generatePricingPreview'


import StripeService from '../../services/stripe.service';



const MailreachSlider = styled(Slider)({
  height: 8,
  '& .MuiSlider-thumbColorSecondary': {
    backgroundColor: "rgb(17 16 59)",
    height: "15px",
    width: "15px",
    position: "absolute",
    borderRadius: "50%",
    marginTop: "-3px",
    marginLeft: "-6px",
  },
});



class ConnectBtn extends React.Component {

	constructor(props) {
		super(props);
		this.state = {test: {}, emailsSeparated: ''};
    this.handleCloseAddAccountModal = this.handleCloseAddAccountModal.bind(this);
    this.openUpgradeModal = this.openUpgradeModal.bind(this);
    this.buySeatsAndRedirect = this.buySeatsAndRedirect.bind(this);
    this.setSeats = this.setSeats.bind(this);
    this.handleCloseAddAccountModal = this.handleCloseAddAccountModal.bind(this);
	}


  componentWillMount() {
  }


	componentWillReceiveProps(nextProps) {
	}

  openUpgradeModal(){
    let newSeats = (this.props.currentProject.used_seats + 1);
    this.setState({newSeats});
    let pricingPreview = GeneratePricingPreview(newSeats, false, {seatsAdded: 1});
    this.setState({showAddAccountModal: true, pricePerSeat: pricingPreview.pricePerSeat, seatsAdded: 1, totalPrice: pricingPreview.totalPrice, priceAdded: pricingPreview.priceAdded});
  }

  handleCloseAddAccountModal(){
    this.setState({showAddAccountModal: false});
  }

  setSeats(e){
    let newSeats = (this.props.currentProject.used_seats + e.target.value);
    this.setState({newSeats});
    let pricingPreview = GeneratePricingPreview(newSeats, false, {seatsAdded: (this.state.newSeats - this.props.currentProject.used_seats)});
    this.setState({pricePerSeat: pricingPreview.pricePerSeat, totalPrice: pricingPreview.totalPrice, seatsAdded: e.target.value, priceAdded: pricingPreview.priceAdded});
  }

  buySeatsAndRedirect(){
    let c = this;
    let data = {
      seats: this.state.newSeats,
      period: (this.props.currentProject.stripe_subscription_period == 'yearly') ? 12 : 1,
      use_card: true
    }
    c.setState({loadingModal: true});
    StripeService.createCustomer(data).then(function(value) {
      c.setState({loadingModal: false, success: true});
      toast.success('Thanks, you can now connect account(s) !');
      c.props.history.push(`/dashboard/${c.props.currentProject.id}/account/connect`);
    }).catch(function(err) {
        toast.error('Your card was rejected, try another card. Contact us if the problem persists.');
        c.setState({loading: false, loadingModal: false});
      });
  }


	render() {


		return (
			<>
        {((this.props.currentProject.stripe_customer_id || this.props.currentProject.free_billing)) &&
          <>
            {this.props.currentProject.config_feature_new_billing_seats &&
              <>
                {this.props.currentProject.stripe_subscription_id &&
                  <>
                    {((this.props.currentProject.seats > this.props.currentProject.used_seats) || this.props.currentProject.free_billing) &&
                      <NavLink to={`/dashboard/${this.props.match.params.project_id}/account/connect`} className="btn pull-right" activeClassName="active">
                        Connect account
                      </NavLink>
                    }

                    {((this.props.currentProject.seats <= this.props.currentProject.used_seats) && !this.props.currentProject.free_billing) &&
                      <button onClick={this.openUpgradeModal} className="btn pull-right" activeClassName="active">
                        Connect account
                      </button>
                    }
                  </>
                }

                {!this.props.currentProject.stripe_subscription_id &&
                  <NavLink to={`/dashboard/${this.props.match.params.project_id}/settings/billing?hide_signout=true`} className="btn pull-right" activeClassName="active">
                    Add payment method
                  </NavLink>
                }

              </>
            }
            {!this.props.currentProject.config_feature_new_billing_seats &&
              <button onClick={() => this.setState({showAddAccountModal: true})} className="btn pull-right" activeClassName="active">
                Connect account
              </button>
            }
          </>
        }

        <Modal className={"add-account-modal " + (this.props.currentProject.config_feature_new_billing_seats ? 'new-seats' : '')} show={this.state.showAddAccountModal} onHide={this.handleCloseAddAccountModal}>
          <Modal.Body>
          {this.props.currentProject.free_billing &&
            <>
              <h1>You have a MailReach Enterprise plan.</h1>

              <div className="txt">
                <p>Ask the support if you need more information about your Enterprise plan.</p>
                <div className="line">
                  Number of accounts : <div className="pull-right"><b>{(this.props.currentProject.used_seats+1)} accounts</b></div>
                </div>
              </div>

              <div className="footer">
                <NavLink to={`/dashboard/${this.props.match.params.project_id}/account/connect`} className="primary btn" activeClassName="active">
                  Connect account
                </NavLink>
                <Button variant="secondary" onClick={this.handleCloseAddAccountModal}>
                  Cancel
                </Button>
              </div>
            </>
          }

          {this.props.currentProject.config_feature_new_billing_seats &&
						<>

              {this.state.loadingModal &&
                <>
                <div className="loader text-center">
                  <Loader
                   type="Oval"
                   color="#b7b7b7"
                   height="100"
                   width="100"
                  />
                </div>
                </>
              }

              {!this.state.loadingModal &&
                <>
                <h1>You need at least one more seat</h1>

                <p className="usage-desc">You're currently using {this.props.currentProject.used_seats} seat(s) out of the {this.props.currentProject.seats} of your plan.
                Add at least one more seat to warm more mailboxes.</p>

                <div className="add-seats-slider">
                  <span className="pull-left desc-seat">Add {this.state.seatsAdded} email warming seat(s)</span>
                  <span className="pull-right">+${this.state.priceAdded} / month</span><br /><br />
                  <MailreachSlider
                  defaultValue={this.state.seatsAdded}
                  onChange={this.setSeats}
                  step={1}
                  min={1}
                  max={100}
                  color="secondary"
                  />
                </div>

                <p className="add-seats-desc">

                  What’s next for your billing :<br /><br />

                  1) You'll get a pro-rated charge today based on the number of days remaining in this billing cycle.<br />

                  2) Starting from your next billing cycle, your monthly payments will be ${this.state.totalPrice}/month. Additional taxes may apply depending on your country.<br /><br />
                </p>
  							<div className="footer">
                  <Button variant="secondary" onClick={this.handleCloseAddAccountModal}>
                    Cancel
                  </Button>
                  <Button onClick={this.buySeatsAndRedirect} className="primary btn" activeClassName="active">
                    Continue
                  </Button>
  							</div>
                </>
              }
						</>
					}

            {(!this.props.currentProject.free_billing && !this.props.currentProject.config_feature_new_billing_seats) &&
              <>
                {(this.props.currentProject.used_seats == 0) &&
                  <h1 className="text-left">Your subscription is about to start</h1>
                }
                {(this.props.currentProject.used_seats > 0) &&
                  <h1 className="text-left">Your monthly subscription will increase.</h1>
                }

                <div className="txt">
                  {(this.props.currentProject.used_seats == 0) &&
                    <p className="text-left">By connecting your first email account, it will start your subscription.</p>
                  }
                  {(this.props.currentProject.used_seats > 0) &&
                    <p className="text-left">If you connect a new account, the amount of your monthly subscription will increase. </p>
                  }
                  <h3 className="text-left">Your subscription if you connect an account</h3>
                  <div className="line no-padding-left">
                    Number of mailboxes (seats) : <div className="pull-right"><b>{(this.props.currentProject.used_seats+1)} seats</b></div>
                  </div>
                    {this.props.currentProject.stripe_subscription_id &&
                      <div className="line no-padding-left">
                        {(this.props.currentProject.used_seats+1) < 6 &&
                          <>
                            Total : <div className="pull-right"><b>${((this.props.currentProject.used_seats+1)*this.props.currentPricing)}</b> / month</div>
                          </>
                        }
                        {((this.props.currentProject.used_seats+1) > 5 && (this.props.currentProject.used_seats+1) < 21)&&
                          <>
                            Total : <div className="pull-right"><b>${((this.props.currentProject.used_seats+1)*19.5)}</b> / month</div>
                          </>
                        }
                        {((this.props.currentProject.used_seats+1) > 20 && (this.props.currentProject.used_seats+1) < 51)&&
                          <>
                            Total : <div className="pull-right"><b>${((this.props.currentProject.used_seats+1)*18)}</b> / month</div>
                          </>
                        }
                        {((this.props.currentProject.used_seats+1) > 50)&&
                          <>
                            Total : <div className="pull-right"><b>${((this.props.currentProject.used_seats+1)*16)}</b> / month</div>
                          </>
                        }
                      </div>
                    }


                  {(this.props.currentProject.used_seats == 0) &&
                    <>
                      <div className="line no-padding-left">
                        Price : <div className="pull-right"><b>$25</b> / month</div>
                      </div>
                      <div className="line">
                        Total : <div className="pull-right"><b>$19.5 now</b></div>
                      </div>
                    </>
                  }

                  <p>
                    <br />
                  </p>

                  <p className="taxes">
                    Additional taxes may apply depending on your country
                  </p>
                </div>

                <div className="footer">
                  <Button variant="secondary" onClick={this.handleCloseAddAccountModal}>
                    Cancel
                  </Button>

                  <NavLink to={`/dashboard/${this.props.match.params.project_id}/account/connect`} className="primary btn" activeClassName="active">
                    {(this.props.currentProject.used_seats == 0) &&
                      <>
                        I confirm that my subscription will start
                      </>
                    }
                    {(this.props.currentProject.used_seats > 0) &&
                      <>
                        I confirm that my subscription will increase
                      </>
                    }
                  </NavLink>
                </div>
              </>
            }

          </Modal.Body>
        </Modal>
			</>
		)
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
    currentPricing: state.currentPricing,
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectBtn));
