import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {ToastContainer, toast} from 'react-toastify';
import Loader from 'react-loader-spinner';
import Tooltip from '@material-ui/core/Tooltip';

import i18n from "../../../../i18n";

import AccountService from '../../../../services/account.service';
import TemplateService from '../../../../services/template.service';


class DashboardTemplatesSettings extends React.Component {
	constructor(props) {
		super(props);
    this.state = {errors: [], currentTemplate: {}};
		this.onChange = this.onChange.bind(this);
		this.updateTemplate = this.updateTemplate.bind(this);
	}

	componentWillMount() {
    TemplateService.get(this.props.match.params.template_id).then(response => {
			let selectedAccounts = response.body.accounts.map(a => a.id);
			this.setState({currentTemplate: response.body, selectedAccounts, loading: false})
    }).catch((error) => {
     this.setState({error: true,});
     toast.error('Oops, we have an error');
   });
	}


	updateTemplate(e){
		e.preventDefault();
		TemplateService.update(this.state.currentTemplate).then((response) => {
			toast.success('Folder updated');
		})
		.catch((error) => {
			toast.error('Oops, we have an issue, try again');
		});
	}

	onChange(event) {
		let currentTemplate = this.state.currentTemplate;
		currentTemplate.name = event.target.value;
		return this.setState({currentTemplate});
	}


	render() {
		return (
			<div className="app container home spamtest">
			  <div className="title">
				  <h1 className="pull-left">Settings</h1>
				  <div className="clearfix"></div>
			  </div>
				<div className="row accounts templates">
					<div className="card">
						<form onSubmit={this.updateTemplate}>
							<div className="form-group">
								<label>Folder name</label>
								<input type="text" className='form-control' value={this.state.currentTemplate.name} onChange={this.onChange} placeholder="Template name"/>
							</div>
							<div className="text-center">
							  <input disabled={!this.state.currentTemplate.name} className="primary btn" type="submit" name="commit" value="Save" />
              </div>
						</form>
					</div>
				</div>
      </div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		currentProject: state.currentProject
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardTemplatesSettings));
