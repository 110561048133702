const request = require('superagent');

// Todo, rename as test folder
class SpamtestAutoFixTaskService {

	static create(spamtest_initial_test_public_id) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}spamtest_auto_fix_tasks`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.send({spamtest_initial_test_public_id})
	}

	static get(id) {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}spamtest_auto_fix_tasks/${id}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}
}

export default SpamtestAutoFixTaskService;
